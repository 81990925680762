import React, { useEffect,useState } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader, Button ,Modal as MD, ModalHeader, ModalBody, ModalFooter,Label,Input} from 'reactstrap';
import PropTypes from 'prop-types';

import Loader from "../../../components/common/Loader"
import {connect} from 'react-redux'
import  {getallCompanies, contactdeleted} from "../../../Redux/Actions/USER/user"
import Modal from '../../../components/Modal/Modal'
import {Link}from "react-router-dom"
import WidgetsSectionTitle from '../../../WidgetsSectionTitle/WidgetSectionTitle';
import CreateCompany from '../Number/createCompany';
import EditCompany from './editCompany';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
const GetCompany = ({getallCompanies, contactdeleted, companies}) => {
    const [delModal, setDelModal] = useState(false);
    const [delID, setDelID] = useState("")
 const [delTitle, setDelTitle] = useState("")
 const [editModal, seteditModal] = useState(false);
 const [editID, seteditID] = useState("")
const [editTitle, seteditTitle] = useState("")
    useEffect(()=>{
        getallCompanies()
    }, [])
    const deleteCompany = (id)=>{
       contactdeleted(id)
    }
    const deleteMe=(title,id)=>{

        setDelID(id);
      setDelTitle(title);
      setDelModal(true)
    
    
    }
    const delToggle = () => {
    
    setDelModal(!delModal)
    
    };
    
    const removeMeConfirm = () => {
      
        deleteCompany(delID)
      setDelModal(false)   
    };

    const editMe=(title,id)=>{

        seteditID(id);
      seteditTitle(title);
      seteditModal(true)
    
    
    }
    const editToggle = () => {
    
    seteditModal(!editModal)
    
    };

   if(companies === null)
   {
       return <Loader></Loader>
   } 
   console.log({companies})
  return (
      <Row  className="justify-content-center">
          <Col xs="12">
              <WidgetsSectionTitle
              className="my-2"
              title="Companies"
              subtitle="You can have multiple companies under one account"
              icon="user"

              />
              </Col> 
            <Col xs="12">
            
            <Card>
               
                <CardBody>
                <Row className="pb-2">
                      
                      <Col md="12" xs="12" className="pt-2">
                      <Modal buttonLabel="Company"></Modal>
                      
                      </Col>
             </Row>
                    <Table responsive bordered striped hover >
                        <thead >
                            <tr>
                            <th>#</th>
                           
                            <th>Name</th>
                            <th>Active Numbers</th>
                            <th>Edit</th>
                            <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {companies.length > 0 && companies.map((item, index)=> (
                                <tr key={item._id}>
                                    <th scope="row">{index +1}</th>
                                    
                                    <td>{item.name}</td>
                                    <td>{item.activenumber ? item.activenumber : 0 }</td>
                                    <td><Tooltipbutton title="Click here to edit company" ><div className="fa fa-edit" onClick={()=>editMe(item.name,item._id)}></div></Tooltipbutton></td>
                                    <td><Tooltipbutton title="Click here to delete company" ><i className="is-isolated fas fa-trash" onClick={()=>deleteMe(item.name,item._id)}></i></Tooltipbutton></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {delModal?
                        <MD isOpen toggle={delToggle}>
            <ModalHeader toggle={delToggle} style={{backgroundColor:"#becdda8f"}}><h3 style={{fontWeight:"bold"}}>Remove - {delTitle}</h3></ModalHeader>
            <ModalBody>Are you sure you want to remove this? This action cannot be undone.
          </ModalBody>
        <ModalFooter>
                     <Button className="mr-2" variant="contained" color="danger" onClick={() =>delToggle()}>No</Button>
                  <Button variant="contained" style={{backgroundColor:"#28a745",color:"white"}} onClick={() =>removeMeConfirm()}>Yes</Button>
          
        </ModalFooter>
            </MD>
            :null}
            
                </CardBody>
            </Card>
            {editModal?
                        <MD isOpen toggle={editToggle}>
            <ModalHeader toggle={editToggle} style={{backgroundColor:"#becdda8f"}}><h5 style={{fontWeight:"bold"}}>Edit - {editTitle}</h5></ModalHeader>
            <ModalBody><EditCompany id={editID} toggle={editToggle}/>
          </ModalBody>
        
            </MD>
            :null}
          </Col>
          
      </Row>
    );
}
const mapStateToProps = (state) => ({
    companies: state.Twilio.companies

 });
GetCompany.propTypes = {
    getallCompanies: PropTypes.func.isRequired,
    contactdeleted: PropTypes.func.isRequired 
  };
export default connect(mapStateToProps, {getallCompanies, contactdeleted})(GetCompany)
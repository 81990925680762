import React, { useEffect, useState } from 'react';
import { Grid, List, ListItem, ListItemText, makeStyles, TextField } from '@material-ui/core';
import { getLeadStage, exportcampprosdata } from '../../../Redux/Actions/Mask/Mask';
import { Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Input } from 'reactstrap';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const styles = makeStyles({
  navdisplay: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  link: {
    textDecoration: 'none',
    textTransform: 'uppercasse',
    color: 'white'
  }
});
const Header = ({ data, socket, getLeadStage, leads, trash, exportcampprosdata }) => {
  const classess = useStyles();

  const classes = styles();
  const [dd, setdd] = useState(null);
  const [trashs, settrash] = useState([]);
  const [search, setsearch] = useState('');
  let { campid } = useParams();

  const onChange = async e => {
    setsearch(e.target.value);
    console.log('result', campid);
    if (e.target.value == '') {
      socket.emit('searchmessages', localStorage.getItem('email'), campid);
    } else {
      socket.emit('searchmessages', localStorage.getItem('email'), e.target.value, campid);
    }
  };
  useEffect(() => {
    setdd(data);
    settrash(trash);
    getLeadStage();
  }, [data, trash]);
  const deletemessages = tt => {
    socket.emit('delete', localStorage.getItem('email'), tt, data._id);
    settrash([]);
  };
  const exportdata = () => {
    console.log(campid);
    exportcampprosdata(search, campid);
  };
  return (
    <Grid container>
      <Grid item xs={6}>
        {/* <FormControl variant="outlined" className={classess.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            onChange={e => onChange(e)}
          >
            <MenuItem value={''}>unread</MenuItem>
            {leads.length > 0 &&
              leads.map((lead, index) => {
                return <MenuItem value={lead._id}>{lead.lead_Stage}</MenuItem>;
              })}
          </Select>
        </FormControl> */}
        <Input type="select" onChange={e => onChange(e)}>
          <option value={''}>Unread</option>
          {leads.length > 0 &&
            leads.map((lead, index) => {
              return (
                <option value={lead._id} key={index}>
                  {lead.lead_Stage}
                </option>
              );
            })}
        </Input>
      </Grid>

      <Grid item xs={2}>
        <List className={classes.navdisplay}>
          {/* <ListItem >
                        {dd != null ?<ListItemText primary={<p>{data.sender}</p>}></ListItemText> : null}
                    </ListItem> */}
          <ListItem button>
            {trashs.length > 0 ? <ListItemText primary={<Delete onClick={() => deletemessages(trashs)} />} /> : null}
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={4}>
        <div onClick={exportdata} className="btn btn-primary btn-sm">
          Export
        </div>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = state => ({
  leads: state.bulk.leads
});
export default connect(
  mapStateToProps,
  { getLeadStage, exportcampprosdata }
)(Header);

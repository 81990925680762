import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import {Input} from "reactstrap"
import  {getallCompanies,  } from "../../Redux/Actions/USER/user"
import {getnumbers} from "../../Redux/Actions/Twilio"
import { useHistory} from 'react-router-dom';
const GetCompany = ({getallCompanies, getnumbers, companies}) => {
     const [company, setComp] = useState("")
    //  const navigate = useNavigate();
    const history = useHistory();
    useEffect(()=>{
        const comp = localStorage.getItem("comp")
        // console.log("comp is",comp)
        if(comp)
        {
            setComp(comp)
            getnumbers(comp)
        }
        getallCompanies()
    }, [getallCompanies, getnumbers])
    useEffect(()=>{
        const comp = localStorage.getItem("comp")
        
            getnumbers(comp)
        
    }, [companies])
    const setCompany = (e)=> {
        if(e.target.value=="createcompany")
        {history.push('/getcompany');}
        else{
        localStorage.setItem("comp", e.target.value)
        setComp(e.target.value)
        getnumbers(e.target.value)
    }}
    
    return <Input style={{ fontSize: "0.95em"}} type="select" bsSize="sm"onChange= {setCompany}>
        {companies&&companies!=""&&localStorage.setItem("comp", companies[0]._id)&&console.log("compis",companies[0]._id)}
        {companies && companies.map(item=>(
            <option selected={item._id === company} key={item._id} value={item._id}>{item.name}</option>
        ))}
        {companies==""&&(<option value="">Select Company</option>)}
        {companies==""&&<option value="createcompany">Create Company</option>}
    </Input>
}
const mapStateToProps = (state) => ({
    companies: state.Twilio.companies

 });
export default connect(mapStateToProps, {getallCompanies, getnumbers})(GetCompany)
import React, { useEffect,useState } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter,Label } from 'reactstrap';
import PropTypes from 'prop-types';
import FundActivity from "../Fund/Fund"
import Loader from "../../../components/common/Loader"
import {connect} from 'react-redux'
import  {getcards, deletecard} from "../../../Redux/Actions/Payment"
import {Link}from "react-router-dom"
import Billingaddress from './billingaddress';
import Invoices from './invoices';
import EditCard from './editcard';
import WidgetsSectionTitle from '../../../WidgetsSectionTitle/WidgetSectionTitle';
// import PurchasesTable from './PurchasesTable';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import Checkout from './Checkout';
import "./style.css"


const PublicCard = ({getcards, deletecard, cards}) => {
    useEffect(()=>{
        getcards()
    }, [getcards])
    const [delModal, setDelModal] = useState(false);
    const [delID, setDelID] = useState("")
 const [delTitle, setDelTitle] = useState("")
 const [editModal, seteditModal] = useState(false);
 const [editID, seteditID] = useState("")
 const [newmodal, setnewmodal] = useState(false);

 const Toggle=()=>{
     setnewmodal(!newmodal)
 }

 const editMe=(id)=>{

    seteditID(id);
  
  seteditModal(true)


}
const editToggle = () => {

    seteditModal(!editModal)
    
    };
 const deleteMe=(title,id)=>{

    setDelID(id);
  setDelTitle(title);
  setDelModal(true)


}

const delToggle = () => {

setDelModal(!delModal)

};

const removeMeConfirm = () => {
  
    deletecard(delID)
  setDelModal(false)   
};
    // const deleteCompany = (id)=>{
    //    deletecard(id)
    // }
   console.log({cards})
   if(cards === null)
   {
       return <Loader></Loader>
   } 
  
  return (
      <div>
      <Row className="justify-content-center">
          
      <Col xs="12" className="mt-2 p-0">
            <WidgetsSectionTitle
                  title="Invoices"
                  icon="list"
                  subtitle="Invoices"
                  />
            <Invoices/>
            </Col>
          <Col md={12} >
              <Row >
                  <WidgetsSectionTitle
                  title="Card Details"
                  icon="list"
                  subtitle="card details"
                  />
            <Card style={{width:"100%"}}>
                {/* <Label>Card Details</Label> */}
                
                <CardBody style={{height:"375px",overflow: 'auto'}}>
                    <Row>
                        <Col xs="12">
                        <Button onClick={()=>Toggle()} color="primary" size="sm">Add Card</Button>
                        </Col>
                        <Col xs="12" className="mt-2">
                        <Table responsive bordered striped hover >
                        <thead >
                            <tr>
                            <th>#</th>
                            
                            <th>Card Number</th>
                            <th>Card Brand</th>
                            <th>Month</th>
                            <th>Edit</th>
                            <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cards.length > 0 && cards.map((item, index)=> (
                                <tr key={item._id}>
                                    <th scope="row">{index +1}</th>
                                    
                                    <td>{item.card_last4}</td>
                                    <td>{item.card_brand }</td>
                                    <td>{item.card_exp_month}/{item.card_exp_year}</td>
                                    <td><Tooltipbutton title="Edit card details"><i className="is-isolated fas fa-edit" onClick={()=>editMe(item._id)}></i></Tooltipbutton></td>
                                    <td><Tooltipbutton title="Delete card"><i className="is-isolated fas fa-trash" onClick={()=>deleteMe(item._id,item.card_id)}></i></Tooltipbutton></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                        </Col>
                    </Row>
                    {
                        newmodal?<Modal isOpen toggle={Toggle}>
                            <Checkout check={true} toggle={Toggle}/>
                        </Modal>:null
                    }
                    {delModal?
                        <Modal isOpen toggle={delToggle}>
                        <ModalHeader toggle={delToggle} style={{backgroundColor:"#becdda8f"}}><h5 style={{fontWeight:"bold"}}>Remove - {delTitle}</h5></ModalHeader>
                        <ModalBody>Are you sure you want to remove this? This action cannot be undone.
                    </ModalBody>
                    <ModalFooter>
                            <Button variant="contained"  color="danger" onClick={() =>delToggle()}>No</Button>
                            <Button variant="contained"color="success"  onClick={() =>removeMeConfirm()}>Yes</Button>
                    
                    </ModalFooter>
                        </Modal>
                        :null}
                        {editModal?
                        <Modal isOpen toggle={editToggle}>
                        <ModalHeader toggle={editToggle} style={{backgroundColor:"#becdda8f"}}><h5 style={{fontWeight:"bold"}}>Edit Card</h5></ModalHeader>
                        <ModalBody>
                            <EditCard id={editID} toggle={editToggle}/>
                    </ModalBody>
                    {/* <ModalFooter>
                            <Button variant="contained"  color="danger" onClick={() =>editToggle()}>No</Button>
                            <Button variant="contained"color="success"  onClick={() =>removeMeConfirm()}>Yes</Button>
                    
                    </ModalFooter> */}
                        </Modal>
                        :null}
                   
                </CardBody>
            </Card></Row>
            {/* <Row>
                <Col>
            <WidgetsSectionTitle
                  title="Wallet"
                  icon="user"
                  subtitle="wallet"
                  />
               <FundActivity/></Col>
            </Row> */}
           
          </Col>
          {/* <Col md="5">
          <WidgetsSectionTitle
                  title="Billing Address"
                  icon="plus"
                  subtitle="Billing Address"
                  />
              <Billingaddress/>
          </Col> */}
        
      </Row>
     
      {/* <Row>
      <Col md={6}>
      <WidgetsSectionTitle
                  title="Wallet"
                  icon="folder-plus"
                  subtitle="wallet"
                  />
            <FundActivity/>
          </Col>
          <Col>
         
          </Col>
      </Row> */}
      </div>
    );
}
const mapStateToProps = (state) => ({
    cards: state.payment.cards

 });
PublicCard.propTypes = {
    getcards: PropTypes.func.isRequired,
    deletecard: PropTypes.func.isRequired ,
  };
export default connect(mapStateToProps, {getcards, deletecard})(PublicCard)
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';
import { Row, Col, CardBody, CardHeader, Navbar, Nav, NavItem, NavLink, Card,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Popover, PopoverHeader, PopoverBody, Input } from 'reactstrap';

import Loader from '../../../components/common/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from 'reactstrap/lib/Button'; 
import { connect } from 'react-redux';
import { sendmessage } from '../../../Redux/Actions/Twilio';
import { crmdata } from '../../../Redux/Actions/USER/user';
import { makeStyles, IconButton } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { toast } from 'react-toastify';


import { getQuickReplies,createQuickReply,getQuickReply,updateQuickReply,deleteQuickReply } from '../../../Redux/Actions/Mask/Mask';
import { Send } from '@material-ui/icons';
import {socket} from '../Main/socket'
// const socket = io(process.env.REACT_APP_PORT, {
//   query: {
//     email: localStorage.getItem('email'),
//     filename:'Conversation'
//   }
// });
const styles = makeStyles({
  scroll: {
    height: '380px',
    overflowY: 'scroll'
  },

  insidelist: {
    display: 'block'
  },
  chipclass: {
    height: 'auto'
  },
  active: {
    opacity: 0.5
  },
  deactive: {
    opacity: 1
  }
});
const Conversation = ({ sendmessage, crmdata, quickreplies, getQuickReplies,createQuickReply,getQuickReply,updateQuickReply,deleteQuickReply }) => {
  const { id } = useParams();
  const [message, setmessage] = useState(null);
  const [trash, settrash] = useState([]);
  const classes = styles();
  const [send, setsendtext] = useState('');
  const [textlength,settextlength]= useState(0)

  const [open,setOpen]= useState(false)
  const [changedQRS, setchangedQRS] = useState("")


  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  console.log('id', id);
  useEffect(() => {
    socket.emit('getmessageid', localStorage.getItem('email'), id);
    getQuickReplies();
  }, []);
  socket.on('message', msg => {
    if (msg._id == id) {
      setmessage(msg);
    }
  });
  if (message == null) {
    return <Loader />;
  }
  const submit = e => {
    e.preventDefault();
    sendmessage(message.sender, send, message.number);
    setsendtext('');
    setPopoverOpen(false)
  };
  const deletemessages = tt => {
    socket.emit('delete', localStorage.getItem('email'), tt, message._id);
    settrash([]);
  };
  const crmdet = id => {
    crmdata(id);
  };
  const selectmessage = async id => {
    const found = trash.findIndex(dd => dd === id);
    if (found === -1) {
      document.getElementById(id).className = classes.active;

      let data = [...trash, id];
      settrash(data);
    } else {
      let data = [...trash];
      data.splice(found, 1);
      settrash(data);
      document.getElementById(id).className = classes.deactive;
    }
    console.log(id, trash);
  };

  const settextbody = message => {

console.log({message});
    setsendtext(message);
  };
  const editToggle = () => {

    setOpen(!open)

};
const createQRS =()=>{
  setOpen(true)
}
const handleNameChange =(e)=>{
  setchangedQRS(e.target.value)
}

const handleSubmit =()=>{
  if (changedQRS===null||changedQRS===undefined||changedQRS===""){
      toast.error("Can not submit an empty Quick Reply ")
  }else{
    console.log({changedQRS})
    createQuickReply(changedQRS)
      editToggle()
  }
}
console.log({quickreplies});
  return (
    <Row className="justify-content-center">
      <Col xs={12}>
        <Navbar color="light" light expand="md">
          <Nav className="mr-auto" navbar>
            <NavItem className="px-3">
              <NavLink href="#" style={{whiteSpace:"nowrap"}}>
                <FontAwesomeIcon icon={faUser} />
                <span > From: {message.number}</span>
              </NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink href="#" style={{whiteSpace:"nowrap"}} >
                <FontAwesomeIcon icon={faUser} />
                <span> To: {message.sender}</span>
              </NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink href="#">
                <Button onClick={() => crmdet(id)} color="primary" size="sm" style={{whiteSpace:"nowrap"}} >
                  Push to Crm
                </Button>
              </NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink href="#">
                <Button onClick={() => createQRS()} color="primary" size="sm" style={{whiteSpace:"nowrap"}}>
                  Add Quick Relpies
                </Button>
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar>
            <NavItem>
              <NavLink href="#">
                {trash.length > 0 && <FontAwesomeIcon onClick={() => deletemessages(trash)} icon={faTrash} />}
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
        <Card>
          <CardHeader />
          <CardBody>
            <Row className="justify-content-center">
              <Col md={12}>
                <div className="conversation" onClick={()=>setPopoverOpen(false)} >
                  <div className="conversation-container">
                    {message &&
                      message.messages.map(item => (
                        <div key={item._id}>
                          <div className={item.direction == 'Inbound' ? 'message received' : 'message sent'}>
                            <span style={{ fontSize:"large"}} id={item._id} onClick={() => selectmessage(item._id)}>
                              {item.body}
                            </span>
                            {/* <span className="metadata">
                                                <span className="time"></span><span className="tick"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>
                                            </span> */}
                          </div>
                        </div>
                      ))}
                  </div>
                  <form className="conversation-compose">
                    <input
                      value={send}
                      className="input-msg"
                      name="input"
                      onChange={e => {setsendtext(e.target.value) ; settextlength(e.target.value.length)}}
                      placeholder="    Type a message"
                      autoComplete="off"
                      autoFocus
                    />
                    <div className="photo">
                      <IconButton id="Popover1" type="button">
                        <div className="circle">*</div>
                      </IconButton>
                      <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                        <PopoverHeader>Quick Reply</PopoverHeader>
                        <PopoverBody>
                          {quickreplies != null &&
                            quickreplies.length > 0 &&
                            quickreplies.map(item => (
                              <Row>
                                <Col md='10'  className="btn btn-link" onClick={() => settextbody(item.message)} key={item._id}>
                              
                                {item.message}
                       
                              </Col>
                              <Col md='1'>
                              <i className="is-isolated fas fa-trash" onClick={()=>deleteQuickReply(item._id)} ></i>
                              </Col>
                              </Row>
                            ))}
                        </PopoverBody>
                      </Popover>
                      </div>
                      <span style={{float:'right',fontSize:"12px"}}>{textlength<=160?<i class="fas fa-check-circle fa-sm" style={{"color": "Green"}}></i>:null}{textlength<=320 && textlength>160?<i class="fas fa-exclamation-circle fa-sm" style={{"color": "orange"}}></i>:null}{textlength>320?<i class="fas fa-times-circle fa-sm" style={{"color": "red"}}></i>:null} {textlength}/320</span>
                   
                    
                    {/* <div className="photo">/ <i className="zmdi zmdi-camera"></i></div> */}
                    <IconButton className="send" onClick={submit}>
                        <div className="circle">
                        <Send />
                        </div>              
                    </IconButton>
                    {/* <Button variant="contained" color="primary" className={classes.button} endIcon={<Send/>}>
                      Send
                    </Button> */}
                  </form>
                </div>
              </Col>
            </Row>
            {open?
                        <Modal isOpen toggle={editToggle}>
            <ModalHeader toggle={editToggle}>Add Quick Replies</ModalHeader>
            <ModalBody>
            <input value={changedQRS} onChange={(e)=>handleNameChange(e)} placeholder="Quick Reply" ></input>
          </ModalBody>
        <ModalFooter>
                     <Button color="secondary"  onClick={() =>{handleSubmit()}}>Submit</Button>
                 
          
        </ModalFooter>
            </Modal>
            :null}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({
  quickreplies: state.bulk.quickreplies
});
export default connect(
  mapStateToProps,
  { sendmessage, crmdata, getQuickReplies,createQuickReply,getQuickReply,updateQuickReply,deleteQuickReply }
)(Conversation);

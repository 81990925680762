import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Form
} from 'reactstrap';
import { getdnclists, removednclist, addDncList, searchdnclists } from '../../../Redux/Actions/USER/user';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../components/common/Loader';

import FormGroup from 'reactstrap/lib/FormGroup';

//table
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify'
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#edf2f9',
    // color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const UserVoice = ({ getdnclists, dncdata, removednclist, dnccount, addDncList, searchdnclists }) => {
  const [offset, setoffset] = useState(0);
  const [activePage, setactivePage] = useState(0);
  const [count, setcount] = useState(0);
  const [delModal, setDelModal] = useState(false);
  const [delID, setDelID] = useState('');
  const [delPhone, setDelPhone] = useState('');
  const [addModal, setAddModal] = useState(false);
  const [number, setnumber] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getdnclists(offset);
  }, []);
  useEffect(() => {
    if (search) {
      searchdnclists(search);
    } else {
      getdnclists(0);
    }
  }, [search]);
  const markdnc = (id, phone) => {
    removednclist(id, phone);
  };
  const handlePageChange = pageNumber => {
    let off = pageNumber * 10 - 10;
    setactivePage(pageNumber);
    console.log({ pageNumber });
    console.log({ off });
    getdnclists(off);
  };
  const deleteMe = (phone, id) => {
    setDelID(id);
    setDelPhone(phone);
    setDelModal(true);
  };
  const delToggle = () => {
    setDelModal(!delModal);
  };
  const addDNCFun = () => {

    const phoneRegex = /^\+\d{10,}$/;
    if(!phoneRegex.test(number)){ 
      toast.error('Please provide a valid phone number in the format +1XXXXXXXXXX');
    
    }else{
      addDncList(number);
    addToggle();

    }
    
  };
  const removeMeConfirm = () => {
    markdnc(delID, delPhone);
    setDelModal(false);
  };
  const addToggle = () => {
    setAddModal(!addModal);
  };
  const classes = useStyles();
  if (dncdata === null) {
    return <Loader />;
  }
  return (
    <Row className="justify-content-center">
      <Col md="12" sm="12">
        <Card>
          <CardHeader className="pb-0">
            <Row>
              <Col xs={9}>
                <div
                  className="btn btn-primary btn-sm"
                  style={{ float: 'right' }}
                  color="primary"
                  onClick={addToggle}
                  style={{ fontSize: '0.85rem' }}
                >
                  Add to DNC
                </div>
              </Col>
              <Col xs="3" className="mb-2">
                <div className="search-box" style={{ float: 'Right' }}>
                  <Form>
                    <Input
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                      className="rounded-pill search-input"
                      value={search}
                      onChange={({ target }) => setSearch(target.value)}
                    />
                    <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
                  </Form>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody style={{ height: '309px', overflow: 'auto' }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell>Number</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dncdata.length > 0 &&
                  dncdata.map((item, index) => (
                    <StyledTableRow key={item._id}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{item.phone}</StyledTableCell>
                      <StyledTableCell>
                        {
                          <i
                            className="is-isolated fas fa-trash"
                            style={{ cursor: 'pointer' }}
                            onClick={() => deleteMe(item.phone, item._id)}
                          />
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </CardBody>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={dnccount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        </Card>
        {delModal ? (
          <Modal isOpen toggle={delToggle}>
            <ModalHeader toggle={delToggle} style={{ backgroundColor: '#becdda8f' }}>
              <h5 style={{ fontWeight: 'bold' }}>Remove - {delPhone}</h5>
            </ModalHeader>
            <ModalBody>Are sure, You want to remove ?</ModalBody>
            <ModalFooter>
              <Button className="mr-2" variant="contained" color="danger" onClick={() => delToggle()}>
                No
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: '#28a745', color: 'white' }}
                onClick={() => removeMeConfirm()}
              >
                Yes
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
        {addModal ? (
          <Modal isOpen toggle={addToggle}>
            <ModalHeader toggle={addToggle} style={{ backgroundColor: '#becdda8f' }}>
              Add New Number
            </ModalHeader>
            <ModalBody>
              <Label>Enter the Number</Label>
              <Input
                type="text"
                bsSize="sm"
                onChange={e => {
                  setnumber(e.target.value);
                }}
                placeholder="number"
              />
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outlined"
                onClick={() => {
                  addDNCFun();
                }}
                color="primary"
              >
                Add
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({
  dncdata: state.Twilio.dncdata,
  dnccount: state.Twilio.dnccount
});
UserVoice.propTypes = {};
export default connect(
  mapStateToProps,
  { getdnclists, removednclist, addDncList, searchdnclists }
)(UserVoice);

import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';
import GetCOmpany from '../../../components/GetCompany/getCompany';
import { getnumbers } from '../../../Redux/Actions/Twilio';
import { getflows, deleteflow } from '../../../Redux/Actions/USER/user';
import Content from './Content';
import { connect } from 'react-redux';

import Loader from '../../../components/common/Loader';

import WidgetsSectionTitle from '../../../WidgetsSectionTitle/WidgetSectionTitle';
import New_Content from './New_Content';



const CreatecallFlow =({numbers, getnumbers, flows, getflows, deleteflow})=>{
    const [from, setfrom] = useState(null);
     
    useEffect(() => {
      getflows();
      getnumbers();
    }, [getflows]);
    if(from!==null){
        return<div> <WidgetsSectionTitle
        className="my-2"
          title="Call Flow"
          icon="folder-plus"
          subtitle="Call Flow"
          />
          {/* <Content number={from} /> */}
          <New_Content number={from} />
          </div>;
    }else{
    return (
        <div>
          <WidgetsSectionTitle
          className="my-2"
            title="Call Flow"
            icon="folder-plus"
            subtitle="Call Flow"
            />
          <Card>
            <CardHeader>
              <CardContent>Call Flows</CardContent>
            </CardHeader>
            <Row>
              <Col md="12">
                <CardContent>
                  <Label>Company</Label>
                  <GetCOmpany />
                </CardContent>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <CardContent>
                  <Label>Number</Label>
                  <Input
                    style={{ fontSize: '0.95em' }}
                    type="select"
                    id="exampleSelect"
                    onChange={e => setfrom(e.target.value)}
                    name="from"
                  >
                    <option value="">Select Number</option>
                    {numbers.map(item => (
                      <option key={item._id} selected={item.number == from} value={item._id}>
                        {item.name && item.number? item.name +" ("+item.number+")" : item.number}
                      </option>
                    ))}
                  </Input>
                </CardContent>
              </Col>
            </Row>
          </Card>
        </div>
      );}
}
const mapStateToProps = state => ({
    numbers: state.Twilio.numbers,
    flows: state.Twilio.getflows
  });
  export default connect(
    mapStateToProps,
    { getflows, getnumbers, deleteflow }
  )(CreatecallFlow);
import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { getallfax, faxSearch } from '../../../Redux/Actions/USER/user';
const UserMessage = ({ getallfax, messages, searchData, faxSearch }) => {
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState('');
  const [body, setBody] = useState(null);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    getallfax();
  }, []);
  useEffect(() => {
    if (search) {
      faxSearch(search);
    } else {
      getallfax();
    }
  }, [search]);
  if (messages === null) {
    return <Loader />;
  }
  return (
    <Row className="justify-content-center">
      <Col xs="12" sm="12">
        <Card>
          <CardHeader>
            <CardTitle style={{ fontSize: '0.95em' }}>FAX</CardTitle>
            <CardTitle>
              <input type="text" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Table responsive bordered striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Media Url</th>
                  <th>Status</th>
                  <th>Direction</th>
                  <th>Charge</th>
                  <th>Quality</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {messages.length > 0 &&
                  messages.map((item, index) => (
                    <tr key={item._id}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.from}</td>
                      <td>{item.to}</td>
                      <td
                        onClick={() => {
                          setBody(item.body);
                          setModal(true);
                        }}
                      >
                        <div className="btn  btn-primary btn-sm">Show Content</div>
                      </td>
                      <td>{item.status}</td>
                      <td>
                        <div className="btn btn-success btn-sm">{item.direction}</div>
                      </td>
                      <td>{item.charges != undefined ? `$${item.charges}` : 0}</td>
                      <td>{item.quality}</td>
                      <td>
                        {
                          <Row>
                            <Col md={12}>{item.createdAt.slice(0, 10)},</Col>
                            <Col md={12}>{item.createdAt.slice(11, 19)}</Col>
                          </Row>
                        }
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Media URL</ModalHeader>
              <ModalBody>{body}</ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({
  messages: state.Twilio.fax,
  searchData: state.Twilio.searchData
});
UserMessage.propTypes = {
  getallfax: PropTypes.func.isRequired
};
export default connect(
  mapStateToProps,
  { getallfax, faxSearch }
)(UserMessage);

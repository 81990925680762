import React, { useEffect, useState } from 'react';
import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { getCategories,deleteCategory, createCategory,updateCategory,getCategory } from '../../../Redux/Actions/Mask/Mask';
import classNames from 'classnames';
import {
    Card,
    CardBody,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form, Input,
    Modal, ModalHeader,ModalFooter, ModalBody,
    Row, Col, Button } from "reactstrap"
    import ButtonIcon from '../../../components/common/ButtonIcon';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { toast } from 'react-toastify';

const Category = ({ getCategories, categories,category, deleteCategory, createCategory,updateCategory,getCategory }) => {
  const [id, setId] = useState(null)
  const [name, setName] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [open, setModal] = useState(false)
  
  const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);
  const toggle = () =>{ 
   
    setModal(!open)};
  useEffect(() => {
    if(category)
    {   
        setName(category.name)
    }
  }, [category]);
 
  useEffect(() => {
    getCategories();
  }, [name]);
  const save  = () => {
       
    let catdata={"name":name}
    updateCategory(catdata,id);
    setName("")
    setModal(false)

}

  const deletelead =async(id)=>{
    deleteCategory(id)
      
  }



  const handleSubmit = async evt => {
    evt.preventDefault();
    if (name){
    let tempdata={"name":name}
    createCategory(tempdata);
    setShowForm(false)
      getCategories();
      setName("")}else{
        toast.error("Name are required!")
      }
  }
    
  console.log("openis",open)  
 
  if (categories == null) {
    return <Loader />;
  } else {
    return (
         <div className={classNames('kanban-column', { 'form-added':  false })}>
               <div className="kanban-column-header">
                    <h5 className="text-serif fs-0 mb-0">
                        {`Categories`} <span className="text-500">({categories.length})</span>
                    </h5>
                    <Button
                        color="falcon-primary"
                        size="sm"
                        id="info-tooltip"
                        onClick={() => setIsOpenThreadInfo(prevState => !prevState)}
                                                >
                            <FontAwesomeIcon icon="info" />
                            <UncontrolledTooltip placement="left" target="info-tooltip" innerClassName="fs--1">
                            Add the Message category for campaign
                            </UncontrolledTooltip>
                    </Button>
                </div>
          <div
            className="kanban-items-container scrollbar">
                { categories && categories.map(item=>(
                    <div key={item._id}
                    className="kanban-item"
                    >
                        <Card
                        className="kanban-item-card hover-actions-trigger"
                        onClick={() => {
                            setId(item._id)
                            setModal(true);
                            getCategory(item._id)
                          }}
                        >  
                            <CardBody>
                                
                                <p
                                    className="mb-0 font-weight-medium text-sans-serif"
                                >{item.name}</p>
                                
                                    
                                <UncontrolledDropdown
                                className="position-absolute text-sans-serif t-0 r-0 mt-card mr-card hover-actions"
                                onClick={e => {
                                e.stopPropagation();
                                }}
                            >
                                <DropdownToggle color="falcon-default" size="sm" className="py-0 px-2">
                                <FontAwesomeIcon icon="ellipsis-h" />
                                </DropdownToggle>
                                <DropdownMenu right className="py-0">
                                <DropdownItem onClick={()=>deletelead(item._id)} className="text-danger">Remove</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            </CardBody>
                        </Card>
                    </div>
                ))}
            
             
        </div>
        <div className="kanban-column mr-3">
      {showForm ? (
        <div className="bg-100 p-card rounded-soft transition-none">
          <Form onSubmit={e => handleSubmit(e)}>
            <Input
              type="text"
              placeholder="Enter name"
              className="mb-2 add-list"
              value={name}
              autoFocus
              onChange={({ target }) => {
                setName(target.value);
              }}
            />
            <Row form>
              <Col>
                <Button color="primary" size="sm" block type="submit">
                  Add
                </Button>
              </Col>
              <Col>
                <Button
                  color="outline-secondary"
                  size="sm"
                  block
                  className="border-400"
                  onClick={() => {
                    setShowForm(false);
                    setName('');
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <ButtonIcon
          className="bg-400 border-400"
          color="primary"
          block
          icon="plus"
          iconClassName="mr-1"
          onClick={() => {
            setShowForm(true);
            setName("")
          }}
        >
          Add Category
        </ButtonIcon>
      )}
    </div>
    {open && <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update Category</ModalHeader>
        <ModalBody>
            {category ? <Row  className="justify-content-center">
              <Col md="12">
                   <Row>
                        <Col md="12">
                            <Input placeholder="Category Name" onChange={(e)=>{setName(e.target.value)}} Label="Category Name" type="text"  name="templatename" value={name}></Input>
                           
                        </Col>
                    </Row>
                    
                    
                    
              </Col>
          </Row> : <Loader/>}
        
        </ModalBody>
        <ModalFooter>
        <Button variant="outlined"  onClick={save} color="primary">Update</Button>
                        
        </ModalFooter>
      </Modal>}
        </div>
    );
  }
};
const mapeState = state => ({
  categories: state.bulk.categories,
  
  category: state.bulk.category
});
export default connect(
  mapeState,
  { getCategories,deleteCategory,createCategory,updateCategory,getCategory }
)(Category);

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import { Button, Tooltip } from 'reactstrap';
import DialBox from '../components/navbar/DialModal';
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import PrivateRoute from './PrivateRoute';
import loadable from '@loadable/component';
import DialerPopup from '../view/pages/Home/DialerPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
// import PrivacyPolicy from './PrivacyPolicy';
// import {Device} from 'twilio-client';
// import Hme from "../view/pages/Home/Home"
// import { useHistory } from 'react-router-dom';
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const Landing = loadable(() => import('../components/landing/Landing'));
const WizardLayout = loadable(() => import('../components/auth/wizard/WizardLayout'));
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));
const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));

const WelcomeModal = loadable(() => import('../components/dashboard/WelcomeModel'));

const Layout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
    Landing.preload();
    WizardLayout.preload();
    AuthCardRoutes.preload();
    AuthSplitRoutes.preload();
  }, []);
  // const history = useHistory()
  const [collapsed, setCollapsed] = useState(true);
  const [popDialog, setpopDialog] = useState(false);
  const [outPopDialog, setOutPopDialog] = useState(false);
  // const [open, setopen] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [outdialog, setOutDialog] = useState(false);
  const [isDialerOpen, setIsDialerOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const openDialog = () => {
    setDialog(true);
  };
  const openOutDialog = () => {
    setOutDialog(true);
  };
  console.log('inside layout');
  // const  handelOpen= () => {
  //   console.log("hit in layout")
  //   history.push({pathname: "/call", state: {popp:true}})
  //   setCollapsed(!collapsed)
  //  }
  //  console.log("history",history)
  const setDialogs = () => {
    setDialog(false);
  };
  const setpopDialogs = data => {
    // console.log("data ",data)
    setpopDialog(data);
  };

  const setoutPopDialogs = data => {
    // console.log("data ",data)
    setOutPopDialog(data);
  };

  const dialerToggle = () => {
    console.log('dialerToggel', !isDialerOpen);
    setIsDialerOpen(!isDialerOpen);
  };
  //  Device.on('connect', function(connection) {
  //       setopen(true)
  //       // console.log("data inlayout")
  //     });
  //     Device.on('disconnect', function() {
  //       setopen(false)
  //     });
  return (
    <Router fallback={<span />}>
      {popDialog ? (
        <Button
          style={{
            backgroundColor: '#0bf957',
            position: 'fixed',
            top: '12vh',
            right: '4vw',
            zIndex: 1,
            borderRadius: '48%'
          }}
          onClick={openDialog}
        >
          <img src="/images/icons/call.png" />
        </Button>
      ) : null}
      {outPopDialog ? (
        <>
          <Button
            id="callButton"
            style={{
              backgroundColor: '#0bf957',
              position: 'fixed',
              top: '12vh',
              right: '8vw',
              zIndex: 1000,
              borderRadius: '50%', // Use '50%' for perfect circle
              padding: '0.75rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0px 0px 12px 4px rgba(0,255,0,0.5)',
              transition: 'transform 0.2s ease-in-out'
            }}
            onClick={dialerToggle}
            onMouseEnter={toggleTooltip}
            onMouseLeave={toggleTooltip}
          >
            <FontAwesomeIcon icon={faPhoneAlt} color="white" size="lg" />
          </Button>
          <Tooltip placement="left" isOpen={tooltipOpen} target="callButton" toggle={toggleTooltip}>
            Active Call
          </Tooltip>
        </>
      ) : null}
      {/* {popDialog ? <Button style={{backgroundColor: '#0bf957',position:"fixed",top:"12vh",right:"4vw",zIndex:1}} onClick={openDialog}><img src="/images/icons/call.png" /></Button>: <Button style={{backgroundColor: '#0bf957',position:"fixed",top:"12vh",right:"4vw",zIndex:1, borderRadius: '48%'}} onClick={openDialog}><img src="/images/icons/call.png" /></Button>} */}
      {<DialBox dialog={dialog} setpopDialogs={setpopDialogs} setDialogs={setDialogs} />}
      {/* {open ? <Button style={{backgroundColor: '#0bf957',position:"fixed",top:"12vh",right:"4vw",zIndex:1}} onClick={handelOpen}><img src="/images/icons/call.png" /></Button>: null} */}
      {/* <Button style={{backgroundColor: '#0bf957',position:"fixed",top:"12vh",right:"4vw",zIndex:1}} onClick={handelOpen}><img src="/images/icons/call.png" /></Button> */}
      {/* {<Hme></Hme>} */}
      <DialerPopup
        outdialog={outdialog}
        setoutPopDialogs={setoutPopDialogs}
        isDialerOpen={isDialerOpen}
        setIsDialerOpen={setIsDialerOpen}
      />
      <Switch>
        <Route path="/authentication" component={AuthCardRoutes} />
        {/* <Route path="/authentication/split" component={AuthSplitRoutes} /> */}
        <Route path="/authentication/wizard" component={WizardLayout} />
        <Route path="/welcome" component={WelcomeModal} />
        <Route path="/errors" component={ErrorLayout} />
        {/* <Route path="/authentication/basic" component={AuthBasicLayout} /> */}
        <Route component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;

import React from 'react';
import { connect } from 'react-redux';
import {Button } from 'reactstrap';
import { getuserform, saveformdata } from '../../../Redux/Actions/USER/user';

import { ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
// import {Button} from "@material-ui/core"
import './Dial.css';

class FormBuild extends React.Component {
  componentDidMount() {
    this.props.getuserform();
  }
  State = { hover: false };
  // onChange = e => {
  //   console.log(e.target,"e.target")
  //   this.setState({ [e.target.name]: e.target.value });
  // };
  onsumbit = data => {
    // console.log({state:data})
    const formdata = this.props.userForm.filter(e => {
      let key = Object.keys(e);

      let lable = key.filter(a => {
        if (a === 'label') {
          return a;
        }
      });

      if (lable[0] === 'label') {
        return lable[0];
      }
    });

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < formdata.length; j++) {
        if (data[i].name === formdata[j].field_name) {
          data[i].name = formdata[j].label;
        }
        if (formdata[j].options) {
          formdata[j].options.map(e => {
            
            if (e.key === data[i].value[0]) {
             
              data[i].value[0]=e.value
            }
          });
        }
      }
    }
    
    this.props.saveformdata(data,this.props.to);
    this.props.changeStatus();
  };
  checkboxchange = e => {
    const name = e.target.name;
    if (e.target.checked) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  mousein = () => {
    this.setState({ hover: true });
  };
  mouseout = () => {
    this.setState({ hover: false });
  };
  render() {
    const { userForm } = this.props;
     if (userForm == null || userForm.length === 0) {
      return <h4>Please Create Form <br /><Button size="small"
      color="success" onClick={() => window.open("https://mergecall.com/form", '_blank', 'noopener,noreferrer')}>
      Click here
    </Button></h4>;
    }
    console.log('user form', userForm);
    if (this.props.multi) {
      // return (
      //   <div>
      //   <Row>
      //     {userForm.length > 0 &&
      //       userForm.map(item => {
      //         if (item != null && item.element != undefined) {
      //           if (item.element === 'Dropdown') {
      //             return (

      //                 <Col sm="4" className="pt-1">
      //                   <Label>{item.label}</Label>
      //                   <Input key={item.id} name={item.field_name} type="select" bsSize="sm" onChange={this.onChange}>
      //                     <option>Select</option>
      //                     {item.options &&
      //                       item.options.map(item => (
      //                         <option key={item.key} value={item.value}>
      //                           {item.value}
      //                         </option>
      //                       ))}
      //                   </Input>
      //                 </Col>

      //             );
      //           }
      //           if (item.element === 'TextInput') {
      //             return (

      //                 <Col sm="4" className="pt-1">
      //                   <Label>{item.label}</Label>
      //                   <Input key={item.id} name={item.field_name} type="text" bsSize="sm" onChange={this.onChange} />
      //                 </Col>

      //             );
      //           }
      //           if (item.element === 'TextArea') {
      //             return (

      //                 <Col sm="4" className="pt-1">
      //                   <Label>{item.label}</Label>
      //                   <Input key={item.id} name={item.field_name} type="textarea" bsSize="sm" onChange={this.onChange} />
      //                 </Col>

      //             );
      //           }
      //           if (item.element === 'NumberInput') {
      //             return (

      //                 <Col sm="4" className="pt-1">
      //                   <Label>{item.label}</Label>
      //                   <Input
      //                     required={item.required}
      //                     key={item.id}
      //                     name={item.field_name}
      //                     type="number"
      //                     bsSize="sm"
      //                     onChange={this.onChange}
      //                   />
      //                 </Col>

      //             );
      //           }
      //           if (item.element === 'Checkboxes') {
      //             return (

      //                 <Col sm="2" className="pt-1">
      //                   <Label>{item.label}</Label>3
      //                   {item.options &&
      //                     item.options.map(it => (
      //                       <div>
      //                         <input
      //                           key={it.key}
      //                           required={item.required}
      //                           name={item.field_name}
      //                           type="checkbox"
      //                           value={it.value}
      //                           onChange={this.checkboxchange}
      //                         />
      //                         <label className="px-2">{it.value}</label>
      //                       </div>
      //                     ))}
      //                 </Col>

      //             );
      //           }
      //           if (item.element === 'RadioButtons') {
      //             return (

      //                 <Col sm="2" className="pt-1">
      //                   <Label>{item.label}</Label>4
      //                   {item.options &&
      //                     item.options.map(it => (
      //                       <div>
      //                         <input
      //                           key={it.key}
      //                           name={item.field_name}
      //                           type="radio"
      //                           required={item.required}
      //                           value={it.value}
      //                           onChange={this.onChange}
      //                         />
      //                         <label className="px-2">{it.value}</label>
      //                       </div>
      //                     ))}
      //                 </Col>

      //             );
      //           }
      //         }
      //       })} </Row>
      //     <Row className={'justify-content-center'}>
      //       <Col sm={{size:6,offset:5}} className="pt-1 pl-0">
      //         <Button outline color="success" onClick={this.onsumbit}>
      //           Save
      //         </Button>
      //       </Col>
      //     </Row>
      //  </div>
      // );
      return (
        <ReactFormGenerator
          data={userForm}
          // toolbarItems={items}

          onChange={() => {
            debugger;
          }}
          onSubmit={this.onsumbit}
          actionName={'Set this to change the default submit button text'}
          submitButton={
            <button type={'submit'} className={'btn  btn-primary'}>
              Submit
            </button>
          }
        />
      );
    } else if (this.props.show) {
      return (
        <div>
          <p>The Call has been disconnected, Do You want to submit the Form?</p>
          <Button color="success" onClick={(this.onsumbit, this.props.toogle)}>
            Yes
          </Button>
          <span className="px-4">
            <Button color="danger" onClick={this.props.toogle}>
              No
            </Button>
          </span>
        </div>
      );
    }
    // else{
    //   return (
    //     <div>
    //     <Row>
    //       {userForm.length > 0 &&
    //         userForm.map(item => {
    //           if (item != null && item.element != undefined) {
    //             if (item.element === 'Dropdown') {
    //               return (

    //                   <Col sm="6" className="pt-1">
    //                     <Label>{item.label}</Label>
    //                     <Input key={item.id} name={item.field_name} type="select" bsSize="sm" onChange={this.onChange}>
    //                       <option>Select</option>
    //                       {item.options &&
    //                         item.options.map(item => (
    //                           <option key={item.key} value={item.value}>
    //                             {item.value}
    //                           </option>
    //                         ))}
    //                     </Input>
    //                   </Col>

    //               );
    //             }
    //             if (item.element === 'TextInput') {
    //               return (

    //                   <Col sm="6" className="pt-1">
    //                     <Label>{item.label}</Label>
    //                     <Input key={item.id} name={item.field_name} type="text" bsSize="sm" onChange={this.onChange} />
    //                   </Col>

    //               );
    //             }
    //             if (item.element === 'TextArea') {
    //               return (

    //                   <Col sm="6" className="pt-1">
    //                     <Label>{item.label}</Label>
    //                     <Input key={item.id} name={item.field_name} type="textarea" bsSize="sm" onChange={this.onChange} />
    //                   </Col>

    //               );
    //             }
    //             if (item.element === 'NumberInput') {
    //               return (

    //                   <Col sm="6" className="pt-1">
    //                     <Label>{item.label}</Label>
    //                     <Input
    //                       required={item.required}
    //                       key={item.id}
    //                       name={item.field_name}
    //                       type="number"
    //                       bsSize="sm"
    //                       onChange={this.onChange}
    //                     />
    //                   </Col>

    //               );
    //             }
    //             if (item.element === 'Checkboxes') {
    //               return (

    //                   <Col sm="6" className="pt-1">
    //                     <Label>{item.label}</Label>4
    //                     {item.options &&
    //                       item.options.map(it => (
    //                         <Col >
    //                           <input
    //                             key={it.key}
    //                             required={item.required}
    //                             name={item.field_name}
    //                             type="checkbox"
    //                             value={it.value}
    //                             onChange={this.checkboxchange}
    //                           />
    //                           <label className="px-2">{it.value}</label>
    //                         </Col>
    //                       ))}
    //                   </Col>

    //               );
    //             }
    //             if (item.element === 'RadioButtons') {
    //               return (

    //                   <Col sm="6" className="pt-1">
    //                     <Label>{item.label}</Label>4
    //                     {item.options &&
    //                       item.options.map(it => (
    //                         <Col >
    //                           <input
    //                             key={it.key}
    //                             name={item.field_name}
    //                             type="radio"
    //                             required={item.required}
    //                             value={it.value}
    //                             onChange={this.onChange}
    //                           />
    //                           <label className="px-2">{it.value}</label>
    //                         </Col>
    //                       ))}
    //                   </Col>

    //               );
    //             }
    //           }
    //         })}</Row>
    //       <Row className={'justify-content-center'}>
    //         <Col md={6} className="pt-4 ml-8 ">
    //           <Tooltip title="Form data will be sent to Registered Webhook(in settings section)">
    //           <Button outline color="success" onClick={this.onsumbit}>
    //             Save
    //           </Button>
    //           </Tooltip>
    //         </Col>
    //       </Row>
    //     </div>
    //   );
    // }
    else {
      return (
        <ReactFormGenerator
          data={userForm}
          // toolbarItems={items}
          onChange={this.onChange}
          onSubmit={this.onsumbit}
          actionName={'Set this to change the default submit button text'}
          submitButton={
            <button type={'submit'} className={'btn btn-primary'}>
              Submit
            </button>
          }
        />
      );
    }
  }
}
const mapStateToProps = state => ({
  userForm: state.Twilio.userform
});
export default connect(
  mapStateToProps,
  { getuserform, saveformdata }
)(FormBuild);

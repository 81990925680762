import { Device } from '@twilio/voice-sdk';
let device;
export function initializeDevice(token) {
  device = new Device(token);
  return device;
}

export function destroyDevice() {
  console.log('Destroy called');
  if (device) {
    device.destroy();
    console.log('destroyed');
    device = null;
  }
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  getCampaign,
  autosendcamp,
  cancelauto,
  getCategories,
  getbatch,
  getTemplatesbycat,
  countProspects,
  getProspectsbycamp,
  sendPropects,
  countTempletebyCat,
  updatestatuspros
} from '../../../Redux/Actions/Mask/Mask';
import { Row, Col, Input, Card, CardBody, Progress, CardHeader, Label, Button, Badge } from 'reactstrap';

import { toast } from 'react-toastify';
import { getnumbers } from '../../../Redux/Actions/Twilio';
import { socket } from '../Main/socket';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import CreateFollowup from './createFollowup';
import Scheduler from './Scheduler';
import Masktexting from './masktexting';
import UploadData from './Uploads';
import WidgetsSectionTitle from '../../../WidgetsSectionTitle/WidgetSectionTitle';

const SendMessage = ({
  updatestatuspros,
  countProspects,
  getCampaign,
  campaign,
  autosendcamp,
  cancelauto,
  getbatch,
  bulkid,
  batchpros,
  name,
  counttemplate,
  countTempletebyCat,
  sendPropects,
  getProspectsbycamp,
  count,
  sent,
  Prospects,
  categories,
  getTemplatesbycat,
  getCategories,
  getnumbers,
  numbers,cdata
}) => {
  const [counttemp, setcounttemp] = useState(0);
  const [procount, setprocount] = useState(0);
  const [total, settotal] = useState(0);
  const [selectedcategory, setCategory] = useState('');
  const { id } = useParams();
  const [skip, setskip] = useState(0);
  const [show, setshow] = useState(false);
  const [cshow, csetshow] = useState(true);
  const [prospect, setprospect] = useState(0);
  const [result, setresult] = useState('');
  const [index, setindex] = useState(0);
  const [prospect_count, setprospect_count] = useState(0);
  const [prospect_show, setprospect_show] = useState(false);
  const [serialState, setserialState] = useState(0);

  const [from, setfrom] = useState(null);
 useEffect(()=>{
   
  socket.on('automessages', async (i, prospects, result) => {
    console.log('bhjgsgfdjfd', i, prospect, result);
    setprospect(prospects);
    setindex(i);
    setresult(result);
  });
 })
var pshow=false
  useEffect(() => {
    countProspects(id);
    getProspectsbycamp(id);
    getCategories();
    getCampaign(id);
    // getnumbers()
    // setprocount(campaign.prospectcount)
  }, [countProspects, getProspectsbycamp, getbatch, getCampaign, getCategories, getTemplatesbycat, getnumbers]);
  useEffect(() => {
    const a = numbers.map(item => {
      if (item.activate) {
        return item.number;
      }
    });
  
    setfrom(a[0]);
  }, [numbers]);
  // console.log(localStorage.getItem('cdatastate'))
  // const serialState=localStorage.getItem('cdatastate')
  const skipprospect = () => {
    let pct = procount + 1;
    let tct = counttemp + 1;
    setprocount(pct);

    if (tct <= counttemplate) {
      updatestatuspros(Prospects._id, selectedcategory, id, tct, pct, bulkid, 'skip');
      settotal(total + 1);
      setskip(skip + 1);
      setcounttemp(tct);
    } else {
      updatestatuspros(Prospects._id, selectedcategory, id, 0, pct, bulkid, 'skip');
      setprocount(0);
      settotal(total + 1);
      setcounttemp(0);
      setskip(skip + 1);
    }
  };
  // var prospect_count
     socket.addEventListener("totalpros", msg=> {
    console.log("totalpros socket", msg);
    setprospect_count(msg)
})
socket.addEventListener("prospectshow", msg=> {
  console.log("prospectshow", msg);
  localStorage.setItem('pshow', msg);
  setprospect_show(msg)
})
socket.addEventListener("cdatas", msg=> {
  console.log("cdatas", msg);
  // localStorage.setItem('pshow', msg);
  setserialState(msg)
})
socket.addEventListener("time", msg=> {
  console.log("time is ", msg);
})
useEffect(() => {
  if(prospect_show==false)
  console.log("inside useeffect",pshow)
  pshow=false
  console.log("inside useeffect after change",pshow)
}, [prospect_show])

pshow=localStorage.getItem('pshow');
  const send = (message, phone) => {
    let pct = procount + 1;
    let tct = counttemp + 1;
    console.log('tsttstst', { pct, tct, counttemp, counttemplate });

    setprocount(pct);
    if (tct < counttemplate) {
      settotal(total + 1);
      sendPropects(selectedcategory, id, tct, pct, bulkid, message, phone, from, Prospects._id, campaign.market);

      setcounttemp(tct);
    } else {
      setprocount(0);
      settotal(total + 1);

      setcounttemp(0);
      sendPropects(selectedcategory, id, 0, pct, bulkid, message, phone, from, Prospects._id, campaign.market);
    }
  };
  const autostart = () => {
    setshow(true);
    autosendcamp(selectedcategory, id, counttemp, procount, bulkid, campaign.market);
    // setshow(false)
    csetshow(false);
  };
  const autocancelservice = () => {
    cancelauto(id);
    csetshow(true);
    setshow(!show);
  };
  console.log({ result });
  console.log({ procount, count });
  console.log('prospects', Prospects);
  console.log('cdata is ', cdata);
  return (
    <div>
      {/* <>
        <nav>
          <Row>
            <Col className="pt-4" md={2} xs={6}>
              <Link to={`/uploads/${id}`}>Upload history</Link>
            </Col>
            <Col className="pt-4" md={2} xs={6}>
              <Link to={`/addprospects/${id}`}>Add Prospects</Link>
            </Col>
            <Col className="pt-4" md={2} xs={6}>
              <Modal buttonLabel={`follow_up`} />
            </Col>
            <Col className="pt-4" md={2} xs={6}>
              <Link to={`/campaignchat/${id}`}>Conversation</Link>
            </Col>
            <Col className="pt-4" md={2} xs={6}>
              <Link to={`/scheduler/${id}`}>Scheduler</Link>
            </Col>
          </Row>
        </nav>
      </> */}
      <Row>
        <Col xs="6">
          <WidgetsSectionTitle className="mt-2" title="Select Category" icon="folder-plus" subtitle="Select Category" />
          <Card>
            <Background image={corner2} className="bg-card" />
            <CardBody className="pt-1">
              <Row>
                <Col xs="6">
                  <Label>Select Category</Label>
                  <Input
                    type="select"
                    bsSize="sm"
                    onChange={e => {
                      if (campaign.batchprocess == true) {
                        toast.info('Batch is under processing');
                      } else if (e.target.value != '') {
                        setCategory(e.target.value);
                        getTemplatesbycat(e.target.value, id, counttemp, 0, bulkid);
                        countTempletebyCat(e.target.value);
                      }
                    }}
                  >
                    <option value="">Select Category</option>
                    {categories.map(item => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </Input>
                </Col>
                <Col xs="6">
                  <Label>From Number</Label>
                  <Input
                    style={{ fontSize: '0.95em' }}
                    type="select"
                    bsSize="sm"
                    id="exampleSelect"
                    onChange={e => setfrom(e.target.value)}
                    name="from"
                  >
                   {numbers != undefined&&numbers&& numbers[0] && numbers[0].hasOwnProperty("number")&&<option selected={numbers[0].number} value={numbers[0].number}>
                        {numbers[0].name ? numbers[0].name : numbers[0].number}
                      </option>}
            
            {numbers != undefined&&numbers&&numbers.slice(1).map(item => {
                      if (item.activate) {
                        return (
                  <option key={item._id} value={item.number} >
                            {item.name ? item.name : item.number}
                          </option>
                        );
                      }
                    })}
                  </Input>
                 
          <small id="emailHelp" class="form-text text-muted">
            {from != null && from != '' && `Message id will be ${from}`}
          </small>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <WidgetsSectionTitle
            title="Follow up"
            icon="percentage"
            subtitle="Select the stage and prospects that you want to reset"
          />
          <Card className="m-2 p-2">
            <Background image={corner1} className="bg-card" />
            <CreateFollowup />
          </Card>
          <WidgetsSectionTitle title="Scheduler" icon="clock" subtitle="Schedule your message for future date" />
          <div>
            <Scheduler />
          </div>
        </Col>
        <Col xs="6">
          {' '}
          {Prospects != null && (
            <div>
              <WidgetsSectionTitle className="mt-2" title="Send Message" icon="folder-plus" subtitle="Send Message" />
              <Card>
                {!show ? (
                  <div>
                    <CardHeader>
                      <Row className="justify-content-center">
                        <Col md={6} className="text-center ">
                          <h3>Send Count </h3>
                          <h3 className="text-center ml-3">
                            {sent}/{count}
                          </h3>
                          <div className="text-center">{Math.round((sent * 100) / count)}%</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Progress value={(sent * 100) / count} />
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody style={{ backgroundColor: '#f9fafd' }}>
                      <Row>
                        <Col md={12}>{name == null ? null : <h3>#Batch {name}</h3>}</Col>
                      </Row>
                      <Row>
                        <Col sm={{ size: 'auto', offset: 2 }}>
                          <Button color="success" outline>
                            Sent <Badge color="success">{sent}</Badge>
                          </Button>
                        </Col>
                        {/* <Col md={5}>
                            <Button color="primary" outline>
                                delivery rate <Badge color="success">45%</Badge>
                            </Button>
                        </Col> */}
                        <Col sm={{ size: 'auto', offset: 2 }}>
                          <Button color="danger" outline>
                            Skip <Badge color="danger">{skip}</Badge>
                          </Button>
                        </Col>
                      </Row>
                      <Row className=" pt-4 justify-content-center">
                        <Col md={6}>
                          <Row>
                            <Col md="12">
                              <span style={{ fontWeight: 'bold' }}>Name : </span>
                              {Prospects.first_name} <br /> <span style={{ fontWeight: 'bold' }}>Phone no :</span>{' '}
                              {Prospects.phone}
                            </Col>
                            <Col md={12}>
                              <p> {Prospects.property_address}</p>
                            </Col>
                            <Col md="12" className="ml-3">
                              <Row>
                                <Col className="m-0 p-0" sm="3">
                                  <span style={{ fontWeight: 'bold' }}>Body :</span>
                                </Col>

                                <Col className="m-0 p-0" sm="9">
                                  {Prospects.body}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="pt-4 ml-5">
                        <Col md={4} xs={4}>
                          <Button
                            color="primary"
                            disabled={procount >= count || show}
                            outline
                            onClick={() => send(Prospects.body, Prospects.phone)}
                          >
                            Send
                          </Button>
                        </Col>
                        <Col md={4} xs={4}>
                          <Button
                            color="danger"
                            disabled={procount >= count || show}
                            outline
                            onClick={() => skipprospect()}
                          >
                            Skip
                          </Button>
                        </Col>
                        <Col md={4} xs={4}>
                          <Button
                            color="danger"
                            disabled={procount >= count || show}
                            outline
                            onClick={() => autostart()}
                          >
                            Auto
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                ) : (
                  <div>
                    <CardHeader>
                      <Row className="justify-content-center">
                        <Col md={8}>
                          <h4>
                            Auto Send Count {index}/{prospect}
                          </h4>
                          {prospect === 0 ? (
                            <div className="text-center">0 %</div>
                          ) : (
                            <div className="text-center">{Math.round((index * 100) / prospect).toFixed(1)}%</div>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Progress value={(index * 100) / prospect} />
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody style={{ backgroundColor: '#f9fafd' }}>
                      <Row className="pt-4">
                        <Col md={6} className="pl-5">
                          <Button color="success" outline>
                            Sent <Badge color="success">{index}</Badge>
                          </Button>
                        </Col>
                        <Col md="6" className="pr-5">
                          {' '}
                          <Button style={{ float: 'right' }} color="danger" outline onClick={() => autocancelservice()}>
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                      <Row className=" pt-4 justify-content-center">
                        <Col md={6}>
                          <Row>
                            {result === null || result === '' ? null : (
                              <div>
                                <Col md="12">
                                  {(result.first_name === undefined || result.first_name === null) &&
                                    (result.first_name = '  ')}
                                  {(result.phone === undefined || result.phone === null) && (result.phone = '  ')}
                                  {result.first_name} ({result.phone})
                                </Col>
                                <Col md={12}>
                                  {(result.property_address === undefined || result.property_address === null) &&
                                    (result.property_address = '  ')}

                                  <p>{result.property_address}</p>
                                </Col>
                                <Col md="12">
                                  {(result.body === undefined || result.first_name === null) &&
                                    (result.first_name = '  ')}

                                  {result.body}
                                </Col>
                              </div>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      {/* <Row className="pt-4">
                      <Col md={4}>
                        <Button color="danger" outline onClick={() => autocancelservice()}>
                          Cancel
                        </Button>
                      </Col>
                    </Row> */}
                    </CardBody>
                  </div>
                )}
              </Card>
            </div>
          )}{console.log("serialState is ",serialState,"prospect show is ",prospect_show,"prospect_count is ",prospect_count)}
          {serialState != null && serialState!=0 &&prospect_show!=false && (
            <div>
             <WidgetsSectionTitle className="mt-2" title="Campaign upload progress" icon="plus" subtitle="For adding contacts" />
             <Card className="pl-4 py-2 ">
                     <Label className="pl-1">Live Count-{prospect_count}/{serialState}</Label>
                    <Row className="pt-1">
                        <Col md="12">
                        <div className="text-center">Progress-{Math.round((prospect_count/serialState)*100)}%</div>
                                <Progress value={(prospect_count/serialState)*100} />
                            
                        </Col>
                    </Row>
                   
                    
                </Card>
                    
            </div>
          )}
          {prospect_show!=true &&(<div><WidgetsSectionTitle className="mt-2" title="Add Prospects" icon="plus" subtitle="For adding prospects" />
          <div className="mt-2">
            <Masktexting />
          </div></div>)}
          <WidgetsSectionTitle title="Upload History" icon="list" subtitle="Upload History" />
          <div>
            <UploadData />
          </div>
        </Col>
      </Row>
    </div>
  );
};
const mapeStateToProps = state => ({
  count: state.bulk.countProspect,
  Prospects: state.bulk.prospectdata,
  categories: state.bulk.categories,
  templates: state.bulk.templates,
  sent: state.bulk.sent,
  counttemplate: state.bulk.counttemplate,
  batchpros: state.bulk.batchpros,
  name: state.bulk.name,
  bulkid: state.bulk.bulkid,
  campaign: state.bulk.campaign,
  numbers: state.Twilio.numbers,
    cdata: state.bulk.cdata
});

export default connect(
  mapeStateToProps,
  {
    updatestatuspros,
    autosendcamp,
    countProspects,
    getbatch,
    getCampaign,
    cancelauto,
    sendPropects,
    countTempletebyCat,
    getProspectsbycamp,
    getTemplatesbycat,
    getCategories,
    getnumbers
  }
)(SendMessage);

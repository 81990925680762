import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Progress, ModalHeader, ModalBody, ModalFooter,Modal, } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadfile, checkskiptracing } from '../../../Redux/Actions/Mask/Mask';
import Get_Headers from './Headers';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';
import { getallCompanies } from '../../../Redux/Actions/USER/user';
import Label from 'reactstrap/lib/Label';
import { socket } from '../Main/socket';
import { useHistory} from 'react-router-dom';
import { toast } from 'react-toastify';
const MaskTexting = ({ upload, cdata, uploadfile, getallCompanies, checkskiptracing, skiptracing }) => {
  const [data, setData] = useState('');
  const [modal, setmodal] = useState(true);
  const [count, setcount] = useState(0);
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    getallCompanies();
  }, [getallCompanies]);
  console.log('id', id);
  const submit = () => {
    console.log('this');

    uploadfile(data);
  };
  checkskiptracing();
  const Toggle = () => {
    setmodal(!modal);
  };
  const takemeback = () => {
    setmodal(!modal);
    history.push('/pages/campaigns');
  };
  const continueprospect = () => {
    toast.success('Please Select Headers');
    setmodal(!modal);
  };
  socket.addEventListener("prodata", msg=> {
    // console.log("prospectshow", msg);
    // localStorage.setItem('pshow', msg);
    setcount(msg)
  })
  //    socket.addEventListener("totalpros", msg=> {
  //     console.log("totalpros socket", msg)
  //     // setcontact(msg)
  // })
  // if(upload !== null && upload.length)
  // {
  //     return <Row  className="justify-content-center ">
  //           <Col md="10">
  //               <Card>
  //                   <Get_Headers id={id} uploadfile = {data}></Get_Headers>
  //               </Card>

  //           </Col>
  //       </Row>

  // }{console.log("upload.length0",upload.length)}
  upload !== null && upload.length&&console.log("upload.length0",upload.length)
  upload !== null && upload.length&&console.log("upload.length",upload)
  console.log('skiptracing', skiptracing);
  if (skiptracing !== null && skiptracing == false&&upload !== null && upload.length) {
    toast.success('Please Select Headers');
      return (
        <Row className="justify-content-center ">
          <Col md="10">
            <Card>
              <Get_Headers id={id} uploadfile={data} />
            </Card>
          </Col>
        </Row>
      );
    // }
  } 
  else if (skiptracing !== null && skiptracing == true&&upload !== null && upload.length) {
    
      return (<>
        <Modal isOpen={modal} toggle={Toggle}>
                <ModalHeader toggle={Toggle} style={{ backgroundColor: '#becdda8f' }}>
                </ModalHeader>
                <ModalBody>Skip tracing is enabled. You will be charged 0.0036$ per contact,
                    Total charges: {(count*0.0036).toFixed(3)}$.</ModalBody>
                <ModalFooter>
                  <Button className="mr-2" variant="contained" color="secondary" onClick={() => takemeback()}>
                  Take me back
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#28a745', color: 'white' }}
                    onClick={() => continueprospect()}
                  >
                     Continue
                  </Button>
                </ModalFooter>
              </Modal>
              <Row className="justify-content-center ">
              <Col md="10">
                <Card>
                  <Get_Headers id={id} uploadfile={data} />
                </Card>
              </Col>
            </Row></>
      );
    // }
  }
  else {
    return (
      <>
        {' '}
        <Row className="justify-content-center ">
          <Col md="12">
            <Card className="pl-4 py-2 ">
              <Label className="pl-1">Upload Campaign</Label>
              <Row className="pt-1">
                <Col md="8">
                  <CardContent className="p-0">
                    <Input style={{ fontSize: '0.95em' }} type="file" onChange={e => setData(e.target.files[0])} />
                    <small id="emailHelp" class="form-text text-muted">
                      Please Select csv or xlxs files
                    </small>
                  </CardContent>
                </Col>
                <Col md="4">
                  <Button
                    className=" mx-2 "
                    variant="text"
                    style={{ fontSize: 12 }}
                    href="https://mergecall.s3.ap-south-1.amazonaws.com/System+Files/Sample+File.xlsx"
                    color="primary"
                  >
                    Sample File <i class="fas fa-download" />
                  </Button>
                </Col>
              </Row>
              <Row className="pl-1">
                <Col md="12">
                  <Button variant="contained" onClick={submit} color="primary">
                    Next{' '}
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
};
MaskTexting.propTypes = {
  uploadfile: PropTypes.func.isRequired
};
const mapStateTOProps = state => ({
  upload: state.bulk.uploaded,
  cdata: state.bulk.cdata,
  skiptracing: state.bulk.skiptracing
});
export default connect(
  mapStateTOProps,
  { uploadfile, getallCompanies, checkskiptracing }
)(MaskTexting);

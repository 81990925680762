import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';
import CardTitle from 'reactstrap/lib/CardTitle';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Pagination from 'react-js-pagination';

//materal ui
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {socket} from '../Main/socket'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#edf2f9',
    // color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

// const socket = io(process.env.REACT_APP_PORT, {
//   query: {
//     email: localStorage.getItem('email'),filename:"Inbox"
//   }
// });

const Inbox = () => {
  const [contact, setcontact] = useState([]);
  const [offset, setoffset] = useState(0);
  const [delModal, setDelModal] = useState(false);
  const [delID, setDelID] = useState('');
  const [delTitle, setDelTitle] = useState('');
  const [count, setcount] = useState('');
  const [activePage, setactivePage] = useState(0);

  const [search,setSearch]=useState(0)


  useEffect(() => {
    socket.emit('getmessagesforcount', localStorage.getItem('email'));
    socket.on('contactmsg', msg => {
      setcount(msg);
      let a= msg.length
      console.log(a,"vount")
    });
    
  
  }, []);
  socket.emit('getmessages', localStorage.getItem('email'), offset,search);
  socket.on('contact', msg => {
    setcontact(msg);
    // console.log("contact", msg)
  });
 

  useEffect(() => {
    socket.emit('getmessages', localStorage.getItem('email'), offset,search);
    socket.on('contact', msg => {
      setcontact(msg);
      // console.log("contact", msg)
    });
  }, [offset]);
  let date;
  const deleteconvo = i => {
    socket.emit('deleteconvo', localStorage.getItem('email'), i);
    toast.success('Conversation Deleted');
    socket.emit('getmessages', localStorage.getItem('email'), offset,search);
    socket.on('contact', msg => {
      setcontact(msg);
      console.log("contacttt", msg)
    });

  };
  const next = () => {
    let data = offset + 5;
    setoffset(data);
  };
  const prev = () => {
    let data = offset - 5;
    setoffset(data);
  };
  const handlePageChange = pageNumber => {
    let off = pageNumber * 10 - 10;
    setactivePage(pageNumber);

    setoffset(off);
  };

  const deleteMe = (title, id) => {
    setDelID(id);
    setDelTitle(title);
    setDelModal(true);
  };
  const delToggle = () => {
    setDelModal(!delModal);
  };

  const removeMeConfirm = () => {
    deleteconvo(delID);
    setDelModal(false);
  };

  const handleInputChange = (e)=>{
       
    setSearch(e.target.value)   
}
const callAPI =()=>{
  console.log({search});
  socket.emit('getmessages', localStorage.getItem('email'), offset,search);
  socket.on('contact', msg => {
    setcontact(msg);
    // console.log("contact", msg)
  });
}
console.log({contact})
  const classes = useStyles();
  return (
    <Row>
      <Col md={12} xs={12}>
        <Card>
          <CardHeader>
            <CardTitle>
              <Row>
                <Col xs="8"><h3>Inbox</h3></Col>
                <Col style={{float:"right"}}><div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Search for..."
              onChange={handleInputChange} aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                <div class="input-group-append">
                    <button className="btn btn-primary"  type="button"  type="submit"  onClick={callAPI}><i class="fa fa-search"></i></button>
                </div>
                </div></Col>
              </Row>
              
              
            </CardTitle>
          </CardHeader>
          <CardBody>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>To</StyledTableCell>
                    <StyledTableCell>Message</StyledTableCell>
                    <StyledTableCell>Time</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contact?.length > 0 &&
                    contact?.map(item => (
                      <StyledTableRow key={item._id}>
                        <StyledTableCell>
                          <Link to={`/inbox/conversation/${item._id}`}>{item.sender}</Link>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            maxWidth: '50ch',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {item.messages.length > 0 ? item.messages[item.messages.length - 1].body : null}
                        </StyledTableCell>
                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
                          {moment(item.createdAt).format('LLL')}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: 'center' }}>
                          <FontAwesomeIcon
                            style={{ cursor: 'pointer' }}
                            icon={faTrash}
                            onClick={() => deleteMe(item.sender, item._id)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Row className="mt-4 ">
              <Col md="12">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={5}
                  totalItemsCount={count.length}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
                {/* {offset != 0 ? <Button onClick={prev}>Prev</Button> : null}
                {offset + contact.length < count.length ? <Button onClick={next}>Next</Button>:null} */}
              </Col>
            </Row>
            {delModal ? (
              <Modal isOpen toggle={delToggle}>
                <ModalHeader toggle={delToggle} style={{ backgroundColor: '#becdda8f' }}>
                  <h5 style={{ fontWeight: 'bold' }}>Remove - {delTitle}</h5>
                </ModalHeader>
                <ModalBody>Are you sure you want to remove this? This action cannot be undone.</ModalBody>
                <ModalFooter>
                  <Button variant="contained" color="danger" onClick={() => delToggle()}>
                    No
                  </Button>
                  <Button variant="contained" color="success" onClick={() => removeMeConfirm()}>
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

  //    return (<Row>
  //        <Col md={12} xs={12}>
  //            <Card>
  //                <CardHeader>
  //                    <CardTitle><p>Inbox</p></CardTitle>
  //                </CardHeader>
  //                <CardBody>
  //                    <Table responsive bordered striped hover>
  //                         <thead >
  //                             <tr>
  //                             <th>From</th>
  //                             <th>Message</th>
  //                             <th>Time</th>
  //                             </tr>
  //                         </thead>
  //                     {contact.length > 0 && contact.map(item=>(
  //                         <tbody id={item._id} >
  //                             <th><Link to={`/conversation/${item._id}`}>{item.sender}</Link></th>
  //                             <th>{item.messages.length > 0 ? item.messages[item.messages.length -1].body :null}</th>
  //                             <th>{moment(item.createdAt).format('LLL')}
  //                                 </th>
  //                         </tbody>
  //                     )) }
  //                    </Table>

  //                </CardBody>
  //            </Card>
  //        </Col>
  //    </Row>)
};
export default Inbox;

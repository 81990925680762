import React,{useState} from 'react';
import Tooltip from '@material-ui/core/Tooltip';


const Tooltipbutton = ({  title,children }) => {
    const [hover, sethover] = useState(false);
  
    const mousein = () => {
      sethover(true);
    };
  
    const mouseout = () => {
      sethover(false);
    };
    return (
      <Tooltip title={title}>
        {children}
      </Tooltip>
    );
  };
  export default Tooltipbutton  

import React, { useEffect, useState } from 'react';
import { setAlert } from '../../../Redux/Actions/alert/alert';
import PropTypes from 'prop-types';

import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { getallCompanies } from '../../../Redux/Actions/USER/user';
import { getnumbers, deactivate, activate, filterNumbers } from '../../../Redux/Actions/Twilio';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button, FormGroup, CustomInput, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from '../../../components/Modal/Modal';
import GetContact from '../../../components/GetCompany/getCompany';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

const renderSuggestion = suggestion => <div>{suggestion.name}</div>;
const MyNumber = ({setAlert, getnumbers, getallCompanies, deactivate, activate, filterNumbers, suggestions, numbers,filteredNumbers}) => {
  const [value, setvalue] = useState('');
  const [id, setid] = useState('');
  const [name, setname] = useState('');
  const [status, setstatus] = useState([]);
  const [filters, setfilters] = useState()

  useEffect(() => {
    getnumbers(); // set data in filteredNumbers
  }, []);

  useEffect(() => {
      // console.log("useeff called")

    callFilterAPI(filters);
  }, [filters,numbers])
//   console.log("filter is",filters)
// console.log("filter number is",filteredNumbers)

  const onChange = (event, { newValue }) => {
    setvalue(newValue);

    // console.log('valllllll', newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    getallCompanies(value);
  };
  const getSuggestionValue = suggestion => {
    getnumbers(suggestion._id);
    setid(suggestion._id);
    setname(suggestion.name);
    return suggestion.name;
  };

  const deactivateNum = phn => {
    // console.log('deactivate', phn);

    deactivate(phn);
    // this.props.getnumbers()
  };

  const activateNum = phn => {
    // console.log('activate', phn);
    activate(phn);
    // this.props.getnumbers()
  };

  const callFilterAPI = status => {
    // console.log('status', status);
    let filter = { status: [] };
    filter.status = status;

    // console.log('filter', filter);
    filterNumbers(filter);
  };


  const addStatus = e => {
    // console.log('eeeeeeeeeee', e.target.name);
    let exist = status.findIndex(item => item == e.target.name);
    let data = [...status];

    // console.log('exist', exist);
    // console.log('data', data);
    if (exist != -1) {
      data.splice(exist, 1);
      setstatus(data);
    } else {
      data = [...data, e.target.name];
      setstatus(data);
    }
    // console.log('filterdata', data);
    setfilters(data)
    
  };

  // console.log('items', numbers);
  //  const { value } = this.state;
  //   const {suggestions} =this.props
  // const inputProps = {
  // placeholder: 'Type Company Name',
  // value,
  // onChange: onChange
  // };
  
  if (numbers?.length < 0) {
    return <Loader />;
  } else {
    return (
      <Row>
        <Col md="12">
          <Card>
            <CardHeader className="pb-0">
              <Row>
                <Col xs="8">
                  <CardTitle style={{ fontSize: '0.95em' }}>Tracking</CardTitle>
                </Col>
                <Col xs="4">
                  <GetContact />
                </Col>
                {/* <Col md="4">
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                            />
                        </Col> */}
              </Row>
              <Row>
              <FormGroup>
              <Row>
                <Col md="3">
                  <Label for="Status" style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                    Status:
                  </Label>
                </Col>
                <Col md="9">
                  <CustomInput
                    type="checkbox"
                    id="true"
                    label="Active"
                    name="true"
                    inline
                    onChange={e => {
                      addStatus(e);
                    }}
                  />

                  <CustomInput
                    type="checkbox"
                    id="false"
                    label="Deactivated"
                    inline
                    name="false"
                    onChange={e => {
                      addStatus(e);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
              </Row>
            </CardHeader>
            
            <CardBody className="pt-1" style={{backgroundColor:"#f9fafd"}}>
              <TableContainer component={Paper}>
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>Tracking Number</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>Friendly Name</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>Forward Number</TableCell>
                      {/* <TableCell style={{ whiteSpace: 'nowrap' }}>Sent/ Received / Carrier Blocked</TableCell> */}
                      <TableCell>Sent</TableCell>
                                <TableCell>Recevied</TableCell>
                                <TableCell>Blocked</TableCell>
                      {/* <TableCell>Company</TableCell> */}
                      {/* <TableCell>SMS Webhook</TableCell>
                                <TableCell>Voice Webhook</TableCell> */}
                      <TableCell>Edit</TableCell>
                      <TableCell>Release</TableCell>
                      <TableCell>Actions</TableCell>
                      <TableCell>Health</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      
                    {filteredNumbers?.map((item, index) => (
                      <TableRow key={item._id}>
                        <TableCell scope="row">{index + 1}</TableCell>
                        <TableCell>{item.number}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.pnumber}</TableCell>
                        {/* <TableCell>
                          {item.sent} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item.recieved}{' '}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item.carrierBlocked}
                        </TableCell> */}
                        <TableCell >{item.sent}</TableCell >
                                    <TableCell >{item.recieved}</TableCell >
                                    <TableCell >{item.carrierBlocked}</TableCell >
                        {/* <TableCell >{item.companyname}</TableCell > */}
                        {/* <TableCell >{item.sms}</TableCell >
                                    <TableCell >{item.voice}</TableCell > */}
                        <TableCell>
                          <Tooltip title="Edit number" arrow>
                          <Link to={`/me/edit/${item._id}`}>
                            <i className="is-isolated fas fa-edit" />
                          </Link></Tooltip>
                        </TableCell>
                        <TableCell>
                          <Modal buttonLabel="Release" number={item.number} />
                        </TableCell>

                        <TableCell>
                          {item.activate == true ? (
                            <Tooltip title="Deactivate" arrow>
                              <Button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  deactivateNum(item.number);
                                }}
                              >
                                <i class="far fa-minus-square" />
                              </Button>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Activate" arrow>
                              <Button
                                onClick={() => {
                                  activateNum(item.number);
                                }}
                                className="btn btn-danger btn-sm"
                              >
                                <i class="far fa-plus-square" />
                              </Button>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.health == 'Good' ? (
                            <i class="fas fa-check-circle" />
                          ) : item.health == 'Moderate' ? (
                            <i class="fas fa-exclamation" />
                          ) : item.health == 'Poor' ? (
                            <i class="fas fa-exclamation-triangle" />
                          ) : null}{' '}
                          {item.health}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
};
MyNumber.propTypes = {
  getnumbers: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  getallCompanies: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  numbers: state.Twilio.numbers,
  filteredNumbers:state.Twilio.filteredNumbers,
  suggestions: state.Twilio.companies
});
export default connect(
  mapStateToProps,
  { setAlert, getnumbers, getallCompanies, deactivate, activate, filterNumbers }
)(MyNumber);

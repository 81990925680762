import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Input, Label } from 'reactstrap';

import { updateoptout, oneoptout,getOptOut } from '../../../Redux/Actions/Mask/Mask';


import Loader from '../../../components/common/Loader';
import { useParams } from 'react-router-dom';
import CardHeader from 'reactstrap/lib/CardHeader';
import CardTitle from 'reactstrap/lib/CardTitle';

const EditOutput = ({ optout, updateoptout, oneoptout, id ,toggle,getOptOut}) => {
  const [title, settile] = useState('');
  const [message, setmessage] = useState('');
  // const {id} = useParams()
  useEffect(() => {
    oneoptout(id);
    if (optout != null) {
      settile(optout.title);
      setmessage(optout.message);
    }
  }, [optout != null]);

  const updateoptoutss = () => {
    updateoptout({ title, message }, id);
    getOptOut()
    toggle()
  };
  if (optout == null) {
    return <Loader />;
  } else {
    return (
      <Row className="justify-content-center">
        <Col md={12}>
          <Row>
            <Col md={12}>
              <Label>Title</Label>
              <Input
                type="text"
                value={title}
                onChange={e => {
                  settile(e.target.value);
                }}
                placeholder="title"
              />
            </Col>
            <Col md={12} className="pt-4">
              <Label>Message</Label>
              <Input
                type="text"
                value={message}
                onChange={e => {
                  setmessage(e.target.value);
                }}
                placeholder="message"
              />
            </Col>
            <Col md={12} className="p-4">
              <div className="btn btn-sm btn-primary" onClick={updateoptoutss}>
                Save
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
};
const mapStateTOProps = state => ({
  optout: state.bulk.ooptout
});
export default connect(
  mapStateTOProps,
  { updateoptout, oneoptout,getOptOut }
)(EditOutput);

import React, { useState,useEffect} from 'react';
import {connect} from 'react-redux'
import  {searchProspects,searchProspectsByLS} from "../../../Redux/Actions/Mask/Mask"

import  {getLeadStage} from "../../../Redux/Actions/Mask/Mask"
const UserVoice = ({searchProspects,prospects,getLeadStage,leads,searchProspectsByLS}) => {
       
const [search,setSearch]=useState(0)
useEffect(()=>{
    if(search.length == 0){
        searchProspects(search)
    }
    getLeadStage()
}, [search,getLeadStage])
    const handleInputChange = (e)=>{
       
        setSearch(e.target.value)
        

      
        
    }

    const searchLS =(value)=>{
    searchProspectsByLS(value)
}

const callAPI =()=>{
    searchProspects(search)
}

    return(
        <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Search for..."
              onChange={handleInputChange} aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                <div class="input-group-append">
                    <button className="btn btn-primary"  type="button"  type="submit"  onClick={callAPI}><i class="fa fa-search"></i></button>
                </div>
                </div>
      
    )

}
const mapStateToProps = (state) => ({
    leads:state.bulk.leads
  });
export default connect(mapStateToProps, {searchProspects,getLeadStage,searchProspectsByLS})(UserVoice)
import Axios from 'axios'
import {store} from "../Redux/Store/store"
import {IS_PAID} from "../Redux/Actions/type"
import {setAlert} from '../Redux/Actions/alert/alert'
import {toast} from "react-toastify"
const api = Axios.create({})

api.interceptors.response.use(res=> res,
    err=> {
        console.log("err", err.response.status)
       if(err.response.status === 401 && err.response.data.message  != undefined && err.response.data.message === "Please Subscribe to Plan")
        {  
            //  console.log("if",err.response.data)
            
            store.dispatch({type: IS_PAID,payload : false})
        }
        else if(err.response.status === 401 && err.response.data.message  != undefined && err.response.data.message === 'Insufficient Fund!')
        {
            // console.log("APiintersepter",err.response.data)
            store.dispatch({type: IS_PAID,payload : true})
            
        }
        else {
            // console.log("else",err.response.data)
            // store.dispatch(setAlert(err.response.data, "danger"))
            return Promise.reject(err)
        }
    })

   

   export default api
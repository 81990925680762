import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { getLeadStage } from '../../../Redux/Actions/Mask/Mask';
import { Table, Card, Row, Col, CardBody, CardHeader,Modal, ModalHeader, ModalBody, ModalFooter,Button } from 'reactstrap';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const LeadStage = ({ getLeadStage, leads }) => {
  //   return 'test';
  // };
  // const LeaasdadsdStage = ({ getLeadStage, leads }) => {

  const [name, setName] = useState('');
  const [showform, setshowform] = useState(false);
  const [deleteform, setdeleteform] = useState(false);
  const [deleteModal, setDelModal] = useState(false);
   const [deleteLead, setDeleteLead] = useState(false);
    const [deleteLeadID, setDeleteLeadID] = useState(false);
  useEffect(() => {
    getLeadStage();
  }, [getLeadStage]);
  const head = {
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  };
 
 const deleteToggle = () => {

    setDelModal(!setDelModal)

};

const deleteMe = (id,name) => {
        setDeleteLead(name);
        setDeleteLeadID(id);
        setDelModal(true)
};

 const deleteMeConfirm = () => {
    
   //   releasenumber(relaseNumID)
        deletelead(deleteLeadID)
        setDelModal(false)
       console.log(deleteLeadID,deleteLead)
};

  const handleSubmit = async evt => {
    evt.preventDefault();
    
    const data = {
      name: name
    };
    if (showform) {
       try {
      const { data: post } = await axios.post(process.env.REACT_APP_PORT + '/api/market/create', data, head);
      // setName({ post });
      // console.log(post)
      getLeadStage();
      setshowform(false);
      } catch (e) {
  
    toast.error(e.response.data.error);
  }
    }
    // if (deleteform) {
    //   const { data: post } = await axios.post(process.env.REACT_APP_PORT + '/api/market/deleteleadstage', data, head);
    //   console.log(post);
      
    //   setName({ post });
    //   getLeadStage();
    //   setdeleteform(false);
    // }

    // const
    //   alert(`Submitting Name ${name}`)
  };
  const deletelead =async(nam)=>{
    const data = {
      id: nam
    };
    // console.log({nam})
    const { data: post } = await axios.post(process.env.REACT_APP_PORT + '/api/market/deleteleadstage', data, head);
      // console.log(post);
      
      // setName({ post }); 
      getLeadStage();
  }
console.log({leads})
  if (leads == null) {
    return <Loader />;
  } else {
    return (
      <Row className="justify-content-center ">
        <Col md="10">
          <Card>
            <CardHeader>
              <Row>
                <Col md={8}>
                  <h5 className="font-weight-bold">Lead Stage</h5>
                </Col>
                <Col>
                  <button
                    className={showform === true ? 'btn btn-warning m-1' : 'btn btn-success m-1'}
                    onClick={() => {
                      setshowform(true);
                      setdeleteform(false);
                    }}
                  >
                    ADD
                  </button>
                </Col>
                {/* <Col>
                  <button
                    className={deleteform === true ? 'btn btn-warning m-1' : 'btn btn-success m-1'}
                    onClick={() => {
                      setdeleteform(true);
                      setshowform(false);
                    }}
                  >
                    DELETE
                  </button>
                </Col> */}
                <Row>
                {(showform)?
                  <Col>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        name="Name"
                        placeholder="Name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                      <button  className="btn btn-warning my-3 mx-1">Submit</button>
                    </form>
                  </Col>:null
                }
                </Row>
              </Row>
            </CardHeader>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {leads != null &&
                    leads.length > 0 &&
                    leads.map((item, index) => (
                      <tr key={item._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.lead_Stage}</td>
                        
                        <td>{item.include && <i className="is-isolated fas fa-trash" onClick={()=>deleteMe(item._id,item.lead_Stage)}></i>}</td> 
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>

           {deleteModal?
                 <Modal isOpen toggle={deleteToggle}>
           <ModalHeader toggle={deleteToggle}style={{backgroundColor:"#becdda8f"}}><h3>Delete - {deleteLead}</h3></ModalHeader>
            <ModalBody>Are you sure you want to remove this? This action cannot be undone.
          </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() =>deleteToggle()}>No</Button>
          <Button color="success" onClick={() =>deleteMeConfirm()}>Yes</Button>
          
        </ModalFooter>
            </Modal>
            :null}
      </Row>
    );
  }
};
const mapeState = state => ({
  leads: state.bulk.leads
});
export default connect(
  mapeState,
  { getLeadStage }
)(LeadStage);

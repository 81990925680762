import React, { useState,useEffect ,useRef} from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle,UncontrolledTooltip } from 'reactstrap';
import Switch from '@material-ui/core/Switch';
import Axios from 'axios';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
var moment = require('moment-timezone');

function SkipTracing() {
    const titleRef = useRef();
    const [toggledata, settoggle] = useState(false);
    const [checked, setChecked] = useState(false);
useEffect(() => {
    console.log("hit")
    getdata()

  
}, [])
useEffect(() => {
    // console.log("toggle in",checked)
    titleRef.current.checked=checked
  
}, [checked])
const getdata=async()=>{
    console.log("hittttt")
    const res = await Axios({
    method: "get",
    url: process.env.REACT_APP_PORT + '/api/me/getskiptracing',
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  });
// console.log("res",res.data.data)
// titleRef.current.checked=res.data.data
// console.log("ref",titleRef.current.checked)
setChecked(res.data.data)


}
    const handleChangeSwitch = async(event) => {
        // console.log("checked",checked)
        setChecked(!checked);
        const res = await Axios({
            method: "post",
            url: process.env.REACT_APP_PORT + '/api/me/setskiptracing',
            headers: {
              'x-auth-token': localStorage.getItem('token')
            },data:{option:!checked}
          });
        //   console.log("checked",checked)
      };
  
  return (
    <Row className="justify-content-center">
      <Col md="12" sm="12">
        <Card>
          <CardBody>
          <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample2">
          Skip Tracing provides a way to retrieve additional information about a phone number i.e Number validation, Carrier Information
          Charges- $0.00366 per contact
      </UncontrolledTooltip>
            <CardTitle tag="h5">Toggle Skip Tracing <span style={{ color:"blue"}} href="#" id="UncontrolledTooltipExample2">ON/OFF</span> </CardTitle>
            <CardSubtitle>{checked?"Enabled":"Disabled"}</CardSubtitle>
            OFF<Switch
            ref={titleRef}
                  checked={checked}
                //   value={toggledata}
                  color="primary"
                  onClick={handleChangeSwitch}
                />ON
                {/* <FormGroup switch>
                <Input
                ref={titleRef}
          type="switch"
          checked={toggledata}
          onClick={handleChangeSwitch}
        /></FormGroup> */}
           </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default SkipTracing
import Axios from 'axios';

import { toast } from 'react-toastify';
import {
  UPDATE_ADDRESS,
  IS_PAID,
  GET_FUND,
  CREATE_CARD,
  GET_CARD,
  GET_CARDS,
  UPDATE_CARD,
  GET_PLAN,
  GET_INVOICES,CHECK_SUB
} from './type';
import { gettoken } from './Twilio';

export const getfund = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + `/api/payment/getfund`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    dispatch({
      type: GET_FUND,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const setdefault = (item_id,card_id) => async dispatch => {
  try {
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/setdefault`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },data: {card:card_id}
    });
    console.log("ids",item_id,card_id)
    toast.success('Changed default card');
    window.location.reload(false)
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const savefund = data => async dispatch => {
  try {
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/addFunds`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: data
    });

    toast.success('Fund Added');
    dispatch(gettoken());
    dispatch(getfund());
    const response = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/company?name=' + undefined,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    const token = response.data.data;
    if (token.length == 0) {
      toast.error('Please create a company');
      return false;
    }
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const editconf = data => async dispatch => {
  try {
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/configureAR`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: data
    });

    toast.success('Configuration Saved!!');
    dispatch(getfund());
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const direct_subs = plan => async dispatch => {
    try {
      if (plan) {
        const subscription = await Axios({
          method: 'POST',
          url: process.env.REACT_APP_PORT + `/api/payment/subscription`,
          headers: {
            'x-auth-token': localStorage.getItem('token')
          },
          data: {
            plan: plan
          }
        });
        // console.log({ subscription });
        dispatch(gettoken());
        dispatch(getplans());
  
        toast.success(`Subscribed to Plan`);
        dispatch({
          type: IS_PAID,
          payload: true
        });
      }
  
      dispatch({
        type: CREATE_CARD,
        payload: true
      });
      dispatch(getcards());
    } catch (e) {
      toast.error(e.response.data.error);
    }
  };
  export const renew = data => async dispatch => {
    try {
      console.log("hittttttttttt",data)
      const res = await Axios({
        method: 'POST',
        url: process.env.REACT_APP_PORT + `/api/payment/renew`,
        headers: {
          'x-auth-token': localStorage.getItem('token')
        },
        data: {plan:data}
      });
      // dispatch(getcards());
      toast.success(`Card Saved`);
    } catch (e) {
      toast.error(e.response.data.error);
    }
  };
  export const Add_card = data => async dispatch => {
    try {
      const res = await Axios({
        method: 'POST',
        url: process.env.REACT_APP_PORT + `/api/payment/addCard`,
        headers: {
          'x-auth-token': localStorage.getItem('token')
        },
        data: data
      });
      dispatch(getcards());
      toast.success(`Card Saved`);
    } catch (e) {
      toast.error(e.response.data.error);
    }
  };
export const createcard = (data, plan) => async dispatch => {
  try {
    const resdata = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + `/api/payment/freeuser`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    // console.log("resdata",resdata.data.data,plan)
    //cheacking if free user

    if (resdata.data.data) {
      // console.log('subcription plan', plan);
      const res = await Axios({
        method: 'POST',
        url: process.env.REACT_APP_PORT + `/api/payment/addCard`,
        headers: {
          'x-auth-token': localStorage.getItem('token')
        },
        data: data
      });
      toast.success(`Card Saved`);
      if (plan) {
        // console.log('entered', plan);
        const subscription = await Axios({
          method: 'POST',
          url: process.env.REACT_APP_PORT + `/api/payment/freeuserplan`,
          headers: {
            'x-auth-token': localStorage.getItem('token')
          },
          data: {
            plan: plan
          }
        });
        // console.log('1log');
        dispatch(gettoken());
        // console.log('2log');
        dispatch(getplans());
        // console.log('3log');

        dispatch({
          type: IS_PAID,
          payload: true
        });
      }
      // console.log('4');
      dispatch({
        type: CREATE_CARD,
        payload: true
      });
      // console.log('5');
      dispatch(getcards());
    } else {
      // console.log('subcription plan', plan);
      const res = await Axios({
        method: 'POST',
        url: process.env.REACT_APP_PORT + `/api/payment/addCard`,
        headers: {
          'x-auth-token': localStorage.getItem('token')
        },
        data: data
      });
      toast.success(`Card Saved`);
      if (plan) {
        const subscription = await Axios({
          method: 'POST',
          url: process.env.REACT_APP_PORT + `/api/payment/subscription`,
          headers: {
            'x-auth-token': localStorage.getItem('token')
          },
          data: {
            plan: plan
          }
        });
        // console.log({ subscription });
        dispatch(gettoken());
        dispatch(getplans());

        toast.success(`Subscribed to Plan`);
        dispatch({
          type: IS_PAID,
          payload: true
        });
      }

      dispatch({
        type: CREATE_CARD,
        payload: true
      });
      dispatch(getcards());
    }
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const updatecard = data => async dispatch => {
  try {
    const { name, number, exp_month, exp_year, cvc, card_id } = data;
    const cardObj = {
      name,
      number,
      exp_month,
      exp_year,
      cvc
    };
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/updateCard`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        card_id,
        cardObj
      }
    });
    toast.success('Card Details Updated!');
    dispatch(getcards());
    dispatch({
      type: UPDATE_CARD,
      payload: true
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const deletecard = card_id => async dispatch => {
  try {
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/deleteCard`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        card_id: card_id
      }
    });
    dispatch(getcards());
    toast.success('Card Deleted!');
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const getcards = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + `/api/payment/getcards`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    dispatch({
      type: GET_CARDS,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const getcardbyid = id => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + `/api/payment/getcard/` + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    dispatch({
      type: GET_CARD,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const cancelSubscription = (sid,reason,detail) => async dispatch => {
  try {
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/cancelSubscription`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        subs_id: sid,
        reason: reason+":"+detail
      }
    });
    dispatch(getplans());
    dispatch({
      type: IS_PAID,
      payload: false
    });
    toast.success('UnSubscribe');
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const checkbal = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/checkbal`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      
    });
    // console.log("res",res.data.data)
    if(res.data.data!=true){
    dispatch({
      type: IS_PAID,
      payload: false
    });
  }
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const getinvoice = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + `/api/payment/getInvoices`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    dispatch({
      type: GET_INVOICES,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const getplans = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + `/api/payment/getplans`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    if (res.data.data.plan == '') {
      toast.error(' Kindly subscribe to relevant plans to access paid features!');
    }
    dispatch({
      type: GET_PLAN,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const checksub = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + `/api/payment/checksub`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
// console.log("res data for check subs ",res.data.data)
    dispatch({
      type: CHECK_SUB,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const updatebillingaddress = formdata => async dispatch => {
  try {
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/updateCustomer`,
      data: formdata,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    toast.success('Billing Address');
    dispatch({
      type: UPDATE_ADDRESS,
      payload: true
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

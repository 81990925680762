import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Label, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';

const Popup = ({ open, setOpen, onChange }) => {
  useEffect(() => {
    setData('');
  }, [open]);
  // const [open, setOpen] = useState(true);
  const [data, setData] = useState('');
  const set_data = e => {
    //   if(e?.type?.split("/")[1]==="mp3"){
    //       setData(e)
    //   }else{
    //       toast.error("Please select mp3 file")
    //   }
    setData(e);
  };
  const submit = async () => {
    const formData = new FormData();
    formData.append('myFile', data, data?.name);
    console.log({ formData });
    try {
      const res = await axios.post(process.env.REACT_APP_PORT + '/api/campaign/uploadvoicemail', formData, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      });
      if (res?.data?.data) {
        onChange({ target: { value: res?.data?.data } });
      }
      setOpen(false)
      //{target:{value:}}
     

      console.log(res.data.data);
      toast.success('File uploaded');
    } catch (e) {
      console.log(e.response)

      toast.error("Request Entity Too Large");
    }
  };
  console.log({ data });
  return (
    <div>
      <Modal isOpen={open} toggle={() => setOpen(false)}>
        <ModalHeader>upload mp3 file</ModalHeader>
        <ModalBody>
          <Input
            style={{ fontSize: '0.95em' }}
            type="file"
            accept="audio/mp3"
            onChange={e => set_data(e.target.files[0])}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="contained" color="primary" size="small" onClick={() => submit()}>
            Upload
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Popup;

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getUploads,downloadcampaign } from '../../../Redux/Actions/Mask/Mask';
import { useParams } from 'react-router-dom';
import { Row, Col, Table, Input, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';

var moment = require('moment-timezone');
const UploadData = ({ getUploads, uploads ,downloadcampaign}) => {
  const currtz = useSelector(state => state.Twilio.timezone);
  const [modal, setModal] = useState(false);
  const [body, setBody] = useState(null);
  const toggle = () => setModal(!modal);
  // const [key, setkey] = useState(0);
  const { id } = useParams();
  useEffect(() => {
    getUploads(id);
  }, [getUploads]);
  const pathname = window.location.pathname
  console.log("pathname is",pathname)
  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <Table responsive bordered striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>File</th>
                  <th>Date Created</th>
                  <th>Charges</th>
                  <th>Download Link</th>
                </tr>
              </thead>
              <tbody>
                {uploads != null &&
                  uploads.length > 0 &&
                  uploads.map((item, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td
                        onClick={() => {
                          setBody(item);
                          
                          setModal(true);
                        }}
                      >
                        <Tooltipbutton title="Click here to see content">
                          <div className="btn  btn-primary btn-sm">{item.file?item.file:"File "}</div>
                        </Tooltipbutton>
                      </td>
                      <td>
                        {moment(item.createdAt)
                          .tz(currtz)
                          .format('LLL')}
                      </td>
                      <td>${item.charges.toFixed(2)}</td>
                      <td><Button color="primary" onClick={() => downloadcampaign(item.url,item.name,pathname)}>
                          <i class="fas fa-download" />
                        </Button></td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Message Body</ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs="6">
                    <Row className="ml-2">
                      <Label>File Name</Label>
                    </Row>
                    <Row className="ml-2">
                      <Label>Prospects</Label>
                    </Row>
                    <Row className="ml-2">
                      <Label>DNC</Label>
                    </Row>
                    <Row className="ml-2">
                      <Label>Mobile</Label>
                    </Row>
                    <Row className="ml-2">
                      <Label>Landline</Label>
                    </Row>
                    <Row className="ml-2">
                      <Label>Other</Label>
                    </Row>
                    <Row className="ml-2">
                      <Label>Skip Traced</Label>
                    </Row>
                  </Col>
                  <Col xs="6">
                    {body != null && (
                      <div>
                        <Row>
                          <Label>{body.campaign.filename}</Label>
                        </Row>
                        <Row>
                          <Label>{body.prospects}</Label>
                        </Row>
                        <Row>
                          <Label>{body.dnc}</Label>
                        </Row>
                        <Row>
                          <Label>{body.mobile}</Label>
                        </Row>
                        <Row>
                          <Label>{body.landline}</Label>
                        </Row>
                        <Row>
                          <Label>{body.other}</Label>
                        </Row>
                        <Row>
                          <Label>{body.skiptrace}</Label>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({
  uploads: state.bulk.uploads
});
export default connect(
  mapStateToProps,
  { getUploads,downloadcampaign }
)(UploadData);

import React, { useState, useEffect } from 'react';

import { Send } from '@material-ui/icons';
import { connect } from 'react-redux';
import Axios from 'axios';
import './nav.css';
import moment from 'moment'
import { Popover, PopoverHeader, PopoverBody, CardBody, Input } from 'reactstrap';
import { getQRS, getProspect } from '../../../Redux/Actions/Mask/Mask';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSlash, faCheckSquare, faTimesCircle, faStar, faBolt } from '@fortawesome/free-solid-svg-icons';
import {
  updatedncstatus,
  verifiedprospect,
  removeverifiedprospect,
  wrongnumprospects,
  removewrongnumprospects,
  isqualified,
  wasqualified,
  isPriority,
  wasPriority,
  getLeadStage,
  updateleadstage
} from '../../../Redux/Actions/Mask/Mask';
import { makeStyles, IconButton, Button, Card } from '@material-ui/core';
import './Conversation.css'
const styles = makeStyles({
  scroll: {
    height: '380px',
    overflowY: 'scroll'
  },

  insidelist: {
    display: 'block'
  },
  chipclass: {
    height: 'auto'
  },
  active: {
    opacity: 0.5
  },
  deactive: {
    opacity: 1
  }
});

const Converstion = ({ socket, data, qrs, getQRS,  updatedncstatus,
  verifiedprospect,
  removeverifiedprospect,
  wrongnumprospects,
  removewrongnumprospects,
  isqualified,
  wasqualified,
  isPriority,
  wasPriority,
  getLeadStage,
  updateleadstage,
  leads,
  existProspects }) => {
  const classes = styles();
  const [message, setmessage] = useState(null);
  const [send, setsendtext] = useState('');
  const [textlength,settextlength]= useState(0)
  const [trash, setrash] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [prospects, setProspect] = useState('');
  const [id, setid] = useState('');
  const toggle = () => setPopoverOpen(!popoverOpen);
  
  const getdata = () => {
    socket.emit('getmessagebypros', localStorage.getItem('email'), id);
  };
  useEffect(() => {
    setmessage(data);
    getQRS();
    setProspect(data.prospect);
    
    
    setid(data.prospect._id);
    getLeadStage()
   
  }, [data, setmessage, getProspect, getQRS, getLeadStage]);
  socket.on('message', msg => {
    if (msg != null && msg._id == data._id) {
      console.log('data', msg);
      setmessage(msg);
    }
  });
  const onChange = async e => {
    updateleadstage(id, e.target.value);
  };
  const submit = async e => {
    console.log('yes this is the work')
    e.preventDefault();
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      from: message.number,
      to: message.sender,
      body: send,
      camp: prospects.campaign
    };
    await Axios.post(process.env.REACT_APP_PORT + '/api/sendconv', data, head);
    setsendtext('');
    toast.success('Message Sent');
  };

  const selectmessage = async id => {
    const found = trash.findIndex(dd => dd === id);
    if (found === -1) {
      document.getElementById(id).className = classes.active;

      let data = [...trash, id];
      await setrash(data);
      // deleteitem(data)
    } else {
      let data = [...trash];
      data.splice(found, 1);
      await setrash(data);
      //    deleteitem(data)
      document.getElementById(id).className = classes.deactive;
    }
  };
  const settextbody = message => {
    let body = message;
    console.log('sda', prospects);
    body = body.replace(/\[@firstName+\]/g, prospects.first_name);
    body = body.replace(/\[@email+\]/g, prospects.email);
    body = body.replace(/\[@lastName+\]/g, prospects.last_name);
    body = body.replace(/\[@address+\]/g, prospects.property_address);
    body = body.replace(/\[@city+\]/g, prospects.property_city);
    body = body.replace(/\[@zip+\]/g, prospects.property_zip);
    body = body.replace(/\[@state+\]/g, prospects.property_state);
    console.log('message', prospects, message, body);
    setsendtext(body);
    setPopoverOpen(!popoverOpen)
  };
  const changesstatus = async id => {
    socket.emit('updatestatus', localStorage.getItem('email'), id);
  };
  const verifiedNumber = async item => {
    console.log({item})
    await verifiedprospect(item._id, false);
    getdata();
  };
  const rverifiedNumber = async item => {
    await removeverifiedprospect(item._id, false);
    getdata();
  };
  const wrongNumber = async item => {
    await wrongnumprospects(item._id, false);
    getdata();
  };

  const rwrongNumber = async item => {
    await removewrongnumprospects(item._id, false);
    getdata();
  };
  const qualifiedNumber = async item => {
    await isqualified(item._id, false);
    getdata();
  };
  const rqualifiedNumber = async item => {
    await wasqualified(item._id, false);
    getdata();
  };
  const priorityNumber = async item => {
    await isPriority(item._id, false);
    getdata();
  };
  const rpriorityNumber = async item => {
    await wasPriority(item._id, false);
    getdata();
  };
  const update = async item => {
    let dnc = item.dnc;
    updatedncstatus(item._id, !dnc, false);
  };
  console.log("aaaaaaaaaaaa",message)
  if (message != null)
   {
    return (
      <div className="marvel-device nexus5" onClick={()=>setPopoverOpen(false)} style={{width:"120%"}}>
        <div className="screen">
          <div className="screen-container">
            <div className="chat">
              <div className="chat-container " style={{paddingRight:"14px"}}>
                <div className="user-bar  "  >{data && data.sender != undefined && data.sender}</div>
                <div className="conversation" >
                  <div className="conversation-container mt-1 "  >
                  <Card  >
                        <CardBody>
                          <p style={{ fontSize: '0.7rem' }}>
                          {(data.prospect.last_name===undefined||data.prospect.last_name===null)&&(data.prospect.last_name="  ")}
                            {data.prospect.first_name + ' ' + data.prospect.last_name}</p>
                          <p style={{ fontSize: '0.7rem' }}>
                          {(data.prospect.property_address===undefined||data.prospect.property_address===null)&&(data.prospect.property_address="  ")}
                          {(data.prospect.property_city===undefined||data.prospect.property_city===null)&&(data.prospect.property_city="  ")}
                          {(data.prospect.property_state===undefined||data.prospect.property_state===null)&&(data.prospect.property_state="  ")}
                          {(data.prospect.property_zip===undefined||data.prospect.property_zip===null)&&(data.prospect.property_zip="  ")}

                            {data.prospect.property_address +
                              '  ' +
                              data.prospect.property_city +
                              '  ' +
                              data.prospect.property_state +
                              '  ' +
                              data.prospect.property_zip}
                          </p>
                          {message.prospect.dnc == false ? (
                            <FontAwesomeIcon
                              onClick={() => update(message.prospect)}
                              icon={faTimesCircle}
                              title="Add to DNC"
                            />
                          ) : (
                            <FontAwesomeIcon
                              onClick={() => update(message.prospect)}
                              color="red"
                              icon={faTimesCircle}
                              title="Remove from DNC"
                            />
                          )}{' '}
                          {message.prospect.verified == false ? (
                            <FontAwesomeIcon
                              onClick={() => verifiedNumber(message.prospect)}
                              icon={faCheckSquare}
                              title="Add to Verified"
                            />
                          ) : (
                            <FontAwesomeIcon
                              color="green"
                              onClick={() => rverifiedNumber(message.prospect)}
                              icon={faCheckSquare}
                              title="Remove from verified"
                            />
                          )}
                          <>
                            {' '}
                            {message.prospect.Wrong_Number == false ? (
                              <FontAwesomeIcon
                                onClick={() => wrongNumber(message.prospect)}
                                icon={faPhoneSlash}
                                title="Add to Wrong number"
                              />
                            ) : (
                              <FontAwesomeIcon
                                color="blue"
                                onClick={() => rwrongNumber(message.prospect)}
                                icon={faPhoneSlash}
                                title="Remove from wrong number"
                              />
                            )}{' '}
                            {message.prospect.isLead == false ? (
                              <FontAwesomeIcon
                                onClick={() => qualifiedNumber(message.prospect)}
                                icon={faStar}
                                title="Add to Qualified"
                              />
                            ) : (
                              <FontAwesomeIcon
                                color="purple"
                                onClick={() => rqualifiedNumber(message.prospect)}
                                icon={faStar}
                                title="Remove from Qualified"
                              />
                            )}{' '}
                            {message.prospect.isPriority == false ? (
                              <FontAwesomeIcon
                                onClick={() => priorityNumber(message.prospect)}
                                icon={faBolt}
                                title="Add to Priority"
                              />
                            ) : (
                              <FontAwesomeIcon
                                color="#FF69B4"
                                onClick={() => rpriorityNumber(message.prospect)}
                                icon={faBolt}
                                title="Remove from Priority"
                              />
                            )}
                          </>
                          <Input type="select" className="my-2" onChange={e => onChange(e, data.prospect._id)}>
                            <option value="">Select</option>
                            {leads.length > 0 &&
                              leads.map((lead, index) => (
                                <option selected={data.prospect.lead == lead._id} value={lead._id} key={index}>
                                  {lead.lead_Stage}
                                </option>
                              ))}
                          </Input>
                        </CardBody>
                      </Card>
                     
                    {message && message.status == 'unread' ? (
                      <Button onClick={() => changesstatus(message._id)} color="secondary">
                        Mark as read
                      </Button>
                    ) : null}
                    {message &&
                      message.messages.map(item => {
                    
                    
                       
                        return (
                          <div key={item._id} id={item._id} onClick={() => selectmessage(item._id)}>
                            <div className={item.direction == 'Inbound' ? 'message received' : 'message sent'}>
                              {item.body}

                              {/* <span className="metadata">
                                <span className="time"></span><span className="tick"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>
                            </span> */}
                              <div className="mt-2" style={{ fontSize: '10px', fontStyle: 'oblique'
                            }}>
                                {moment(item.createdAt).format('LLL')}
                              </div>
                              <div style={{ fontSize: '10px', fontStyle: 'oblique'}}> {item.status.charAt(0).toUpperCase()+item.status.slice(1)}</div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <form className="conversation-compose">
                    <input
                      value={send}
                      className="input-msg"
                      name="input"
                      onChange={e => {setsendtext(e.target.value); settextlength(e.target.value.length)}}
                      placeholder="Type a message"
                      autoComplete="off"
                      autoFocus
                    />
                    <div className="photo">
                      <IconButton id="Popover1" type="button">
                        <div className="circle">*</div>
                      </IconButton>
                      <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                        <PopoverHeader>Quick Reply</PopoverHeader>
                        <PopoverBody>
                          {qrs != null &&
                            qrs.length > 0 &&
                            qrs.map(item => (
                              <div className="btn btn-link" onClick={() => settextbody(item.message)} key={item._id}>
                                {item.message}
                              </div>
                            ))}
                        </PopoverBody>
                      </Popover>
                    </div>
                    <span style={{float:'right',fontSize:"12px"}}>{textlength<=160?<i class="fas fa-check-circle fa-sm" style={{"color": "Green"}}></i>:null}{textlength<=320 && textlength>160?<i class="fas fa-exclamation-circle fa-sm" style={{"color": "orange"}}></i>:null}{textlength>320?<i class="fas fa-times-circle fa-sm" style={{"color": "red"}}></i>:null} {textlength}/320</span>
                    <IconButton className="send" onClick={submit}>
                      <div className="circle">
                        <Send />
                      </div>
                    </IconButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
   }
   else{
     return <h1>Loading..</h1>
   }
};

const mapStateToProps = state => ({
  qrs: state.bulk.qrs,
  
  leads: state.bulk.leads
});

export default connect(
  mapStateToProps,
  { getProspect, getQRS,  updatedncstatus,
    verifiedprospect,
    removeverifiedprospect,
    wrongnumprospects,
    removewrongnumprospects,
    isqualified,
    wasqualified,
    isPriority,
    wasPriority,
    getLeadStage,
    updateleadstage }
)(Converstion);

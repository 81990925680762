import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { store } from './Redux/Store/store';
import { Provider } from 'react-redux';
import * as $ from "jquery";

ReactDOM.render(
  <Provider store={store}> 
   <Main>
    <App />
  </Main>
</Provider>,
  document.getElementById('main')
);

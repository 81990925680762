import React, { useEffect, useState } from 'react';
import './assets/css/owl.carousel.css';
import './assets/css/owl.theme.green.css';
import './assets/css/bootstrap.min.css';
import './assets/css/mystyle.css';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../Redux/Actions/Auth/Auth';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import * as $ from 'jquery';
import './main.css';

const Main = ({ isAuthenticated, isPaid, logout }) => {
  const history = useHistory();
  useEffect(() => {
    //    window.location.reload()
    //  document.addEventListener('DOMContentLoaded', function (event) {
    // array with texts to type in typewriter
    var dataText = [
      'The Smartest phone & sales dialer.',
      'Create your own custom sales forms',
      'Integrate with any system using our API and WEBHOOKS',
      ' Best value for money'
    ];

    const timeoutList = [];
    // type one text in the typwriter
    // keeps calling itself until the text is finished
    function typeWriter(text, i, fnCallback) {
      // chekc if text isn't finished yet
      if (i < text.length) {
        // add next character to h1
        const dd = document.getElementById('sss');
        if (dd) {
          dd.innerHTML = text.substring(0, i + 1) + '<span aria-hidden="true"></span>';
        }
        // wait for a while and call this function again for next character
        const a = setTimeout(function() {
          typeWriter(text, i + 1, fnCallback);
        }, 100);
        timeoutList.push(a);
      }
      // text finished, call callback if there is a callback function
      else if (typeof fnCallback == 'function') {
        // call callback after timeout
        const b = setTimeout(fnCallback, 700);
        timeoutList.push(b);
      }
    }
    // start a typewriter animation for a text in the dataText array
    function StartTextAnimation(i) {
      if (typeof dataText[i] == 'undefined') {
        const c = setTimeout(function() {
          StartTextAnimation(0);
        }, 20000);
        timeoutList.push(c);
      }
      // check if dataText[i] exists
      if (dataText[i] != undefined && i < dataText[i].length) {
        // text exists! start typewriter animation
        typeWriter(dataText[i], 0, function() {
          // after callback (and whole text has been animated), start next text
          StartTextAnimation(i + 1);
        });
      }
    }
    // start the text animation
    StartTextAnimation(0);
    //   });
    return () => {
      timeoutList.forEach(timeout => clearTimeout(timeout));
    };
  }, []);

  const openNav = () => {
    document.getElementById('mySidenav').style.width = '100%';
  };
  const Logout = () => {
    logout();
    window.location.reload(false);
  };
  const closeNav = () => {
    document.getElementById('mySidenav').style.width = '0';
  };
  const opendocs = () => {
    window.open('https://mergecall.docs.apiary.io/#podio-development');
  };
  const openblog = () => {
    window.open('https://mergeasy.com/blog/');
  };
  const opentut = () => {
    window.open('https://www.youtube.com/watch?v=WSZM0PQ6Jgk&list=PLp5go4gGNsPc0g0SS--55zhWEn_8X9XHy');
  };
  const dsaads = () => {
    $('html,body').animate(
      {
        scrollTop: $('#second').offset().top
      },
      'slow'
    );
  };
  const notLoggedin = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="logo-item">
        <a href="#">
          <img src="/images/main-logo.png" alt="Logo" />
        </a>
      </div>
      <div className="mob-menu-only">
        <span style={{ cursor: 'pointer' }} onClick={openNav}>
          &#9776;
        </span>
      </div>
      <div className="link-item only-desktop">
        <ul className="d-flex nav-link-item">
          <li>
            <NavLink onClick={openblog} to="#">
              Blogs
            </NavLink>
          </li>
          <li>
            <NavLink onClick={opentut} to="#">
              Tutorials
            </NavLink>
          </li>
          <li>
            <NavLink onClick={dsaads} to="#">
              Pricing
            </NavLink>
          </li>
          <li>
            <NavLink onClick={opendocs} to="#">
              API Docs
            </NavLink>
          </li>

          {/* <li className="sub-menu-item">
                  <a href="#">How it works?</a>
                  <ul className="sub-menu">
                    <li>
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <a href="#">Our Products</a>
                    </li>
                    <li>
                      <a href="#">Offers</a>
                    </li>
                    <li>
                      <a href="#">News Links</a>
                    </li>
                  </ul>
                </li> */}
          <li>
            <Link to="/contactus">Contact Us</Link>
            {/* <a href="https://podio.com/webforms/25579499/1901445">Contact Us</a> */}
          </li>
          <li className="sub-menu-item">
            <NavLink exact to="/authentication/login">
              Sign In
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/authentication/register">
              SIGN UP
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="link-item only-desktop">
        <ul className="nav-link-item d-flex" />
      </div>
      <div id="mySidenav" className="sidenav">
        <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
          &times;
        </a>
        <ul>
          <li>
            <NavLink onClick={openblog} to="#">
              Blogs
            </NavLink>
          </li>
          <li>
            <NavLink onClick={opentut} to="#">
              Tutorials
            </NavLink>
          </li>
          <li>
            <NavLink onClick={opendocs} to="#">
              API Docs
            </NavLink>
          </li>
          <li>
            <a onClick={dsaads}>Pricing</a>
          </li>

          <li>
            <Link to="/contactus">Contact Us</Link>
            {/* <a href="https://podio.com/webforms/25579499/1901445">Contact Us</a> */}
          </li>
          <li>
            <NavLink exact to="/authentication/login">
              Sign In
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/authentication/register">
              SIGN UP
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );

  const Loggedin = (
    <div className="d-flex justify-content-between ">
      <div className="logo-item">
        <a href="#">
          <img src="/images/main-logo.png" alt="Logo" />
        </a>
      </div>
      <div className="mob-menu-only">
        <span style={{ cursor: 'pointer' }} onClick={openNav}>
          &#9776;
        </span>
      </div>
      <div className="link-item only-desktop">
        <ul className="d-flex nav-link-item">
          <li>
            <NavLink onClick={opendocs} to="#">
              API Docs
            </NavLink>
          </li>
          <li>
            <NavLink to="/pages/call">Call</NavLink>
          </li>
          <li>
            <NavLink to="/pages/chat">Message</NavLink>
          </li>
          {/* <li>
            <NavLink to="/pages/fax">Fax</NavLink>
          </li> */}
          <li>
            <NavLink to="/buynumber">BuyNumber</NavLink>
          </li>
          <li>
            <NavLink to="#" onClick={dsaads}>
              Payment
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/me/numbers">
              Tracking
            </NavLink>
          </li>

          <li className="sub-menu-item">
            <div class="dropdown">
              <NavLink class="dropbtn" to="#">
                {localStorage.getItem('email')}
              </NavLink>

              <div class="dropdown-content">
                <NavLink exact to="/getcompany">
                  Company
                </NavLink>
                <NavLink exact to="/myaccount">
                  My Account
                </NavLink>
                <NavLink to="/pages/campaigns">Campaign</NavLink>
                <NavLink onClick={Logout} to="#">
                  Logout
                </NavLink>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="link-item only-desktop">
        <ul className="nav-link-item d-flex" />
      </div>
      <div id="mySidenav" className="sidenav">
        <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
          &times;
        </a>
        <ul>
          <li>
            <NavLink to="/call">Call</NavLink>
          </li>
          <li>
            <NavLink to="/pages/chat">Message</NavLink>
          </li>
          {/* <li>
            <NavLink to="/pages/fax">Fax</NavLink>
          </li> */}
          <li>
            <NavLink to="/buynumber">BuyNumber</NavLink>
          </li>
          <li>
            <NavLink to="#" onClick={dsaads}>
              Payment
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/me/numbers">
              Tracking
            </NavLink>
          </li>

          <li>
            <NavLink to="#">{localStorage.getItem('email')}</NavLink>

            <ul className="mob-sub-menu">
              <li>
                <NavLink exact to="/getcompany">
                  Company
                </NavLink>
              </li>

              <li>
                <NavLink to="/campaigns">Campaign</NavLink>
              </li>
              <li>
                <NavLink onClick={Logout} to="#">
                  Logout
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <section style={{ paddingTop: '0px', paddingBottom: '0px' }} className="main-banner">
        <div className="container">
          <header className="main-header">{isAuthenticated ? Loggedin : notLoggedin}</header>
          <div className="d-flex justify-content-between flex-wrap align-items-center">
            <div className="banner-left-item">
              <div className="banner-overlay-text">
                <h2 />
                <h1 id="sss">
                  <span />
                </h1>
                <p>Communicate with your customers from your desk or your smartphone.</p>
              </div>
            </div>
            <div className="banner-right-item">
              <div className="banner-overlay-calling" />

              <div>
                <img src="/images/sms.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cloud-section py-3 py-md-5">
        <div className="container">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="cloud-img">
              <img src="/images/clude-1.jpg" alt="Cloud" />
            </div>
            <div className="cloud-content-inner">
              <span>Versatile Cloud Phone System Which Meets Vertical & Horizontal Organizational Needs</span>
              <h2>One Place For All Telephonic Activities</h2>
              <p>
                mergecall has special integrations with Podio, Zapier and Google Chrome, on top of publically available
                API and webhooks which allows any system to Interact with us
              </p>
              <ul className="d-flex justify-content-between flex-wrap cloud-list">
                <li>Instant local & toll free numbers</li>
                <li>International numbers</li>
                <li>Simple billing</li>
                <li>Instant local & toll free numbers</li>
                <li>International </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="customer-section pt-3 mt-3 pt-md-5 mt-md-5">
        <div className="container">
          <div className="customer-header">
            <span>Be up and running in minutes.</span>
            <h3>
              Why do our customers <br /> love mergeCall?
            </h3>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons8-cell-phone-100.png" alt="Call Phone" />
              <h4>Multi line dialer</h4>
              <p>Boost your right-party connects by over 200%. Connect more Leads in Less Time! </p>
            </div>
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-office-phone-100.png" alt="Call Phone" />
              <h4>Calling</h4>
              <p>Initiate calling to any number instantly </p>
            </div>
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-sms-100.png" alt="Call Phone" />
              <h4>SMS</h4>
              <p>Send SMS or in bulk to anyone from anywhere </p>
            </div>
            <div className="customer-service-box">
              <img
                className="service-icon"
                src="/images/icons/icons8-natural-user-interface-2-100.png"
                alt="Call Phone"
              />
              <h4>Click To Call</h4>
              <p>
                Use our google chrome plugin for podio click to call Or our click to call links to initiate calls{' '}
                <a
                  href="https://chromewebstore.google.com/detail/mergecall/ihgbmckjcoehaahnhhfcilllgkhkdgkh"
                  target="_blank"
                >
                  Get Extension
                </a>
              </p>
            </div>
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-rest-api-100.png" alt="Call Phone" />
              <h4>API AND WEBHOOKS</h4>
              <p>We get easily integrated with any CRM system</p>
            </div>
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-form-100.png" alt="Call Phone" />
              <h4>Custom Forms</h4>
              <p>Create your own sales script form which automatically is sent via webhook as soon as you hangup. </p>
            </div>
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-strike-100.png" alt="Call Phone" />
              <h4>Campaign Management</h4>
              <p>Bulk Messaging with respect to camapign</p>
            </div>
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-account-100.png" alt="Call Phone" />
              <h4>Lead Center</h4>
              <p>One place to manage all your prospects and sales/cold callers</p>
            </div>
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-video-conference-100.png" alt="Call Phone" />
              <h4>Call Recording</h4>
              <p>Easily record phone calls within your company</p>
            </div>
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-group-message-100.png" alt="Call Phone" />
              <h4>Bulk Texting</h4>
              <p>Just upload your contact sheet and send bulk sms on single click of a button</p>
            </div>
            {/* <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-fax-100.png" alt="Call Phone" />
              <h4>Fax</h4>
              <p>We made sending faxes simple & secure</p>
            </div> */}
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-money-box-100.png" alt="Call Phone" />
              <h4>Cheapest</h4>
              <p>We are cheapest and most reliable compared to similar services available</p>
            </div>
            <div className="customer-service-box">
              <img className="service-icon" src="/images/icons/icons8-conference-100.png" alt="Call Phone" />
              <h4>Multiple Companies Management</h4>
              <p>Create as many company required and seggregate your contacts and calling accordingly</p>
            </div>
          </div>
        </div>
      </section>

      <section className="integrate-web-section py-3 py-md-5 my-md-5">
        <div className="container">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="integrate-web-item">
              <div className="integrate-header">
                <h3>
                  We integrate with your <br />
                  favorite apps
                </h3>
                <span>here are just a few of the third-party apps that Mergecall integrates with.</span>
              </div>
              <div className="read-more-btn bg-light-pink" />
            </div>
            <div className="integrate-web-item">
              <ul className="integrate-web-list d-flex flex-wrap flex-md-nowrap align-items-center">
                <li>
                  <a onClick={() => window.open('https://mergeasy.com/blog/zapier-mergecall-integration/')}>
                    <img src="/images/zapier.jpg" alt="Zapier" />
                    <p>Zapier</p>
                  </a>
                </li>
                <li>
                  <a onClick={() => window.open('https://mergeasy.com/blog/podio-merge-call/')}>
                    <img src="/images/podio.jpg" alt="podio" />
                    <p>Podio</p>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      window.open('https://chromewebstore.google.com/detail/mergecall/ihgbmckjcoehaahnhhfcilllgkhkdgkh')
                    }
                    href="#"
                  >
                    <img src="/images/chrome.jpg" alt="chrome" />
                    <p>Chrome</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing-plans-section py-3 py-md-5 my-md-5">
        <div className="container" id="second">
          <div className="pricing-plans-header">
            <h3>Pricing plans</h3>
            <p>
              Pricing that scales with your business with perfect sales call! Pricing plans for businesses of all sizes.{' '}
            </p>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="pricing-plans-box">
              <h3>Premium</h3>
              <small>Basic Platform</small>
              <h3>$34.99/mo</h3>
              <ul>
                <li>45 days free trial</li>
                <li>Free $15 Credits on Signup</li>
                <li>$0.011/text Inbound/Outbound SMS</li>
                <li>$0.0195/min Call</li>
                <li>$3/month/number</li>
                {/* <li>$0.005/page Fax</li> */}
                <li>Multiline dialing</li>
                <li>Easy SMS/Call Tracking</li>
                <li>Easy Payments</li>
                <li>Mass texting</li>
                <li>Multiple companies</li>
                <li>Upto 3000 Messages per day</li>
                <li>24/7 Support</li>
              </ul>
              <div className="updeta-btn">
                {isPaid ? <a href="/home/upgradeplans">Upgrade Now</a> : <a href="/payment">Start Free Trial</a>}
              </div>
            </div>

            <div className="pricing-plans-box">
              <h3>Premium plus</h3>
              <small>Basic Platform</small>
              <h3>
                $44.99/mo+$50<small>(one-time)</small>
              </h3>
              <ul>
                <li>45 days free trial</li>
                <li>Free $15 Credits on Signup</li>
                <li>$0.011/text Inbound/Outbound SMS</li>
                <li>$0.0195/min Call</li>
                <li>$3/month/number</li>
                {/* <li>$0.005/page Fax</li> */}
                <li>Multiline dialing</li>
                <li>Easy SMS/Call Tracking</li>
                <li>Easy Payments</li>
                <li>Mass texting</li>
                <li>Multiple companies</li>
                <li>3000+ Messages per day</li>
                <li>24/7 Support</li>
              </ul>
              <div className="updeta-btn">
                {isPaid ? <a href="/home/upgradeplans">Upgrade Now</a> : <a href="/payment">Start Free Trial</a>}
              </div>
            </div>

            <div className="pricing-plans-box">
              <h3>ENTERPRISE</h3>
              <small>Customizable Platform</small>
              <h3>Contact Here</h3>
              <ul>
                <li>Customized system build on top of our API</li>
                <li>Dedicated System Manager</li>
                <li> Have an idea to share?</li>
                {/* <li>Limited API Access</li> */}
              </ul>
              <div className="updeta-btn">
                <Link to="/contactus">Contact Us</Link>
                {/* <a href="https://podio.com/webforms/25579499/1901445">Contact Us</a> */}
              </div>
            </div>
            {/* <div className="pricing-plans-box">
            <h3>BUSINESS YEARLY PLAN</h3>
            <small>Advance Platform</small>
            <h3>$499/mo</h3>
            <ul>
              <li>10 Merges</li>
              <li>1 Template</li>
              <li> Unlimited Security Service</li>
              <li>Limited API Access</li>
            </ul>
            <div className="updeta-btn">
              <a href="#">Upgrade Now</a>
            </div>

          </div>
          */}
          </div>
        </div>
      </section>

      <footer className="main-footer mt-md-5 pt-md-5">
        <div className="container">
          <div className="footer-logo">
            <img src="/images/footer-main-logo.png" alt="Logo" />
          </div>
          <div className="footer-inner d-flex flex-wrap justify-content-between">
            <div className="footer-item-1">
              <p>A product of mergeasy.com</p>
            </div>
            <div className="footer-item-2">
              <ul>
                <li>
                  <Link to="/contactus">Contact Us</Link>
                  {/* <a href="https://podio.com/webforms/25579499/1901445">Contact Us</a> */}
                </li>
                <li>
                  <a href="#">Customer Service</a>
                </li>
                <li>
                  <a href="#">Term & Condition</a>
                </li>
                <li>
                  <a href="#">FAQs</a>
                </li>
              </ul>
            </div>
            <div className="footer-item-2">
              <ul>
                <li>
                  <a
                    href="https://chromewebstore.google.com/detail/mergecall/ihgbmckjcoehaahnhhfcilllgkhkdgkh"
                    target="_blank"
                  >
                    Chrome Extension
                  </a>
                </li>
                <li>
                  <a href="#">Refund Policy</a>
                </li>
                <li>
                  <a href="#">Payment Methods</a>
                </li>
                <li>
                  <Link to="/privacypolicy">Privacy & Policy</Link>
                  <Link to="/refundpolicy">Refund & Policy</Link>
                  {/* <a href="#">Privacy & Policy</a> */}
                </li>
              </ul>
            </div>
            <div className="footer-item-3">
              <div className="footer-email d-flex">
                <input type="text" placeholder="Enter Email" />
                <a href="#">Subscribe</a>
              </div>
              <div className="footer-follow mt-4">
                <h3>Follow Us</h3>
                <ul className="d-flex">
                  <li>
                    <a href="#">
                      <img src="/images/fb.png" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/images/gmail.jpg" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/images/twitter.png" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/images/-Linkedin.png" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copy-right text-center">
            <p>© 2020 MergeCall. All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </div>
  );
};
Main.propTypes = {
  logout: PropTypes.func
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isPaid: state.auth.isPaid
});
export default connect(
  mapStateToProps,
  { logout }
)(Main);

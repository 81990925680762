import {
  GET_QR,
  CALL_CAMP_LOGS,
  GET_SESSION,
  GET_NOTES,
  SHOW_LOADER,
  GET_OPTOUT,
  ONE_OPT_OUT,
  GET_SCHEDULE,
  GET_COUNTS_STATS,
  GET_CONFIG,
  GET_METRICS,
  GET_UPLOADS,
  GET_LEAD_STAGE,
  SEND_MESSAGE,
  COUNT_TEMPLATE,
  COUNT_PROSPECTS,
  GET_PROSPECTS,
  GET_BATCH,
  GET_QRS,
  GET_TEMPLATES,
  GET_CLIENT_DATA,
  GET_TEMPLATE,
  GET_CATEGORIES,
  GET_CATEGORY,
  MASK_TEXTING_UPLOAD,
  GET_PROSPECT,
  COUNT_CONTACT,
  DONE,
  GET_CAMPAIGNS,
  GET_CAMPAIGN,
  GET_MULTILINE_TOKEN,
  GET_CONTACTS,
  GET_MULTI_CONTACTS,
  GET_NOTIFICATION,
  GET_QuickReplies,
  GET_QuickReply,
  COUNT_ALL_PROSPECTS,
  CALL_MONITOR,
  CAMP_PROGRESS,
  SKIP_TRACING
} from '../Actions/type';

const initialState = {
  session: [],
  uploaded: null,
  campaigns: [],
  campaign: null,
  token: null,
  contact: [],
  countcontact: 0,
  prospects: [],
  prospect: null,
  templates: [],
  template: null,
  categories: [],
  category: null,
  qrs: [],
  qr: null,
  quickreplies: [],
  quickreply: null,
  notification: null,
  optout: [],
  prospects: [],
  counttemplate: 0,
  sent: 0,
  counttemplate: 0,
  batchpros: 0,
  name: null,
  bulkid: null,
  countProspect: null,
  prospectdata: null,
  metrics: null,
  countprospects: 0,
  notes: [],
  countstat: null,
  config: null,
  uploads: [],
  leads: [],
  scheduledata: null,
  ooptout: null,
  SHOW_LOADER: false,
  callmonitor: [],
  CALLCAMPLOGS: [],
  cdata:null,
  skiptracing:null
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SKIP_TRACING:
      return {
        ...state,
        skiptracing: payload
      };
    case CAMP_PROGRESS:
      return {
        ...state,
        cdata: payload
      };
    case GET_SESSION:
      return {
        ...state,
        session: payload
      };
    case CALL_CAMP_LOGS:
      return {
        ...state,
        CALLCAMPLOGS: payload
      };
    case CALL_MONITOR:
      return {
        ...state,
        callmonitor: payload
      };
    case SHOW_LOADER:
      return {
        showLoader: payload
      };
    case GET_COUNTS_STATS:
      return {
        ...state,
        countstat: payload
      };
    case GET_CONFIG:
      return {
        ...state,
        config: payload
      };
    case GET_SCHEDULE:
      return {
        ...state,
        scheduledata: payload
      };
    case GET_NOTES:
      console.log('pp', payload);
      return {
        ...state,
        uploaded: null,
        notes: payload
      };
    case ONE_OPT_OUT:
      return {
        ...state,
        ooptout: payload
      };
    case GET_LEAD_STAGE:
      return {
        ...state,
        uploaded: null,
        leads: payload
      };
    case GET_UPLOADS:
      return {
        ...state,
        uploads: payload
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        notification: payload
      };
    case GET_METRICS:
      return {
        ...state,
        metrics: payload
      };
    case GET_CLIENT_DATA:
      return {
        ...state,
        prospectdata: payload
      };
    case GET_BATCH:
      return {
        ...state,
        batchpros: payload.prospectcount,
        name: payload.name,
        bulkid: payload.uid
      };
    case COUNT_ALL_PROSPECTS:
      return {
        ...state,
        countprospects: payload
      };
    case SEND_MESSAGE:
      return {
        ...state,
        sent: state.sent + 1
      };
    case GET_PROSPECTS:
      return {
        ...state,
        uploaded: null,
        prospects: payload
      };
    case COUNT_PROSPECTS:
      return {
        ...state,
        uploaded: null,
        countProspect: payload,
        sent: 0
      };

    case COUNT_ALL_PROSPECTS:
      return {
        ...state,
        countprospects: payload
      };
    case COUNT_TEMPLATE:
      return {
        ...state,
        counttemplate: payload
      };
    case GET_QRS:
      return {
        ...state,
        qrs: payload
      };
    case GET_QR:
      return {
        ...state,
        qr: payload
      };
    case GET_QuickReplies:
      return {
        ...state,
        quickreplies: payload
      };
    case GET_QuickReply:
      return {
        ...state,
        quickreply: payload
      };
    case GET_TEMPLATES:
      return {
        ...state,
        templates: payload
      };
    case GET_TEMPLATE:
      return {
        ...state,
        template: payload
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: payload
      };
    case GET_PROSPECT:
      return {
        ...state,
        prospect: payload
      };
    case GET_PROSPECTS:
      return {
        ...state,
        prospects: payload
      };
    case COUNT_CONTACT:
      return {
        ...state,
        countcontact: payload
      };
    case GET_CONTACTS:
      return {
        ...state,
        contact: payload
      };
    case GET_MULTI_CONTACTS:
      return {
        ...state,
        contact: payload
      };
    case GET_OPTOUT:
      return {
        ...state,
        optout: payload
      };
    case GET_MULTILINE_TOKEN:
      return {
        ...state,
        token: payload
      };
    case MASK_TEXTING_UPLOAD:
      return {
        ...state,
        uploaded: payload,
        campaign: null
      };
    case DONE:
      return {
        ...state,
        uploaded: null,
        campaign: null
      };
    case GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: payload,
        uploaded: null,
        campaign: null,
        contact: [],
        prospectdata: null,

        countProspect: null
      };
    case GET_CAMPAIGN:
      return {
        ...state,
        //  campaigns: null,  i commented this because it breaking the code at prospect.js line 558 multiselect dropdown if campaign is null so its breaking the code
        uploaded: null,
        campaign: payload
      };

    default:
      return state;
  }
}

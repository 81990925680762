import React from 'react'
import {Row, Col, Card, CardBody, Input, Label, Button} from 'reactstrap'

import {connect} from 'react-redux'
import {createComapny}from "../../../Redux/Actions/USER/user"
import {setAlert} from '../../../Redux/Actions/alert/alert'

import { toast } from 'react-toastify'
class CreateComapny extends React.Component{
    state = {
        name: ""
    }
    handleChange = e =>{
        this.setState({name: e.target.value})
    }
    submit = () =>{
        if(this.state.name === "" )
        {
            toast.error("Name of the Comapny is Required")
        }
        
        else{
        this.props.createComapny(this.state.name)
        this.props.toggle()
    }
}
   
    render(){
         return <Row  className="justify-content-center ">
        <Col md="12">
             <Row>
                  <Col md="12">
                      
                       <Input style={{ fontSize: "0.95em"}} placeholder="Enter Company's Name"  type="text" onChange={this.handleChange} name="name" value={this.props.name}></Input>
                      
                  </Col>
              </Row>
              <Row className="pt-2">
                  <Col md="12">
                      <Button onClick={()=>this.submit()}  color="primary">Create Company</Button>
                  </Col>
              </Row>
              
        </Col>
    </Row>
    
    }
}

export default connect(null,{ createComapny, setAlert} )(CreateComapny)
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import {connect} from "react-redux"
import {sendEmail} from "../../Redux/Actions/Auth/Auth"
const ForgetPasswordForm = ({ setRedirect, setRedirectUrl, layout, sendEmail }) => {
  // State
  const [email, setEmail] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (email) {
      sendEmail(email)
      setRedirect(true);
    }
  };

  useEffect(() => {
    setRedirectUrl(`/authentication/login`);
  }, [setRedirectUrl, layout]);

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          className="form-control"
          placeholder="Email address"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"></Input>
        
      </FormGroup>
      <FormGroup>
        <Button color="primary" block disabled={!email}>
          Send reset link
        </Button>
      </FormGroup>
      <Link className="fs--1 text-600" to="#!">
        I can't recover my account using this page
        <span className="d-inline-block ml-1">&rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string,
  
  sendEmail: PropTypes.func.isRequired
};

ForgetPasswordForm.defaultProps = { layout: 'basic' };
const combo = withRedirect(ForgetPasswordForm)
export default connect(null, {sendEmail})(combo);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import Label from 'reactstrap/es/Label';
import classNames from 'classnames';
import {verify, reserpassword} from "../../Redux/Actions/Auth/Auth"
import {connect} from 'react-redux'
import {useParams, Redirect} from "react-router-dom"
import Loader from "../common/Loader"
const PasswordResetForm = ({verified,  hasLabel, verify,changed, reserpassword }) => {
  // State
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const {token} = useParams()
  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    reserpassword(password, token)
  };

  useEffect(() => {
    verify(token)
  }, []);

  useEffect(() => {
    if (password === '' || confirmPassword === '') return setIsDisabled(true);

    setIsDisabled(password !== confirmPassword);
  }, [password, confirmPassword]);
  console.log(verified)
  if(verified)
  {
    return (
      <Form className={classNames('mt-3', { 'text-left': hasLabel })} onSubmit={handleSubmit}>
        <FormGroup>
          {hasLabel && <Label>New Password</Label>}
          <Input
            placeholder={!hasLabel ? 'New Password' : ''}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            type="password"
          />
        </FormGroup>
        <FormGroup>
          {hasLabel && <Label>Confirm Password</Label>}
          <Input
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={confirmPassword}
            onChange={({ target }) => setConfirmPassword(target.value)}
            type="password"
          />
        </FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Set password
        </Button>
      </Form>
    );
     
  }
  else if(changed)
  {
      return <Redirect to="/authentication/login"></Redirect>
  }
  else{
    return <Loader></Loader>
  }
};

PasswordResetForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

PasswordResetForm.defaultProps = { layout: 'basic', hasLabel: false };


const mapStateToProps = (state) => ({
  verified: state.auth.verified,
  changed: state.auth.changed

});


const Combo =  withRedirect(PasswordResetForm);
export default connect(mapStateToProps, {verify, reserpassword})(Combo)
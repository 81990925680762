import {UPDATE_ADDRESS, CREATE_CARD, GET_FUND, GET_CARDS, GET_CARD, UPDATE_CARD, GET_INVOICES, GET_PLAN } from '../Actions/type';

const initialState = {
 paid: false,
 cards: null,
 card: null,
 updated: false,
 invoices: null,
 plan: null,
 fund: null,
 updatedcustomer: false

};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_FUND: 
    return {
      ...state,
      fund: payload,
      updatedcustomer: false
    }
    case GET_PLAN:
      return {
        ...state,
        plan: payload,
         updatedcustomer: false

      }
    case CREATE_CARD: 
    return{
        ...state,
        paid: payload,
         updatedcustomer: false
      }
    case GET_INVOICES: 
      return { ...state,  updatedcustomer: false, invoices: payload}
    case GET_CARDS: 
    return{
        ...state,
        cards: payload,
        updated: false,
         updatedcustomer: false
      }
    case GET_CARD: 
    return{
        ...state,
        card: payload,
        updated: false,
         updatedcustomer: false
      }
    case UPDATE_CARD: 
    return{
        ...state,
        updated: payload,
         updatedcustomer: false
      }
      case UPDATE_ADDRESS: 
    return{
        ...state,
         updatedcustomer: true
      }
    default:
      return state;
  }
}

import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Label,
  Button,
  Modal,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input as IMP
} from 'reactstrap'; // Replace with your component library if different
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewDialer from './newdialer';
import AddProspect1 from '../Prospects/addProspect1';
import { callmonitor, getVoices, savenotes } from '../../../Redux/Actions/USER/user';
import UserForm from './Form';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Device } from '@twilio/voice-sdk';
import { toast } from 'react-toastify';
import { closeDialog } from '../../../Redux/Actions/Twilio';
import { initializeDevice } from './twilioDevice';
const DialerPopup = ({
  dialogOpen,
  outdialog,
  setoutPopDialogs,
  outCallDialogData,
  callmonitor,
  getVoices,
  savenotes,
  isDialerOpen,
  setIsDialerOpen,
  closeDialog,
  ...props
}) => {
  const [num, setnum] = useState('');
  const [isOpenDial, setclosedialer] = useState(false);
  const [connection, setConnection] = useState(null);
  const [callconnect, setcallconnect] = useState(false);
  const [allowdigit, setallowdigit] = useState(false);
  const [inbound, setinbound] = useState(false); //
  const [conn, setconn] = useState(null);
  const [dil, setdil] = useState(false);
  const [dis, setdis] = useState(true);
  const [open, setopen] = useState(false);
  const [notetoggle, setnotetoggle] = useState(false);
  const [formshow, setshowform] = useState(false);
  const [timer, setTimer] = useState(false);
  const [mic, setmicrophone] = useState(false);
  const [device, setDevice] = useState();
  const [errorexist, setErrorExist] = useState(false);
  const [handle, setHandle] = useState(null);
  const [details, setdetails] = useState(null);
  const [cmpname, setcmpname] = useState(null);
  const [showprospet, setshowprospet] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [note, setnotes] = React.useState('');
  useEffect(() => {
    console.log('dialogOpen', dialogOpen);
    if (dialogOpen) {
      setErrorExist(false);
      setoutPopDialogs(true);
      setIsDialerOpen(true);
      handleCall();
    }
    // Handle any side effects or cleanup if necessary
    return () => {
      // Cleanup logic if needed
    };
  }, [dialogOpen]);

  useEffect(() => {
    console.log('outdialog', isDialerOpen);
  }, [isDialerOpen]);

  useEffect(() => {
    if (timer) {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
      setHandle(interval);
    }
  }, [timer]);

  const changestatus = () => {
    setcallconnect(false);
  };
  const formtoogleToggle = () => {
    setshowform(!formshow);
  };
  const stateChanger = title => {
    // setclosedialer(title);
    setIsDialerOpen(true);
  };
  const stateChanger1 = title => {
    setshowprospet(title);
    setIsDialerOpen(true);
  };
  const closedialer = () => {
    setclosedialer(!isDialerOpen);
  };
  const NoteToggle = () => {
    setnotetoggle(!notetoggle);
  };
  useEffect(() => {
    if (conn != null) {
      conn.mute(mic);
    }
  }, [mic]);
  const disconnect = () => {
    console.log('Home.js connection>>', connection);
    console.log('Inside Before Home.js >> disconnect>> device >>', device);
    // Device.disconnectAll();
    device.disconnectAll();
    console.log('Inside Home.js >> disconnect>> device >>', device);
    if (connection) {
      connection.disconnect();
    }
    setallowdigit(false);
    // setdil(true);
    setoutPopDialogs(false);
    setIsDialerOpen(false);
    setclosedialer(false);
    closeDialog();
  };

  const handleCall = async () => {
    console.log('outCallDialogData', outCallDialogData.data);
    console.log('outCallDialogData.token', outCallDialogData.Token);
    let url = process.env.REACT_APP_PORT + `/api/me/getdetails`;
    var numb = outCallDialogData.data.params.To;
    setnum(numb);
    var config = {
      method: 'post',
      url: url,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: { option: numb }
    };

    axios(config)
      .then(function(response) {
        console.log('response.data.data[0]', response.data.data[0], response.data.data[1]);
        console.log(JSON.stringify(response.data.data));
        setdetails(response.data.data[0]);
        setcmpname(response.data.data[1]);
      })
      .catch(function(error) {
        console.log(error);
      });

    // var device1 = new Device(outCallDialogData.Token);
    var device1 = await initializeDevice(outCallDialogData.Token);
    console.log('device1', device1);
    setDevice(device1);

    try {
      console.log('device>>>', device1);
      let call = await device1.connect(outCallDialogData.data);
      if (call) {
        setcallconnect(true);
        toast.success('Calling...');
        setclosedialer(true);
        setdis(false);
        setallowdigit(true);
        // // callmonitor()
        // console.log("call",call)
        setConnection(call);
        setopen(true);
        setconn(call);
        callmonitor();
        // // device.on('connect', function(conn) {
        // //   console.log("Device connect 3")

        // //   // conn.mute(mic);
        // // });
        call.on('error', error => {
          console.log('An error has occurred: ', error);
          if (error.code != 31009 && errorexist != true) {
            setErrorExist(true);
          }
        });
        call.on('disconnect', function() {
          console.log('call.on disconnect');
          getVoices(0);
          setdis(true);
          setinbound(false);
          setTimer(false);
          // setdil(true);
          setmicrophone(false);
          setconn(null);
          setdetails(null);
          setshowprospet(false);
          setopen(false);
          // callmonitor()
          setclosedialer(false);
          setoutPopDialogs(false);
          setIsDialerOpen(false);
          closeDialog();
          if (handle) {
            clearInterval(handle);
          }

          setSeconds(0);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Popup modal open={isDialerOpen} closeOnDocumentClick={false}>
        {close => (
          <>
            <Card>
              <CardHeader>
                {/* {dil ? (
                  // <span class="pull-right clickable close-icon" data-effect="fadeOut">
                  //   <i onClick={closedialer} class="fa fa-times" />
                  // </span>
                  <></>
                ) : null} */}
              </CardHeader>
              <CardBody>
                {console.log(formshow, dil, isOpenDial, isDialerOpen)}
                {dil && isDialerOpen ? null : (
                  // <UserForm toogle={closedialer} show={true} changeStatus={changestatus} />
                  <>
                    <center className="pt-2">
                      <NewDialer
                        disconnect={disconnect}
                        savenotes={NoteToggle}
                        setmicrophone={setmicrophone}
                        mic={mic}
                        connection={connection}
                      />
                      <center className="pt-2">
                        <Button
                          onClick={() => {
                            setshowform(true);
                            setIsDialerOpen(false);
                          }}
                          size="small"
                          color="primary"
                          variant="contained"
                        >
                          Form
                        </Button>
                        {details != null && details == 'Not Found' && (
                          <Button
                            onClick={() => {
                              setshowprospet(true);
                              setIsDialerOpen(false);
                            }}
                            size="small"
                            color="primary"
                            variant="contained"
                            className="mx-1"
                          >
                            Add Prospect
                          </Button>
                        )}
                      </center>
                      {details != null && details != 'Not Found' && (
                        <p>
                          <strong>Prospect Info:</strong>
                          <br />
                          <strong>Name:</strong>{' '}
                          {details != null && details.hasOwnProperty('first_name') && details.first_name}{' '}
                          {details != null && details.hasOwnProperty('last_name') && details.last_name}
                          <br />
                          <strong>Campaign Name:</strong> {cmpname != null && cmpname} <br />
                          <strong>Address: </strong>{' '}
                          {details != null && details.hasOwnProperty('address') && details.address}{' '}
                          {details != null && details.hasOwnProperty('street') && details.street}{' '}
                          {details != null && details.hasOwnProperty('city') && details.city}{' '}
                          {details != null && details.hasOwnProperty('state') && details.state}
                          <br />
                          <strong>Email:</strong> {details != null && details.hasOwnProperty('email') && details.email}
                        </p>
                      )}
                      <center className="pt-2" />
                    </center>
                  </>
                )}
              </CardBody>
              {num ? <CardFooter>To :{num}</CardFooter> : null}
              {errorexist && (
                <Button size="sm" style={{ backgroundColor: '#F32013', color: '#FFFFFF' }}>
                  <FontAwesomeIcon icon="exclamation" />
                  Network issue
                </Button>
              )}
            </Card>
          </>
        )}
      </Popup>
      {formshow ? (
        <Modal size="lg" backdrop="static" isOpen={formshow} toggle={formtoogleToggle} style={{ width: '30%' }}>
          <ModalHeader toggle={formtoogleToggle}>
            <Button
              onClick={() => {
                setshowform(false);
                setIsDialerOpen(true);
              }}
              size="small"
              color="primary"
              variant="contained"
            >
              {' '}
              <i className="fas fa-long-arrow-alt-left my-2" />
              &nbsp;&nbsp; Back to Dialer
            </Button>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#f9fafd', height: '600px', overflow: 'auto' }}>
            <UserForm changeStatus={changestatus} to={num} />
          </ModalBody>
        </Modal>
      ) : null}
      {details != null && details == 'Not Found' && showprospet && (
        <div style={{ position: 'fixed', top: '20vh', right: '25vw', zIndex: 1 }}>
          <AddProspect1 stateChanger={stateChanger} stateChanger1={stateChanger1} />
        </div>
      )}

      {notetoggle ? (
        <Modal isOpen toggle={NoteToggle}>
          <ModalBody>
            <Row>
              <Col md="12">
                <Label>Call Notes {connection != null ? connection.parameters.From : ''}</Label>
              </Col>
            </Row>
            <Row>
              <Col md="12">{timer ? <div>{`${seconds} seconds`}</div> : null}</Col>
            </Row>
            <Row>
              <Col xs="12">
                <IMP type="text" onChange={e => setnotes(e.target.value)} placeholder="add note" />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                savenotes(note, connection.parameters.From, connection.parameters.CallSid);
                setnotetoggle(false);
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
};
const mapStateToProps = state => ({
  dialogOpen: state.Twilio.dialogOpen,
  outCallDialogData: state.Twilio.outCallDialogData
});

export default connect(
  mapStateToProps,
  { callmonitor, getVoices, closeDialog, savenotes }
)(DialerPopup);
// export default DialerPopup;

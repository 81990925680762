import React, { useState , useEffect} from 'react'
import {Row, Col, Card, CardBody, CardHeader, CardTitle, Label, Input, Popover, PopoverHeader, PopoverBody} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {createTemplate,getCategories, getOptOut} from "../../../Redux/Actions/Mask/Mask"
import {Button,IconButton} from "@material-ui/core"
import {Redirect} from 'react-router-dom'
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
const CreateTemplates = ({createTemplate,redirectTemp,getCategories,categories, getOptOut,optout})=> {

 useEffect(()=>{
        getCategories()
        getOptOut()
    }, [getCategories, getOptOut])
    let history = useHistory();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [name, setName] = useState("")
    const [category, setCategory] = useState("")
    const [message, setMessage] = useState("")


    
    const [optToken, setoptToken] = useState("");
    const toggle = () => setPopoverOpen(!popoverOpen);
    const callOptToken  = (title) => {
       
        setoptToken(title);
        setPopoverOpen(false)

    }
   const submit  = () => {
       
          if(name && message){
          let tempdata={"name":name,"category":category,"message":message}
         createTemplate(tempdata);
          }else{toast.success("Name and Message are required!")}
          history.push("/template");
   }
   const addTag  = (e,tag) => {
    e.target.blur()
          setMessage(message+tag);

   }
  
if(redirectTemp)
      {
        return <Redirect to={`/templates`} />;
      }
      return  <Row  className="justify-content-center ">
              <Col md="10">
                  <Card>

                      <CardHeader>
                      <CardTitle style={{fontSize: "0.95rem"}}>Create Template</CardTitle>
                      <b>Instructions</b>
                      <ol>
                      <li>You may use the <Button color="primary">[@street]</Button> dynamic field to name the property address you are interested in purchasing.</li>
                      <li>End with a question</li>
                      <li>It is required to use the OptOut Language merge tag. You may include this the body of message to make it sound more natural. Choose the language type from dropdown, You may update language on your accout settings.</li>
                      </ol>
                      </CardHeader>
                   
                     <Row>
                        <Col md="12">
                            <CardBody>
                                <Input placeholder="Template Name" onChange={(e)=>{setName(e.target.value)}} Label="Template Name" type="text"  name="templatename"></Input>
                            </CardBody>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                        <CardBody>
                    <Label >Category</Label>
                    <Input type="select" onChange={(e)=>{setCategory(e.target.value)}}>
                    <option value="">Select Category</option>
                    {categories.map((item, index)=>(
                                        <option key={item._id} value={item._id}>{item.name}</option>
                                    ))}
                    
                    </Input>
                    </CardBody>
                    </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <CardBody>
                                <Input placeholder="Message" onChange={(e)=>{setMessage(e.target.value)}} Label="Message" type="textarea"  name="message"  value={message}></Input>
                            </CardBody>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <CardBody>
                               <Button onClick={(e)=>{addTag(e,"[@firstName]")}}  color="primary">[@firstName]</Button>
                               <Button onClick={(e)=>{addTag(e,"[@lastName]")}}  color="primary">[@lastName]</Button>
                               <Button onClick={(e)=>{addTag(e,"[@street]")}}  color="primary">[@street]</Button>
                               <Button onClick={(e)=>{addTag(e,"[@city]")}}  color="primary">[@city]</Button>
                               <Button onClick={(e)=>{addTag(e,"[@state]")}}  color="primary">[@state]</Button>
                               <Button onClick={(e)=>{addTag(e,"[@zip]")}}  color="primary">[@zip]</Button>
                               <Button onClick={(e)=>{addTag(e,"[@email]")}}  color="primary">[@email]</Button>
                                {optToken!=""?<Button onClick={(e)=>{addTag(e,"[@"+optToken+"]")}}  color="primary">[@{optToken}]</Button>:null}
                               <IconButton id="Popover1" type="button">
                      <div className="circle">
                        <i className="fas fa-sort-down"/>
                      </div>
                  </IconButton>
                  <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
                    <PopoverHeader>OptOut Languages</PopoverHeader>
                  <PopoverBody>
                    {optout != null && optout.length > 0 && optout.map(item => (

                      <div className="btn btn-link" onClick={()=>callOptToken(item.title)} key={item._id} >{item.title}</div>
                    ))}
                    </PopoverBody>
                  </Popover>
                            </CardBody>
                        </Col>
                    </Row>
                    <Row className="p-4">
                        <Col md="12">
                            <Button variant="outlined" onClick={submit}  color="primary">Create</Button>
                        </Col>
                    </Row>
                </Card>
                    
              </Col>
          </Row>
    
  
}
CreateTemplates.propTypes = {
     setAlert: PropTypes.func.isRequired,
     createTemplate: PropTypes.func.isRequired,
     getCategories: PropTypes.func.isRequired,
     
  };
  const mapStateTOProps = (state)=> ({
    redirectTemp: state.bulk.redirectTemp,
    categories:state.bulk.categories,
    
    optout:state.bulk.optout


})
export default connect(mapStateTOProps, { createTemplate,getCategories, getOptOut})(CreateTemplates)
import React from "react"
import 'react-credit-cards/es/styles-compiled.css';
import {createcard,Add_card} from "../../../Redux/Actions/Payment"
import {connect} from "react-redux"
import {Redirect} from "react-router-dom"
import Cards from  "react-credit-cards"
import InputMask from 'react-input-mask'
import {Row, Col, Card, CardBody} from "reactstrap"
import "./style.css"
class  Checkout extends React.Component{
    state = {
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    check:false
  };
 
  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }
  
  handleInputChange = async (e) => {
    const { name, value } = e.target;
     this.setState({ [name]: value }); 
  }
   
     submit = async (e) => {
       if(this.props.check){
        e.preventDefault()
        this.setState({check:true})
        const {expiry} = this.state
        const exp_month = expiry.slice(0,2)
        const exp_year = expiry.slice(3)
        await this.setState({exp_month:exp_month, exp_year:exp_year })
        await this.props.Add_card(this.state)
        this.props.toggle()
       }else{
          e.preventDefault()
       this.setState({check:true})
       const {expiry} = this.state
       const exp_month = expiry.slice(0,2)
       const exp_year = expiry.slice(3)
       await this.setState({exp_month:exp_month, exp_year:exp_year })
       await this.props.createcard(this.state, this.props.item)
       this.setState({check:false})
       }
      
    }
    render(){
      if(this.props.paid)
      {
        return <Redirect to="/home/fund"></Redirect>
      }
        return <Row>
           <Col md={5} xs={12} sm={12}>  <Cards 
          cvc={this.state.cvc}
          expiry={this.state.expiry}
          focused={this.state.focus}
          name={this.state.name}
          number={this.state.number}
        /></Col>
     <Col md={7} xs={12} sm={12}>
            
            <Card> 
              <CardBody>
                  <form onSubmit={this.submit}>
      
            
            <Row>
              <Col xs={12} className="p-2" >
                <input style={{ fontSize: "0.95em"}} type="text" required onChange={this.handleInputChange} id="cname" name="name" placeholder="John More Doe"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="p-2">
                <InputMask style={{ fontSize: "0.95em"}} mask={"9999 9999 9999 9999"}  type="text"   required onChange={this.handleInputChange}  name="number" placeholder="1111-2222-3333-4444">
              
            </InputMask>
              </Col>
            </Row>
            
            <Row>
              <Col xs={12} className="p-2">
                <InputMask type="text" style={{ fontSize: "0.95em"}}  mask={"99/9999"}   required onChange={this.handleInputChange} id="expmonth" name="expiry" placeholder="01/2021"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="p-2">
                <input style={{ fontSize: "0.95em"}} type="text" required onChange={this.handleInputChange} maxLength="4"   pattern="\d*" required  id="cvv" name="cvc" placeholder="352"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="p-2">
                {this.state.check?<input style={{ fontSize: "0.95em"}} type="submit" value="Submit" disabled className="btn btn-primary"/>:<input style={{ fontSize: "0.95em"}} type="submit" value="Submit"  className="btn btn-primary"/>}
              </Col>
            </Row>
            
          
          
        
      </form>
   
              </CardBody>
              </Card>
     </Col>
          
    
  
  
</Row>
    }
}
const mapStateToProps = state=> ({
    paid: state.payment.paid
})
export default connect(mapStateToProps , {createcard,Add_card})(Checkout)
import styled, { css } from "styled-components";

export const Box = styled.div`
  bottom: 70px;
  right: 15px;
  width: 100%;
  max-width: 200px;
  padding: 15px;
  background: #ffff;
  visibility: hidden;
  opacity: 0;
  
  border-radius: .375rem;
  transform: translateY(30px);
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.15s ease-in, transform 0.25s ease-in,
   border-radius 0.3s ease-in-out, visibility 0s linear 0.3s;
  z-index: 10;
  ${props =>
    props.opened &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.15s ease-out, transform 0.25s ease-out,
        border-radius 0.3s ease-in-out, visibility 0s; 
      border-bottom-right-radius: 0;
    `}
`;

export const Input = styled.input`
  width: 100%;
  margin-bottom: 5px;
  border: none;
  background: #ffff;
  height: 35px;
  padding: 0 15px;
  border-radius: 4px;
  color: #000000;
  
  
`;

export const ButtonsContainer = styled.div`
  font-size: 0;
`;

export const Button = styled.button`
  display: inline-block;
  border-radius: 50%;
  
  width: calc(33.33% - 10px);
  font-size: 14px;
  background: transparent;
  color: #000000;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  transform: perspective(500px) rotateY(-15deg);
  border: none;
  &:hover {
    color: 	#000000;
    transform: perspective(500px) rotateY(15deg);
  }
  &:nth-child(3n - 1) {
    margin: 0 15px;
  }
`;

export const CallButton = styled.button`
  background: #8bc34a;
  color: #fff;
    display: inline-block;
  width: calc(33.33% - 10px);
  font-size: 14px;
  border: none;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  
  border-radius: 30px;
 
  transform: perspective(500px) rotateY(-15deg);
  &:nth-child(3n - 1) {
    margin: 0 15px;
  }
  &:hover {
    background: #7cb342;
    
    transform: perspective(500px) rotateY(15deg);
  }
`;

export const DisCallButton = styled.button`
  margin-top: 5px;
  background:  #e50000;
  color: #fff;
  display: inline-block;
  width: calc(33.33% - 10px);
  font-size: 14px;
  border: none;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  
  
  transform: perspective(500px) rotateY(-15deg);
  border-radius: 30px;
  
  &:nth-child(3n - 1) {
    margin: 0 15px;
  }
  &:hover {
    background: #cc0000;
    
    transform: perspective(500px) rotateY(15deg);
  }
`;
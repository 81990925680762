import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Label,
  Input as IMP,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { Button } from '@material-ui/core';
import { Device } from '@twilio/voice-sdk';
import { connect } from 'react-redux';
import { gettoken } from '../../../Redux/Actions/Twilio';
import PropTypes from 'prop-types';
import Loader from '../../../components/common/Loader';
import 'react-phone-input-2/lib/style.css';
import UserForm from './Form';
import './sigCanvas.css';
import Popup from 'reactjs-popup';
import GetCOmpany from '../../../components/GetCompany/getCompany';
import { useHistory } from 'react-router-dom';
import { getuserStatus, postuserStatus, savenotes, getVoices } from '../../../Redux/Actions/USER/user';
import Switch from '@material-ui/core/Switch';

import { toast } from 'react-toastify';
import NewDialer from './newdialer';

import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';
import AddProspect1 from '../Prospects/addProspect1';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserVoice from '../User/Voice';
import PhoneInput from 'react-phone-input-2';
import Dialmonitor from '../User/callmonitor';
import { sendmessage } from '../../../Redux/Actions/Twilio';
import Newmultiline from '../Multiline/newmultilineCall';
import { callmonitor } from '../../../Redux/Actions/USER/user';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import FormGroup from 'reactstrap/lib/FormGroup';
import { checkbal } from '../../../Redux/Actions/Payment';
import { openDialog } from '../../../Redux/Actions/Twilio';
// import Switch from '@material-ui/core/Switch';
const axios = require('axios');
const Dial = ({
  sendmessage,
  toggle,
  savenotes,
  Token,
  gettoken,
  numbers,
  getuserStatus,
  location,
  postuserStatus,
  callmonitor,
  getVoices,
  isPaid,
  count,
  openDialog
}) => {
  const [num, setnum] = useState('');
  const [dis, setdis] = useState(true);
  const [from, setfrom] = useState(null);
  const [inbound, setinbound] = useState(false); //
  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState(false);
  const [checked, setchecked] = useState(false);
  const [connection, setConnection] = useState(null);
  const [callconnect, setcallconnect] = useState(false);
  const [permissionStatus, setpermissionStatus] = useState('');
  const [allowdigit, setallowdigit] = useState(false);
  const [formshow, setshowform] = useState(false);
  const [search, setSearch] = useState('');
  const [details, setdetails] = useState(null);
  const [cmpname, setcmpname] = useState(null);
  const [modal, setmodal] = useState(false);
  const [notetoggle, setnotetoggle] = useState(false);
  const [body, setbod] = useState('');
  const [bodyerr, setbodyerr] = useState(false);
  const [err, setErr] = useState(false);
  const [showprospet, setshowprospet] = useState(false);
  const [open, setopen] = useState(false);
  const [dil, setdil] = useState(true); //
  const [errorexist, setErrorExist] = useState(false);
  const [note, setnotes] = React.useState('');
  const [isOpenDial, setclosedialer] = useState(false);
  const [mic, setmicrophone] = useState(false);
  const [conn, setconn] = useState(null);
  const [inboundcheck, setinboundcheck] = useState(false);
  const [outboundcheck, setoutboundcheck] = useState(false);
  const [device, setDevice] = useState();
  // console.log({inbound:inboundcheck})
  // console.log({outbound:outboundcheck})
  const handleInboundChange = () => {
    if (!inboundcheck && !outboundcheck) {
      setinboundcheck(true);
    } else {
      setinboundcheck(!inboundcheck);
      setoutboundcheck(!outboundcheck);
    }
  };
  const handleOutboundChange = () => {
    if (!inboundcheck && !outboundcheck) {
      setoutboundcheck(true);
    } else {
      setoutboundcheck(!outboundcheck);
      setinboundcheck(!inboundcheck);
    }
  };
  const history = useHistory();
  const formtoogleToggle = () => {
    setshowform(!formshow);
  };
  const Toggle = () => {
    setmodal(!Modal);
  };
  const closedialer = () => {
    setclosedialer(!isOpenDial);
  };
  const NoteToggle = () => {
    setnotetoggle(!notetoggle);
  };
  // useImperativeHandle(reff, () => ({
  //    DialToggle () {
  //     setclosedialer(true)
  //   }
  // }));
  // const DialToggle = () => {
  //   setclosedialer(true)
  // };

  const submitmsg = () => {
    if (num === '' || num.length > 13 || from === '') {
      setErr(true);
    } else {
      setErr(false);
      sendmessage(num, body, from);
      setnum('');
      setbod('');
      setmodal(!modal);
    }
  };

  const bodydata = e => {
    if (e.target.value.length > 1590) {
      setbodyerr(true);
      toast.error('Message Body length cannot be greater than 1590');
    } else {
      setbod(e.target.value);
      setbodyerr(false);
    }
  };

  if (location.state != undefined && location.state.inbound && !inbound) {
    console.log('location.state', location.state);
    history.replace('/call');
    setdetails(location.state.detailss);
    setcmpname(location.state.cmpnamee);
    setdil(false);
    setinbound(true);
    setclosedialer(true);
    setTimer(true);
  }
  // if (location.state != undefined && location.state.popp) {
  //   console.log("hitttt")
  //   // history.replace('/call');
  //   setshowform(false);
  //   setclosedialer(true);
  // }
  // console.log("history loc",history,location)

  const handleChange = event => {
    postuserStatus(event.target.checked);
    setchecked(event.target.checked);
  };

  useEffect(() => {
    const a = numbers.map(item => {
      if (item.activate) {
        return item.number;
      }
    });
    // console.log("i am in here")
    setfrom(a[0]);
    // console.log("from in useeffect",from)
  }, [numbers]);
  useEffect(() => {
    if (from == 'buynumber') {
      {
        history.push('/buynumber');
      }
    }
  }, [from]);

  const [handle, setHandle] = useState(null);
  useEffect(() => {
    getuserStatus();
    if (toggle != checked) {
      setchecked(toggle);
    }
    if (timer) {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
      setHandle(interval);
    }
    const param = new URLSearchParams(window.location.search);
    const f = param.get('from');

    const g = param.get('to');
    localStorage.removeItem('redirecturl');

    // setfrom(`+${f}`);
    setnum(g);

    if (Token === '') {
      gettoken();
    } else {
      fetchdata();
    }
    async function fetchdata() {
      var device1 = new Device(Token);
      setDevice(device1);
      console.log('device >>>>>>>>>>', device);
      // if(device){
      // setAlert("Got a Token", "success")
      // device.on('ready', function(device) {
      //   // setAlert("Successfully logged in", "success")
      //   // callmonitor()
      // });
      // device.on('connect', function(connection) {
      //   console.log("device.on connect")
      //   setConnection(connection);
      //   setopen(true);
      // });
      // device.on('error', err => {
      //   console.log("device.on error")
      //   if (err.code != 31009 && errorexist != true) {
      //     setErrorExist(true);
      //   }
      // });
      // device.on('canceled', function() {});
      // Device.on('incoming', function(connection) {
      //     setinbound(connection);
      //   //connection.accept();
      //   // do awesome ui stuff here
      //   // $('#call-status').text("you're on a call!");
      // });
      // device.on('disconnect', function() {
      //   console.log("device.on disconnect")
      //   // setAlert("Call Ended", "success")
      //   getVoices(0);
      //   setdis(true);
      //   setinbound(false);
      //   setTimer(false);
      //   setdil(true);
      //   setmicrophone(false);
      //   setconn(null);
      //   setdetails(null);
      //   setshowprospet(false);
      //   setopen(false);
      //   // callmonitor()
      //   setclosedialer(false);
      //   if (handle) {
      //     clearInterval(handle);
      //   }
      //   setSeconds(0);
      // });
      // }
    }
  }, [Token, gettoken, timer, getuserStatus]);

  useEffect(() => {
    return () => {
      if (handle) {
        clearInterval(handle);
      }
    };
  }, [handle]);
  const backtodialer = () => {
    setshowform(false);
    setclosedialer(true);
  };
  const changestatus = () => {
    setcallconnect(false);
  };
  //   if(device){
  //     console.log("device if device.on destroyed")
  //     device.on('destroyed', function() {
  //       console.log("device.on destroyed")

  //     });
  //   device.on('connect', function(conn) {
  //     console.log("Device connect 2")
  //     setconn(conn);
  //     callmonitor();

  //     // conn.mute(mic);
  //   });
  // }

  useEffect(() => {
    if (conn != null) {
      conn.mute(mic);
    }
  }, [mic]);

  // useEffect(() => {
  //   callmonitor()
  // }, [isOpenDial])

  // useEffect(() => {
  // Device.on('connect', function(conn) {
  //   conn.mute(mic);
  // });
  // // (async ()=>{
  // //   let stream = null;

  // // try {
  // //   stream = await navigator.mediaDevices.getUserMedia({audio:!mic});
  // //   /* use the stream */
  // // } catch(err) {
  // //   console.log({err})
  // //   /* handle the error */
  // // }
  // // })();

  // }, [mic])

  // checkbal();
  // console.log("paid is",isPaid)
  const submit = async numb => {
    // const ab=await checkbal();
    // console.log("ab",ab)
    const res = await axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/checkbal`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    setErrorExist(false);
    if (res.data.data) {
      // let url = process.env.REACT_APP_PORT + `/api/me/getdetails`;
      // // var data1 = numb

      // var config = {
      //   method: 'post',
      //   url: url,
      //   headers: {
      //     'x-auth-token': localStorage.getItem('token')
      //   },
      //   data: { option: numb }
      // };

      // axios(config)
      //   .then(function(response) {
      //     console.log('response.data.data[0]', response.data.data[0], response.data.data[1]);
      //     console.log(JSON.stringify(response.data.data));
      //     setdetails(response.data.data[0]);
      //     setcmpname(response.data.data[1]);
      //   })
      //   .catch(function(error) {
      //     console.log(error);
      //   });
      await navigator.permissions.query({ name: 'microphone' }).then(async function(permissionstatus) {
        // granted, denied, prompt
        // await setpermissionStatus(permissionstatus.state)
        // permissionStatus.onchange = function(){
        //     console.log("Permission changed to " + this.state);
        // }

        // debugger
        if (permissionstatus.state === 'granted') {
          if (numb == null || from == '+null' || numb.length === 0) {
            toast.error('Please select from number');
          } else if (numb.length > 13) {
            toast.error('Enter a Valid Number');
          } else {
            const data = {
              params: {
                To: numb,
                From: from,
                FromNum: from
              }
            };
            console.log('Device.connect >> data', data);
            let url = process.env.REACT_APP_PORT + `/api/logdevice`;
            var data1 = JSON.stringify({
              Device: 'Browser'
            });

            var config = {
              method: 'post',
              url: url,
              headers: {
                'Content-Type': 'application/json'
              },
              data: data1
            };

            axios(config)
              .then(function(response) {
                console.log(JSON.stringify(response.data));
              })
              .catch(function(error) {
                console.log(error);
              });
            // sendcall(numb,from)
            console.log('device.connect', device, 'data', data, 'details', details, 'cmpname', cmpname);
            openDialog(data, Token);
            // let call=await device.connect(data);
            // if(call){
            // setcallconnect(true);
            // toast.success('Calling...');
            // setdis(false);
            // setallowdigit(true);
            // setdil(!dil);
            // // callmonitor()
            // setclosedialer(true);
            // console.log("call",call)
            // setConnection(call);
            // setopen(true);
            // setconn(call);
            // callmonitor();
            // // device.on('connect', function(conn) {
            // //   console.log("Device connect 3")

            // //   // conn.mute(mic);
            // // });
            // call.on('error', (error) => {
            //   console.log('An error has occurred: ', error);
            //   if (error.code != 31009 && errorexist != true) {
            //     setErrorExist(true);
            //   }
            // });
            // call.on('disconnect', function() {
            //   console.log("call.on disconnect")
            //   getVoices(0);
            //   setdis(true);
            //   setinbound(false);
            //   setTimer(false);
            //   setdil(true);
            //   setmicrophone(false);
            //   setconn(null);
            //   setdetails(null);
            //   setshowprospet(false);
            //   setopen(false);
            //   // callmonitor()
            //   setclosedialer(false);
            //   if (handle) {
            //     clearInterval(handle);
            //   }

            //   setSeconds(0);

            // });
            // }
          }
        } else {
          toast.error('Micro-Phone is disabled please enabled it');
        }
      });
    } else {
      toast.error('Please check Funds');
    }
  };
  const stateChanger = title => {
    setclosedialer(title);
  };
  const stateChanger1 = title => {
    setshowprospet(title);
  };
  const disconnect = () => {
    console.log('Home.js connection>>', connection);
    console.log('Inside Before Home.js >> disconnect>> device >>', device);
    // Device.disconnectAll();
    device.disconnectAll();
    console.log('Inside Home.js >> disconnect>> device >>', device);
    if (connection) {
      connection.disconnect();
    }
    setallowdigit(false);
    setdil(true);

    setclosedialer(false);
  };
  //switch logic
  const [state, setState] = useState({
    checkedB: false
  });
  const handleChangeSwitch = event => {
    if (event.target.checked) {
      window.location.href = '/call/multilinedialer';
    }
    // setState({ ...state, [event.target.name]: event.target.checked });
  };
  // console.log('checkedB',state.checkedB)

  const ref = useRef();
  const handelOpen = () => {
    setshowform(false);
    setclosedialer(true);
  };
  // useEffect(() => {

  // }, [ref]);

  //GeoCallerIdCode
  async function geoCallerIDNumber(value, code) {
    if (value && code.dialCode) {
      console.log('value.toString().length=', value.toString().length);
      var countryCode = code.dialCode;
      console.log('countryCode=', countryCode);
      var codeLength = code.dialCode.toString().length;
      console.log('codeLength=', codeLength);
      var areaCode = '';
      console.log('value.toString().length=', value.toString().length);
      if (value.toString().length > 7) {
        areaCode = value.toString().substring(codeLength, codeLength + 3);

        const getAreaOrCountryNumberGeoCallerID = await axios({
          method: 'get',
          url:
            process.env.REACT_APP_PORT +
            `/api/me/getAreaOrCountryNumberGeoCallerID?areaCode=${areaCode}&countryCode=${countryCode}`,
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        });

        var areaNumber = getAreaOrCountryNumberGeoCallerID.data.data.areaNumber;
        var countryNumber = getAreaOrCountryNumberGeoCallerID.data.data.countryNumber;
        var defaultNumber = getAreaOrCountryNumberGeoCallerID.data.data.defaultNumber;
        var geocallerid = getAreaOrCountryNumberGeoCallerID.data.data.geocallerid;
        if (geocallerid == true && areaNumber != '') {
          console.log('areaNumber=', areaNumber);
          setfrom(areaNumber);
        } else if (geocallerid == true && countryNumber != '') {
          console.log('countryNumber=', countryNumber);
          setfrom(countryNumber);
        } else if (geocallerid == true && defaultNumber != '') {
          console.log('defaultNumber=', defaultNumber);
          setfrom(defaultNumber);
        } else {
          console.log('nothing to do');
        }
      }
    }
    // getGeoCallerAreaAndCountryNumber(countryCode,areaCode)
  }
  //Get area number and country number

  // async function getGeoCallerAreaAndCountryNumber(countryCode,areaCode){

  // }

  return (
    <div>
      {open ? (
        <Button
          style={{
            backgroundColor: '#0bf957',
            position: 'fixed',
            top: '12vh',
            right: '4vw',
            zIndex: 1,
            borderRadius: '48%'
          }}
          onClick={handelOpen}
        >
          <img src="/images/icons/call.png" />
        </Button>
      ) : null}
      {state.checkedB === false ? (
        <Row>
          <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
            <Card>
              <Background image={corner1} className="bg-card" />
              <Row className="mb-2">
                <Col sm={{ size: 5 }} style={{ marginLeft: '24px' }}>
                  <Label>Select Company</Label>

                  <GetCOmpany />
                </Col>

                <Col sm={{ size: 5, offset: 1 }}>
                  <Label>From Number</Label>
                  <IMP
                    style={{ fontSize: '0.95em' }}
                    type="select"
                    id="exampleSelect"
                    bsSize="sm"
                    onChange={e => setfrom(e.target.value)}
                    name="from"
                  >
                    {/* <option value="">Select Dialer Id</option> */}

                    {numbers != undefined && numbers && numbers[0] && numbers[0].hasOwnProperty('number') && (
                      <option selected={numbers[0].number} value={numbers[0].number}>
                        {numbers[0].name ? numbers[0].name : numbers[0].number}
                      </option>
                    )}

                    {numbers != undefined &&
                      numbers &&
                      numbers.slice(1).map(item => {
                        if (item.activate) {
                          return (
                            <option key={item._id} value={item.number}>
                              {item.name ? item.name : item.number}
                            </option>
                          );
                        }
                      })}
                    {numbers == '' && <option value="">Select Number</option>}
                    {numbers == '' && <option value="buynumber">Buy Number</option>}
                    {/* {numbers&&console.log("numbers is",numbers)} */}
                  </IMP>
                  {numbers != '' && (
                    <small>{from != '+null' && from != null && from != '' && `Caller id will be ${from}`}</small>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      ) : null}
      {state.checkedB === false ? (
        <Row className="mt-3">
          <Col lg={6} className="pr-lg-2 mb-3 mb-lg-0">
            {dil ? (
              <div ref={ref}>
                <Card>
                  <Background image={corner3} className="bg-card" style={{ zIndex: 1 }} />
                  <CardBody style={{ zIndex: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Tooltipbutton title="Dial single line at a time">
                        <span style={{ fontWeight: 500, marginRight: '30px', fontSize: '16px', paddingTop: '5px' }}>
                          Dialer
                        </span>
                      </Tooltipbutton>
                      <Switch
                        checked={state.checkedB}
                        onChange={handleChangeSwitch}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <Tooltipbutton title="Dials multiple line at a time">
                        <span style={{ fontWeight: 500, marginLeft: '30px', fontSize: '16px', paddingTop: '5px' }}>
                          Multidialer
                        </span>
                      </Tooltipbutton>
                    </div>
                  </CardBody>
                </Card>

                <Card className="mt-2">
                  <CardBody style={{ backgroundColor: '#f9fafd' }} className="pl-7">
                    <Row>
                      <Col sm={{ size: '8', offset: '3' }}>
                        <p style={{ fontSize: '30px', fontWeight: 'bold' }}>Make a call</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={{ size: '8', offset: '3' }} className="ml-4 pl-6">
                        {/* <PhoneInput onChange={phone => setnum(phone)} value={num} /> */}
                        <PhoneInput
                          onChange={async (value, country, e, formattedValue) => {
                            geoCallerIDNumber(value, country);
                            setnum(value);
                          }}
                          value={num}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 pl-4">
                      <Col sm={{ size: '8', offset: '2' }}>
                        <button
                          // color="success"

                          className="ml-2 btn btn-success btn-md"
                          onClick={() => submit(num)}
                        >
                          <i class="fas fa-phone-alt" />
                          &nbsp;&nbsp;Call
                        </button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="sm"
                          className="ml-2"
                          onClick={() => setmodal(!modal)}
                        >
                          Message &nbsp;&nbsp;
                          <i class="far fa-envelope" />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <CardBody className="pl-6">
                          <Col xs={12} className="pt-2">
                            <small id="emailHelp" class="form-text text-muted">
                              Your call will be placed using the browserphone.{' '}
                            </small>
                          </Col>
                        </CardBody>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            ) : (
              <div ref={ref}>
                {/* {!dis ? (
                  <Card>
                    <CardBody>
                      <AudioVisual dis={dis} />
                    </CardBody>
                    </Card>
                  ) : null} */}
                <Row>
                  {Token !== '' && inbound ? (
                    <div>
                      <Col xs="12">
                        {/*                           
                          <NewDialer disconnect={disconnect} savenotes={NoteToggle} /> */}
                      </Col>
                      <Col xs="12" className="mt-2 ">
                        <Card>
                          <CardBody className="p-2">
                            <Label>Inbound Call {connection != null ? connection.parameters.From : ''}</Label>
                            <Row>
                              <Col xs={12}>{timer ? <div>{`${seconds} seconds`}</div> : null}</Col>
                            </Row>
                            <Row>
                              <Col xs="8">
                                <IMP type="text" onChange={e => setnotes(e.target.value)} placeholder="add note" />
                              </Col>
                              <Col xs="4">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    savenotes(note, connection.parameters.From, connection.parameters.CallSid);
                                  }}
                                >
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </div>
                  ) : null}

                  {/* <Col xs="12"> 
                    <NewDialer disconnect={disconnect}/>
                  </Col> */}
                </Row>
              </div>
            )}
          </Col>
          <Col lg={dil ? 6 : 12} className="pr-lg-2 mb-3 mb-lg-0">
            <Card>
              <Background image={corner3} className="bg-card" />
              <CardBody>
                <Label style={{ marginRight: '30px', fontSize: '16px', paddingTop: '5px' }}>Active Calls</Label>
                {/* {details != null && details != "Not Found" && (
                  <p>
                    Name:{details != null && details.hasOwnProperty('first_name') && details.first_name}{' '}
                    {details != null && details.hasOwnProperty('last_name') && details.last_name}
                    <br />
                    Address: {details != null && details.hasOwnProperty('address') && details.address}{' '}
                    {details != null && details.hasOwnProperty('street') && details.street}{' '}
                    {details != null && details.hasOwnProperty('city') && details.city}{' '}
                    {details != null && details.hasOwnProperty('state') && details.state}
                    <br />
                    Email:{details != null && details.hasOwnProperty('email') && details.email}
                  </p>
                )} */}
                {details != null && details == 'Not Found' && showprospet && (
                  <div style={{ position: 'fixed', top: '20vh', right: '25vw', zIndex: 1 }}>
                    <AddProspect1 stateChanger={stateChanger} stateChanger1={stateChanger1} />
                  </div>
                )}
              </CardBody>
              {/* <div style={{backgroundColor: '#e3e3e3',position:"fixed",top:"20vh",right:"4vw",zIndex:1}} ><AddProspect /></div> */}
            </Card>
            <Card className="mt-2">
              <Dialmonitor />
            </Card>
          </Col>
          {/* <Col lg={6} className="pl-lg-2 " style={{ height: ref?.current?.clientHeight || 'auto', overflow: 'auto' }}>
              <Card>
                <CardHeader className="pb-0">
                  <Label>Form</Label>
                </CardHeader>
                <CardBody className="pt-1" style={{ backgroundColor: '#f9fafd' }}>
                  <UserForm changeStatus={changestatus} />
                </CardBody>
              </Card>
            </Col> */}
        </Row>
      ) : (
        <Newmultiline handleswitch={handleChangeSwitch} />
      )}

      {state.checkedB === false ? (
        <div>
          {' '}
          <Row className="mt-3">
            <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
              <Card>
                <Background image={corner2} className="bg-card" />
                <Row className="pl-3 pr-3 pt-3 pb-1">
                  <Col>
                    <Label>Call logs</Label>
                  </Col>
                  <Col xs="4">
                    <Form style={{ display: 'flex' }}>
                      <FormGroup check style={{ marginLeft: '90px' }}>
                        <Label check>
                          <Input type="radio" name="radio1" onChange={() => handleInboundChange()} /> Inbound
                        </Label>
                      </FormGroup>
                      <FormGroup check style={{ marginLeft: '90px' }}>
                        <Label check>
                          <Input type="radio" name="radio1" onChange={() => handleOutboundChange()} /> Outbound
                        </Label>
                      </FormGroup>
                    </Form>{' '}
                  </Col>
                  <Col xs="3.5" className="mb-2">
                    <div className="search-box" style={{ float: 'Right' }}>
                      <Input
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        className="rounded-pill search-input"
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                      />
                      <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
              <UserVoice search={search} outboundcheck={outboundcheck} inboundcheck={inboundcheck} />
            </Col>
          </Row>{' '}
        </div>
      ) : null}

      {/* faltucode */}
      {/* <Row className={'justify-content-center'} />
        <Row style={{ paddingTop: '5%' }} className={'justify-content-center'}>
          <Col md={!dis ? '6' : '10'} xs={!dis ? '12' : '12'}>
            <Card>
              <Row>
                <Col xs="12">
                  {!dis ? (
                    <CardBody>
                      <AudioVisual dis={dis} />
                    </CardBody>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <CardBody>
                    <Col xs={12} className="pt-2">
                      <small id="emailHelp" class="form-text text-muted">
                        Your call will be placed using the browserphone
                      </small>
                    </Col>
                    <Col xs={12} className="pt-2">
                      <small id="emailHelp" class="form-text text-muted">
                        {from != '+null' && from != null && from != '' && `Caller id will be ${from}`}
                      </small>
                    </Col>
                  </CardBody>
                </Col>
              </Row>
              <Row className={'justify-content-center'}>
                <Col md={!dis ? '10' : '6'} xs={!dis ? '12' : '12'}>
                  <CardBody>
                    <Dialer callsubmit={submit} disconnect={disconnect} dis={dis} dial={allowdigit} />
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
          {callconnect == true && (
            <Col className="pt-2" md="6" xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle>Form</CardTitle>
                </CardHeader>
                <CardBody>
                  <UserForm changeStatus={changestatus} />
                </CardBody>
              </Card>
            </Col>
          )}
        </Row> */}
      <>
        {/* <Popup modal open={isOpenDial} closeOnDocumentClick={false}>
          {close => (
            <>
              <Card>
                <CardHeader>
                  {dil ? (
                    <span class="pull-right clickable close-icon" data-effect="fadeOut">
                      <i onClick={closedialer} class="fa fa-times" />
                    </span>
                  ) : null}
                </CardHeader>
                <CardBody>
                  {console.log(formshow, dil, isOpenDial)}
                  {dil && isOpenDial ? (
                    <UserForm toogle={closedialer} show={true} changeStatus={changestatus} />
                  ) : (
                    <>
                      <center className="pt-2">
                        <NewDialer
                          disconnect={disconnect}
                          savenotes={NoteToggle}
                          setmicrophone={setmicrophone}
                          mic={mic}
                          connection={connection}
                        />
                        <center className="pt-2">
                          <Button
                            onClick={() => {
                              setshowform(true);
                              setclosedialer(false);
                            }}
                            size="small"
                            color="primary"
                            variant="contained"
                          >
                            Form
                          </Button>
                          {details != null && details == 'Not Found' && (
                            <Button
                              onClick={() => {
                                setshowprospet(true);
                                setclosedialer(false);
                              }}
                              size="small"
                              color="primary"
                              variant="contained"
                              className="mx-1"
                            >
                              Add Prospect
                            </Button>
                          )}
                        </center>
                        {console.log('details', details)}
                        {details != null && details != 'Not Found' && (
                          <p>
                            <strong>Prospect Info:</strong>
                            <br />
                            <strong>Name:</strong>{' '}
                            {details != null && details.hasOwnProperty('first_name') && details.first_name}{' '}
                            {details != null && details.hasOwnProperty('last_name') && details.last_name}
                            <br />
                            <strong>Campaign Name:</strong> {cmpname != null && cmpname} <br />
                            <strong>Address: </strong>{' '}
                            {details != null && details.hasOwnProperty('address') && details.address}{' '}
                            {details != null && details.hasOwnProperty('street') && details.street}{' '}
                            {details != null && details.hasOwnProperty('city') && details.city}{' '}
                            {details != null && details.hasOwnProperty('state') && details.state}
                            <br />
                            <strong>Email:</strong>{' '}
                            {details != null && details.hasOwnProperty('email') && details.email}
                          </p>
                        )}
                      </center>
                    </>
                  )}
                </CardBody>
                {num ? <CardFooter>To :{num}</CardFooter> : null}
                {errorexist && (
                  <Button size="sm" style={{ backgroundColor: '#F32013', color: '#FFFFFF' }}>
                    <FontAwesomeIcon icon="exclamation" />
                    Network issue
                  </Button>
                )}
              </Card>
            </>
          )}
        </Popup> */}
      </>
      {/* {formshow ? (
        <Modal size="lg" backdrop="static" isOpen={formshow} toggle={formtoogleToggle} style={{ width: '30%' }}>
          <ModalHeader toggle={formtoogleToggle}>
            <Button
              onClick={() => {
                setshowform(false);
                setclosedialer(true);
              }}
              size="small"
              color="primary"
              variant="contained"
            >
              {' '}
              <i className="fas fa-long-arrow-alt-left my-2" />
              &nbsp;&nbsp; Back to Dialer
            </Button>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#f9fafd', height: '600px', overflow: 'auto' }}>
            <UserForm changeStatus={changestatus} to={num} />
          </ModalBody>
        </Modal>
      ) : null} */}
      {}
      {modal ? (
        <Modal isOpen toggle={Toggle}>
          <ModalBody>
            <Row>
              <Col md="12">
                <Label>To</Label>
                <PhoneInput onChange={phone => setnum(phone)} value={num} />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Label>Text</Label>
                <Input
                  style={{ fontSize: '0.95em' }}
                  placeholder="Enter a Text"
                  type="textarea"
                  onChange={bodydata}
                  name="body"
                  value={body}
                />
                <small id="emailHelp" class="form-text text-muted">
                  Enter your text here
                </small>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={submitmsg}
              disabled={bodyerr}
              color="primary"
              variant="contained"
              size="sm"
              className="mr-2"
            >
              Send Message
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
      {notetoggle ? (
        <Modal isOpen toggle={NoteToggle}>
          <ModalBody>
            <Row>
              <Col md="12">
                <Label>Inbound Call {connection != null ? connection.parameters.From : ''}</Label>
              </Col>
            </Row>
            <Row>
              <Col md="12">{timer ? <div>{`${seconds} seconds`}</div> : null}</Col>
            </Row>
            <Row>
              <Col xs="12">
                <IMP type="text" onChange={e => setnotes(e.target.value)} placeholder="add note" />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                savenotes(note, connection.parameters.From, connection.parameters.CallSid);
                setnotetoggle(false);
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </div>
  );

  //  else if (Token !== '' && inbound) {
  //   return (
  //     <div>
  //       <Row style={{ paddingTop: '7%' }} className={dis ? 'justify-content-center' : ''}>
  //         <Col xs="6">
  //           <Card>
  //             <CardHeader>
  //               <CardTitle>Inbound Call {connection != null ? connection.parameters.From : ''}</CardTitle>
  //             </CardHeader>
  //             <CardBody>
  //               <Row>
  //                 <Col xs={12} className={'p-4'}>
  //                   {timer ? <div>{`${seconds} seconds`}</div> : null}
  //                 </Col>
  //               </Row>
  //               <Row>
  //                 <Col xs="6">
  //                   <IMP type="text" onChange={e => setnotes(e.target.value)} placeholder="add note" />
  //                 </Col>
  //                 <Col xs="6">
  //                   <Button
  //                     variant="contained"
  //                     onClick={() => {
  //                       savenotes(note, connection.parameters.From, connection.parameters.CallSid);
  //                     }}
  //                   >
  //                     Submit
  //                   </Button>
  //                 </Col>
  //               </Row>
  //               <Row>
  //                 <Col xs={12} className={'p-4'}>
  //                   <Button onClick={disconnect} variant="contained" color="secondary">
  //                     Disconnect
  //                   </Button>
  //                 </Col>
  //               </Row>
  //             </CardBody>
  //           </Card>
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // }
  // else {
  //   return <Loader />;
  // }
};
Dial.propTypes = {
  gettoken: PropTypes.func.isRequired,
  Token: PropTypes.string.isRequired
};
const mapStateToProps = state => ({
  Token: state.Twilio.token,
  toggle: state.Twilio.toggle,
  numbers: state.Twilio.numbers,
  isPaid: state.auth.isPaid
});
export default connect(
  mapStateToProps,
  { sendmessage, gettoken, savenotes, getuserStatus, postuserStatus, callmonitor, getVoices, openDialog }
)(Dial);

import React, { Fragment, useState, useEffect } from 'react';
import { Link,useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row ,Input,Label,Modal} from 'reactstrap';
import PricingCard from './PricingCard';
import PageHeader from '../common/PageHeader';
import { isIterableArray } from '../../helpers/utils';
import pricing from '../../data/pricing/pricing';
import {connect} from "react-redux"
import { getplans } from '../../Redux/Actions/Payment';
import { ModalHeader, ModalBody, ModalFooter,Modal as Md } from 'reactstrap';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@material-ui/core';
// import { getfund } from '../../Redux/Actions/Payment';
const Pricing = (isPaid) => {
  // State
  const [isYearly, setIsYearly] = useState(false);
  const [pop, setpop] = useState(false);
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [email, setemail] = useState('');
  const [phoneno, setphoneno] = useState('');
  const [address, setaddress] = useState('');
  const [modal, setmodal] = useState(false);
  const [bal, setbal] = useState(null);
//   const opentab = ()=>{
//     window.open("https://podio.com/webforms/25579499/1901445")
// }
const history = useHistory();
// useEffect(() => {
//   getfund();
  
// }, [getfund]);
useEffect(() => {
  (async () => {
    try {
      const res = await Axios({
        method: 'GET',
        url: process.env.REACT_APP_PORT + `/api/payment/paymentdata`,
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });

      // console.log('userdata', res.data.data);
      if(res.data.data<=0){
        setmodal(true)
      }
      setbal(res.data.data)
    } catch (e) {
      toast.error(e.response.data.error);
    }
  })();
  }
, [])
useEffect(()=>{ 
  (async () => {
    try {
      const res = await Axios({
        method: 'GET',
        url: process.env.REACT_APP_PORT + `/api/payment/getuserdata`,
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });

      // console.log('userdata', res.data.data);
     
     
      if(res.data.data.message==="3000+"&&!isPaid){
       
        setpop(true)
        
      }
    } catch (e) {
      toast.error(e.response.data.error);
    }
  })();
  getplans()
}, [getplans])
const takeme =()=>{
  history.push("/home/fund");
}
const Toggle = () => {
  setmodal(!modal);
};
const handleSubmit = async () => {
  if (firstname == '' || lastname == '' || email == '' || phoneno == '' || address == '') {
    toast.error('All fields are required');
  } else {
    // console.log('form', firstname, lastname, email, phoneno, address);
    try {
      const res = await Axios({
        method: 'POST',
        url: process.env.REACT_APP_PORT + `/api/payment/senddata`,
        data: { firstname, lastname, email, phoneno, address },
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      // console.log('datttaaa', res.data);
      setpop(!pop);
      
      // window.location.reload();

    } catch (e) {
      toast.error(e.response.data.error);
    }
  }
  // setRedirect(true);
};
const toggle = () => setpop(!pop);
  return (
    <Fragment>
      <PageHeader
        title="Pricing plans"
        titleTag="h2"
        
        className="mb-3"
        col={{ lg: 9 }}
      ><Link to="/contactus">
        <Button color="link"  size="sm" className="pl-0" to="#!">
          Have questions? Contact us
        </Button>
        </Link>
      </PageHeader>

      <Card className="mb-3">
        <CardBody>
          <Row className="justify-content-center" noGutters>
             {isIterableArray(pricing) &&
                pricing.map((pricingItem, index) => (
                  <Col lg={4} className={`${index !== 1 ? 'border-lg-y' : 'border-y'}`} key={index}>
                    <PricingCard {...pricingItem}  isYearly={isYearly} />
                  </Col>
                ))}

           

          </Row>
        </CardBody>
      </Card>
    <Md isOpen={pop} toggle={toggle}>
        <ModalHeader style={{ fontSize: '32px', fontWeight: 'bold' }} toggle={toggle}>
          Alert!!
        </ModalHeader>
        <ModalBody>
          <Carousel>
            <Paper>
              <p>
                Dear user , You have selected an option that your daily outbound SMS is greater than 3000.
                <br />
                T-Mobile daily message limits for long code messaging with A2P 10DLC Under the new A2P 10DLC service for
                sending messages via 10-digit long code numbers in the United States, there are changes to how message
                sending capacity is allocated. Message sending throughput (MPS) is changing and will be based on your
                Trust Score, as described in Message throughput (MPS) and Trust Scores for A2P 10DLC in the US. In
                addition, T-Mobile (including Sprint) is imposing daily message limits based on your Trust Score. This
                sets a total number of outbound SMS segments and MMS messages that your registered A2P Brand can send
                toward T-Mobile (incl. Sprint) subscribers per day.
              </p>
              <a href="https://support.twilio.com/hc/en-us/articles/1260804800549-T-Mobile-daily-message-limits-for-long-code-messaging-with-A2P-10DLC">Click here for more information</a>
              <p>
                
              <Row>
                <Col>Registration Fees</Col>
                <Col>$44 Secondary Brand registration and $6 Service Charge</Col>
                <Col>$10 per month Campaign use case registration</Col>
              </Row>
             
              <a href="https://www.twilio.com/blog/a2p-10dlc-isv-starter-brands">Click here for more information</a>
              </p>
            </Paper>
            <Paper>
              <Row className="p-4">
                <Col md="6">
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    onChange={({ target }) => setfirstname(target.value)}
                    placeholder="firstname"
                    value={firstname}
                  />
                </Col>
                <Col md="6">
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    onChange={({ target }) => setlastname(target.value)}
                    placeholder="lastname"
                    value={lastname}
                  />
                </Col>
              </Row>

              <Row className="p-4">
                <Col md="6">
                  <Label>Email</Label>
                  <Input
                    type="email"
                    onChange={({ target }) => setemail(target.value)}
                    placeholder="Email"
                    value={email}
                  />
                </Col>
                <Col md="6">
                  <Label>SMS-capable phone number</Label>
                  <Input
                    type="text"
                    onChange={({ target }) => setphoneno(target.value)}
                    placeholder="Phone number"
                    value={phoneno}
                  />
                </Col>
              </Row>

              <Row className="p-4">
                <Col md="12">
                  <Label>Billing address</Label>
                  <Input
                    type="text"
                    onChange={({ target }) => setaddress(target.value)}
                    placeholder="Address"
                    value={address}
                  />
                </Col>
              </Row>
            </Paper>
            <Paper>
              <p>Thankyou for you patience . Please Continue</p>
              <Button color="primary" block className="mt-3" onClick={handleSubmit}>
                Register
              </Button>
            </Paper>
          </Carousel>
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter> */}
      </Md>{modal ? (
              <Modal isOpen toggle={Toggle}>
                <ModalHeader toggle={Toggle} style={{ backgroundColor: '#becdda8f' }}>
                  <h3 style={{ fontWeight: 'bold' }}>Alert </h3>
                </ModalHeader>
                <ModalBody>Please add fund to the wallet.Your current balance is {bal&&bal.toFixed(2)}$</ModalBody>
                <ModalFooter>
                  <Button className="mr-2" variant="contained" color="secondary" onClick={() => Toggle()}>
                  Remind me later
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#28a745', color: 'white' }}
                    onClick={() => takeme()}
                  >
                     Take me to Wallet
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
    </Fragment>
  );
};
const mapeStateTOProps = state => ({
  
  isPaid: state.auth.isPaid
});
export default connect(null, getplans)(Pricing);

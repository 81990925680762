import React from 'react';
import { Row, Col, Card, CardBody, Button, Input, Label, CardHeader, CardTitle , Progress} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createCampaign } from '../../../Redux/Actions/Mask/Mask';
import { withRouter } from 'react-router-dom';
import { getallCompanies } from '../../../Redux/Actions/USER/user';
import { socket } from '../Main/socket';
import { toast } from 'react-toastify';
class MaskTexting extends React.Component {
  state = {
    name: '',
    firstname: '',
    lastname: '',
    address: '',
    state: '',
    city: '',
    phone: '',
    zip: '',
    street: '',
    email: '',
    country: '',
    company: '',
    show:false,
    prospect_count:0,
    prospect_show:false
  };
  // componentDidMount() {
  //     getallCompanies()
  //   }

  
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
pccount=msg=>{
  this.setState({ prospect_count: msg })
}
psshow=msg=>{
  this.setState({ prospect_show: msg })
}
  submit = async() => {
    this.setState({show:true})
    const { name, firstname, lastname, address, state, city, phone, zip, street, email, country, company } = this.state;
    const uploadfile = this.props.uploadfile;
    if ((name === '' && this.props.id == undefined) || phone === '' || company === ''||company===undefined) {
      toast.error('Company, Name and Phone Number is required');
      this.setState({show:false})
    } else {
    //   socket.addEventListener("campid", msg=> {
    //     console.log("campid socket", msg);
    //         let page='/pages/campaigns/'+msg
    // this.props.history.push(page);

    // })
  //   if(localStorage.getItem('campiddddd'))
  //   {console.log("local storage id",localStorage.getItem('campiddddd'))
  //   let str=  localStorage.getItem('campiddddd')
  //   let page='/pages/campaigns/'+str
  //   console.log("page is",page)
  //   this.props.history.push(page);
  // }
      this.props.history.push('/pages/campaigns');
      toast.success('Campaign creation started');
      await this.props.createCampaign(
        {
          name,
          firstname,
          lastname,
          address,
          state,
          city,
          phone,
          zip,
          street,
          email,
          country,
          uploadfile,
          company
        },
        this.props.id
      );
  //     if(localStorage.getItem('campiddddd'))
  //   {console.log("local storage id",localStorage.getItem('campiddddd'))
  //   let str=  localStorage.getItem('campiddddd')
  //   let page='/pages/campaigns/'+str
  //   this.props.history.push(page);
  // }
      // this.props.history.push('/pages/campaigns');
    }
  };
  
  render() {
    var pshow=false
    const serialState=localStorage.getItem('cdatastate')
    socket.addEventListener("totalpros", msg=> {
      console.log("totalpros socket", msg);
      // this.setState({ [prospect_count]: msg })
      // this.setState({ [this.state.prospect_count]: msg })
      // pcount=msg;
      this.pccount(msg)
      // setprospect_count(msg)
  })
  socket.addEventListener("prospectshow", msg=> {
    console.log("prospectshow", msg);
    localStorage.setItem('pshow', msg);
    // this.setState({ [prospect_show]: msg })
    // this.setState({ [this.state.prospect_show]: msg })
    this.psshow(msg)
    // setprospect_show(msg)
  })
  // useEffect(() => {
  //   if(this.state.prospect_show==false)
  //   console.log("inside useeffect",pshow)
  //   pshow=false
  //   console.log("inside useeffect after change",pshow)
  // }, [this.state.prospect_show])
  
  pshow=localStorage.getItem('pshow');
    // console.log(this.props.companies, 'companies');
    // console.log(this.state.company, 'company');
    const { upload } = this.props;
    return (<div>
      
      <Card>
        <CardHeader>
          <CardTitle style={{ fontSize: '0.95em' }}>Create Campaign</CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="p-4">
            <Col md="12" className="mb-4">
            <Label>Select Company</Label> 
              <Input style={{ fontSize: '0.95em' }} type="select" onChange={this.onChange} name="company">
                <option value="">Select Company</option>
                {this.props.companies &&
                  this.props.companies.map(item => (
                    <option selected={item._id === this.state.company} key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </Col>
            <Col md="12">
              {this.props.id == undefined && (
                <>
                  {' '}
                  <Label>Campaign Name</Label> <Input type="text" onChange={this.onChange} name="name" />
                </>
              )}
            </Col>
          </Row>
          <Row className="p-4">
            <Col md="6">
              <Label>First Name</Label>
              <Input type="select" onChange={this.onChange} name="firstname">
                <option>Select First Name</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md="6">
              <Label>Last Name</Label>
              <Input type="select" onChange={this.onChange} name="lastname">
                <option>Select Last Name</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
          <Row className="p-4">
            <Col md="6">
              <Label>Address</Label>
              <Input type="select" onChange={this.onChange} name="address">
                <option>Select Address</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md="6">
              <Label>Street</Label>
              <Input type="select" onChange={this.onChange} name="street">
                <option>Select Street Code</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>

          <Row className="p-4">
            <Col md="6">
              <Label>City</Label>
              <Input type="select" onChange={this.onChange} name="city">
                <option>Select City</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md="6">
              <Label>State</Label>
              <Input type="select" onChange={this.onChange} name="state">
                <option>Select State</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>

          <Row className="p-4">
            <Col md="6">
              <Label>Zip Code</Label>
              <Input type="select" onChange={this.onChange} name="zip">
                <option>Select Zip Code</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md="6">
              <Label>Country</Label>
              <Input type="select" onChange={this.onChange} name="country">
                <option>Select Country</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>

          <Row className="p-4">
            <Col md="6">
              <Label>Phone</Label>
              <Input type="select" onChange={this.onChange} name="phone">
                <option>Select Phone</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
            <Col md="6">
              <Label>Email</Label>
              <Input type="select" onChange={this.onChange} name="email">
                <option>Select Email</option>
                {upload.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>

          <Row className="p-4">
            <Col md="12">
              <Button onClick={this.submit} color="success" disabled={this.state.show} >
                Save
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      </div>
    );
  }
}
MaskTexting.propTypes = {
  createCampaign: PropTypes.func.isRequired
};
const mapStateTOProps = state => ({
  upload: state.bulk.uploaded,
  companies: state.Twilio.companies
});
const Location = withRouter(MaskTexting);
export default connect(
  mapStateTOProps,
  { getallCompanies, createCampaign }
)(Location);

import React, {useEffect,useState} from "react"
import {connect} from "react-redux"
import PropTypes from 'prop-types';
import {getCONFIG, savefilterdata} from "../../../Redux/Actions/Mask/Mask"
import { Card, Row, Col, CardBody, Label} from "reactstrap"

import Switch from '@material-ui/core/Switch';
const Config = ({config,getCONFIG,savefilterdata})=>{
    const [autofilter, setfilter] = useState(false)
    const [autodbdfilter, setdndfilter] = useState(false)
    useEffect(()=>{
        getCONFIG()
        if(config != null){
           setfilter(config.autofilter)
           setdndfilter(config.autodndfilter )
       }
     
    }, [getCONFIG,config == null])
    const savefilter = ()=>{
        savefilterdata(autofilter, autodbdfilter)
      }
    
        return  <Row className="justify-content-center ">
        
        
        
        <Col md={12} className="pt-4">
          <Card>
            <CardBody>
              <Row>
                 <Col md={2} xs={2}>
                   <Label>Auto Dead</Label>
                 </Col>
                 <Col md={8} xs={8}>
                     <p style={{fontSize: "0.86rem"}}>Choose whether your campaigns mark prospects as auto-dead</p>
                 </Col>
                 <Col md={2}  xs={2}>
                   <Switch
                         checked={ autodbdfilter}
                         onChange={() => {
                          setdndfilter(!autodbdfilter)
                         }} /> 
                 </Col>
              </Row>
              <Row>
                 <Col md={2} xs={2}>
                  <Label>Auto Filter</Label>
                 </Col>
                 <Col md={8} xs={8}>
                     <p style={{fontSize: "0.86rem"}}>Choose whether your campaigns filters out "STOP" messages</p>
                 </Col>
                 <Col md={2}  xs={2}>
                      <Switch
                         checked={autofilter}
                         onChange={() => {
                          setfilter(!autofilter)
                         }} /> 
                 </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div onClick={savefilter} className="btn btn-primary btn-xs">Save</div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    
}
const mapeState = (state)=>({
  config: state.bulk.config  
})

Config.propTypes = {
    getCONFIG: PropTypes.func.isRequired,
  };
export default connect(mapeState, {getCONFIG, savefilterdata})(Config)
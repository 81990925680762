import {createStore, compose, applyMiddleware} from 'redux'
import thunk from "redux-thunk"
import createDebounce from 'redux-debounced'
import rootReducer from '../Reducer/rootreducer'
const middleware = [thunk, createDebounce()]
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


 const saveStateToLocal = (state)=> {
    try {
        const savedata = JSON.stringify(state)
    localStorage.setItem("state", savedata )
    }
    catch(e)
    {
        console.log(e)
    }

}
function LoadState (){
     try {
        
    const loadStateLocal = localStorage.getItem("state" )
    if(loadStateLocal === null) return undefined
    return JSON.parse(loadStateLocal)
    }
    catch(e)
    {
        console.log(e)
        return undefined
    }
}
const persistState = LoadState()
const store = createStore(
    rootReducer,
    {auth:persistState},
    composeEnhancer(applyMiddleware(...middleware))
)
store.subscribe(()=>{
   
    saveStateToLocal(store.getState().auth)
})

export {store}
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { createQR,getQRS,getQR,deleteQR,updateQR} from '../../../Redux/Actions/Mask/Mask';
import classNames from 'classnames';
import {Button as BT} from "@material-ui/core"
import {
    Card,
    CardBody,
    UncontrolledDropdown,
    UncontrolledTooltip,
    DropdownToggle,
    DropdownMenu,
    Row, Col,
    DropdownItem, Input,
    Modal, ModalHeader, ModalBody,Button, ModalFooter } from "reactstrap"
    import ButtonIcon from '../../../components/common/ButtonIcon';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { toast } from 'react-toastify';


const Category = ({createQR,getQRS,qrs,deleteQR,getQR,updateQR,qr }) => {
  const [id, setId] = useState(null)
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);  
  
  const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);
  const addToggle = () => {
    setAddModal(!addModal)
  };
  const editToggle = () => {
    setEditModal(!editModal)
  };
  const addTag  = (e,tag) => {
    e.target.blur()
            setMessage(message+tag);
  
     }
    useEffect(()=>{
        getQRS()
    }, [getQRS])
  useEffect(() => {
    if(qr)
    {   
        setTitle(qr.title)
        setMessage(qr.message)
    }
  }, [qr]);
 
  const addQR  = ()=>{

    if(title && message){
            let tempdata={"title":title,"message":message}
           createQR(tempdata);
           setAddModal(false);
            }else{toast.error("Name and Message are required!")}
  
  
   }
  const saveQR  = ()=>{

    if(title && message && id){
            let tempdata={"title":title,"message":message}
           updateQR(tempdata,id);
           setEditModal(false);
           setTitle("")
           setMessage("")
    }else{alert("Name and Message are required!", "danger")}
  
  
   }
  const deletelead =async(id)=>{
    deleteQR(id)
      
  }



    
 
  if (qrs == null) {
    return <Loader />;
  } else {
    return (
         <div className={classNames('kanban-column', { 'form-added':  false })}>
               <div className="kanban-column-header">
                    <h5 className="text-serif fs-0 mb-0">
                        {`Quick Replies`} <span className="text-500">({qrs.length})</span>
                    </h5>
                    <Button
                        color="falcon-primary"
                        size="sm"
                        id="qr-tooltip"
                        onClick={() => setIsOpenThreadInfo(prevState => !prevState)}
                                                >
                            <FontAwesomeIcon icon="info" />
                            <UncontrolledTooltip placement="left" target="qr-tooltip" innerClassName="fs--1">
                            Add the Quick reply for conversation
                            </UncontrolledTooltip>
                    </Button>
                </div>
          <div
            className="kanban-items-container scrollbar">
                { qrs && qrs.map(item=>(
                    <div key={item._id}
                    className="kanban-item"
                    >
                        <Card
                        className="kanban-item-card hover-actions-trigger"
                        onClick={() => {
                            setId(item._id)
                            setEditModal(true);
                            getQR(item._id)
                          }}
                        >  
                            <CardBody>
                                
                                <p
                                    className="mb-0 font-weight-medium text-sans-serif"
                                >{item.title}</p>
                                
                                    
                                <UncontrolledDropdown
                                className="position-absolute text-sans-serif t-0 r-0 mt-card mr-card hover-actions"
                                onClick={e => {
                                e.stopPropagation();
                                }}
                            >
                                <DropdownToggle color="falcon-default" size="sm" className="py-0 px-2">
                                <FontAwesomeIcon icon="ellipsis-h" />
                                </DropdownToggle>
                                <DropdownMenu right className="py-0">
                                <DropdownItem onClick={()=>deletelead(item._id)} className="text-danger">Remove</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            </CardBody>
                        </Card>
                    </div>
                ))}
            
             
        </div>
        <div className="kanban-column mr-3">
     
        <ButtonIcon
          className="bg-400 border-400"
          color="primary"
          block
          icon="plus"
          iconClassName="mr-1"
          onClick={() => {
            setAddModal(true);
            setTitle("")
            setMessage("")
          }}
        >
         Add New Quick Reply
        </ButtonIcon>
      
    </div>
    {addModal?
                        <Modal isOpen toggle={addToggle}>
           <ModalHeader toggle={addToggle}>Add New Quick Reply</ModalHeader>
            <ModalBody> 
              <Row className="justify-content-center">
                  <Col md={12}>
                      <Row>
                          <Col md={12}>
                                <Input placeholder="Title" onChange={(e)=>{setTitle(e.target.value)}} Label="Title" type="text"  name="title" value={title}></Input>
                           </Col>
                      </Row>
                      <Row className="pt-2">
                          <Col md={12}>
                            <Input placeholder="Message" onChange={(e)=>{setMessage(e.target.value)}} Label="Message" type="textarea"  name="message" value={message}></Input>
                              <BT onClick={(e)=>{addTag(e,"[@firstName]")}}  color="primary">[@firstName]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@lastName]")}}  color="primary">[@lastName]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@street]")}}  color="primary">[@street]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@city]")}}  color="primary">[@city]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@state]")}}  color="primary">[@state]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@zip]")}}  color="primary">[@zip]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@email]")}}  color="primary">[@email]</BT>
                          </Col>
                      </Row>
                  </Col>
              </Row>
          </ModalBody>
        <ModalFooter>
                     <Button variant="outlined"  onClick={addQR} color="primary">Add</Button>
          
        </ModalFooter>
            </Modal>
            :null}
    {editModal?
                        <Modal isOpen toggle={editToggle}>
            <ModalHeader toggle={editToggle}>Update {title}</ModalHeader>
            <ModalBody>
                <Row className="justify-content-center">
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <Input placeholder="Title" onChange={(e)=>{setTitle(e.target.value)}} Label="Title" type="text"  name="title" value={title}></Input>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col md={12}>
                            <Input placeholder="Message" onChange={(e)=>{setMessage(e.target.value)}} Label="Message" type="textarea"  name="message" value={message}></Input>
                              <BT onClick={(e)=>{addTag(e,"[@firstName]")}}  color="primary">[@firstName]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@lastName]")}}  color="primary">[@lastName]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@street]")}}  color="primary">[@street]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@city]")}}  color="primary">[@city]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@state]")}}  color="primary">[@state]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@zip]")}}  color="primary">[@zip]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@email]")}}  color="primary">[@email]</BT>
                            
                            </Col>
                        </Row>
                    </Col>
                </Row>
           </ModalBody>
        <ModalFooter>
            
                  <Button color="primary" onClick={() =>saveQR()}>Update</Button>
          
        </ModalFooter>
            </Modal>
            :null}
        </div>
    );
  }
};
const mapStateToProps = (state) => ({
    qrs: state.bulk.qrs,
    qr:state.bulk.qr
});
export default connect(
  mapStateToProps,
  {createQR,getQRS,getQR,deleteQR,updateQR}
)(Category);

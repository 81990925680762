import React, {useState} from "react"

import {Card, Grid, Badge,  CardContent, makeStyles, TextField, Button, InputAdornment, IconButton, List, ListItem, ListItemText} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search";
import {DeleteSweep, Message, Markunread} from "@material-ui/icons"
const styles = makeStyles({
    back: {
        color: 'black',
        background: "#f5f5f5",
        borderRadius: "0",
        height: "500px"
    },
    
})
const SideBar = ({socket})=> {
    const classes = styles()
    const [search, setsearch] = useState("")
    const submit = () => {
        socket.emit("searchmessage", localStorage.getItem("email"), search )
    }
return  <Card className = {classes.back} variant="outlined">
        <CardContent>
             <Grid container justify="center">
                <Grid item item xs={12}>
                    <TextField
                        required
                        onChange={(e)=>setsearch(e.target.value)}
                        label="Search"
                        InputProps={{
                            endAdornment: (
                            <InputAdornment>
                                <IconButton>
                                <SearchIcon onClick={submit} />
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                        />
                </Grid>
                <Grid item xs={12} className="pt-4">
                    <List>
                        <ListItem>
                            <ListItemText primary={<>
                             <Message/> Messages
                            </>}></ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} className="pt-4">
                    <List>
                        <ListItem>
                            <ListItemText primary={<>
                            <Button><Badge color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'left',}} badgeContent={11} max={10}><Markunread/></Badge> UnRead</Button>
                            </>}></ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </CardContent>
    </Card>

}

export default SideBar
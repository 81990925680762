import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getfund } from '../../../Redux/Actions/Payment';
import Loader from '../../../components/common/Loader';
import Modal from '../../../components/Modal/Modal';
import './fund.css';
import { Card, CardBody, Row, Col, Media,Label } from 'reactstrap';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';
import AddFund from './AddFund';
import EditConf from './EditConf';

const FundDetail = ({ getfund, fund }) => {
  useEffect(() => {
    getfund();
  }, [getfund]);
  if (fund === null) {
    return <Loader />;
  }
  //     return      <div class="con">
  //                 <div class="iphone">
  //                 <div class="header">
  //                     <div class="header-summary">
  //                     <div class="summary-text">
  //                         My Balance
  //                     </div>
  //                     <div class="summary-balance">
  //                         {`$ ${(Math.round(fund.balance * 100)/100).toFixed(2)}`}
  //                     </div>
  //                     </div>
  //                 </div>

  //       <div class="content">
  //         <div class="card">
  //           <div class="upper-row">
  //             <div class="card-item">
  //                 <Modal buttonLabel="AddFund"></Modal>
  //             </div>
  //             <div class="card-item">
  //                 <Modal buttonLabel="EditConfiguration" ></Modal>

  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //                 </div>
  //   </div>
  return (
    <div>
      <Row>
        <Col xs="6">
          <Row>
            {' '}
            <Card style={{width:"100%"}}>
              {' '}
              <Background image={corner1} className="bg-card" />
              <CardBody>
                <h6>Current Fund</h6>
                <h3 style={{ color: '#f5803e' }}> {`$ ${(Math.round(fund.balance * 100) / 100).toFixed(2)}`}</h3>
              </CardBody>
            </Card>
          </Row>

          <Row className="mt-2">
            <Card style={{width:"100%"}}>
            <Label  className="mx-3 mt-3 ">Add Fund</Label>
            <div  className="mx-3 my-2">
            <small   id="emailHelp" class="form-text text-muted">
             Enter the details to add fund manually
                                
            </small></div>
              <CardBody style={{backgroundColor:"#f9fafd"}}>
                <AddFund />
              </CardBody>
            </Card>
          </Row>
        </Col>
        <Col xs="6">
          <Card style={{width:"100%"}}>
          <Label className="mx-3 mt-3 ">Auto add Fund </Label>
          <div  className="mx-3 my-2 ">
          <small  id="emailHelp" class="form-text text-muted">
          Enter the configuration below to add fund automatically
                                
            </small></div>
            <CardBody style={{backgroundColor:"#f9fafd"}}>
              <EditConf />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <Card>
        {' '}
        <Background image={corner1} className="bg-card" />
        <CardBody>
          <h6>Fund</h6>
          <h3 style={{ color: '#f5803e' }}> {`$ ${(Math.round(fund.balance * 100) / 100).toFixed(2)}`}</h3>
        </CardBody>
      </Card>
      <Card className="mb-3">
        <CardBody className="border-top">
          <Row>
            <Col md={5} xs={6}>
              <h5 className="mb-2">
                <i class="fas fa-wallet" /> Wallet
              </h5>
              <Modal buttonLabel="AddFund" />
            </Col>
            <Col xs={5} md={4}>
              <Media body />
            </Col>
            <Col md={3}>
              <Modal buttonLabel="EditConfiguration" />
            </Col>
          </Row>
        </CardBody>
      </Card> */}
    </div>
  );
};
const mapeStateToProps = state => ({
  fund: state.payment.fund
});
export default connect(
  mapeStateToProps,
  { getfund }
)(FundDetail);

import React, {useEffect, useState} from "react"
import {Card, CardBody, Row, Button, Col,  CardTitle, Input, CardHeader, Label,Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap"
import {Link} from "react-router-dom"
import {getOptOut, saveoptout, deleteopt} from "../../../Redux/Actions/Mask/Mask"
import {connect} from "react-redux"
//table


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Editoptout from "./editoptout"
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#edf2f9',
    // color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const Optout =({getOptOut, optout, deleteopt, saveoptout})=>{
    
    const  [title, settile] = useState("")  
    const  [message, setmessage] = useState("")  
    const  [addModal, setAddModal] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [delID, setDelID] = useState("")
 const [delTitle, setDelTitle] = useState("")
 const [editModal, seteditModal] = useState(false);
 const [editID, seteditID] = useState("")
const [editTitle, seteditTitle] = useState("")
    const addToggle = () => {
        setAddModal(!addModal)

    };
    const deleteoptoutss = (id)=>{
        deleteopt(id)
    }
    useEffect(()=>{
        getOptOut()
    }, [getOptOut])
    const save = ()=>{
        saveoptout({title, message})
        setAddModal(!addModal)
    }

    const deleteMe=(title,id)=>{

        setDelID(id);
      setDelTitle(title);
      setDelModal(true)
    
    
    }
    const delToggle = () => {
    
    setDelModal(!delModal)
    
    };
    
    const removeMeConfirm = () => {
      
        deleteoptoutss(delID)
      setDelModal(false)   
    };

    const editMe=(title,id)=>{

        seteditID(id);
      seteditTitle(title);
      seteditModal(true)
    
    
    }
    const editToggle = () => {
    
    seteditModal(!editModal)
    
    };
    const classes = useStyles();
    console.log('optout',optout)
    return<> 
            <Card>
                <CardHeader className="pb-0">
                    <Row>
                        
                        <Col md={12}>
                          <div className="btn btn-primary btn-sm" style={{float:"right"}} color="primary" onClick={addToggle} style={{fontSize: "0.85rem"}}>Create Optout</div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody style={{backgroundColor:"#f9fafd",height:"309px",overflow: 'auto'}}>
                <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Title</StyledTableCell>
                    <StyledTableCell>Message</StyledTableCell>
                    <StyledTableCell>Edit</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {optout!=null && optout.length > 0 && optout.map(item=>(
                    <StyledTableRow>
                        <StyledTableCell>{item.title}</StyledTableCell>
                        <StyledTableCell>{item.message}</StyledTableCell>
                        <StyledTableCell><div className="fa fa-edit" onClick={()=>editMe(item.title,item._id)}></div></StyledTableCell>
                        <StyledTableCell><i className="is-isolated fas fa-trash px-3"  onClick={()=>deleteMe(item.title,item._id)}></i></StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
                </Table>
                </TableContainer>
                    
                </CardBody>
            </Card>
        
    {addModal?
                        <Modal isOpen toggle={addToggle}>
           <ModalHeader toggle={addToggle}  style={{backgroundColor:"#becdda8f"}}>Add New output</ModalHeader>
            <ModalBody> 
                <Row>
                    <Col md={12}>
                        <Label>Title</Label>
                        <Input type="text" bsSize="sm" onChange={(e)=>{
                              settile(e.target.value)
                        }} placeholder="title"></Input>
                    </Col>
                    <Col md={12} className="pt-4">
                        <Label>Message</Label>
                        <Input type="text" bsSize="sm" onChange={(e)=>{
                              setmessage(e.target.value)
                        }} placeholder="message"></Input>
                    </Col>
                </Row>
            </ModalBody>
             <ModalFooter>
                     
                     <Button variant="outlined"  onClick={save} color="primary">Add</Button>
          
            </ModalFooter>
            </Modal>
            :null}
            {delModal?
                        <Modal isOpen toggle={delToggle}>
            <ModalHeader toggle={delToggle} style={{backgroundColor:"#becdda8f"}}><h5 style={{fontWeight:"bold"}}>Remove - {delTitle}</h5></ModalHeader>
            <ModalBody>Are sure, You want to remove ?
          </ModalBody>
        <ModalFooter>
                     <Button className="mr-2" variant="contained" color="danger" onClick={() =>delToggle()}>No</Button>
                  <Button variant="contained" style={{backgroundColor:"#28a745",color:"white"}} onClick={() =>removeMeConfirm()}>Yes</Button>
          
        </ModalFooter>
            </Modal>
            :null}
            {editModal?
                        <Modal isOpen toggle={editToggle}>
            <ModalHeader toggle={editToggle} style={{backgroundColor:"#becdda8f"}}><h5 style={{fontWeight:"bold"}}>Edit - {editTitle}</h5></ModalHeader>
            <ModalBody><Editoptout id={editID} toggle={editToggle}/>
          </ModalBody>
        
            </Modal>
            :null}
    </>
}
const mapStateTOProps = (state)=> ({
    optout:state.bulk.optout

})
export default connect(mapStateTOProps, {getOptOut, saveoptout, deleteopt})(Optout)
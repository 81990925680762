import React from "react"
import LeadStage from "./newleadstage"
import Categories from "./newCategory"
import Template from "./newTemplate"
import Quickreplies from "./newquick"
const MainCamp = ()=>{
    return  <div className="kanban-container scrollbar" id={`container-${1}`} >
        <Categories/>
        <Template/>
        <Quickreplies/>
    <LeadStage/></div>

}
export default MainCamp
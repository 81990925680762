import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { getLeadStage } from '../../../Redux/Actions/Mask/Mask';
import classNames from 'classnames';
import {
    Card,
    CardBody,
    UncontrolledTooltip,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form, Input,
    Row, Col, Button } from "reactstrap"
    import ButtonIcon from '../../../components/common/ButtonIcon';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';import axios from 'axios';
    

const LeadStage = ({ getLeadStage, leads }) => {
  
  const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);

  const [name, setName] = useState('');
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    getLeadStage();
  }, [getLeadStage]);
  const head = {
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  };


  const deletelead =async(nam)=>{
    const data = {
      id: nam
    };
    // console.log({nam})
    const { data: post } = await axios.post(process.env.REACT_APP_PORT + '/api/market/deleteleadstage', data, head);
      // console.log(post);
      
      // setName({ post }); 
      getLeadStage();
      
  }



  const handleSubmit = async evt => {
    evt.preventDefault();
    if(name){
      const data = {
        name: name
      };
  
        try {
        const { data: post } = await axios.post(process.env.REACT_APP_PORT + '/api/market/create', data, head);
        setShowForm(false)
        getLeadStage();
        } catch (e) {
    
      toast.error(e.response.data.error);
    }
    }else{toast.error("Name  are required!")}
    
    
  };
 
  if (leads == null) {
    return <Loader />;
  } else {
    return (
         <div className={classNames('kanban-column', { 'form-added':  false })}>
               <div className="kanban-column-header">
            <h5 className="text-serif fs-0 mb-0">
                {`Lead Stage`} <span className="text-500">({leads.length})</span>
            </h5>
            <Button
                        color="falcon-primary"
                        size="sm"
                        id="info-leads"
                        onClick={() => setIsOpenThreadInfo(prevState => !prevState)}
                                                >
                            <FontAwesomeIcon icon="info" />
                            <UncontrolledTooltip placement="left" target="info-leads" innerClassName="fs--1">
                            Set the stage of a leads
                            </UncontrolledTooltip>
                    </Button>
    </div>
        <div
            className="kanban-items-container scrollbar">
                {leads.map(item=>(
                    <div key={item._id}
                    className="kanban-item"
                    >
                        <Card
                        className="kanban-item-card hover-actions-trigger"
                        > 
                            <CardBody>
                                
                                <p
                                    className="mb-0 font-weight-medium text-sans-serif"
                                >{item.lead_Stage}</p>
                                
                                    
                               {item.include && <UncontrolledDropdown
                                className="position-absolute text-sans-serif t-0 r-0 mt-card mr-card hover-actions"
                                onClick={e => {
                                e.stopPropagation();
                                }}
                            >
                                <DropdownToggle color="falcon-default" size="sm" className="py-0 px-2">
                                <FontAwesomeIcon icon="ellipsis-h" />
                                </DropdownToggle>
                                <DropdownMenu right className="py-0">
                                <DropdownItem onClick={()=>deletelead(item._id)} className="text-danger">Remove</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                            </CardBody>
                        </Card>
                    </div>
                ))}
            
             
        </div>
        <div className="kanban-column mr-3">
      {showForm ? (
        <div className="bg-100 p-card rounded-soft transition-none">
          <Form onSubmit={e => handleSubmit(e)}>
            <Input
              type="text"
              placeholder="Enter text"
              className="mb-2 add-list"
              value={name}
              autoFocus
              onChange={({ target }) => {
                setName(target.value);
              }}
            />
            <Row form>
              <Col>
                <Button color="primary" size="sm" block type="submit">
                  Add
                </Button>
              </Col>
              <Col>
                <Button
                  color="outline-secondary"
                  size="sm"
                  block
                  className="border-400"
                  onClick={() => {
                    setShowForm(false);
                    setName('');
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <ButtonIcon
          className="bg-400 border-400"
          color="primary"
          block
          icon="plus"
          iconClassName="mr-1"
          onClick={() => {
            setShowForm(true);
          }}
        >
          Add lead stage
        </ButtonIcon>
      )}
    </div>
        </div>
    );
  }
};
const mapeState = state => ({
  leads: state.bulk.leads
});
export default connect(
  mapeState,
  { getLeadStage }
)(LeadStage);

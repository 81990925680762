import React from 'react';
import { Row, Col, Input, Label, Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <Card style={{ width: '65vw', color: '#424242' }} className="container">
        <CardHeader>
          <CardTitle>
            <p style={{ textAlign: 'center', fontSize: '1.5rem', color: '#2c7be5' }}>Privacy Policy</p>

            <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Last updated: ​2021-08-01.</p>

            <Row>
              <Col>
                <p style={{ fontSize: '0.9rem' }}>
                  <a href="https://mergecall.com/">Mergecall.com</a> ("us", "we", or "our") operates{' '}
                  <a href="https://mergecall.com/">​https://mergecall.com/</a> (the "Site"). This page informs you of
                  our policies regarding the collection, use and disclosure of Personal Information we receive from
                  users of the Site.
                  <br />
                  We use your Personal Information only for providing and improving the Site. By using the Site, you
                  agree to the collection and use of information in accordance with this policy.
                </p>
              </Col>
            </Row>

            <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Information Collection And Use</p>
            <Row>
              <Col>
                <p style={{ fontSize: '0.9rem' }}>
                  While using our Site, we may ask you to provide us with certain personally identifiable information
                  that can be used to contact or identify you. Personally identifiable information may include, but is
                  not limited to your name ("Personal Information").
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Log Data</p>

                <p style={{ fontSize: '0.9rem' }}>
                  Like many site operators, we collect information that your browser sends whenever you visit our Site
                  ("Log Data").
                  <br />
                  This Log Data may include information such as your computer's Internet Protocol ("IP") address,
                  browser type, browser version, the pages of our Site that you visit, the time and date of your visit,
                  the time spent on those pages and other statistics.
                  <br />
                  In addition, we may use third party services such as Google Analytics that collect, monitor and
                  analyze.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Communications</p>

                <p style={{ fontSize: '0.9rem' }}>
                  We may use your Personal Information to contact you with newsletters, marketing or promotional
                  materials and other information.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Cookies</p>

                <p style={{ fontSize: '0.9rem' }}>
                  Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies
                  are sent to your browser from a web site and stored on your computer's hard drive.
                  <br />
                  Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all
                  cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not
                  be able to use some portions of our Site.
                  <br />
                  We may use your Personal Information to contact you with newsletters, marketing or promotional
                  materials and other information.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Security</p>

                <p style={{ fontSize: '0.9rem' }}>
                  The security of your Personal Information is important to us, but remember that no method of
                  transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to
                  use commercially acceptable means to protect your Personal Information, we cannot guarantee its
                  absolute security.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Changes To This Privacy Policy</p>

                <p style={{ fontSize: '0.9rem' }}>
                  This Privacy Policy is effective as of ​2021-08-01​ and will remain in effect except with respect to
                  any changes in its provisions in the future, which will be in effect immediately after being posted on
                  this page.
                  <br />
                  We reserve the right to update or change our Privacy Policy at any time and you should check this
                  Privacy Policy periodically. Your continued use of the Service after we post any modifications to the
                  Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent
                  to abide and be bound by the modified Privacy Policy.
                  <br />
                  If we make any material changes to this Privacy Policy, we will notify you either through the email
                  address you have provided us, or by placing a prominent notice on our website.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Contact Us</p>

                <p style={{ fontSize: '0.9rem' }}>
                  If you have any questions about this Privacy Policy, please{' '}
                  <a href="https://mergecall.com/contactus">contact us</a>.
                </p>
              </Col>
            </Row>
          </CardTitle>
        </CardHeader>

        <Row className=" justify-content-center">
          <Col md={{ size: 8 }}>
            <p style={{ textAlign: 'center', fontSize: '1.5rem', color: '#2c7be5' }}>
              Support <i class="material-icons">phone</i> +1 415-417-0410
            </p>
            <p style={{ textAlign: 'center' }}>© 2022 mergecall.com, Inc C Corp</p>
            <p style={{ textAlign: 'center' }}>325 Front St W, Toronto, ON M5V 2Y1, Canada</p>
            <p style={{ textAlign: 'center' }}>2035 Sunset Lake Rd SUITE B2, Newark, DE 19702, United States</p>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default PrivacyPolicy;

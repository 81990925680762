import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Card, CardGroup, CardBody, CardTitle, CardSubtitle, UncontrolledTooltip, Input } from 'reactstrap';
import Switch from '@material-ui/core/Switch';
import Axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import ReactDOM from 'react-dom'
import { FaRegQuestionCircle } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#edf2f9',
    // color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 480
  }
});


// const element = <FontAwesomeIcon icon={faCoffee} />
// var moment = require('moment-timezone');

function GeoCallerId() {  

  const titleRef = useRef();
  const [checked, setChecked] = useState(false);
  const [defNum, setdefNum] = useState();
  const [DisplaydefNum, setDisplaydefNum] = useState();
  const [countryNum, setCountryNum] = useState();
  const [countryCode, setCountryCode] = useState();
  const [areaNum, setAreaNum] = useState();
  const [areaCode, setAreaCode] = useState();
  const [areaModalIsOpen, setAreaModalIsOpen] = useState(false)
  const [allNum, setallNum] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [defaultmodalIsOpen, setdefaultModalIsOpen] = useState(false)
  const [allCountryNum, setallCountryNum] = useState([])
  const [allAreaNum, setallAreaNum] = useState([])
  const [areaDeleteModalIsOpen, setAreaDeleteModalIsOpen] = useState(false)
  const [countryDeleteModalIsOpen, setcountryDeleteModalIsOpen] = useState(false)
  const [toDeleteCountryNum, settoDeleteCountryNum] = useState()
  const [toDeleteAreaNum, settoDeleteAreaNum] = useState()
  const [toDeleteCountryCode, settoDeleteCountryCode] = useState()
  const [toDeleteAreaCode, settoDeleteAreaCode] = useState()
  const [firstdefaultmodalIsOpen, setFirstdefaultModalIsOpen] = useState(false)
  const classes = useStyles();
  useEffect(() => {
    console.log("hit")
    async function fetchData() {
   await getdata()
   await getAreaDetail()
   await  getCountryDetail()
    }
    fetchData()
  }, [])
  useEffect(() => {
    // console.log("toggle in",checked)
    titleRef.current.checked=checked
  
}, [checked])
  const getdata = async () => {
    console.log("hittttt")
    //   const res = await Axios({
    //   method: "get",
    //   url: process.env.REACT_APP_PORT + '/api/me/getskiptracing',
    //   headers: {
    //     'x-auth-token': localStorage.getItem('token')
    //   }
    // });
    // console.log("res",res.data.data)
    const resNum = await Axios({
      method: "get",
      url: process.env.REACT_APP_PORT + '/api/me/buynumber?company=undefined',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    console.log("res", resNum.data.data)
    setallNum(resNum.data.data)
    
    
    // titleRef.current.checked=res.data.data
    // console.log("ref",titleRef.current.checked)
    // setChecked(res.data.data)


  }

  // const sendGeoCallerIdToggle= async ()=>{
  //   const sendGeoCallerIdToggle = await Axios({
  //     method: "get",
  //     url: process.env.REACT_APP_PORT + `/api/me/sendGeoCallerIdToggle?geoCallerId=${checkedgci}`,
  //     headers: {
  //       'x-auth-token': localStorage.getItem('token')
  //     }
  //   });
  
  // }


const sendCountryDetails= async ()=>{
  const countryDetailsent = await Axios({
    method: "get",
    url: process.env.REACT_APP_PORT + `/api/me/addGeocallerCountry?countryCode=${countryCode}&phoneNumber=${countryNum}`,
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  });

  getCountryDetail();

}

const sendAreaDetails= async ()=>{
  const areaDetailsent = await Axios({
    method: "get",
    url: process.env.REACT_APP_PORT + `/api/me/addGeocallerArea?areaCode=${areaCode}&phoneNumber=${areaNum}`,
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  });

  getAreaDetail()

}

// getGeoCallerIdAreaDetail
const getCountryDetail=async()=>{

  const allcountryDetail = await Axios({
    method: "get",
    url: process.env.REACT_APP_PORT + `/api/me/getGeoCallerIdCountryDetail`,
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  });
  console.log("allcountryDetail=",allcountryDetail)
  await setChecked(allcountryDetail.data.data.geoCallerIdTogg)
  await setDisplaydefNum(allcountryDetail.data.data.defaultNumber)
  await setallCountryNum(allcountryDetail.data.data.AllnumbersCountry)

}
const getAreaDetail=async()=>{
  const allareaDetail = await Axios({
    method: "get",
    url: process.env.REACT_APP_PORT + `/api/me/getGeoCallerIdAreaDetail`,
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
    
  });
  console.log("allareaDetail=",allareaDetail)
  setallAreaNum(allareaDetail.data.data)
}


const deleteCountryDetail=async(countrycode,countrynum)=>{
  const countryDetailDelete = await Axios({
    method: "get",
    url: process.env.REACT_APP_PORT + `/api/me/DeleteGeoCallerIdCountryDetail?countryCode=${countrycode}&phoneNumber=${countrynum}`,
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  });
  getCountryDetail()

}
const deleteAreaDetail=async(areacode,areanum)=>{
  const areaDetailDelete = await Axios({
    method: "get",
    url: process.env.REACT_APP_PORT + `/api/me/DeleteGeoCallerIdAreaDetail?areaCode=${areacode}&phoneNumber=${areanum}`,
    headers: {
      'x-auth-token': localStorage.getItem('token')
}});
getAreaDetail()
}

const sendDefaultNumber = async () => {
   
  const defNumberChange = await Axios({
    method: "get",
    url: process.env.REACT_APP_PORT + `/api/me/changeDefaultNumber?defaultNumber=${defNum}`,
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  });
 await  geoCallerIDFirstTimeTrue()
  setDisplaydefNum(defNumberChange.data.data.defaultNumber)
  
};


// const countyCodeNumber=async(value,countycode)=>{
//   console.log("County Number Value=",value);
//   console.log("County Code =",countycode.dialCode) 
// }


  const handleChangeSwitch = async (event) => {
   
    setChecked(!checked)
    console.log("checked,DisplaydefNum=",checked,DisplaydefNum)
    if(DisplaydefNum && DisplaydefNum!=""){
      console.log("checked=",!checked)
    const sendGeoCallerIdToggle = await Axios({
      method: "get",
      url: process.env.REACT_APP_PORT + `/api/me/sendGeoCallerIdToggle?geoCallerId=${!checked}`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
  }
    if((checked==false && DisplaydefNum=="")||(checked==false && DisplaydefNum==undefined))
    {
      console.log("inside 2nd if",checked,DisplaydefNum)
      setFirstdefaultModalIsOpen(true)
      
    }
  };
  const geoCallerIDFirstTimeTrue = async () => {
    // setChecked(!checked)
    const sendGeoCallerIdToggle = await Axios({
      method: "get",
      url: process.env.REACT_APP_PORT + `/api/me/sendGeoCallerIdToggle?geoCallerId=true`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
  }

  return (
    <Row className="justify-content-center">
      <Col md="12" sm="12">
        <CardGroup>
          <Card style={{ margin: "2px" }}>
            <CardBody>
              <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                The feature that automatically selects the best from number based on the number you trying to call/text.
              </UncontrolledTooltip>
              <CardTitle tag="h5">Geo Called ID <span style={{ color: "blue" }} href="#" id="UncontrolledTooltipExample"><FaRegQuestionCircle /></span> </CardTitle>
              <CardSubtitle>{checked ? "Enabled" : "Disabled"}</CardSubtitle>
              OFF<Switch
                 ref={titleRef}
                 checked={checked}
               
                color="primary"
                onClick={handleChangeSwitch}
              />ON
             
              <h5>Default number</h5><CardSubtitle>{DisplaydefNum}</CardSubtitle>

              <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " onClick={() => { setdefaultModalIsOpen(true) }}>Change Default Number</Button>
              <Modal isOpen={firstdefaultmodalIsOpen} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalHeader>Select a number to use as Default</ModalHeader>
                <ModalBody>
                                 
                
                  <Input
                    style={{ fontSize: '0.95em', width: '25vw' }}
                    type="select"
                    bsSize="sm"
                    onChange={e => {setdefNum(e.target.value)}}
                    
                  > 
                  <option  selected>
                   Select a Phone Number
                 </option>
                  {allNum.map(item => (
                    <option value={item._id} >
                    {item.number}
                  </option>
                  ))} 

               {/* <option> 1234567890 </option>
               <option> 0987654321 </option>
               <option> 9890987658 </option> */}
                  </Input><small class="form-text text-muted">
                    Select the number to as Default
                  </small>


                </ModalBody>
                <ModalFooter>
                  <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " disabled={!defNum} onClick={() => { setFirstdefaultModalIsOpen(false); sendDefaultNumber()}}>Change</Button>
                  {' '}
                </ModalFooter>
              </Modal>

              <Modal isOpen={defaultmodalIsOpen} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalHeader>Select a number to use as Default</ModalHeader>
                <ModalBody>
                                 
                
                  <Input
                    style={{ fontSize: '0.95em', width: '25vw' }}
                    type="select"
                    bsSize="sm"
                    onChange={e => {setdefNum(e.target.value)}}                   
                  > 
                  <option value='SelectAPhoneNumber' selected>
                   Select a Phone Number
                 </option>
                  {allNum.map(item => (
                    <option value={item._id} >
                    {item.number}
                  </option>
                  ))} 

               {/* <option> 1234567890 </option>
               <option> 0987654321 </option>
               <option> 9890987658 </option> */}
                  </Input><small class="form-text text-muted">
                    Select the number to as Default
                  </small>


                </ModalBody>
                <ModalFooter>
                  <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " onClick={() => { setdefaultModalIsOpen(false); sendDefaultNumber()}}>Change</Button>
                  {' '}
                  <Button color="secondary" onClick={async () => { await setdefaultModalIsOpen(false) }}>Cancel</Button>
                </ModalFooter>
              </Modal>



              {/* <Input
                style={{ fontSize: '0.95em', width: '25vw' }}
                type="select"
                bsSize="sm"
                defaultValue="Select a number"
                onChange={e => setdefNum(e.target.value)}
              >
                
                <option value='SelectAPhoneNumber' selected>
                   Select a Phone Number
                 </option>
                {allNum.map(item => (
                   <option value={item._id} >
                    {item.number}
                  </option>
                ))}
               <option> 1234567890 </option>
               <option> 0987654321 </option>
               <option> 9890987658 </option>
              </Input> */}
            </CardBody>

          </Card>
         
        </CardGroup>
      </Col>
      <Col md="12" sm="12">
        <CardGroup>
          
         
          <Card style={{ margin: "2px" }}>
            <CardBody>
              <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample0">
                The feature that automatically selects the best from number based on the country code you trying to call/text.
              </UncontrolledTooltip>
              <CardTitle tag="h5">Country number matching<span style={{ color: "blue" }} href="#" id="UncontrolledTooltipExample0"> <FaRegQuestionCircle /> </span> </CardTitle>
              <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Country Code</StyledTableCell>
                    <StyledTableCell>Phone Number</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {allCountryNum!=null && allCountryNum.length > 0 && allCountryNum.map(item=>(
                    <StyledTableRow>
                        <StyledTableCell >{item.countrycode}</StyledTableCell>
                        <StyledTableCell >{item.countrynumber}</StyledTableCell>
                        <StyledTableCell><i className="is-isolated fas fa-trash px-3" onClick={async () => { setcountryDeleteModalIsOpen(true); settoDeleteCountryNum(item.countrynumber);settoDeleteCountryCode(item.countrycode) }}></i></StyledTableCell>
                    </StyledTableRow>
                ))} 
                </TableBody>
                </Table>
                </TableContainer>

                {/* countryDeleteModal */}
                <Modal isOpen={countryDeleteModalIsOpen} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalHeader>Do you really want to delete? This action cannot be undone.</ModalHeader>
                {/* <ModalBody>
                                 
                
                  
                  <small class="form-text text-muted">
                    Select the number to as Default
                  </small>


                </ModalBody> */}
                <ModalFooter>
                  <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " onClick={async () => { setcountryDeleteModalIsOpen(false);await deleteCountryDetail(toDeleteCountryCode,toDeleteCountryNum) }}>Confirm</Button>
                  {' '}
                  <Button color="secondary" onClick={async () => { await setcountryDeleteModalIsOpen(false) }}>Cancel</Button>
                </ModalFooter>
              </Modal>

             

<br></br>
              <small class="form-text text-muted">
                Add a County Code and Phone Number
              </small>
              <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " onClick={() => { setModalIsOpen(true) }}><FaPlus /> Add Country</Button>

              <Modal isOpen={modalIsOpen} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalHeader>Select a Country Code and Phone Number below</ModalHeader>
                <ModalBody>
                  <h5>Country code</h5>
                 
                  {/* <PhoneInput style={{ fontSize: '0.95em', width: '25px' }}
                    placeholder="Use dropdown to pick code"
                    type="select"
                    bsSize="sm"
                    onChange={e => {setCountryCode(e)}} 
                   
                    value={countryCode}
                  /> */}
                  <PhoneInput style={{ fontSize: '0.95em', width: '25px' }}
                    placeholder="Use dropdown to pick code"
                    type="select"
                    bsSize="sm"
                    onChange={e => {setCountryCode(e)}} 
                  //  onChange={async (value,country,e, formattedValue) => {countyCodeNumber(value,country)}}
                  //   value={countryCode}
                  />
                  <small class="form-text text-muted">
                    Enter the country code only
                  </small>
                  <br></br> <br></br>
                  <h5>Country number</h5>
                  <Input
                    style={{ fontSize: '0.95em', width: '25vw' }}
                    type="select"
                    bsSize="sm"
                    onChange={e => {setCountryNum(e.target.value)}}
                    
                  >  
                  <option value='SelectAPhoneNumber' selected>
                   Select a Phone Number
                 </option>
                  {allNum.map(item => (
                    <option value={item._id} >
                    {item.number}
                  </option>
                  ))} 

               {/* <option> 1234567890 </option>
               <option> 0987654321 </option>
               <option> 9890987658 </option> */}
                  </Input><small class="form-text text-muted">
                    Select the number to use for the county code
                  </small>


                </ModalBody>
                <ModalFooter>
                  <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " onClick={() => { setModalIsOpen(false); sendCountryDetails()}}><FaPlus /> Add</Button>
                  {' '}
                  <Button color="secondary" onClick={async () => { await setModalIsOpen(false) }}>Cancel</Button>
                </ModalFooter>
              </Modal>



            </CardBody>

          </Card>
          <Card style={{ margin: "2px" }}>
            <CardBody>
              <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample1">
                The feature that automatically selects the best from number based on the area code you trying to call/text.
              </UncontrolledTooltip>
              <CardTitle tag="h5">Area code matching <span style={{ color: "blue" }} href="#" id="UncontrolledTooltipExample1"><FaRegQuestionCircle /></span> </CardTitle>
             
<TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Area Code</StyledTableCell>
                    <StyledTableCell>Phone Number</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {allAreaNum!=null && allAreaNum.length > 0 && allAreaNum.map(item=>(
                    <StyledTableRow>
                        <StyledTableCell>{item.areacode}</StyledTableCell>
                        <StyledTableCell>{item.areanumber}</StyledTableCell>
                        <StyledTableCell><i className="is-isolated fas fa-trash px-3" onClick={async () => { setAreaDeleteModalIsOpen(true);settoDeleteAreaNum(item.areanumber);settoDeleteAreaCode(item.areacode)}}></i></StyledTableCell>
                    </StyledTableRow>
                 ))} 
                </TableBody>
                </Table>
                </TableContainer>
{/* deleteModalForAreaCode */}
                <Modal isOpen={areaDeleteModalIsOpen} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalHeader>Do you really want to delete? This action cannot be undone.</ModalHeader>
                
                                 
                
                  
                <ModalFooter>
                  <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " onClick={async () => { setAreaDeleteModalIsOpen(false);await deleteAreaDetail(toDeleteAreaCode,toDeleteAreaNum)}}>Confirm</Button>
                  {' '}
                  <Button color="secondary" onClick={async () => { await setAreaDeleteModalIsOpen(false) }}>Cancel</Button>
                </ModalFooter>
              </Modal>




            <br></br>
              <small class="form-text text-muted">
                Add an Area Code and Phone Number
              </small>
              <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1" onClick={() => { setAreaModalIsOpen(true) }}><FaPlus /> Add Area</Button>

              <Modal isOpen={areaModalIsOpen} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <ModalHeader>Select an Area code and Phone Number below</ModalHeader>
                <ModalBody>

                  <h5>Area code </h5>
                
{/* typenumber */}
                  <Input
                    style={{ fontSize: '0.95em', width: '25vw' }}
                    placeholder="Enter an area code"
                    maxlength = "3"
                    
                    
                    onChange={code => setAreaCode(code.target.value)}
                    /><small class="form-text text-muted">
                    Enter the area code only
                  </small>
                  <br></br><br></br>
                  <h5>Area number</h5>

                  <Input
                    style={{ fontSize: '0.95em', width: '25vw' }}
                    type="select"
                    bsSize="sm"
                    onChange={e => setAreaNum(e.target.value)}
                  > 
                  <option value='SelectAPhoneNumber' selected>
                   Select a Phone Number
                 </option> 
                  {allNum.map(item => (
                    <option value={item._id} >
                    {item.number}
                  </option>
                  ))} 
                   {/* <option> 1234567890 </option>
               <option> 0987654321 </option>
               <option> 9890987658 </option> */}
                  </Input><small class="form-text text-muted">
                    Select the number to use for the area code
                  </small>


                </ModalBody>
                <ModalFooter>
                  <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1"  onClick={() => { setAreaModalIsOpen(false); sendAreaDetails()}}><FaPlus /> Add</Button>
                  {' '}
                  <Button color="secondary" onClick={async () => { await setAreaModalIsOpen(false) }}>Cancel</Button>
                </ModalFooter>
              </Modal>


            </CardBody>

          </Card>

        </CardGroup>
      </Col>
    </Row>

    
  );
}

export default GeoCallerId





// <Card style={{ margin: "2px" }}>
// <CardBody>
//   <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample0">
//     The feature that automatically selects the best from number based on the country code you trying to call/text.
//   </UncontrolledTooltip>
//   <CardTitle tag="h5">Country number matching<span style={{ color: "blue" }} href="#" id="UncontrolledTooltipExample0"> <FaRegQuestionCircle /> </span> </CardTitle>
//   <TableContainer component={Paper}>
//   <Table className={classes.table} aria-label="simple table">
//     <TableHead>
//       <TableRow>
//         <StyledTableCell>Country Code</StyledTableCell>
//         <StyledTableCell>Phone Number</StyledTableCell>
//         <StyledTableCell>Delete</StyledTableCell>
//       </TableRow>
//     </TableHead>
//     <TableBody>
//     {/* {optout!=null && optout.length > 0 && optout.map(item=>( */}
//         <StyledTableRow>
//             <StyledTableCell>{"item.title"}</StyledTableCell>
//             <StyledTableCell>{"item.message"}</StyledTableCell>
//             <StyledTableCell><i className="is-isolated fas fa-trash px-3">{"delete"}</i></StyledTableCell>
//         </StyledTableRow>
//     {/* ))} */}
//     </TableBody>
//     </Table>
//     </TableContainer>
//   {/* <CardSubtitle>{checkedgci?"Enabled":"Disabled"}</CardSubtitle>
// OFF<Switch
// ref={titleRef1}
//       checked={checkedgci}
//     //   value={toggledata}
//       color="primary"
//       onClick={handleChangeSwitch}
//     />ON */}
//   {/* <FormGroup switch>
//     <Input
//     ref={titleRef}
// type="switch"
// checked={toggledata}
// onClick={handleChangeSwitch}
// /></FormGroup> */}
//   {/* <Input
//   style={{ fontSize: '0.95em', width: '35vw' }}
//   type="select"
//   bsSize="sm"
//   onChange={e => setdefNum(e.target.value)}
// >
  
// </Input> */}

// <br></br>
//   <small class="form-text text-muted">
//     Add a County Code and Phone Number
//   </small>
//   <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " onClick={() => { setModalIsOpen(true) }}><FaPlus /> Add Country</Button>

//   <Modal isOpen={modalIsOpen} size="lg"
//     aria-labelledby="contained-modal-title-vcenter"
//     centered>
//     <ModalHeader>Select a Country Code and Phone Number below</ModalHeader>
//     <ModalBody>
//       <h5>Country code</h5>
//       {/* <Input
//         style={{ fontSize: '0.95em', width: '25vw' }}
//         type="select"
//         bsSize="sm"
//         onChange={e => setCountryCode(e.target.value)}
//       ></Input> */}
//       <PhoneInput style={{ fontSize: '0.95em', width: '25px' }}
//         country={'us'}
//         type="select"
//         bsSize="sm"
//         onChange={e => setCountryCode(e)} value={countryCode}
//       /><small class="form-text text-muted">
//         Enter the country code only
//       </small>
//       <br></br> <br></br>
//       <h5>Country number</h5>
//       <Input
//         style={{ fontSize: '0.95em', width: '25vw' }}
//         type="select"
//         bsSize="sm"
//         onChange={e => setCountryNum(e.target.value)}
//       >  {allNum.map(item => (
//         <option value={item} selected={item}>
//         </option>
//       ))} </Input><small class="form-text text-muted">
//         Select the number to use for the county code
//       </small>


//     </ModalBody>
//     <ModalFooter>
//       <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " onClick={() => { setModalIsOpen(false) }}><FaPlus /> Add</Button>
//       {' '}
//       <Button color="secondary" onClick={async () => { await setModalIsOpen(false) }}>Cancel</Button>
//     </ModalFooter>
//   </Modal>



// </CardBody>

// </Card>
// <Card style={{ margin: "2px" }}>
// <CardBody>
//   <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample1">
//     The feature that automatically selects the best from number based on the area code you trying to call/text.
//   </UncontrolledTooltip>
//   <CardTitle tag="h5">Area code matching <span style={{ color: "blue" }} href="#" id="UncontrolledTooltipExample1"><FaRegQuestionCircle /></span> </CardTitle>
//   {/* <CardSubtitle>{checkedgci?"Enabled":"Disabled"}</CardSubtitle>
// OFF<Switch
// ref={titleRef1}
//       checked={checkedgci}
//     //   value={toggledata}
//       color="primary"
//       onClick={handleChangeSwitch}
//     />ON */}
//   {/* <FormGroup switch>
//     <Input
//     ref={titleRef}
// type="switch"
// checked={toggledata}
// onClick={handleChangeSwitch}
// /></FormGroup> */}
//   {/* <Input
//   style={{ fontSize: '0.95em', width: '35vw' }}
//   type="select"
//   bsSize="sm"
//   onChange={e => setdefNum(e.target.value)}
// >
  
// </Input> */}
// <br></br>
//   <small class="form-text text-muted">
//     Add an Area Code and Phone Number
//   </small>
//   <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1" onClick={() => { setAreaModalIsOpen(true) }}><FaPlus /> Add Area</Button>

//   <Modal isOpen={areaModalIsOpen} size="lg"
//     aria-labelledby="contained-modal-title-vcenter"
//     centered>
//     <ModalHeader>Select an Area code and Phone Number below</ModalHeader>
//     <ModalBody>

//       <h5>Area code </h5>
//       {/* <Input
//         style={{ fontSize: '0.95em', width: '25vw' }}
//         type="select"
//         bsSize="sm"
//         onChange={e => setAreaCode(e.target.value)}
//       ></Input><br></br> <br></br> */}

//       <Input
//         style={{ fontSize: '0.95em', width: '25vw' }}
//         placeholder="Enter an area code"
//         type="text"
//         onChange={e => setAreaCode(e)}
//         name="body"
//         value={areaCode}
//       /><small class="form-text text-muted">
//         Enter the area code only
//       </small>
//       <br></br><br></br>
//       <h5>Area number</h5>

//       <Input
//         style={{ fontSize: '0.95em', width: '25vw' }}
//         type="select"
//         bsSize="sm"
//         onChange={e => setAreaNum(e.target.value)}
//       >  {allNum.map(item => (
//         <option value={item} selected={item}>

//         </option>
//       ))} </Input><small class="form-text text-muted">
//         Select the number to use for the area code
//       </small>


//     </ModalBody>
//     <ModalFooter>
//       <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1" onClick={() => { setAreaModalIsOpen(false) }}><FaPlus /> Add</Button>
//       {' '}
//       <Button color="secondary" onClick={async () => { await setAreaModalIsOpen(false) }}>Cancel</Button>
//     </ModalFooter>
//   </Modal>


// </CardBody>

// </Card>

import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button, Input,Modal, ModalHeader, ModalBody, ModalFooter,Form, FormGroup, } from 'reactstrap';
import { getUser } from '../../Redux/Actions/USER/user';
import { connect } from 'react-redux';
import {verify,reserpassword} from "../../Redux/Actions/Auth/Auth"
import PasswordResetForm from '../../components/auth/PasswordResetForm';
import ForgetPasswordForm from '../../components/auth/ForgetPasswordForm';


function Myaccount({ getUser, user,verify,reserpassword }) {

const [showpass, setshowpass] = useState("password")
const [oModal, setoModal] = useState(false);

  useEffect(() => {
    getUser();
   
  }, []);
  const Me=()=>{

   
  setoModal(true)


}
const Toggle = () => {

setoModal(!oModal)

};

const removeMeConfirm = () => {
  
    
  setoModal(false)   
};
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <Card>
          <CardHeader>
            <Row>
              <Col xs="12">
                <CardTitle>
                  <h3 style={{ color: '#5e6e82' }}>
                    <i class="fas fa-user-cog" />&nbsp;My Account-{user?.name}
                  </h3>
                  <p style={{ color: '#5e6e82', fontSize: '11px' }}>Account Settings</p>
                </CardTitle>
                <CardBody>
                  <Card>
                    <CardBody>
                      <Row className="m-2">
                        <h4 style={{ color: '#5e6e82' }}>
                          <i class="fas fa-envelope-open-text" />Email and Password
                        </h4>
                      </Row>
                      <Row className="ml-1 my-2">
                      <Col className="m-0,p-0" xs="4">Email :</Col>
                        <Col className="m-0,p-0" xs="2" xs="6">
                          <Input type="text" name="Email" value={user.email} />
                        </Col>
                      </Row>
                     
                      <Row className="m-2">
                        {' '}
                        <Button onClick={()=>Me()} className="m-0 p-0" color="link">
                          Click here
                        </Button>
                        &nbsp;to change password
                      </Row>
                    </CardBody>
                  </Card>
                </CardBody>
              </Col>
            </Row>
          </CardHeader>
          {oModal?
                        <Modal isOpen toggle={Toggle}>
            <ModalHeader toggle={Toggle} style={{backgroundColor:"#becdda8f"}}><h3 style={{fontWeight:"bold"}}>Are sure, You want to changepassword ?</h3></ModalHeader>
            <ModalBody>
                <ForgetPasswordForm/>
          </ModalBody>
        <ModalFooter>
                     
        </ModalFooter>
            </Modal>
            :null}
          <CardBody />
        </Card>
      </Col>
    </Row>
  );
}
const mapStateToProps = state => ({
  user: state.Twilio.user
});
export default connect(
  mapStateToProps,
  { getUser,verify,reserpassword }
)(Myaccount);

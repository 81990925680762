import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Input } from 'reactstrap';
import Switch from '@material-ui/core/Switch';
import { DncReset, DNCFunctinalities, DncExport } from '../../../Redux/Actions/Mask/Mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DNCLIST = ({ DncReset, DNCFunctinalities, DncExport }) => {
  const [data, setData] = useState('');
  const [toggledata, settoggle] = useState(false);
  const dncwork = () => {
    DNCFunctinalities(data, toggledata);
  };
  const pathname = window.location.pathname

  return (
        <Card style={{width:"100%"}}>
          <CardHeader style={{backgroundColor:"#f9fafd"}}>
            <Row>
              
              <Col xs={3}>
                <div onClick={()=>DncExport(pathname)} className="btn btn-sm btn-primary">
                  Export
                </div>
              </Col>
              <Col xs={6}>
                <div className="justify-content-center pl-4">
                Import
                <Switch
                  checked={toggledata}
                  onChange={() => {
                    settoggle(!toggledata);
                  }}
                />
                Remove</div>
              </Col>
              <Col xs={3}>
                <div onClick={DncReset} style={{float:"right"}} className="btn btn-sm btn-danger">
                  Reset
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            
            
            <Row className="pt-3">
              <Col xs={8}>
                
                <Input
                  type="file"
                  onChange={e => {
                   
                    if (!e.target.files.length) {
                      toast('No file uploaded!');
                    }
                    setData(e.target.files[0]);
                  }}
                />
              </Col>
            
              <Col xs={4}>
                <div onClick={dncwork} style={{float:"right"}} className="btn btn-xs btn-primary">
                  {toggledata ? 'Remove' : 'Import'}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      
  );
};

export default connect(
  null,
  { DncReset, DNCFunctinalities, DncExport }
)(DNCLIST);


import React, { useState , useEffect} from 'react'
import {Row, Col, Card, CardBody, CardHeader, Table,Button, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import  {createQR,getQRS,deleteQR,getQR,updateQR} from "../../../Redux/Actions/Mask/Mask"
import {Button as BT} from "@material-ui/core"
import CardTitle from 'reactstrap/lib/CardTitle';

const QuickReply = ({setAlert,createQR,getQRS,qrs,deleteQR,getQR,updateQR}) => { 

  useEffect(()=>{
        getQRS()
    }, [getQRS])
          
const [addModal, setAddModal] = useState(false);
const [delModal, setDelModal] = useState(false);
const [editModal, setEditModal] = useState(false);  
const [title, setTitle] = useState("")
const [message, setMessage] = useState("")
const [qrID,setQrID] = useState("")
const [delQRID, setDelQRID] = useState("")
const [delQRTitle, setDelQRTitle] = useState("")

  const addToggle = () => {
         setAddModal(!addModal)

};
 const addNew = () => {
         setQrID("")
         setTitle("")
         setMessage("")
         setAddModal(true)

};
 const addTag  = (e,tag) => {
  e.target.blur()
          setMessage(message+tag);

   }
   const deleteMe=(title,id)=>{

          setDelQRID(id);
        setDelQRTitle(title);
        setDelModal(true)
  

   }
    const delToggle = () => {

    setDelModal(!delModal)

};

 const removeMeConfirm = () => {
          deleteQR(delQRID)
        setDelModal(false)   
};


 const editToggle = () => {

    setEditModal(!editModal)

};

  const editMe=(id,title,message)=>{

         setQrID(id)
         setTitle(title)
         setMessage(message)
         setEditModal(true)

   }
 const addQR  = ()=>{

  if(title && message){
          let tempdata={"title":title,"message":message}
         createQR(tempdata);
         setAddModal(false);
          }else{setAlert("Name and Message are required!", "danger")}


 }

 const saveQR  = ()=>{

  if(title && message && qrID){
          let tempdata={"title":title,"message":message}
         updateQR(tempdata,qrID);
         setEditModal(false);
          }else{setAlert("Name and Message are required!", "danger")}


 }

  return (
    <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                    <Row>
                        <Col md="8">
                             <CardTitle style={{fontSize: "0.95rem"}}>Quick Replies</CardTitle>
                        </Col>
                        <Col md="4">
                        <Button  className="btn btn-primary btn-sm" color="primary" onClick={addNew} style={{whiteSpace:"nowrap",float:"right"}}>Add Quick Reply</Button>
                        {addModal?
                        <Modal isOpen toggle={addToggle}>
           <ModalHeader toggle={addToggle}>Add New Quick Reply</ModalHeader>
            <ModalBody> 
            <Input placeholder="Title" onChange={(e)=>{setTitle(e.target.value)}} Label="Title" type="text"  name="title" value={title}></Input>
            <Input placeholder="Message" onChange={(e)=>{setMessage(e.target.value)}} Label="Message" type="textarea"  name="message" value={message}></Input>
                              <BT onClick={(e)=>{addTag(e,"[@firstName]")}}  color="primary">[@firstName]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@lastName]")}}  color="primary">[@lastName]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@street]")}}  color="primary">[@street]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@city]")}}  color="primary">[@city]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@state]")}}  color="primary">[@state]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@zip]")}}  color="primary">[@zip]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@email]")}}  color="primary">[@email]</BT>
          </ModalBody>
        <ModalFooter>
                     <Button variant="outlined"  onClick={addQR} color="primary">Add</Button>
          
        </ModalFooter>
            </Modal>
            :null}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table responsive bordered striped hover >
                        <thead >
                            <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Message</th>
                            <th>Edit</th>
                            <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {qrs && qrs.map((item, index)=> (
                                <tr key={item._id}>
                                    <th scope="row">{index +1}</th>
                                    <td>{item.title}</td>
                                    <td>{item.message}</td>
                                    <td><i  className="fa fa-edit" onClick={() =>editMe(item._id,item.title,item.message)}></i></td>  
                                    <td><i className="is-isolated fas fa-trash" onClick={() =>deleteMe(item.title,item._id)}></i></td>     
                                </tr>
                            ))}


                            {delModal?
                        <Modal isOpen toggle={delToggle}>
            <ModalHeader toggle={delToggle} style={{backgroundColor:"#becdda8f"}}><h5 style={{fontWeight:"bold"}}>Remove - {delQRTitle}</h5></ModalHeader>
            <ModalBody>Are you sure you want to remove this? This action cannot be undone.
          </ModalBody>
        <ModalFooter>
                     <Button className="mr-2" variant="contained"color="danger" onClick={() =>delToggle()}>No</Button>
                  <Button  variant="contained" style={{backgroundColor:"#28a745",color:"white"}}onClick={() =>removeMeConfirm()}>Remove</Button>
          
        </ModalFooter>
            </Modal>
            :null}

            {editModal?
                        <Modal isOpen toggle={editToggle}>
            <ModalHeader toggle={editToggle}>Update {title}</ModalHeader>
            <ModalBody>
            <Input placeholder="Title" onChange={(e)=>{setTitle(e.target.value)}} Label="Title" type="text"  name="title" value={title}></Input>
            <Input placeholder="Message" onChange={(e)=>{setMessage(e.target.value)}} Label="Message" type="textarea"  name="message" value={message}></Input>
                              <BT onClick={(e)=>{addTag(e,"[@firstName]")}}  color="primary">[@firstName]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@lastName]")}}  color="primary">[@lastName]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@street]")}}  color="primary">[@street]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@city]")}}  color="primary">[@city]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@state]")}}  color="primary">[@state]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@zip]")}}  color="primary">[@zip]</BT>
                               <BT onClick={(e)=>{addTag(e,"[@email]")}}  color="primary">[@email]</BT>
          </ModalBody>
        <ModalFooter>
            
                  <Button color="primary" onClick={() =>saveQR()}>Update</Button>
          
        </ModalFooter>
            </Modal>
            :null}
                        </tbody>
                    </Table>
 
                </CardBody>
            </Card>
            </Col>
          </Row>
    );
  
}

const mapStateToProps = (state) => ({
  qrs: state.bulk.qrs,
  qr:state.bulk.qr

 });

QuickReply.propTypes = {
  createQR:PropTypes.func.isRequired,
  getQRS:PropTypes.func.isRequired
  };
export default connect(mapStateToProps, {createQR,getQRS,getQR,deleteQR,updateQR})(QuickReply)
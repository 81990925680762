import React, { useState } from 'react';
import { Box, Button, Input, ButtonsContainer, CallButton, DisCallButton } from './atom';
import { Device } from '@twilio/voice-sdk';

import './Dial.css';
export default ({disconnect,savenotes,setmicrophone,mic,connection}) => {
  const [number, setNumber] = React.useState('');
  const [state, dispatch] = useState(true);
  const [dis, setdis] = useState(false);
  // const [mic, setmicrophone] = useState(true);
  const buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

  // ComponentDidMount & ComponentWillUnmount
  React.useEffect(() => {
    document.addEventListener('click', onOuterClick);

    return () => document.removeEventListener('click', onOuterClick);
  });
  const disconnectcall = () => {
    console.log("disconnectcall >> newDialer",disconnect)
    disconnect()
    setdis(true);
  };
  const onOuterClick = () => {
    dispatch({ type: 'DIALER_CLOSE' });
  };
  const microphone = status => {
    console.log(status);
    if (status === 'off') {
      setmicrophone(false);
      // Device.on('connect', function(conn) {
      //   conn.mute(false);
      // });
      //  turn off the microphone
    } else {
      setmicrophone(true);
      // Device.on('connect', function(conn) {
      //   conn.mute(true);
      // });
      //  turn on the microphone
    }
  };
  const onDialerClick = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  const submit = () => {
    setdis(false);
  };
  const add =(char)=>{
    console.log(char)
    setNumber(number+char)
    connection.sendDigits(char);
  }
  return (
    <Box opened={true} onClick={onDialerClick}>
      
      <Input placeholder="0000-0000" value={number} onChange={e => setNumber(e.target.value)} />

      <ButtonsContainer>
        {buttons.map(char => (
          <Button key={char} onClick={()=>add(char)}>
            {char}
          </Button>
        ))}
      </ButtonsContainer>
      <ButtonsContainer>
        {!dis && mic ? (
          <Button onClick={() => microphone('off')}>
            <i style={{ color: 'red' }} class="fas fa-microphone-slash" />
          </Button>
        ) : !dis ? (
          <Button onClick={() => microphone('on')}>
            <i  class="fas fa-microphone-slash" />
            <br />
          </Button>
        ) : (
          <Button style={{ display: 'none' }} />
        )}
        {dis ? (
          <div style={{ textAlign: 'center' }}>
            <CallButton onClick={submit}>
              <i className="fa fa-phone" aria-hidden="true" />
            </CallButton>
          </div>
        ) : (
          <DisCallButton onClick={disconnectcall}>
            <i className="fa fa-phone" aria-hidden="true" />
          </DisCallButton>
        )}
        {!dis && (
          <Button onClick={()=>savenotes()}>
            <i class="fa fa-sticky-note" aria-hidden="true" />
          </Button>
        )}
      </ButtonsContainer>
    </Box>
  );
};

import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input
} from 'reactstrap';
import PropTypes from 'prop-types';
import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import {
  getCampaigns,
  downloadcampaign,
  download,
  campaigndeleted,
  updatecampname
} from '../../../Redux/Actions/Mask/Mask';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux'
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { getallCompanies } from '../../../Redux/Actions/USER/user';
import { getnumbers } from '../../../Redux/Actions/Twilio';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import { socket } from '../Main/socket';
var moment = require('moment-timezone');
const UserVoice = ({ getCampaigns, downloadcampaign, campaigns, download, campaigndeleted, updatecampname,getallCompanies,companies,getnumbers }) => {
  const [open, setOpen] = useState(false);
  const [editCampID, setEditCampID] = useState('');
  const [changedname, setchangedname] = useState('');
  const [changeddelay, setchangeddelay] = useState('');
  const [delModal, setDelModal] = useState(false);
  const [delID, setDelID] = useState('');
  const [delTitle, setDelTitle] = useState('');
  const [prospect_show, setprospect_show] = useState(false);

  const [company, setComp] = useState("")
  const currtz=useSelector(state=>state.Twilio.timezone)
  useEffect(() => {
    getCampaigns("");
    getallCompanies()
  }, [getCampaigns,getallCompanies]);
  const deleteCampaign = id => {
    campaigndeleted(id);
  };
  socket.addEventListener("prospectshow", msg=> {
  console.log("prospectshow", msg);
  localStorage.setItem('pshow', msg);
  setprospect_show(msg)
})
  const setCompany = (e)=> {
    setComp(e.target.value)
    getCampaigns(e.target.value)
    getnumbers(e.target.value)


}
socket.addEventListener("time", msg=> {
  console.log("time is ", msg);
})
  if (campaigns === null) {
    return <Loader />;
  }
  const downloadfile = (id, name) => {
    download(id, name);
  };
  const editToggle = () => {
    setOpen(!open);
  };
  const editname = (title, camp_id, delay1) => {
    setOpen(true);
    setEditCampID(camp_id);
    setchangedname(title);
    setchangeddelay(delay1);
  };
  const handleNameChange = e => {
    setchangedname(e.target.value);
  };

  const handleSubmit = () => {
    if (changedname === null || changedname === undefined || changedname === '' || changeddelay === '') {
      toast.error('Can not submit an empty name or delay');
    } else {
      updatecampname(editCampID, changedname, changeddelay);
      editToggle();
    }
  };

  const deleteMe = (title, id) => {
    setDelID(id);
    setDelTitle(title);
    setDelModal(true);
  };
  const delToggle = () => {
    setDelModal(!delModal);
  };

  const removeMeConfirm = () => {
    deleteCampaign(delID);
    setDelModal(false);
  };
 

  const pathname = window.location.pathname
  // console.log("pathname is",pathname)
  return (
    <Row className="justify-content-center">
      <Col md="12">
        <Card>
          <CardHeader>
            <Row>
              <Col md="3" xs="4">
                <CardTitle style={{ fontSize: '0.95em' }}>Campaign</CardTitle>
              </Col>
              <Col md="6" xs="4">
                <Input style={{ fontSize: '0.95em' }} type="select" onChange= {setCompany}>
                  {/* <option value="">Select Company</option> */}
                  {companies &&
                    companies.map(item => (
                      <option selected={item._id === company} key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Input>
              </Col>
              {prospect_show!=true &&(<Col md="3" xs="4">
                <Link className="btn btn-primary btn-sm" to="/pages/campaigns/uploadcampaign" style={{float:"right"}}>
                  Add Campaign
                </Link>
              </Col>)}
            </Row>
          </CardHeader>
          <CardBody>
            <Table responsive bordered striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>

                  <th>Status</th>
                  <th>Priority</th>
                  <th>Metric</th>
                  <th>Created At</th>
                  <th>Multidialer</th>
                  <th>Download</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {campaigns.map((item, index) => {
                  // console.log({ item });
                  return (
                    <tr key={item._id}>
                      <th scope="row">
                        {index + 1} {item.count > 0 ? <FontAwesomeIcon color="yellow" icon={faBolt} /> : null}
                      </th>
                      <td>
                        <Tooltipbutton title="Click here to send bulk message">
                        <Link to={`/pages/campaigns/${item._id}`}>{item.name}</Link>
                        </Tooltipbutton>
                      </td>
                      <td>{item.status}</td>
                      <td>
                        {item.count} {<FontAwesomeIcon color="yellow" icon={faBolt} />}
                      </td>
                      <td>
                        <Tooltipbutton title="Click here to see the metric" >
                        <Link className="btn btn-sm btn-primary" to={`/metric/${item._id}`}>
                          show
                        </Link>
                        </Tooltipbutton>
                      </td>
                      <td>
                        {moment(item.createdAt).tz(currtz).format('LLL')}
                      </td>
                      <td><Tooltipbutton title="Click here to see Multidialer"><Link to={`/campaigns/multilinedialer/${item._id}`}><i class="fas fa-phone-alt"></i></Link></Tooltipbutton></td>
                      <td>
                        <Tooltipbutton title="Click here to download file">
                        <Button color="primary" onClick={() => downloadcampaign(item.url,item.name,pathname)}>
                          <i class="fas fa-download" />
                        </Button>
                        </Tooltipbutton>
                      </td>

                      <td>
                        <Tooltipbutton title="Click here to edit campaign name">
                        <i
                          className="is-isolated fas fa-edit"
                          onClick={() => editname(item.name, item._id, item.delay)}
                        /></Tooltipbutton>
                      </td>
                      <td><Tooltipbutton title="Click here to delete campaign">
                        <i className="is-isolated fas fa-trash" onClick={() => deleteMe(item.name, item._id)} />
                      </Tooltipbutton></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {open ? (
              <Modal isOpen toggle={editToggle}>
                <ModalHeader toggle={editToggle}>Edit Campaign Name</ModalHeader>
                <ModalBody>
                  <Input value={changedname} onChange={e => handleNameChange(e)} />

                  <Label>Delay Interval Per Text Message(s)</Label>
                  <Input value={changeddelay} onChange={e => setchangeddelay(e.target.value)} />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}

            {delModal ? (
              <Modal isOpen toggle={delToggle}>
                <ModalHeader toggle={delToggle} style={{ backgroundColor: '#becdda8f' }}>
                  <h3 style={{ fontWeight: 'bold' }}>Remove - {delTitle}</h3>
                </ModalHeader>
                <ModalBody>Are you sure you want to remove this? This action cannot be undone.</ModalBody>
                <ModalFooter>
                  <Button className="mr-2" variant="contained" color="secondary" onClick={() => delToggle()}>
                    No
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#28a745', color: 'white' }}
                    onClick={() => removeMeConfirm()}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({
  campaigns: state.bulk.campaigns,
  companies: state.Twilio.companies

});
UserVoice.propTypes = {
  getCampaigns: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
};
export default connect(
  mapStateToProps,
  { getCampaigns, downloadcampaign, download, campaigndeleted, updatecampname,getallCompanies,getnumbers }
)(UserVoice);

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody,ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import CardDetail from '../../view/pages/Stripe/Checkout';
import Buynumber from '../../view/pages/Number/Buynumber';
import ReleaseNumber from '../../view/pages/MyNumbers/releaseNumber';
import CreateCompany from '../../view/pages/Number/createCompany';
import CancelledSubscription from '../../view/pages/Stripe/Modal';
import AddFund from '../../view/pages/Fund/AddFund';
import EditConf from '../../view/pages/Fund/EditConf';
import { connect } from 'react-redux';
// import { useSelector } from 'react-redux'
import CreateFollowup from '../../view/pages/Mask/createFollowup';
import './Modal.css';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { Tooltip as TOL } from 'reactstrap';
import Tooltipbutton from '../Tooltip/Tooltip';
import { direct_subs,renew } from '../../Redux/Actions/Payment';
var moment = require('moment-timezone');
class ModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      note: '',
      tooltipOpen: false,
      tooltipOpens: false,
      cancellationReason: '',
      otherReason: ''
    };
  }
  toggles = () => {
    this.setState(prevState => ({
      tooltipOpen: !prevState.modal
    }));
  };
  toggless = () => {
    this.setState(prevState => ({
      tooltipOpens: !prevState.modal
    }));
  };
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      cancellationReason: '',
      otherReason: '',
    }));
  };
  handleNoteChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handledirect_subs = (item) => {
    this.props.direct_subs(item);
}
handlerenew = (item) => {
  this.props.renew(item); 
}

handleCancellationReasonChange = (e) => {
  this.setState({ cancellationReason: e.target.value });
};

handleOtherReasonChange = (e) => {
  this.setState({ otherReason: e.target.value });
  console.log("otherReason",e.target.value)
};

  // this.currtz=useSelector(state=>state.auth.subs)
  // console.log("currrrrrrr",this.currrrr)
  render() {
    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    const label = this.props.buttonLabel;
    if (label === 'BUY') {
      return (
        <><Tooltipbutton title="Click here to buy number">
          <Button
            // style={{ padding: "0.9rem 0.938rem"}}
            // className= {this.props.active === 0 ? "active mr-1" : "mr-1"}
            size="sm"
            color="primary"
            onClick={this.toggle}
            outline
          >
            BUY
          </Button></Tooltipbutton>
          <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle} close={closeBtn}>
              Buy Number
            </ModalHeader>
            <ModalBody>
              <Buynumber toggle={this.toggle} number={this.props.number} />
              {this.props.children}
            </ModalBody>
          </Modal>
        </>
      );
    }

    if (label === 'Release') {
      return (
        <>
          <Tooltip title="Release Number" arrow>
            <Button size="sm" color="danger" onClick={this.toggle} outline>
              <i class="fas fa-times-circle" />
            </Button>
          </Tooltip>
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle} close={closeBtn} style={{ backgroundColor: '#becdda8f' }}>
              <h4 style={{ fontWeight: 'bold' }}>Remove {this.props.number}</h4>
            </ModalHeader>
            <ModalBody>
              <ReleaseNumber toggle={this.toggle} number={this.props.number} />
              {this.props.children}
            </ModalBody>
          </Modal>
        </>
      );
    }
    if (label === 'Company') {
      return (
        <>
          <Button
            // style={{ padding: "0.9rem 0.938rem"}}
            // className= {this.props.active === 0 ? "active mr-1" : "mr-1"}
style={{float:"right"}}
            color="primary"
            onClick={this.toggle}
            outline
          >
            Create Company
          </Button>
          <Modal size="md" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle}style={{ backgroundColor: '#becdda8f' }} close={closeBtn}>
              Create Company
            </ModalHeader>
            <ModalBody>
              <CreateCompany toggle={this.toggle} />
              {this.props.children}
            </ModalBody>
          </Modal>
        </>
      );
    }

    if (label === 'Notes') {
      return (
        <>
          <Button size="sm" onClick={this.toggle}>
            Add Notes
          </Button>
          <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle} close={closeBtn}>
              Add Notes
            </ModalHeader>
            <ModalBody>
              <form
                onSubmit={e => {
                  this.props.callNotesAPI(e, this.state.note);
                  this.toggle();
                }}
              >
                <textarea name="note" required onChange={this.handleNoteChange} rows="4" cols="50" />

                <button style={{ background: 'blue', borderRadius: '20px', margin: '20px' }}>Add</button>
              </form>
            </ModalBody>
          </Modal>
        </>
      );
    }

    if (label === 'NotesEdit') {
      return (
        <>
          <Link onClick={this.toggle}>Edit Notes</Link>
          <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle} close={closeBtn}>
              Edit Notes
            </ModalHeader>
            <ModalBody>
              <form
                onSubmit={e => {
                  this.props.callNotesEditAPI(e, this.state.note, this.props.noteID);
                  this.toggle();
                }}
              >
                <textarea
                  name="note"
                  required
                  onChange={this.handleNoteChange}
                  rows="4"
                  cols="50"
                  value={this.state.note}
                />

                <button style={{ background: 'blue', borderRadius: '20px', margin: '20px' }}>Edit Note</button>
              </form>
            </ModalBody>
          </Modal>
        </>
      );
    }

    if (label === 'AddCard') {
      return (
        <>
          <Button
            // style={{ padding: "0.9rem 0.938rem"}}
            // className= {this.props.active === 0 ? "active mr-1" : "mr-1"}

            color="primary"
            onClick={this.toggle}
            outline
          >
            Add Card
          </Button>
          <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle} close={closeBtn}>
              Add Card
            </ModalHeader>
            <ModalBody>
              <CardDetail toggle={this.toggle} />
              {this.props.children}
            </ModalBody>
          </Modal>
        </>
      );
    }
    if (label === 'Card') {
      console.log("this.props",this.props.check)
      return (
        <>
          <button
            onClick={(e)=>{
              
              
                if(this.props.check==="true"){
                  // this.props.direct_subs(this.props.item)
                  this.handledirect_subs(this.props.item)
                }else{
                   const pop = this.props.onSubscribe();
                !pop && this.toggle(e);
                // this.toggle(e)
                }
               
              
            }}
            className="modal-button"
            tag={Link}
          >
            Start Free Trial
          </button>
          <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle} close={closeBtn}>
              Add Card
            </ModalHeader>
            <ModalBody>
              <CardDetail item={this.props.item} price={this.props.price} toggle={this.toggle} />
              {this.props.children}
            </ModalBody>
          </Modal>
        </>
      );
    }
    if (label === 'UnSubscribe') {
      return (
        <>
          <button onClick={this.toggle} className="modal-button">
            UnSubscribe
          </button>
          {(this.props.subs&&moment.unix(this.props.subs.edate).utc().tz(this.props.currtz).endOf('day').isBefore(moment.tz(this.props.currtz)))&&(<button onClick={()=>{this.handlerenew(this.props.item)}} className="modal-button mx-1">
            Renew
          </button>) 
    
  }
  {/* <button onClick={()=>{this.props.renew(this.props.item)}} className="modal-button">
            Renew
          </button> */}
          
          <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader  style={{ backgroundColor: '#becdda8f' }} toggle={this.toggle} close={closeBtn}>
            <h5 style={{ fontWeight: 'bold' }}> UnSubscribe </h5>
            </ModalHeader>
            <ModalBody>
            Are you sure you want to remove this? This action cannot be undone.
            <FormGroup>
            <Label for="cancellationReason">Select cancellation reason:</Label>
            <Input
              type="select"
              name="cancellationReason"
              id="cancellationReason"
              value={this.state.cancellationReason}
              onChange={this.handleCancellationReasonChange}
            >
              <option value="">Select reason</option>
              <option value="customer_service">Customer Service</option>
              <option value="low_quality">Low Quality</option>
              <option value="missing_features">Missing Features</option>
              <option value="switched_service">Switched Service</option>
              <option value="too_complex">Too Complex</option>
              <option value="too_expensive">Too Expensive</option>
              <option value="unused">Unused</option>
              <option value="other">Other</option>
            </Input>
          </FormGroup>
          <FormGroup>
              <Label for="otherReason">Detail:</Label>
              <Input
                type="textarea"
                name="otherReason"
                id="otherReason"
                value={this.state.otherReason}
                onChange={this.handleOtherReasonChange}
                required
              />
            </FormGroup>
          {/* {this.state.cancellationReason === 'other' && (

          )} */}
              
            </ModalBody>
            <ModalFooter>
              <CancelledSubscription number={this.props.item} price={this.props.price} reason={this.state.cancellationReason} detail={this.state.otherReason} toggle={this.toggle} />
              {this.props.children}
            </ModalFooter>
          </Modal>
        </>
      );
    }

    if (label === 'AddFund') {
      return (
        <>
          <Button
            // style={{ padding: "0.9rem 0.938rem"}}
            // className= {this.props.active === 0 ? "active mr-1" : "mr-1"}
            id="TooltipExamples"
            color="transparent"
            onClick={this.toggle}
            outline
          >
            <div class="icon"><i class="fas fa-upload"></i></div>
            <div class="icon-text">Top-up</div>

          </Button>
          <TOL placement="right" isOpen={this.state.tooltipOpens} target="TooltipExamples" toggle={this.toggless}>
             Add Fund
          </TOL>
          <Modal  isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle} close={closeBtn}>
              Fund
            </ModalHeader>
            <ModalBody>
              <AddFund toggle={this.toggle} />
              {this.props.children}
            </ModalBody>
          </Modal>
        </>
      );
    }
    if (label === 'EditConfiguration') {
      return (
        <>
          <Button
            id="TooltipExample"
            // style={{ padding: "0.9rem 0.938rem"}}
            // className= {this.props.active === 0 ? "active mr-1" : "mr-1"}

            color="transparent"
            onClick={this.toggle}
            outline

          >
            <div class="icon">
            <i class="fas fa-edit"></i></div>
            <div class="icon-text">Edit</div>
          </Button>
          <TOL placement="right" isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={this.toggles}>
            Edit Configuration
          </TOL>
          <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle} close={closeBtn}>
              Configuration
            </ModalHeader>
            <ModalBody>
              <EditConf toggle={this.toggle} />
              {this.props.children}
            </ModalBody>
          </Modal>
        </>
      );
    }
    if (label === 'follow_up') {
      return (
        <>             <Tooltip title="This button will reset the campaign given below">
          <Button color="primary" size="sm" onClick={this.toggle}>
            Follow Up
          </Button>
          </Tooltip>
          <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={this.toggle} close={closeBtn}>
              Follow up
            </ModalHeader>
            <ModalBody>
              <CreateFollowup toggle={this.toggle} />
            </ModalBody>
          </Modal>
        </>
      );
    }
  }
}
// export default ModalForm;
const mapeStateToProps = (state) => ({
   subs:state.auth.subs,currtz:state.Twilio.timezone
})
// const mapDispatchToProps = dispatch => {
//   return {
//     // renew:actions.renew,
//     // direct_subs:actions.direct_subs // you should send your action like this
//     handledirect_subs: (item) => { // handles onTodoClick prop's call here
//       dispatch(direct_subs(item))
//     },
//     handlerenew: (item) => { // handles onTodoClick prop's call here
//       dispatch(renew(item))
//     }
//   };
// };
export default connect(
  mapeStateToProps,
  // null,
  // mapDispatchToProps,
  {direct_subs,renew}
)(ModalForm);
import React, { useState, useEffect } from 'react';
import './prospect.css';
import Conversation from '../Mask/Conversation';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import {  TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col ,CardHeader,CardBody } from 'reactstrap';
import { createNotes, getNotes, updateNotes, deleteNotes } from '../../../Redux/Actions/Notes/Notes';
import { useParams,useHistory,Link  } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal/Modal';
import { connect } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';
import {
  getProspect,
  updatedncstatus,
  getLeadStage,
  updateleadstage,
  updateProspects,
  wrongnumprospects,
  verifiedprospect,
  removeverifiedprospect,
  removewrongnumprospects,
  isqualified,
  wasqualified,
  isPriority,
  wasPriority,
  pushToWH
} from '../../../Redux/Actions/Mask/Mask';
import {socket} from "../Main/socket"
// const socket = io(process.env.REACT_APP_PORT, {
//   query: {
//     email: localStorage.getItem('email'),
//     filename:"GetProspects"
//   }
// });
const GetProspectR = ({
  getProspect,
  getLeadStage,
  prospect,
  updatedncstatus,
  updateleadstage,
  leads,
  wrongnumprospects,
  verifiedprospect,
  removeverifiedprospect,
  removewrongnumprospects,
  isqualified,
  wasqualified,
  isPriority,
  wasPriority,
  createNotes,
  getNotes,
  notes,
  updateNotes,
  deleteNotes,
  pushToWH,
  updateProspects
}) => {
  const { id } = useParams();
  const history = useHistory();
  const [message, setmessage] = useState(null);
  const [cond,setcondition] = useState(true)
  const [array, setArray] = useState(prospect || []);
  const [loading, setloading] = useState(false);
  const [count, setcount] = useState(60);
  const [activeTab, setActiveTab] = useState('1');
  const setleadStage = (e, pr) => {
    if (e.target.value != '') {
      updateleadstage(id, e.target.value);
    } else {
      alert('Cannot be empty');
    }
  };
  useEffect(() => {
    getNotes(id);
    getProspect(id);
    socket.emit('getmessagebypros', localStorage.getItem('email'), id);
    getLeadStage();
  }, [getProspect]);
  socket.on('message', msg => {
    console.log(msg);
    setmessage(msg);
  });

  const pushToWHT = () => {
    setloading(true);
    const intervel = setInterval(() => {
      // let c=count-1
  
      setcount(count => {
        if (count <= 0) {
          return 0;
        } else {
          return count - 1;
        }
      });
    }, 1000);
    setTimeout(() => {
      pushToWH(id);
      setloading(false);
      setcount(60)

      clearInterval(intervel)
    }, 60000);

    // console.log(percount)
  };

  const callNotesAPI = (e, note) => {
    e.preventDefault();
    createNotes(id, note);
  };

  const callAPI = (e, f, l) => {
    e.preventDefault();
    updateProspects(id, f, l);
  };

  const callNotesEditAPI = (e, note, note_id) => {
    e.preventDefault();
    console.log('noted', note_id);
    updateNotes(note_id, note, id);
  };

  const deleteNote = (e, note_id) => {
    e.preventDefault();
    deleteNotes(note_id, id);
  };

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  const deleteprospect = data => {
    console.log('dd', data);
  };
  const update = (dnc) => {
    updatedncstatus(id, dnc,true);
  };
  const wrongNumber = () => {
    console.log('wrong', id);
    wrongnumprospects(id);
  };

  const verifiedNumber = () => {
    console.log('wrong', id);
    verifiedprospect(id);
  };

  const rwrongNumber = () => {
    console.log('wrong', id);
    removewrongnumprospects(id);
  };

  const rverifiedNumber = () => {
    console.log('wrong', id);
    removeverifiedprospect(id);
  };

  const qualifiedNumber = () => {
    console.log('wrong', id);
    isqualified(id);
  };
  const rqualifiedNumber = () => {
    console.log('wrong', id);
    wasqualified(id);
  };

  const priorityNumber = () => {
    console.log('wrong', id);
    isPriority(id);
  };
  const rpriorityNumber = () => {
    console.log('wrong', id);
    wasPriority(id);
  };

  var p = prospect != undefined ? prospect.verified : null;
  console.log('p status', p);


  var index = array.findIndex(e => e._id === id);
  const previousPage = async () => {
    console.log('previous');
    if (index <= 0) {
      setcondition(false)
      toast('cant go lower');
      return;
    } else {
      index = index - 1;
      const new_id = array[index]?._id;

      new_id && history.push(`/prospect/${new_id}`);
      // getProspect(id);
    }
  };
  const nextPage = async () => {
    console.log('next');
    if (index > array.length) {
      toast('please switch to next page');
      return;
    } else {
      index = index + 1;

      const new_id = array[index]?._id;
      new_id && history.push(`/prospect/${new_id}`);

      getProspect(id);
    }
  };





  return (

    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            Notes
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
    <Row>
      <Col md={7}>
        <Card>
          <CardHeader>
            <CardTitle style={{ fontSize: '0.96rem' }}>
              <Row className="justify-content-center">
                <Col md="4" xs="4">
                  Prospect
                </Col>
              </Row>
            </CardTitle>
          </CardHeader>
          <CardBody>
            {prospect != null && (
              <>
                {' '}
                <Row>
                  <Col md={6}>
                    <h1 style={{ fontSize: '0.96rem' }}>Name:</h1>
                  </Col>
                  <Col md={6}>
                  {(prospect.first_name === undefined || prospect.first_name === null) && (prospect.first_name = '  ')}
                  {(prospect.last_name === undefined || prospect.last_name === null) && (prospect.last_name = '  ')}
                    <h1 style={{ fontSize: '0.96rem' }}>{prospect.first_name + '  ' + prospect.last_name}</h1>
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col md={6}>
                    <h1 style={{ fontSize: '0.96rem' }}>Phone:</h1>
                  </Col>
                  <Col md={6}>
                  {(prospect.phone === undefined || prospect.phone === null) && (prospect.phone = '  ')}
                    <h1 style={{ fontSize: '0.96rem' }}>{prospect.phone}</h1>
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col md={6}>
                    <h1 style={{ fontSize: '0.96rem' }}>Address:</h1>
                  </Col>
                  <Col md={6}>
                  {(prospect.address === undefined || prospect.address === null) && (prospect.address = '  ')}
                  {(prospect.street === undefined || prospect.street === null) && (prospect.street = '  ')}
                  {(prospect.city === undefined || prospect.city === null) && (prospect.city = '  ')}
                  {(prospect.state === undefined || prospect.state === null) && (prospect.state = '  ')}
                  {(prospect.country === undefined || prospect.country === null) && (prospect.country = '  ')}
                    <h1 style={{ fontSize: '0.96rem' }}>{prospect.address + ' ' + prospect.street}</h1>
                    <h1 style={{ fontSize: '0.96rem' }}>
                      {prospect.city + '   ' + prospect.state + '   ' + prospect.country}
                    </h1>
                  </Col>
                </Row>
                <Row className="pt-4">
                  <Col md={6}>
                    <h1 style={{ fontSize: '0.96rem' }}>LeadStage:</h1>
                  </Col>
                  <Col md={6}>
                    <select onChange={e => setleadStage(e, prospect._id)}>
                      <option value={''}>Select</option>
                      {leads.length > 0 &&
                        leads.map((lead, index) => {
                          return (
                            <option selected={prospect.lead == lead._id} value={lead._id} key={index}>
                              {lead.lead_Stage}
                            </option>
                          );
                        })}
                    </select>
                  </Col>
                </Row>
                <Row className="pt-4 justify-content-center">
                {loading ? (
                                <div style={{ marginTop: '10px' }}>
                                  <Button style={{ background: 'blue', borderRadius: '20px', marginTop: '10px' }}>
                                    Pushing to webhook in {count}sec
                                  </Button>
                                </div>
                              ) : (
                                <Button
                                  style={{ background: 'blue', borderRadius: '20px', marginTop: '10px' }}
                                  onClick={pushToWHT}
                                >
                                  Push to Webhook
                                </Button>
                              )}
                              <br /> <br />
                  <Col md={6}>
                  {prospect.dnc ? (
                                <span style={{ color: 'Red' }}>
                                  <i
                                    class="fas fa-times-circle fa-lg"
                                    title="Remove From DNC"
                                    onClick={() => {
                                      update(false);
                                    }}
                                  />
                                </span>
                              ) : (
                                <span style={{ color: 'Grey' }}>
                                  <i
                                    class="fas fa-times-circle fa-lg"
                                    title="Add To DNC"
                                    onClick={() => {
                                      update(true);
                                    }}
                                  />
                                </span>
                              )}{' '}
                              {prospect.verified == false ? (
                                <i
                                  onClick={() => verifiedNumber()}
                                  class="fas fa-check-square fa-lg"
                                  title="Add to verified"
                                />
                              ) : (
                                <span style={{ color: 'Green' }}>
                                  <i
                                    onClick={() => rverifiedNumber()}
                                    class="fas fa-check-square fa-lg"
                                    title="Remove From Verified"
                                  />
                                </span>
                              )}{' '}
                              {prospect.Wrong_Number == false ? (
                                <i
                                  onClick={() => wrongNumber()}
                                  class="fas fa-phone-slash fa-lg"
                                  title="Add to wrong number"
                                />
                              ) : (
                                <span style={{ color: 'Blue' }}>
                                  <i
                                    onClick={() => rwrongNumber()}
                                    class="fas fa-phone-slash fa-lg"
                                    title="Remove From Wrong number"
                                  />
                                </span>
                              )}{' '}
                              {prospect.isLead == false ? (
                                <i
                                  onClick={() => qualifiedNumber()}
                                  class="fas fa-star fa-lg"
                                  title="Add to qualified"
                                />
                              ) : (
                                <span style={{ color: 'Purple' }}>
                                  <i
                                    onClick={() => rqualifiedNumber()}
                                    class="fas fa-star fa-lg"
                                    title="Remove From Qualified"
                                  />
                                </span>
                              )}{' '}
                              {prospect.isPriority == false ? (
                                <i
                                  onClick={() => priorityNumber()}
                                  class="fas fa-bolt fa-lg"
                                  title="Add to priority number"
                                />
                              ) : (
                                <span style={{ color: '#FF69B4' }}>
                                  <i
                                    onClick={() => rpriorityNumber()}
                                    class="fas fa-bolt fa-lg"
                                    title="Remove From Priority number"
                                  />
                                </span>
                              )}
                               <br />
                              <div className="d-flex ">
                                {cond == false? 
                                 null
                                : (
                                  <span className="mr-2 mt-3 " style={{ cursor: 'pointer', color: 'Green' }}>
                                    <i className="fas fa-arrow-circle-left fa-lg" onClick={() => previousPage()} />
                                  </span>
                                )}
                                {/* <span className="mr-2 mt-3 " style={{ cursor: 'pointer', color: 'Green' }}>
                                  <i className="fas fa-arrow-circle-left fa-lg" onClick={() => previousPage()} />
                                </span> */}
                                <span className="mx-auto" />
                                <span className="mr-2 mt-3 " style={{ cursor: 'pointer', color: 'Green' }}>
                                  <i className="fas fa-arrow-circle-right fa-lg" onClick={() => nextPage()} />
                                </span>
                              </div>
                  </Col>
                </Row>
                </>
            )}
          </CardBody>
        </Card>
      </Col>
      <Col md={5}>{message != null && <Conversation deleteprospect={deleteprospect} data={message} socket={socket} />}</Col>
    </Row>
    </TabPane>
    <TabPane tabId="2">
          <Row>
            <Col sm="6">
              <Card body>
                <Row sm="6">
                  <Col sm="4">
                    <CardTitle>Notes</CardTitle>
                  </Col>
                  <Col sm="4">
                    <Modal buttonLabel="Notes" callNotesAPI={callNotesAPI} />
                  </Col>
                </Row>

                {notes.length > 0
              
                  ? notes.map((note, index) => {
                    console.log('notrs',note)
                      return (
                        <Card body>
                          <CardText>{moment(note.createdAt).format('LLL')}</CardText>
                          <CardText>{note.notes}</CardText>
                          <Row sm="6">
                            <Col sm="3">
                              <Modal buttonLabel="NotesEdit" callNotesEditAPI={callNotesEditAPI} noteID={note._id} />
                            </Col>
                            <Col sm="3">
                              <Link onClick={e => deleteNote(e, note._id)}>Delete</Link>
                            </Col>
                          </Row>
                        </Card>
                      );
                    })
                  : null}
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};
const mapStateToProps = state => ({
  prospect: state.bulk.prospect,

  leads: state.bulk.leads,
  notes: state.bulk.notes,
});
GetProspectR.propTypes = {
  getProspect: PropTypes.func.isRequired,
  updatedncstatus: PropTypes.func.isRequired,
  getNotes: PropTypes.func.isRequired
};
export default connect(
  mapStateToProps,
  {
    getProspect,
    getLeadStage,
    updatedncstatus,
    updateleadstage,
    wrongnumprospects,
    verifiedprospect,
    removeverifiedprospect,
    removewrongnumprospects,
    isqualified,
    wasqualified,
    isPriority,
    wasPriority,
    createNotes,
    getNotes,
    updateNotes,
    deleteNotes,
    pushToWH
  }
)(GetProspectR);

import {
  GET_TOKEN,
  GET_DETAIL,
  GET_AVAILABLE,
  SEND_MESSAGE,
  GET_NUMBER,
  SEND_FAX,
  SENT_OTP,
  VERIFY_OTP,
  GET_FILTERNO,
  GET_COUNTAVAI,
  OPEN_DIALOG,
  CLOSE_DIALOG
} from './type';
import Api from '../../util/api';

import { toast } from 'react-toastify';

import { Device } from 'twilio-client';

export const openDialog = (data, Token) => async dispatch => {
  console.log('openDialog');
  let dataSend = { data: data, Token: Token };

  dispatch({
    type: OPEN_DIALOG,
    payload: dataSend
  });
  // { type: OPEN_DIALOG }
};
export const closeDialog = () => async dispatch => {
  console.log('closeDialog');

  dispatch({
    type: CLOSE_DIALOG
  });
};

export const getnumbers = name => async dispatch => {
  try {
    // if(name==undefined)
    // {name=localStorage.getItem("comp")}
    // console.log("name is",name)
    const res = await Api({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/buynumber?company=' + name,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;
    //   console.log("token is",token)
    dispatch({
      type: GET_NUMBER,
      payload: token
    });
  } catch (e) {
    console.log(e);
  }
};
export const releasenumber = number => async dispatch => {
  try {
    const res = await Api({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/release',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        number: number
      }
    });

    dispatch(getnumbers());
    toast.success(`This   ${number} number has been Released `);
  } catch (e) {
    console.log(e);
  }
};
export const getnumber = id => async dispatch => {
  try {
    const res = await Api({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/detail?id=' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;
    dispatch({
      type: GET_DETAIL,
      payload: token
    });
  } catch (e) {
    console.log(e);
  }
};
export const updatenumber = (id, voice, sms, pnumber, name, callflow) => async dispatch => {
  try {
    const res = await Api({
      method: 'PUT',
      url: process.env.REACT_APP_PORT + '/api/me/detail?id=' + id,
      headers: {
        'content-type': 'application/json',
        'x-auth-token': localStorage.getItem('token')
      },
      data: JSON.stringify({
        voice: voice,
        sms: sms,
        pnumber: pnumber,
        name: name,
        callflow: callflow
      })
    });

    toast.success('Updated!!');

    dispatch(getnumber(id));
  } catch (e) {
    console.log(e);
  }
};

export const gettoken = () => async dispatch => {
  try {
    //   const test = await Api.get('https://jsonplaceholder.typicode.com/todos/1')
    //   console.log("test",test)
    const res = await Api({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/token',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    const token = res.data.token;
    // console.log("🚀 ~ file: Twilio.js ~ line 124 ~ gettoken ~ token", token)

    dispatch({
      type: GET_TOKEN,
      payload: token
    });
    dispatch(getnumbers());
    const respo = await Api({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/payment/checkfreetrail',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    const check = respo.data.data;
    if (check.freetrail && check.pop) {
      toast.info(
        'Your free-trail will end in few days. Money will automatically deduct from you account. Thankyou !!!  '
      );
    }
  } catch (e) {
    if (e.response != undefined) {
      toast.error(e.response.data.error);
    }

    console.log(e);
  }
};
//GET AVAILABLE NUMBER
export const availablenumber = (code, prefix, offset) => async dispatch => {
  try {
    const res = await Api({
      url: process.env.REACT_APP_PORT + `/api/search?code=${code}&prefix=${prefix}&offset=${offset}`,
      method: 'GET',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    dispatch({
      type: GET_AVAILABLE,
      payload: res.data.data
    });

    const response = await Api({
      url: process.env.REACT_APP_PORT + `/api/searchcount?code=${code}&prefix=${prefix}`,
      method: 'GET',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    dispatch({
      type: GET_COUNTAVAI,
      payload: response.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
// Buy a number
export const buynumber = (id, number, voice, sms, company, name, alias) => async dispatch => {
  try {
    const res = await Api({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/buy/${id}/${number}`,
      headers: { 'content-type': 'application/json', 'x-auth-token': localStorage.getItem('token') },
      data: JSON.stringify({
        voice: voice,
        sms: sms,
        company: company,
        name: name,
        alias: alias
      })
    });
    toast.success(`The number ${id} has been bought`);
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
//send call
// export const sendcall = (phone, from) => async (dispatch) => {
//     try{
//         console.log("from",from,"phone",phone)
//         const res = await  Api({url:process.env.REACT_APP_PORT+`/api/sendcall`,
//         method:'POST',
//         headers: { 'content-type': "application/json", "x-auth-token": localStorage.getItem("token") },
//         data: JSON.stringify({
//             to: phone,
//             from: from
//         }) })
//         // dispatch({
//         //     type: SEND_MESSAGE,
//         //     payload: res.data.data
//         // })
//         console.log(res,"response from call")

//     }
//     catch(e)
//     {
//        toast.error(e.response.data.error);

//        }
//     }

//Send Message
export const sendmessage = (phone, body, from) => async dispatch => {
  try {
    const ress = await Api({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/checkbal`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    if (ress.data.data) {
      const res = await Api({
        url: process.env.REACT_APP_PORT + `/api/send`,
        method: 'POST',
        headers: { 'content-type': 'application/json', 'x-auth-token': localStorage.getItem('token') },
        data: JSON.stringify({
          to: phone,
          body: body,
          from: from
        })
      });
      dispatch({
        type: SEND_MESSAGE,
        payload: res.data.data
      });

      toast.success('Sent!!');
    } else {
      toast.error('Please check Funds');
    }
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

//Send Fax
export const sendfax = (phone, body, to, quality) => async dispatch => {
  try {
    const res = await Api({
      url: process.env.REACT_APP_PORT + `/api/fax`,
      method: 'POST',
      headers: { 'content-type': 'application/json', 'x-auth-token': localStorage.getItem('token') },
      data: JSON.stringify({
        from: phone,
        url: body,
        to: to,
        quality: quality
      })
    });
    dispatch({
      type: SEND_FAX,
      payload: res.data.data
    });
    toast.success('Sent!');
  } catch (e) {
    if (e.response.data.error.match(' is not a valid E.164-formatted phone number')) {
      toast.error("Please Check  Recipient's Fax Number");
    } else {
      toast.error(e.response.data.error);
    }
  }
};
//Send OTP
export const sendotp = number => async dispatch => {
  try {
    const n = '+' + number;

    const res = await Api({
      url: process.env.REACT_APP_PORT + `/api/otpsend`,
      method: 'POST',
      headers: { 'content-type': 'application/json', 'x-auth-token': localStorage.getItem('token') },
      data: JSON.stringify({
        to: n
      })
    });
    localStorage.setItem('number', n);
    dispatch({
      type: SENT_OTP,
      payload: true
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const verifyotp = (number, code, twilio) => async dispatch => {
  try {
    const res = await Api({
      url: process.env.REACT_APP_PORT + `/api/verifyotp`,
      method: 'POST',
      headers: { 'content-type': 'application/json', 'x-auth-token': localStorage.getItem('token') },
      data: JSON.stringify({
        to: number,
        code: code
      })
    });
    dispatch({
      type: VERIFY_OTP,
      payload: true
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const deactivate = number => async dispatch => {
  try {
    const res = await Api({
      url: process.env.REACT_APP_PORT + `/api/deactivate`,
      method: 'POST',
      headers: { 'content-type': 'application/json', 'x-auth-token': localStorage.getItem('token') },
      data: JSON.stringify({
        number: number
      })
    });
    dispatch(getnumbers());
    toast.success('Number Deactivated');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const activate = number => async dispatch => {
  try {
    const res = await Api({
      url: process.env.REACT_APP_PORT + `/api/activate`,
      method: 'POST',
      headers: { 'content-type': 'application/json', 'x-auth-token': localStorage.getItem('token') },
      data: JSON.stringify({
        number: number
      })
    });
    dispatch(getnumbers());
    toast.success('Number Activated');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const filterNumbers = filterData => async dispatch => {
  try {
    const comp = localStorage.getItem('comp');
    filterData['comp'] = comp;
    //  console.log("comp is",comp)
    //  console.log("filterData is",filterData);
    const res = await Api({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/filternumbers',
      headers: { 'content-type': 'application/json', 'x-auth-token': localStorage.getItem('token') },
      data: JSON.stringify(filterData)
    });
    const numbers = res.data.data;
    //   console.log(numbers,"jj",res);
    dispatch({
      type: GET_FILTERNO,
      payload: numbers
    });

    //  console.log("Res",res)
  } catch (e) {
    // dispatch(setAlert(e.response.data.error, "danger"))
  }
};

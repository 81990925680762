import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NavbarDropdown from './NavbarDropdown';
import NavbarDropdownComponents from './NavbarDropdownComponents';
import {connect} from "react-redux"
import {
  authenticationRoutes,
  CallService,
  componentRoutes,
  ECommerceRoutes,
  Payment,
  homeRoutes,
  // pageRoutes,
  // pluginRoutes,
  utilityRoutes,
  MessageService,
  BulkMessage
  
  
} from '../../routes';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { breakpoints, getPageName } from '../../helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed, setShowBurgerMenu,  isAuthenticated}) => {
  const { isCombo, isTopNav } = useContext(AppContext);

  const components = [componentRoutes,  utilityRoutes, Payment];
  const pages = [ECommerceRoutes];
  // const Numbers = [pageRoutes]
  
  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);
  };
  const isLanding = getPageName('landing');

  const loggedin  =  <>
  <NavbarDropdown
    title={homeRoutes.name}
    items={homeRoutes.children}
    handleSetNavbarCollapsed={handleSetNavbarCollapsed}
  />
 
    <NavItem onClick={handleSetNavbarCollapsed}>
      <NavLink className="nav-link" to={CallService.to}>
        {CallService.name}
      </NavLink>
    </NavItem>
    <NavItem onClick={handleSetNavbarCollapsed}>
      <NavLink  className="nav-link" to={MessageService.to}>
        {MessageService.name}
      </NavLink>
    </NavItem>
    
 
  <NavbarDropdownComponents title={ECommerceRoutes.name} items={pages} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
  <NavbarDropdownComponents
    title={componentRoutes.name}
    items={components}
    handleSetNavbarCollapsed={handleSetNavbarCollapsed}
  />
  <NavbarDropdownComponents
    // title={pageRoutes.name}
    // items={Numbers}
    handleSetNavbarCollapsed={handleSetNavbarCollapsed}
  />
  

  {isTopNav && !isLanding && (
    <NavItem onClick={handleSetNavbarCollapsed}>
      <NavLink className="nav-link" to="/documentation">
        Documentation
      </NavLink>
    </NavItem>
  )}
  {isLanding && (
    <NavItem onClick={handleSetNavbarCollapsed}>
      <NavLink className="nav-link" to="/changelog">
        Changelog
      </NavLink>
    </NavItem>
  )}
</>
  const notloggedin = <NavbarDropdown
  title={authenticationRoutes.name}
  items={authenticationRoutes.children}
  handleSetNavbarCollapsed={handleSetNavbarCollapsed}
/>
console.log("asd", isAuthenticated)
  return isAuthenticated ? loggedin : notloggedin
  
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };
const mapStateToProps = state=> ({
  isAuthenticated: state.auth.isAuthenticated,
})
export default connect(mapStateToProps)(NavbarTopDropDownMenus);

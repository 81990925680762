import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { getapitoken, refreshtoken } from '../../../Redux/Actions/Auth/Auth';
import { Row, Col, Input, Label, CardTitle, CardBody, CardHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardContent } from '@material-ui/core';
import { toast } from 'react-toastify';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';
import Optout from '../optout/optout';
import WidgetsSectionTitle from '../../../WidgetsSectionTitle/WidgetSectionTitle';
import BusinessProfile from './BusinessProfile';

const ConfidenceHub = ({ }) => {
  useEffect(() => {
   
  }, []);
// console.log(timeZone)

  // if (token === null) {
  //   return <Loader />;
  // }
  return (
    <div>

    <Row>
      <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
      <WidgetsSectionTitle
        className="my-2"
          title="A2P 10 DLC Registration"
          icon="plus"
          subtitle="Register below"
          />
          <BusinessProfile />
      </Col>
      </Row>

    </div>
  );
};
const mapStateToProps = state => ({
 
});

const ConfHub = withRouter(ConfidenceHub);
export default connect(
  mapStateToProps,
  {}
)(ConfHub);

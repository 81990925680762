import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { getTemplates,getTemplate, updateTemplate,deleteTemplate,getCategories,deleteCategory,createTemplate,getOptOut} from '../../../Redux/Actions/Mask/Mask';
import classNames from 'classnames';
import Select from 'react-select'
import {IconButton, Button as Bu} from "@material-ui/core"
import {
    Card,
    CardBody,
    UncontrolledTooltip,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Popover, PopoverHeader, PopoverBody, Input,
    Row, Col, Button,ModalFooter, Modal, ModalHeader, ModalBody} from "reactstrap"
import ButtonIcon from '../../../components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var dataItem = []
const Template = ({getTemplate,createTemplate,template, updateTemplate, getOptOut,getTemplates,templates,optout,deleteTemplate,categories,getCategories,deleteCategory }) => {
  
  const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);
  const [edit, setEdit] = useState(false)
  const [open, setModal] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [name, setName] = useState("")
  const [category, setCategory] = useState("")
  const [message, setMessage] = useState("")
  const [id, setId] = useState("")
  const [selectedOption, setselectedOption] = useState(null)
  const toggle = () =>{ 
   
    setModal(!open)};

    const settoggle = () =>{ 
   
        setEdit(!edit)};
     useEffect(() => {
    getTemplates()
    getCategories()
    getOptOut()
  }, [getTemplates]);
  useEffect(() => {
    if(template)
    {   
        console.log("result",template )
        setName(template.name)
        setMessage(template.message)
        if(template.category)
        {
          setselectedOption({value: template.category._id,label: template.category.name })
        }
    }
  }, [template]);
  useEffect(()=>{
      if(categories.length)
      {
       dataItem=categories.map((org)=>{return {label:org.name,value:org._id}}) 
      }
  },[categories])
  const [optToken, setoptToken] = useState("");
  const toggles = () => setPopoverOpen(!popoverOpen);
  const callOptToken  = (title) => {
     
      setoptToken(title);
      setPopoverOpen(false)

  }
 const submit  = () => {
     
        if(name && message && selectedOption){
        let tempdata={"name":name,"category":selectedOption.value,"message":message}
       createTemplate(tempdata);
       setName("")
       setCategory("")
       setMessage("")
       setselectedOption(null)
       toggle()
        }else{toast.error("Name, Message and category are required!")}
 }
 const addTag  = (e,tag) => {
  e.target.blur()
        setMessage(message+tag);

 }


  const deletelead =async(id)=>{
   deleteTemplate(id)
   getTemplates();
      
  }



 const save  = () => {
    if(name && message && selectedOption){
        let tempdata={"name":name,"category":selectedOption.value,"message":message}
        updateTemplate(tempdata,id);
        setEdit(false)
        setName("")
       setCategory("")
       setMessage("")
       setselectedOption(null)
      }else{ 
        toast.success("Name, category and Message are required!")}

 }
    


  
  if (templates == null) {
    return <Loader />;
  } else {
    return (
         <div className={classNames('kanban-column', { 'form-added':  false })}>
               <div className="kanban-column-header">
            <h5 className="text-serif fs-0 mb-0">
                {`Template`} <span className="text-500">({templates.length})</span>
            </h5>
                   <Button
                        color="falcon-primary"
                        size="sm"
                        id="info-temp"
                        onClick={() => setIsOpenThreadInfo(prevState => !prevState)}
                                                >
                            <FontAwesomeIcon icon="info" />
                            <UncontrolledTooltip placement="left" target="info-temp" innerClassName="fs--1">
                            Add the templates for campaign
                            </UncontrolledTooltip>
                    </Button>
    </div>
        <div
            className="kanban-items-container scrollbar">
                {templates.map(item=>(
                    <div key={item._id}
                    className="kanban-item"
                    >
                        <Card
                        className="kanban-item-card hover-actions-trigger"
                        onClick={()=>{setEdit(true)
                            setId(item._id)
                            getTemplate(item._id)}
                        }
                        > 
                            <CardBody>
                                
                                <p
                                    className="mb-0 font-weight-medium text-sans-serif"
                                >{item.name}</p>
                                
                                    
                               <UncontrolledDropdown
                                className="position-absolute text-sans-serif t-0 r-0 mt-card mr-card hover-actions"
                                onClick={e => {
                                e.stopPropagation();
                                }}
                            >
                                <DropdownToggle color="falcon-default" size="sm" className="py-0 px-2">
                                <FontAwesomeIcon icon="ellipsis-h" />
                                </DropdownToggle>
                                <DropdownMenu right className="py-0">
                                <DropdownItem onClick={()=>deletelead(item._id)} className="text-danger">Remove</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            </CardBody>
                        </Card>
                    </div>
                ))}
            
             
        </div>
        <div className="kanban-column mr-3">
      
        <ButtonIcon
          className="bg-400 border-400"
          color="primary"
          block
          icon="plus"
          iconClassName="mr-1"
          onClick={()=>{
            setModal(true)
            setName("")
            setCategory("")
            setMessage("")
            setselectedOption(null)
        }}
          
        >
          Add template
        </ButtonIcon>
    </div>
    {open && <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Create Template</ModalHeader>
        <ModalBody>
              <Row className="justify-content-center">
              <Col md="12">
                    <Row>
                        <Col md="12">
                            <Input placeholder="Template Name" onChange={(e)=>{setName(e.target.value)}} Label="Template Name" type="text"  name="templatename"></Input>
                           
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="pt-3">
                            <Select placeholder="Select Category" value={selectedOption} options={dataItem} onChange={(e)=>setselectedOption(e)}></Select>
                    </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="pt-3">
                                <Input placeholder="Message" onChange={(e)=>{setMessage(e.target.value)}} Label="Message" type="textarea"  name="message"  value={message}></Input>
                         
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                               <Bu onClick={(e)=>{addTag(e,"[@firstName]")}}  color="primary">[@firstName]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@lastName]")}}  color="primary">[@lastName]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@street]")}}  color="primary">[@street]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@city]")}}  color="primary">[@city]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@state]")}}  color="primary">[@state]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@zip]")}}  color="primary">[@zip]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@email]")}}  color="primary">[@email]</Bu>
                                {optToken!=""?<Bu onClick={(e)=>{addTag(e,"[@"+optToken+"]")}}  color="primary">[@{optToken}]</Bu>:null}
                               <IconButton id="Popover1" type="button">
                      <div className="circle">
                        <span style={{fontSize: "1rem"}}>optout</span>
                      </div>
                  </IconButton>
                  <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggles}>
                    <PopoverHeader>OptOut Languages</PopoverHeader>
                  <PopoverBody>
                    {optout != null && optout.length > 0 && optout.map(item => (

                      <div className="btn btn-link" onClick={()=>callOptToken(item.title)} key={item._id} >{item.title}</div>
                    ))}
                    </PopoverBody>
                  </Popover>
                        </Col>
                    </Row>
                    
              </Col>
             </Row>    
             
        
        </ModalBody>
        <ModalFooter>
          <Button variant="outlined" onClick={submit}  color="primary">Create</Button>
        </ModalFooter>
      </Modal>}
      {edit && <Modal isOpen={edit} toggle={settoggle}>
        <ModalHeader toggle={settoggle}>Edit Template</ModalHeader>
        <ModalBody>
              <Row className="justify-content-center">
              <Col md="12">
                    <Row>
                        <Col md="12">
                            <Input placeholder="Template Name" value={name} onChange={(e)=>{setName(e.target.value)}} Label="Template Name" type="text"  name="templatename"></Input>
                           
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="pt-3">
                            <Select placeholder="Select Category" value={selectedOption} options={dataItem} onChange={(e)=>setselectedOption(e)}></Select>
                    </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="pt-3">
                                <Input placeholder="Message" onChange={(e)=>{setMessage(e.target.value)}} Label="Message" type="textarea"  name="message"  value={message}></Input>
                         
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                               <Bu onClick={(e)=>{addTag(e,"[@firstName]")}}  color="primary">[@firstName]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@lastName]")}}  color="primary">[@lastName]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@street]")}}  color="primary">[@street]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@city]")}}  color="primary">[@city]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@state]")}}  color="primary">[@state]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@zip]")}}  color="primary">[@zip]</Bu>
                               <Bu onClick={(e)=>{addTag(e,"[@email]")}}  color="primary">[@email]</Bu>
                                {optToken!=""?<Bu onClick={(e)=>{addTag(e,"[@"+optToken+"]")}}  color="primary">[@{optToken}]</Bu>:null}
                               <IconButton id="Popover1" type="button">
                      <div className="circle">
                        <span style={{fontSize: "1rem"}}>optout</span>
                      </div>
                  </IconButton>
                  <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggles}>
                    <PopoverHeader>OptOut Languages</PopoverHeader>
                  <PopoverBody>
                    {optout != null && optout.length > 0 && optout.map(item => (

                      <div className="btn btn-link" onClick={()=>callOptToken(item.title)} key={item._id} >{item.title}</div>
                    ))}
                    </PopoverBody>
                  </Popover>
                        </Col>
                    </Row>
                    
              </Col>
             </Row>    
             
        
        </ModalBody>
        <ModalFooter>
          <Button variant="outlined" onClick={save}  color="primary">Update</Button>
        </ModalFooter>
      </Modal>}
        </div>
    );
  }
};
const mapStateToProps = (state) => ({
    templates: state.bulk.templates,
    categories:state.bulk.categories,
    
    template: state.bulk.template,
    
    optout:state.bulk.optout
 });
export default connect(
    mapStateToProps,
  {createTemplate,updateTemplate, getTemplates,deleteTemplate,getCategories,deleteCategory,getOptOut,getTemplate }
)(Template);

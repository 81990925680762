import React from 'react'

import {Row, Col, Card, Button} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {cancelSubscription } from '../../../Redux/Actions/Payment'
import { toast } from 'react-toastify';
class CanclledNumber extends React.Component{
    cancelled = (text, number, reason, detail)=> {
        console.log("text, number, reason, detail",text, number, reason, detail)
       if(text === "Yes")
       {
        if(reason && detail){
            
            this.props.cancelSubscription(number,reason,detail)
           this.props.toggle()
            
        }else{
        
            toast.error('Please provide a valid cancellation reason and details.');
        }
       }
       else{
        
        this.props.toggle()
       }
     }
    render(){
        return <Row >
        
           
             
                  <Col md="6">
                      <Button  variant="contained" color="success"  onClick={()=>this.cancelled('Yes', this.props.number,this.props.reason,this.props.detail)}  color="success">Yes</Button>
                  </Col>
                  <Col md="6">
                      <Button  variant="contained" color="danger" onClick={()=>this.cancelled('No')}  color="danger">NO</Button>
                  </Col>
             
              <br/>
          
              
        
    </Row>
    }
}
CanclledNumber.propTypes = {
   cancelSubscription:  PropTypes.func.isRequired
  };

export default connect(null,{cancelSubscription})(CanclledNumber)

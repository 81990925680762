import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isIterableArray } from '../../helpers/utils';
import Modal from '../Modal/Modal';
import { connect } from 'react-redux';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Modal as Md } from 'reactstrap';
import { Row, Col, Input, Label } from 'reactstrap';

import Carousel from 'react-material-ui-carousel';
import { Paper, Button as Bt } from '@material-ui/core';
import { RibbonContainer, LeftCornerRibbon } from 'react-ribbons';


const PricingRow = ({ children }) => (
  <li className="py-1">
    <FontAwesomeIcon icon="check" transform="shrink-2" className="text-success" /> {children}
  </li>
);

PricingRow.propTypes = { children: PropTypes.node };

const PricingCard = ({
  ribbon,
  click,
  type,
  description,
  price,
  featureTitle,
  features,
  button,
  isYearly,
  backgroundColor,
  plans,
  isPaid,
  plan_id,
  
}) => {
  useEffect(() => {
    (async () => {
      try {
        const res = await Axios({
          method: 'GET',
          url: process.env.REACT_APP_PORT + `/api/payment/getuserdata`,
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        });

        // console.log('userdata', res.data.data);
        setmsglimit(res.data.data.message);
        setregistered(res.data.data.registered);
        if (res.data.data.message === '3000+') {
          // setpln('price_1JZVf3GVMLEvSdQHaeNNYpcX'); //3000+ test
          setpln('price_1Jjj7KGVMLEvSdQHq9ovpKMr'); //3000+ msg live
        }
      } catch (e) {
        toast.error(e.response.data.error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await Axios({
          method: 'GET',
          url: process.env.REACT_APP_PORT + `/api/payment/getusercard`,
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        });

        // console.log('usercard', res.data.data);
        setcheck(res.data.data);
      } catch (e) {
        toast.error(e.response.data.error);
      }
    })();
  }, [check]);

  const [msglimit, setmsglimit] = useState('');
  const [registered, setregistered] = useState(false);

  const [check, setcheck] = useState('false');

  const [pln, setpln] = useState('price_1Jjj2aGVMLEvSdQH0sXc4MRe'); //3000 live

  // const [pln, setpln] = useState('price_1JX3J5GVMLEvSdQH3TMObFj7'); //3000 test
  // const [pln,setpln]=useState("price_1Jaw8CGVMLEvSdQHXEUTytEg")//free
  const plan = isYearly ? 'year' : 'month';
  // const opentab = () => {
  //   window.open('https://podio.com/webforms/25579499/1901445');
  // };
  const popup = () => {
    // if (registered) return false;
    // if (msglimit === '3000+') {
    //   setpop(true);
    //   return true;
    // }
    return false;
  };

  // console.log('plan', plans, plan_id);

  return (
    <div className="h-100" style={{ backgroundColor }}>
      <Ribbon ribbon={plans && plans.plan == plan_id ? true : false} isPaid={isPaid} text={'Your plan'}>
        <div className="text-center p-4">
          <h3 className="font-weight-normal my-0">{type}</h3>
          <p className="mt-3">{description}</p>
          <h2 className="font-weight-medium my-4">
            {type !== 'ENTERPRISE' ? <sup className="font-weight-normal fs-2 mr-1">$</sup> : '  '}
            {type !== 'ENTERPRISE' ? (type === 'Premium plus' ? `${price[plan]}+$50` : price[plan]) : 'Contact Here'}
            {type !== 'ENTERPRISE' ? (
              type === 'Premium plus' ? (
                <small className="fs--1 text-700">(one time)</small>
              ) : (
                ''
              )
            ) : (
              ' '
            )}
            {type !== 'ENTERPRISE' ? <small className="fs--1 text-700">/ {plan}</small> : ' '}
            {/* {msglimit==="3000+" ? (
                <div>
                  <sup className="font-weight-normal fs-2 mr-1">$</sup>
                  50 +<small className="fs--1 text-700">/ onetime registration</small>
                </div>
              ) : null} */}
          </h2>

          {click ? (
            <Link to="/contactus">
              <button className="modal-button">{button.text}</button>
            </Link>
          ) : plans && plans.plan == plan_id ? (
            <Modal item={plans.sub} price=" ₹1,000.00 INR / month" onSubscribe={popup} buttonLabel="UnSubscribe" />
          ) : (
            <Modal
              item={plan_id}
              price=" ₹1,000.00 INR /  month"
              onSubscribe={popup}
              buttonLabel="Card"
              check={check}
              
              
            />
          )}
        </div>
      </Ribbon>
      <hr className="border-bottom-0 m-0" />
      <div className={classNames('text-left px-sm-4 py-4', { 'px-3': backgroundColor })}>
        <h5 className="font-weight-medium fs-0">{featureTitle}</h5>
        <ul className="list-unstyled mt-3">
          {isIterableArray(features) &&
            features.map((feature, index) => (
              <PricingRow key={index}>
                {feature.title}{' '}
                {feature.badge && (
                  <Badge color={feature.badge.color} pill className="ml-1">
                    {feature.badge.text}
                  </Badge>
                )}
              </PricingRow>
            ))}
        </ul>
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.object.isRequired,
  featureTitle: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  button: PropTypes.object.isRequired,
  // bottomButtonText: PropTypes.string.isRequired,
  isYearly: PropTypes.bool.isRequired,
  backgroundColor: PropTypes.string
};
const mapeStateTOProps = state => ({
  plans: state.payment.plan,
  isPaid: state.auth.isPaid
});
export default connect(
  mapeStateTOProps,
 
  null
)(PricingCard);

const Ribbon = ({ ribbon, children, text, isPaid }) => {
  if (isPaid) {
    return ribbon ? (
      <RibbonContainer className="custom-class">
        <LeftCornerRibbon backgroundColor="#0088ff" color="#f0f0f0" fontFamily="Arial">
          {text}
        </LeftCornerRibbon>
        {children}
      </RibbonContainer>
    ) : (
      children
    );
  } else {
    return children;
  }
};

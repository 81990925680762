import React, { useState , useEffect} from 'react'
import {Row, Col, Card, CardBody, CardHeader, CardTitle, Label, Input} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import  {getCategory,updateCategory} from "../../../Redux/Actions/Mask/Mask"
import {Button} from "@material-ui/core"
import {Redirect,useParams} from 'react-router-dom'
import Loader from "../../../components/common/Loader"
import { useHistory } from "react-router-dom";
const Category = ({getCategory,category,updateCategory}) => { 
    let history = useHistory();
let {id} = useParams()
    useEffect(()=>{
        getCategory(id)
       if(category != null){
           setName(category.name)
       }

    }, [getCategory,category == null])
     const [name, setName] = useState("")

 const save  = () => {
       
          let catdata={"name":name}
          updateCategory(catdata,id);
          history.push("/template");

   }
   
   if(category === null)
   {
       return <Loader></Loader>
   }else{ 

  return (
      <Row  className="justify-content-center ">
              <Col md="10">
                  <Card>

                      <CardHeader>
                      <CardTitle style={{fontSize: "0.95rem"}}>Update Category</CardTitle>
                      </CardHeader>
                   
                     <Row>
                        <Col md="12">
                            <CardBody>
                                <Input placeholder="Category Name" onChange={(e)=>{setName(e.target.value)}} Label="Category Name" type="text"  name="templatename" value={name}></Input>
                            </CardBody>
                        </Col>
                    </Row>
                    
                    <Row className="p-4">
                        <Col md="12">
                            <Button variant="outlined"  onClick={save} color="primary">Save</Button>
                        </Col>
                    </Row>
                </Card>
                    
              </Col>
          </Row>
    );
  }
}

const mapStateToProps = (state) => ({
    category: state.bulk.category
 });

Category.propTypes = {
    getCategory: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired
  };
export default connect(mapStateToProps, {getCategory,updateCategory})(Category)
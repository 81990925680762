import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { getapitoken, refreshtoken } from '../../../Redux/Actions/Auth/Auth';
import { Row, Col, Input, Label, CardTitle, CardBody, CardHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardContent } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';
import Optout from '../optout/optout';
import WidgetsSectionTitle from '../../../WidgetsSectionTitle/WidgetSectionTitle';
import Dnc from './dnclist';
import Dnclist from '../User/Dnclist';
import TimeZone from '../User/timeZone';
import SkipTracing from "../User/skipTracing"
import GeoCallerId from "./geoCallerID"

// var timeZone=require('../User/timeZone')
// const timeZone = React.lazy(() => import('../User/timeZone'));

const AccountSetting = ({ getapitoken, token, refreshtoken }) => {
  useEffect(() => {
    getapitoken();
  }, [getapitoken, token]);
// console.log(timeZone)
  const onCopyText = () => {
    toast.success('Copied !');
  };

  const Submit = async () => {
    await refreshtoken();
    window.location.reload(false);
  };
  if (token === null) {
    return <Loader />;
  }
  return (
    <div>
    <Row>
        <Col xl={5} className="col-xxl-4 pr-xl-2">
      <WidgetsSectionTitle
        className="my-1"
          title="Api Key"
          icon="minus"
          subtitle="Secret token for authentication purpose"
          />
        <Row className="p-2">
          <Col className="p-0">
            <Card style={{ width: '100%' }}>
              <Background image={corner1} className="bg-card" />
              <CardBody>
                <Input style={{ fontSize: '0.95em' }} disabled type="string" name="key" value={token} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="px-2">
          <Col xs="6" className="p-0">
            <Card className="p-2" style={{ width: '100%' }}>
              <Background image={corner2} className="bg-card" />

              <CopyToClipboard text={token} onCopy={onCopyText}>
                  <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }}>
                    Copy to Clipboard
                  </Button>
              </CopyToClipboard>
            </Card>
          </Col>
          <Col xs="6" className="p-0">
            <Card className="p-2" style={{ width: '100%' }}>
              <Background image={corner3} className="bg-card" />

                <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} onClick={Submit}>
                Refresh API Key
              </Button>
            </Card>
          </Col>
        </Row>
          <WidgetsSectionTitle className="my-2" title="DNC " icon="folder-plus" subtitle="Add DNC contact in bulk" />
        <Row className="p-2">
            <Dnc />
        </Row>
      </Col>
      <Col xl={7} className="col-xxl-8 pl-xl-2 mb-3 mb-lg-0">
          <WidgetsSectionTitle className="my-2" title="DNC List" icon="folder-plus" subtitle="" />
          <Dnclist />
      </Col>
    </Row>
    <Row>
      <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
      <WidgetsSectionTitle
        className="my-2"
          title="Opt Out"
          icon="plus"
          subtitle="The message customer will be choosing to remove themselves from any further text messages from your business"
          />
          <Optout />
      </Col>
      </Row>
      <Row>
        <Col xs={6} className="col-xxl-8 pr-xl-2 mb-3">
          <WidgetsSectionTitle className="my-2" title="TimeZone" icon="clock" subtitle="TimeZone Settings" />
          <TimeZone/>
        </Col>
        <Col xs={6} className="col-xxl-8 pr-xl-2 mb-3">
        <WidgetsSectionTitle className="my-2" title="Skip Tracing" icon="plus" subtitle="Enable/Disable Skip Tracing for Campaign Upload" />
          <SkipTracing/> </Col>
    </Row>
    <Row>
      <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
      <WidgetsSectionTitle
        className="my-2"
          title="Geo Caller Id"
          icon="plus"
          subtitle="The feature that automatically selects the best from number based on the number you trying to call/text."
          />
          <GeoCallerId/>
      </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.token
});
AccountSetting.propTypes = {
  getapitoken: PropTypes.func.isRequired,
  refreshtoken: PropTypes.func.isRequired
};
const Location = withRouter(AccountSetting);
export default connect(
  mapStateToProps,
  { getapitoken, refreshtoken }
)(Location);

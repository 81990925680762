import React, {  useEffect } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader} from 'reactstrap';

import {connect} from "react-redux"
import {getSession} from "../../../Redux/Actions/USER/user"
import {useParams} from "react-router-dom"
const Dial = ({session, getSession})=>{
    const {id} = useParams()
    useEffect(()=>{
        getSession(id)
      }, [])
     
     return  <Row className="justify-content-center">
        <Col xs="12" sm="12">
          <Card>
             
            <CardBody>
             <Table responsive bordered striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Call</th>
                </tr>
              </thead>
              <tbody>
                {session && session.map((item, i)=>(
                     <tr>
                        <td>{i+1}</td>
                        <td>{(item.call)/60} mins</td>
                     </tr>
                )) }
              </tbody>
            </Table>
             </CardBody>
          </Card>
        </Col>
      </Row>
    
      
}

const mapStateToProps = state => ({
    session: state.bulk.session,
});
export default connect(
  mapStateToProps,
  {getSession}
)(Dial);

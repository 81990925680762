import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import "./app.css"
import { useDispatch } from 'react-redux'
import { gettz } from './Redux/Actions/USER/user';
import { useSelector } from 'react-redux'
var moment = require('moment-timezone');


const App = ({Token}) => {
  const dispatch =useDispatch();
useEffect(()=>{
  dispatch(gettz())

  navigator.mediaDevices.getUserMedia({ audio: true}).then( stream => {
    window.localStream = stream;
    window.localAudio.srcObject = stream;
    window.localAudio.autoplay = true;
    
}).catch( err => {
    console.log("u got an error:" + err)
});
},[])

const currtz=useSelector(state=>state.Twilio.timezone)
  // console.log("inside app.js"+currtz)
  moment.tz.setDefault(currtz);
  return (
    <Router  basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};


export default App;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Card, Col, CardBody, Input, Label, Button } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';
import CardTitle from 'reactstrap/lib/CardTitle';
import { toast } from 'react-toastify';
import { getCampaigns, saveprospectdata } from '../../../Redux/Actions/Mask/Mask';
const AddProspect1 = ({ getCampaigns, campaigns, saveprospectdata,stateChanger,stateChanger1 }) => {
  const [first_name, setfirst] = useState('');
  const [last_name, setlast] = useState('');
  const [phone, setphone] = useState('');
  const [email, setemail] = useState('');
  const [mstate, setmstate] = useState('');
  const [maddress, setmaddress] = useState('');
  const [mcity, setmcity] = useState('');
  const [mzip, setmzip] = useState('');
  const [country, setcountry] = useState('');
  const [street, setstreet] = useState('');
  const [campaign, setcampaign] = useState('');
  const history = useHistory();
  useEffect(() => {
    getCampaigns();
  }, []);
  const save = async () => {
    if (
      
      campaign == '' ||
      
      first_name == '' ||
      
      phone == '' 
      
      
      
    ) {
      toast.error('Campaign,First Name,Phone is required');
    } else {
     await saveprospectdata({
        email,
        mzip,
        country,
        campaign,
        street,
        first_name,
        last_name,
        phone,
        mstate,
        maddress,
        mcity
      });
      // toast.success('Prospect Saved');
      handleClick()
    }
    
  };const handleClick = () => {
    stateChanger(true)
    stateChanger1(false)
  }
  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <CardTitle style={{ fontSize: '22px' }}>Add Prospect</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={12}>
                <Label>Campaigns</Label>
                <Input type="select" onChange={e => setcampaign(e.target.value)}>
                  <option value="">Select Campaign</option>
                  {campaigns != null &&
                    campaigns.length &&
                    campaigns.map(item => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label>First Name</Label>
                <Input type="text" onChange={e => setfirst(e.target.value)} />
              </Col>
              <Col md={6}>
                <Label>Last Name</Label>
                <Input type="text" onChange={e => setlast(e.target.value)} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label>Phone</Label>
                <Input type="text" onChange={e => setphone(e.target.value)} />
              </Col>
              <Col md={6}>
                <Label>Email</Label>
                <Input type="text" onChange={e => setemail(e.target.value)} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label> Address</Label>
                <Input type="text" onChange={e => setmaddress(e.target.value)} />
              </Col>
              <Col md={6}>
                <Label>Street</Label>
                <Input type="text" onChange={e => setstreet(e.target.value)} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label> City</Label>
                <Input type="text" onChange={e => setmcity(e.target.value)} />
              </Col>
              <Col md={6}>
                <Label> State</Label>
                <Input type="text" onChange={e => setmstate(e.target.value)} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label>Country</Label>
                <Input type="text" onChange={e => setcountry(e.target.value)} />
              </Col>
              <Col md={6}>
                <Label> Zip</Label>
                <Input type="text" onChange={e => setmzip(e.target.value)} />
              </Col>
            </Row>

            <Row className="pt-4">
              <Col md={6}>
                  
                <Button color="primary" onClick={save}>
                  Save
                </Button>
              </Col>
              <Col md={6}>
                
                  <Button color="danger" onClick={handleClick}>Back</Button>
                
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({
  campaigns: state.bulk.campaigns
});
export default connect(
  mapStateToProps,
  { getCampaigns, saveprospectdata }
)(AddProspect1);

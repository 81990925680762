import React from 'react';
import { Row, Col, Input, Label, Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

const RefundPolicy = () => {
  return (
    <div className="container">
      <Card style={{ width: '65vw', color: '#424242' }} className="container">
        <CardHeader>
          <CardTitle>
            <p style={{ textAlign: 'center', fontSize: '1.5rem', color: '#2c7be5' }}>Refund Policy</p>

            <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Last updated: ​2023-09-29.</p>

            <Row>
              <Col>
                <p style={{ fontSize: '0.9rem' }}>
                  <a href="https://mergecall.com/">Mergecall.com</a> ("us", "we", or "our") operates{' '}
                  <a href="https://mergecall.com/">​https://mergecall.com/</a> (the "Site"). This page informs you of
                  our policies refund.
                  <br />
                  At mergecall.com, we strive to provide you with the best service possible. However, we understand that circumstances may change, and you may need to cancel or request a refund for your subscription. Please review our refund policy below to understand our guidelines regarding refunds:
                </p>
              </Col>
            </Row>

            <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Subscription Cancellation:</p>
            <Row>
              <Col>
                <p style={{ fontSize: '0.9rem' }}>
                You have the freedom to cancel your subscription to mergecall.com at any time. Upon cancellation, no further payments will be deducted from your account.
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Non-Refundable Payments:</p>

                <p style={{ fontSize: '0.9rem' }}>
                Please note that payments already made for your subscription are non-refundable. We do not offer refunds for payments that have already been processed.
                  
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>No Pro-Rated Usage:</p>

                <p style={{ fontSize: '0.9rem' }}>
                We do not provide pro-rated refunds for unused portions of your subscription. If you wish to upgrade to a different subscription tier, any remaining service from your current subscription will not be refunded. To maximize the value of your upgrade, we recommend doing so when your current subscription is near its expiration or during its final days.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Unused Allocated Resources:</p>

                <p style={{ fontSize: '0.9rem' }}>
                If you have not utilized the allocated resources within the subscription interval, no refunds will be issued for the unused resources.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Payment Disputes:</p>

                <p style={{ fontSize: '0.9rem' }}>
                In the event of a payment dispute, we reserve the right to handle the dispute with the payment gateway accordingly. By accepting our privacy policy and refund policy, you agree that refund requests should not be pursued through mergecall.com.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Special Cases:</p>

                <p style={{ fontSize: '0.9rem' }}>
                There are exceptions in cases of accidental upgrades. If you unintentionally upgrade your subscription and wish to request a refund, you must contact us within 24 hours from the time of the upgrade. We will review such cases on an individual basis.
                </p>
              </Col>
            </Row>
            
            
            <Row>
              <Col>
                <p style={{ fontSize: '1.2rem', color: '#2c7be5' }}>Contact Us</p>

                <p style={{ fontSize: '0.9rem' }}>
                We value your satisfaction and aim to provide clear guidelines for refunds. If you have any questions or need further assistance regarding our refund policy, please don't hesitate to contact our {' '}
                  <a href="https://mergecall.com/contactus">support team.</a>
                  <br/> <br/>
                Thank you for choosing mergecall.com, and we appreciate your understanding of our refund policy.
                </p>
              </Col>
            </Row>
          </CardTitle>
        </CardHeader>

        <Row className=" justify-content-center">
          <Col md={{ size: 8 }}>
            <p style={{ textAlign: 'center', fontSize: '1.5rem', color: '#2c7be5' }}>
              Support <i class="material-icons">phone</i> +1 415-417-0410
            </p>
            <p style={{ textAlign: 'center' }}>© 2022 mergecall.com, Inc C Corp</p>
            <p style={{ textAlign: 'center' }}>325 Front St W, Toronto, ON M5V 2Y1, Canada</p>
            <p style={{ textAlign: 'center' }}>2035 Sunset Lake Rd SUITE B2, Newark, DE 19702, United States</p>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default RefundPolicy;

import React from "react"
import {connect} from "react-redux"

import {Link} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComment} from "@fortawesome/free-solid-svg-icons"
const Footer = ({isPaid, isAuthenticated})=>{
  return   <div className="move">
    <br/>
      {isPaid && isAuthenticated ? <Link  to="/conversation" >
     <FontAwesomeIcon icon={faComment}></FontAwesomeIcon>
   </Link> : null }
  </div>
}

const mapStateToProps = state=> ({
  isPaid: state.auth.isPaid,
  isAuthenticated: state.auth.isAuthenticated,
})
export default connect(mapStateToProps)(Footer)
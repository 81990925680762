import React from 'react'

import {Row, Col, Card, CardBody, Button, Input, Label } from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {getallCompanies} from  "../../../Redux/Actions/USER/user"
import { buynumber} from '../../../Redux/Actions/Twilio'
import {setAlert} from "../../../Redux/Actions/alert/alert"
import './search.css'
 
import { toast } from 'react-toastify'

const axios = require('axios');
class Buynumber extends React.Component{
    state= {
        num: "",
        twilio:"",
        voice: "",
        sms:"",
        value: '',
        id: "",
        name:"",
        alias: "",
    }
      onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };
 
  onSuggestionsFetchRequested = ({ value }) => {
    this.props.getallCompanies(value)
  };
   getSuggestionValue = suggestion => {
    this.setState({id: suggestion._id, name:suggestion.name  })
    return suggestion.name
  };

    handleChange = (e)=>{
        this.setState({[e.target.name]:e.target.value})
     }
     componentDidMount(){
         this.props.getallCompanies()
         this.setState({twilio:this.props.number})
     }

     buyNumber = async()=> {
        const res = await axios({
            method: 'POST',
            url: process.env.REACT_APP_PORT + `/api/payment/checkbal1`,
            headers: {
              'x-auth-token': localStorage.getItem('token')
            },
            
          });
          if(res.data.data){
            const phoneRegex = /^\+\d{10,}$/;
       if(!phoneRegex.test(this.state.num))
       {
           toast.error("Forward Number is Required and should be in the format like +1XXXXXXXXXX")
       }
       else if(this.state.name==="")
       {
           toast.error("Company is required, Please Create one if you don't have")
       }
       else{
        this.props.buynumber(this.state.twilio, this.state.num, this.state.voice, this.state.sms, this.state.name , this.state.name, this.state.alias )
        this.props.toggle()
       }
    }
    else{
      toast.error('Please check Funds');
    }
     }
    render(){
          const { value } = this.state;
          const {suggestions} =this.props
        const inputProps = {
        placeholder: 'Type Company Name',
        value,
        onChange: this.onChange
        };
        return <Row  className="justify-content-center ">
        <Col xs="10">
              <Row>
                  <Col md={12} xs="12">
                      
                          <p>Selected MergeCall number is :{this.state.twilio} </p>
                      
                  </Col>
              </Row>
              <Row >
                  <Col md={12} xs="12" className="pt-4">
                      <Label>Friendly Name</Label>
                      <Input style={{ fontSize: "0.95em"}} placeholder="Alias"  type="text" onChange={this.handleChange} name="alias" value={this.state.alias}></Input>
                      
                  </Col>
              </Row>
              <Row>
                  <Col md={6} xs="12" className="pt-4">
                      <Label>Call Forwarding Number</Label>
                     <Input style={{ fontSize: "0.95em"}} placeholder="Call Forwarding Number"  type="text" onChange={this.handleChange} name="num" value={this.props.num}></Input>
                      
                  </Col>
                  <Col md={6} xs="12" className="pt-4">
                      <Label>Company</Label>
                      <Input style={{ fontSize: "0.95em"}} type="select" name="name" onChange={this.handleChange}>
                          <option>Select Company</option>
                          {suggestions!= null && suggestions.map(item=>(
                              <option  key={item._id} value={item._id}>{item.name}</option>
                          ))}
                      </Input>
                     </Col>
             
              </Row>
               <Row>
                  <Col className="pt-4" xs="12">
                      
                          <Label>Voice  Webhook</Label>
                          <Input style={{ fontSize: "0.95em"}} placeholder="Enter Voice Webhook "  type="text" onChange={this.handleChange} name="voice" value={this.state.voice}></Input>
                          <small id="emailHelp" class="form-text text-muted">Voice Responses will go to this webhook</small>
                  </Col>
              </Row>
              <Row>
                  <Col className="pt-4" xs="12">
                          <Label>SMS Webhook</Label>
                          <Input style={{ fontSize: "0.95em"}} placeholder="Enter SMS Webhook "  type="text" onChange={this.handleChange} name="sms" value={this.state.sms}></Input>
                          <small id="emailHelp" class="form-text text-muted">SMS Responses will go to this webhook</small>
                  </Col>
              </Row>
             
             
             
             
              <Row className="pt-4">
                  <Col xs="12">
                      <Button onClick={()=>this.buyNumber()}  color="primary">BUY</Button>
                  </Col>
              </Row>
          
              
        </Col>
    </Row>
    }
}
Buynumber.propTypes = {
   buynumber:  PropTypes.func.isRequired,
   setAlert: PropTypes.func.isRequired,
   getallCompanies:  PropTypes.func.isRequired
  };
  const mapStateToProps = (state) => ({
    suggestions: state.Twilio.companies,
  });

export default connect(mapStateToProps,{buynumber, setAlert, getallCompanies})(Buynumber)

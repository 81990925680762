import React, {useState, useEffect} from "react"
import Header from "./Header"
import SideBar from "./SideBar"
import Conversation from "./Conversation"
import {Grid} from "@material-ui/core"
import {AppBar, Toolbar} from "@material-ui/core"
import Contacts from "./Contact"
import io from 'socket.io-client';

import {useParams} from "react-router-dom"
import "./nav.css"
import './Conversation.css'
import {socket} from '../Main/socket'

// const  socket = io(process.env.REACT_APP_PORT, {
//     query: {
//         email: localStorage.getItem("email"),
//         filename:"Main 2"
//     }
// });

const Main = ()=> {
    const [contact, setcontact] = useState([])
    const [message, setmessage] = useState(null)
    const [trash, settrash] = useState([])
      let {campid} = useParams()
    socket.on("contacts", msg=> {
        console.log("con", msg)
        setcontact(msg)
    })
    useEffect(()=>{
     socket.emit("getmessagescamp", localStorage.getItem("email"), campid)
    },[])
    const getmessage= (item) => {
       setmessage(item)
    }
    const deleteitem = (data) => {
        settrash(data)
    }
return <Grid container >
    <Grid item xs={12}  >
        <AppBar color="transparent" position="static">
            <Toolbar>
                <Header data={message} trash={trash} socket={socket}></Header>
            </Toolbar>
        </AppBar>
        
    </Grid>  
     {/* <Grid item xs={12} md={3}>
            <SideBar socket={socket}/>
        </Grid> */}
        <Grid item  xs={5}>
         {contact.length > 0 ? <Contacts getmessage={getmessage}  data= {contact}></Contacts> : null}   
        </Grid>
        <Grid item  xs={6} >
           {message != null ? <Conversation deleteitem={deleteitem} data={message} socket={socket}/> : null } 
        </Grid>
    </Grid>
}

export default Main
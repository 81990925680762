import Axios from 'axios';
import React,{useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isLogin} from "../util/util"
import {connect} from "react-redux"
import {checksub} from "../Redux/Actions/Payment"
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux'

var moment = require('moment-timezone');

const param = new URLSearchParams(window.location.search)
      const f = param.get('from')
      const g = param.get('to')
        if(f== null && g!=null)
       {
         localStorage.setItem("redirecturl",`/pages/call?to=${g}` )
       }
       else if(f!= null && g!=null)
       {
         localStorage.setItem("redirecturl",`/pages/call?from=${f}&to=${g}` )
       }
       
const PrivateRoute = ({
  component: Component,
  isPaid,subs,
  ...rest
}) =>{ 
  // useEffect(() => {
  //   checksub();
  // }, [])
  const currtz=useSelector(state=>state.Twilio.timezone)
  var subsactive=true
  // if(moment().unix()>subs.edate)
  // {
  //   subsactive=false
  //   console.log("subs active is",subsactive)
  //   // stripesub();
    
  // }
  // {console.log("subs",subs)}
  if (subs&&moment.unix(subs.edate).utc().tz(currtz).endOf('day').isBefore(moment.tz(currtz))) {
    console.log("true")
    subsactive=false
    console.log("subs active is",subsactive)
  }
if(!subsactive)
{
  if(subs.status!="Active")
  {
    toast.error("Subscription is not active");
  }
  else{
  toast.error("Please renew your subscription"); }}
    return(
  <Route
    {...rest}
    render={props =>
      
        isLogin() && !isPaid ? (
        <Component {...props} />
      ) : isLogin() && !subsactive ? ( <Component {...props} />   
      ) : (
       <Redirect to="/home" />
      )
      
    }
  />
);
}



const mapeStateToProps = (state) => ({
    isPaid: state.auth.isPaid,
     isAuthenticated: state.auth.isAuthenticated,
     subs:state.auth.subs
})


export default connect(mapeStateToProps,{checksub})(PrivateRoute);
import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader, Button, Form, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import { getVoices, countvoices, voiceSearch,countfiltervoice } from '../../../Redux/Actions/USER/user';
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from 'reactstrap/lib/Label';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import './pagenation.css'
var moment = require('moment-timezone');
const UserVoice = ({
  getVoices,
  voices,
  countvoices,
  count,
  searchData,
  voiceSearch,
  search,
  inboundcheck,
  outboundcheck,
  countfiltervoice
}) => {
  // const [search, setSearch] = useState('');
  const [offset, setoffset] = useState(0);
  const [activePage, setactivePage] = useState(0);
  // console.log({ voice: inboundcheck });
  // console.log({ voice: outboundcheck });
  const currtz=useSelector(state=>state.Twilio.timezone)
  useEffect(() => {
    getVoices(offset);
    countvoices();
  }, []);
  useEffect(() => {
    if (search) {
      setoffset(0);
      let ss = search.startsWith("+") ? search.substr(1) : search
      voiceSearch(ss, outboundcheck, inboundcheck, 0);
      countfiltervoice(outboundcheck,inboundcheck,search)
    } else if (inboundcheck || outboundcheck) {
      setoffset(0);
      getVoices(offset,inboundcheck, outboundcheck);
      countfiltervoice(outboundcheck,inboundcheck,search)
    } else {
      getVoices(offset,inboundcheck, outboundcheck);
      countvoices();
    }
  }, [search, outboundcheck, inboundcheck]);
  useEffect(() => {
   setactivePage(0)
  }, [outboundcheck, inboundcheck])
  // const next = () => {
  //   const data = offset + 10;
  //   setoffset(data);

  //   if(search){
  //     voiceSearch(search,data);
  //   }else{getVoices(data);
  // }

  // };
  // const prev = () => {
  //   const data = offset - 10;
  //   setoffset(data);
  //   if(search){
  //     voiceSearch(search,data);
  //   }else{getVoices(data);
  // }
  // };
  const handlePageChange = pageNumber => {
    let off = pageNumber * 10 - 10;
    setactivePage(pageNumber);
    // console.log({ pageNumber });
    // console.log({ search });
    // console.log({ off }); 
    if (search){ 
      let ss = search.startsWith("+") ? search.substr(1) : search
      voiceSearch(ss, outboundcheck,inboundcheck, off);}
     else getVoices(off, inboundcheck, outboundcheck);
  };
  // console.log({ voices });
  if (voices === null || voices.length === 0) {
    return (
      <Row className="justify-content-center">
<Col sm="12">
<Card>
  <CardBody>
    <Label  style={{display:"flex",justifyContent:"center"}}>No Log Present</Label>
  </CardBody>
</Card>
</Col>
      </Row>
    )
  }

  return (
    <Row className="justify-content-center">
      <Col md="12" sm="12">
        <Card>
          <CardBody>
            <Row>
              {/* <Col className="mb-2" >
                {' '}
                <div className="search-box" style={{float:"Right"}}>
                <Form>
                  <Input
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    className="rounded-pill search-input"
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                  />
                  <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
                </Form>
                </div>
              </Col> */}
            </Row>

            <Table responsive bordered striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Record Link</th>
                  <th>Status</th>
                  <th>Charge</th>
                  <th>Direction</th>
                  <th>Duration</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {voices.length &&
                  voices.map((item, index) => (
                    <tr key={item._id}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.from}</td>
                      <td
                        style={{ maxWidth: '16ch', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                      >
                        {item.to.startsWith('client') ? '+' + item.calledvia : item.to}
                      </td>
                      <td>
                        {item.RecordingUrl ? (
                          <Tooltipbutton title="Click and listen to the call recording">
                            <div
                              className="btn btn-info btn-sm"
                              onClick={() => {
                                window.open(item.RecordingUrl);
                              }}
                            >
                              <i class="far fa-play-circle" />
                              &nbsp; Click
                            </div>
                          </Tooltipbutton>
                        ) : null}
                      </td>
                      <td>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</td>
                      <td>{item.charges != undefined ? `$${item.charges.toFixed(3)}` : 0}</td>
                      <td>
                        {item.direction == 'outbound-dial' ? (
                          <div className="btn btn-danger btn-sm ">Outbound</div>
                        ) : (
                          <div className="btn btn-success btn-sm ">
                            {item.direction && item.direction.charAt(0).toUpperCase() + item.direction.slice(1)}
                          </div>
                        )}
                      </td>
                      <td>{item.duration ? item.duration + 'sec' : null}</td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {
                         moment(item.createdAt).tz(currtz).format('LLL')}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Row className="mt-4">
              <Col xs="12">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={count}
                  pageRangeDisplayed={3}
                  onChange={handlePageChange}
                />
              </Col>
              {/* <Col md="4">
                {offset != 0 ? <Button onClick={prev}>Prev</Button> : null}
                {offset + voices.length < count ? <Button onClick={next}>Next</Button> : null}
              </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({
  voices: state.Twilio.voices,
  count: state.Twilio.voicecount,
  searchData: state.Twilio.searchData
});
UserVoice.propTypes = {
  getVoices: PropTypes.func.isRequired
};
export default connect(
  mapStateToProps,
  { getVoices, countvoices, voiceSearch,countfiltervoice }
)(UserVoice);

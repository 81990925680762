import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import team3 from '../../assets/img/team/3.jpg';
import Avatar from '../common/Avatar';
import { connect } from 'react-redux';
import { logout } from '../../Redux/Actions/Auth/Auth';
import { destroyDevice } from '../../view/pages/Home/twilioDevice';
import { destroyDevice as destroyDeviceIn } from '../../components/navbar/twilioDevice';
const ProfileDropdown = ({ logout, isAuthenticated }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const logoutuser = () => {
    console.log('second logout');
    destroyDevice();
    destroyDeviceIn();
    logout();
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={'/user.png'} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem
            className="font-weight-bold text-warning"
            href="https://mergecall.docs.apiary.io/#podio-development"
          >
            {/* <FontAwesomeIcon icon="crown" className="mr-1" /> */}
            <span>Api Docs</span>
          </DropdownItem>
          {/* <DropdownItem divider /> */}
          {isAuthenticated ? (
            <>
              {' '}
              <DropdownItem divider />
              <DropdownItem tag={Link} to="/myaccount">
                My Account
              </DropdownItem>
            </>
          ) : null}
          {/* <DropdownItem href="#!">Set status</DropdownItem>
          <DropdownItem tag={Link} to="/pages/profile">
            Profile &amp; account
          </DropdownItem> */}
          {isAuthenticated ? (
            <>
              {' '}
              <DropdownItem divider />
              <DropdownItem tag={Link} to="/home/upgradeplans">
                Plans
              </DropdownItem>
            </>
          ) : null}
          {isAuthenticated ? (
            <>
              {' '}
              <DropdownItem divider />
              <DropdownItem tag={Link} to="/contactus">
                Contact us
              </DropdownItem>
            </>
          ) : null}
          {/* <DropdownItem tag={Link} to="/account/api">
          Integrations
          </DropdownItem>  */}
          {isAuthenticated ? (
            <>
              {' '}
              <DropdownItem divider />
              <DropdownItem tag={Link} onClick={logoutuser} to="/authentication/login">
                Logout
              </DropdownItem>
            </>
          ) : null}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(
  mapStateToProps,
  { logout }
)(ProfileDropdown);

export const GET_TOKEN = 'GET_TOKEN';
export const GET_AVAILABLE = 'GET_AVAILABLE';
export const GET_COUNTAVAI = 'GET_COUNTAVAI';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_FAX = 'SEND_FAX';
export const SENT_OTP = 'SENT_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const GET_NUMBER = 'GET_NUMBER';
export const GET_FILTERNO = 'GET_FILTERNO';
export const GET_DETAIL = 'GET_DETAIL';
export const UPDATE_DETAIL = 'UPDATE_DETAIL';
export const USER_MESSAGES = 'USER_MESSAGES';
export const USER_VOICE = 'USER_VOICE';
export const GET_ALL_FAX = 'GET_ALL_FAX';
export const GET_ALL_COMPANY = 'GET_ALL_COMPANY';
export const NUMBER_BOUGHT = 'NUMBER_BOUGHT';
export const DONE = 'DONE';
export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const MASK_TEXTING_UPLOAD = 'MASK_TEXTING_UPLOAD';
export const GET_LOGS = 'GET_LOGS';
export const GET_MULTILINE_TOKEN = 'GET_MULTILINE_TOKEN';
export const GET_METRICS = 'GET_METRICS';
export const GET_FORM_WEBHOOK = 'GET_FORM_WEBHOOK';
export const GET_USER_FORM = 'GET_USER_FORM';
export const GET_COMPANY = 'GET_COMPANY';
export const CREATE_CARD = 'CREATE_CARD';
export const GET_CARDS = 'GET_CARDS';
export const UPDATE_CARD = 'UPDATE_CARD';
export const GET_CARD = 'GET_CARD';
export const GET_INVOICES = 'GET_INVOICES';
export const IS_PAID = 'IS_PAID';
export const GET_PLAN = 'GET_PLAN';
export const GET_FUND = 'GET_FUND';
export const TIME_ZONE = 'TIME_ZONE';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';

export const GET_USER_STATUS = 'GET_USER_STATUS';
export const GET_USER = 'GET_USER';

export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_MULTI_CONTACTS = 'GET_MULTI_CONTACTS';

export const COUNT_CONTACT = 'COUNT_CONTACT';

export const COUNT_VOICES = 'COUNT_VOICES';
export const COUNT_MESSAGES = 'COUNT_MESSAGES';
export const COUNT_NOTIFICATION = 'COUNT_NOTIFICATION';

export const GET_FLOW = 'GET_FLOW';

export const GET_FLOWS = 'GET_FLOWS';
export const GET_SEARCH = 'GET_SEARCH';
export const GET_REPORT = 'GET_REPORT';
export const GET_PROSPECTS = 'GET_PROSPECTS';

export const HEARD_ABOUT = 'HEARD_ABOUT';

export const GET_PROSPECT = 'GET_PROSPECT';

export const GET_TEMPLATES = 'GET_TEMPLATES';

export const REDIRECT_TEMP = 'REDIRECT_TEMP';

export const GET_TEMPLATE = 'GET_TEMPLATE';
export const GET_CATEGORIES = 'GET_CATEGORIES';

export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_QRS = 'GET_QRS';
export const GET_QR = 'GET_QR';

export const GET_QuickReplies = 'GET_QuickReplies';
export const GET_QuickReply = 'GET_QuickReply';

export const GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const COUNT_TEMPLATE = 'COUNT_TEMPLATE';
export const COUNT_PROSPECTS = 'COUNT_PROSPECTS';
export const GET_BATCH = 'GET_BATCH';
export const GET_UPLOADS = 'GET_UPLOADS';
export const GET_LEAD_STAGE = 'GET_LEAD_STAGE';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const ONE_OPT_OUT = 'ONE_OPT_OUT';
export const GET_OPTOUT = 'GET_OPTOUT';
export const GET_CONFIG = 'GET_CONFIG';
export const GET_COUNTS_STATS = 'GET_COUNTS_STATS';
export const SHOW_LOADER = 'SHOW_LOADER';
export const COUNT_ALL_PROSPECTS = 'COUNT_ALL_PROSPECTS';
export const GET_NOTES = 'GET_NOTES';
export const CALL_MONITOR = 'CALL_MONITOR';
export const GET_SESSION = 'GET_SESSION';
export const CALL_CAMP_LOGS = 'CALL_CAMP_LOGS';
export const CAMP_PROGRESS = 'CAMP_PROGRESS';
export const GET_DNC_LIST_ALL = 'GET_DNC_LIST_ALL';
export const CHECK_SUB = 'CHECK_SUB';
export const SKIP_TRACING = 'SKIP_TRACING';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

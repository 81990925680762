import React, {useEffect} from "react"
import "./Stripe.css"
import Modal from "../../../components/Modal/Modal"
import {connect} from "react-redux"
import {getplans} from "../../../Redux/Actions/Payment"
import {Row, Col} from "reactstrap"
const Subscriptionplan = ({getplans, plan}) => {
    
    useEffect(()=>{ 
       
        getplans()
    }, [getplans])
    const opentab = ()=>{
        window.open("https://podio.com/webforms/25579499/1901445")
    }
   return <div id="generic_price_table">   
    
        <div>
              <Row>
                <Col xs={12}>
                     <div className="price-heading clearfix">
                        <h1>Pricing plans</h1>
                    </div>
                </Col>
            </Row>
           <Row>
                {/* <Col xs="4">  
                
                   <div className={plan && plan.plan == "price_1HqebuHMvEdublEySKXtRFI6" ?"generic_content active clearfix" : "generic_content clearfix"}>
                        
                        <div className="generic_head_price clearfix">
                        
                            <div className="generic_head_content clearfix">
                            
                              <div className="head_bg"></div>
                                <div className="head">
                                    <span>Premium</span>
                                </div>
                               
                            </div>
                            <div className="generic_price_tag clearfix">  
                                <span className="price">
                                    <span className="sign">$</span>
                                    <span className="currency">1</span>
                                    <span className="cent">.00</span>
                                    <span className="month">/MON</span>
                                </span>
                            </div>
                            
                        </div>   
                        <div className="generic_feature_list">
                          <ul>
                              <li><span>$0.011/text</span> Inbound SMS</li>
                                <li><span>$0.011/text</span> Outbound SMS</li>
                                <li><span>$0.0195/min</span> Call</li>
                                <li><span>$0.005/page</span> Fax</li>
                                <li><span>24/7</span> Support</li>
                            </ul>
                        </div>
                        <div className="generic_price_btn clearfix">
                          <div className="generic_price_btn clearfix">
                           {plan && plan.plan == "price_1HqebuHMvEdublEySKXtRFI6" ? <Modal item={plan.sub} price="₹1,000.00 INR / month" buttonLabel="UnSubscribe"></Modal> : <Modal item={"price_1HqebuHMvEdublEySKXtRFI6"} price="₹1,000.00 INR / month" buttonLabel="Card"></Modal> }   
                         
                        </div>
                        </div>
                        
                    </div>
                        
               
                   <div className={plan && plan.plan == "price_1HwQNkBKqG4RchU557ZclWaS" ?"generic_content active clearfix" : "generic_content clearfix"}>
                        
                        <div className="generic_head_price clearfix">
                        
                            <div className="generic_head_content clearfix">
                            
                              <div className="head_bg"></div>
                                <div className="head">
                                    <span>Premium</span>
                                </div>
                               
                            </div>
                            <div className="generic_price_tag clearfix">  
                                <span className="price">
                                    <span className="sign">$</span>
                                    <span className="currency">1</span>
                                    <span className="cent">.00</span>
                                    <span className="month">/MON</span>
                                </span>
                            </div>
                            
                        </div>   
                        <div className="generic_feature_list">
                          <ul>
                              <li><span>$0.011/text</span> Inbound SMS</li>
                                <li><span>$0.011/text</span> Outbound SMS</li>
                                <li><span>$0.0195/min</span> Call</li>
                                <li><span>$0.005/page</span> Fax</li>
                                <li><span>24/7</span> Support</li>
                            </ul>
                        </div>
                        <div className="generic_price_btn clearfix">
                          <div className="generic_price_btn clearfix">
                           {plan && plan.plan == "price_1HwQNkBKqG4RchU557ZclWaS" ? <Modal item={plan.sub} price="₹1,000.00 INR / month" buttonLabel="UnSubscribe"></Modal> : <Modal item={"price_1HwQNkBKqG4RchU557ZclWaS"} price="₹1,000.00 INR / month" buttonLabel="Card"></Modal> }   
                         
                        </div>
                        </div>
                        
                    </div>
                        
                 </Col>
              
            
              */}
              <Col md="2" xs="2"></Col>
              <Col md="4" xs="12">
                   <div className={plan && plan.plan == "price_1JX3J5GVMLEvSdQH3TMObFj7" ?"generic_content active clearfix" : "generic_content clearfix"}>
                        <div className="generic_head_price clearfix">
                            <div className="generic_head_content clearfix">
                              <div className="head_bg"></div>
                                <div className="head">
                                    <span>Premium</span>
                                </div>
                            </div>
                            <div className="generic_price_tag clearfix">  
                                <span className="price">
                                    <span className="sign">$</span>
                                    <span className="currency">34</span>
                                    <span className="cent">.99</span>
                                    <span className="month">/MON</span>
                                </span>
                            </div>
                        </div>   
                        <div className="generic_feature_list">
                          <ul>
                              <li><span>$0.011/text</span> Inbound SMS</li>
                                <li><span>$0.011/text</span> Outbound SMS</li>
                                <li><span>$0.0195/min</span> Call</li>
                                <li><span>$0.005/page</span> Fax</li>
                                {/* <li><span>24/7</span> Support</li> */}
                                <li><span>Multiline dialing</span></li>
                                <li><span>Easy  SMS/Call Tracking</span></li>
                                <li><span>Easy Payments</span></li>
                                <li><span>Mass texting</span></li>
                                <li><span>Multiple companies</span></li>
                                 <li><span>24/7</span> Support</li>
                            </ul>
                        </div>
                        <div className="generic_price_btn clearfix">
                          <div className="generic_price_btn clearfix">
                           {plan && plan.plan == "price_1JX3J5GVMLEvSdQH3TMObFj7" ? <Modal item={plan.sub} price="₹1,000.00 INR / month" buttonLabel="UnSubscribe"></Modal> : <Modal item={"price_1JX3J5GVMLEvSdQH3TMObFj7"} price="₹1,000.00 INR / month" buttonLabel="Card"></Modal> }   
                        </div>
                        </div>
                    </div>
                </Col>
            <Col md="4" xs="12">
                    <div className={"generic_content clearfix"}>
                        <div className="generic_head_price clearfix">
                            <div className="generic_head_content clearfix">
                                <div className="head_bg"></div>
                                <div className="head">
                                    <span>ENTERPRISE</span>
                                </div>
                            </div>
                            <div className="generic_price_tag clearfix">  
                                <span className="price"> 
                                    <span className="sign">$</span>
                                    <span className="currency">59</span>
                                    <span className="cent">.99</span>
                                    <span className="month">/MON</span>
                                 </span>
                            </div>
                        </div>                            
                        <div className="generic_feature_list">
                          <ul>
                              <li>Customized system build on top of our API</li>
                                {/* <li>Increase monthly or yearly merges or templates </li> */}
                                <li>Dedicated System Manager </li>
                                <li>Have an idea to share? </li>
                                <li><span>24/7</span> </li>
                            </ul>
                        </div>
                        <div className="generic_price_btn clearfix">
                          <button onClick={opentab} className="modal-button">Contact us</button>
                        </div>
                    </div>
                </Col>
                <Col xs="2"></Col>
        </Row>
        </div>
             
  <footer>
      <a className="bottom_btn" href="#">&copy; MergeCall</a>
    </footer>
</div>

}
const mapeStateTOProps = (state) =>({
    plan: state.payment.plan
})
export default connect(mapeStateTOProps,{getplans})(Subscriptionplan)
import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import DialpadIcon from '@material-ui/icons/Dialpad';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import { TextFields } from '@material-ui/icons';
import { getnumber } from '../../../Redux/Actions/Twilio';
import { postflow, getflow } from '../../../Redux/Actions/USER/user';
import CallEndIcon from '@material-ui/icons/CallEnd';
import Loader from '../../../components/common/Loader';
import CloseIcon from '@material-ui/icons/Close';
import { Link, useHistory } from 'react-router-dom';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import ReactFlow from 'react-flow-renderer';
import { toast } from 'react-toastify';
import './content.css';
// import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import Switch from '@material-ui/core/Switch';
import Popup from './popup';


const SubFlow_map = ({ fields, setfields, subflow, selected }) => {
  const [subflowslected, setsubflowslected] = useState();
  useEffect(() => {
    setsubflowslected(null);
  }, [selected]);
  const submenuchange = (e, index, i) => {
    const { value } = e.target;
    console.log({ value });
    console.log('subchange', index, i);
    const values = [...fields];
    //   console.log({values})
    if (e.target.value < 0) {
      values[index].flow[i].value = 0;
    } else if (e.target.value > 9) {
      values[index].flow[i].value = 9;
    } else {
      values[index].flow[i].value = value;
    }

    setfields(values);
  };
  const subtextchange = (e, index, i) => {
    const { value } = e.target;
    console.log({ value });
    console.log('subchange', index, i);
    const values = [...fields];
    //   console.log({values})
    values[index].flow[i].text = value;
    setfields(values);
  };
  const subvoicemailchange = (e, index, i) => {
    const { value } = e.target;
    console.log({ value });
    console.log('subchange', index, i);
    const values = [...fields];
    //   console.log({values})
    values[index].flow[i].play = value;
    setfields(values);
  };
  const subforwardchange = (e, index, i) => {
    const { value } = e.target;
    console.log({ value });
    console.log('subchange', index, i);
    const values = [...fields];
    console.log({ values });
    values[index].flow[i].forward = value;
    setfields(values);
  };
  const onAddNoAns = e => {
    var temp = {};
    if (e === 'hangup') {
      temp[e] = true;
    }else if(e="play"){
      temp={
        play:"",
        txt:false
      }
    } else {
      temp[e] = '';
    }
    console.log({ temp });
    const values = [...fields];

    let index = values[selected].flow.findIndex(e => e.noans);
    console.log({ index });
    if (index != -1) {
      values[selected].flow[index].noans[0]=temp;
      console.log('existfields', values);
      setfields(values);
    } else {
      values[selected].flow.push({ noans: [temp] });

      console.log(values);
      setfields(values);
    }
  };
  const onNoanstextChange = (index, ind, e) => {
    const values = [...fields];
    values[selected].flow[index].noans[ind].text = e.target.value;
    setfields(values);
  };
  const onNoansplayChange = (index, ind, e) => {
    const values = [...fields];
    values[selected].flow[index].noans[ind].play = e.target.value;
    setfields(values);
  };
  const onNoansforwardChange = (index, ind, e) => {
    const values = [...fields];
    values[selected].flow[index].noans[ind].forward = e.target.value;
    setfields(values);
  };
  const onNoansvalueChange = (index, ind, e) => {
    const values = [...fields];
    values[selected].flow[index].noans[ind].value = e.target.value;
    setfields(values);
  };

  const onAddNoAnsCall = (i, e) => {
    var temp = {};
    if (e === 'hangup') {
      temp[e] = true;
    }else if(e==='play'){
      temp={
        play:"",
        txt:false
      }
    } else {
      temp[e] = '';
    }
    const values = [...fields];
    if (values[selected].flow[i].noans_menue) {
      values[selected].flow[i].noans_menue[0]=temp;
      console.log('existfields', values);
      setfields(values);
    } else {
      values[selected].flow[i]['noans_menue'] = [];
      values[selected].flow[i].noans_menue.push(temp);
      console.log(values);
      setfields(values);
    }
    // values[i].forward = e;
    // setfields(values);
  };
  const onCallNoansText = (i, e) => {
    const values = [...fields];

    values[selected].flow[subflowslected].noans_menue[i].text = e.target.value;
    setfields(values);
  };
  const onCallNoansPlay = (i, e) => {
    const values = [...fields];

    values[selected].flow[subflowslected].noans_menue[i].play = e.target.value;
    setfields(values);
  };
  const onCallNoansForward = (i, e) => {
    const values = [...fields];

    values[selected].flow[subflowslected].noans_menue[i].forward = e.target.value;
    setfields(values);
  };
  const onCallNoansValue = (i, e) => {
    const values = [...fields];

    values[selected].flow[subflowslected].noans_menue[i].value = e.target.value;
    setfields(values);
  };
  const subFlowDelete = (s, i) => {
    const values = [...fields];
    values[s].flow.splice(i, 2);
    if(values[s].flow.length===0){
       delete values[s].flow
    }
   setfields(values);
  };
  const removeFlow = index => {
    const values = [...fields];
    values[selected].flow.splice(index, 1);
    setfields(values);
  };
  const changeType=(index)=>{
    const values = [...fields];
  
    values[selected].flow[index].txt=!values[selected].flow[index].txt
    setfields(values);
  }
  const changenoansType=(index,ind)=>{
    const values = [...fields];
  
    values[selected].flow[index].noans[ind].txt=!values[selected].flow[index].noans[ind].txt
    setfields(values);
  }
  const changenoansPlayType=(index)=>{
    const values = [...fields];
  
    values[selected].flow[subflowslected].noans_menue[index].txt=!values[selected].flow[subflowslected].noans_menue[index].txt
    setfields(values);
  }
  const [popup1, setpopup1] = useState(false)
  console.log({ fields, subflowslected, selected });
  return (
    <div>
      {fields[selected].flow?.filter(e => e.value != undefined).length ? (
        <Row className="mt-2">
          <Col>
            <Card>
              <CardHeader
                title={
                  <p
                    style={{
                      fontSize: '1rem',
                      backgroundColor: 'RoyalBlue',

                      textAlign: 'center',
                      fontWeight: 'Bold',
                      color: 'white',
                      padding: '12px'
                    }}
                  >
                    IVR Menu
                  </p>
                }
              />
              <CardContent>
                <Label> Menu Prompt the caller to select from a menu of options using the keypad on their phone.</Label>
                {fields[selected].flow &&
                  fields[selected].flow.map((item, index) => {
                    return (
                      <div>
                        {item.value != undefined ? (
                          <Row>
                            <Col xs="6">
                              <Input
                                style={{ fontSize: '0.95em' }}
                                placeholder="1"
                                type="number"
                                value={item.value}
                                onChange={e => submenuchange(e, selected, index)}
                                placeholder="Number"
                                onWheel={e => e.target.blur()}
                              />
                            </Col>
                            <Col xs="4">
                              {fields[selected].flow[index + 1]?.text != undefined ? (
                                <Button
                                  style={{ display: 'block', color: 'grey', width: '30%' }}
                                  variant="outlined"
                                  onClick={() => {
                                    setsubflowslected(index + 1);
                                  }}
                                >
                                  <TextFields /> <p>text</p>
                                </Button>
                              ) : null}
                              {fields[selected].flow[index + 1]?.hangup != undefined ? (
                                <Button
                                  style={{ display: 'block', color: 'grey', width: '30%' }}
                                  variant="outlined"
                                  onClick={() => {
                                    setsubflowslected(index + 1);
                                  }}
                                >
                                  <CallEndIcon />
                                  <p>Hang Up</p>
                                </Button>
                              ) : null}
                              {fields[selected].flow[index + 1]?.forward != undefined ? (
                                <Button
                                  style={{ display: 'block', color: 'grey', width: '30%' }}
                                  variant="outlined"
                                  onClick={() => {
                                    setsubflowslected(index + 1);
                                  }}
                                >
                                  <PhoneForwardedIcon />
                                  <p>Dial</p>
                                </Button>
                              ) : null}
                              {fields[selected].flow[index + 1]?.play != undefined ? (
                                <Button
                                  style={{ display: 'block', color: 'grey', width: '30%' }}
                                  variant="outlined"
                                  onClick={() => {
                                    setsubflowslected(index + 1);
                                  }}
                                >
                                  <VoicemailIcon /> <p>Voicemail</p>
                                </Button>
                              ) : null}
                            </Col>
                            <Col xs="2">
                              {/* <Button variant="contained" color="primary" size="small" onClick={e => subFlowDelete(selected, index)}>
                                Delete&nbsp; <i class="fas fa-trash" />
                              </Button> */}
                              <i class="fas fa-trash" style={{color:"blue"}} onClick={() => subFlowDelete(selected, index)}/>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    );
                  })}
              </CardContent>
            </Card>
          </Col>
        </Row>
      ) : null}
      {subflowslected && fields[selected]?.flow&&(
        <Row>
          <Col>
            <Card>
              <CardHeader
                title={
                  <div>
                    {Object.keys(fields?.[selected]?.flow?.[subflowslected])[0] === 'forward' && (
                      <p
                        style={{
                          fontSize: '1rem',
                          backgroundColor: 'RoyalBlue',

                          textAlign: 'center',
                          fontWeight: 'Bold',
                          color: 'white',
                          padding: '12px'
                        }}
                      >
                        Call Forward
                      </p>
                    )}
                    {Object.keys(fields?.[selected]?.flow?.[subflowslected])[0] === 'play' && (
                      <p
                        style={{
                          fontSize: '1rem',
                          backgroundColor: 'RoyalBlue',

                          textAlign: 'center',
                          fontWeight: 'Bold',
                          color: 'white',
                          padding: '12px'
                        }}
                      >
                        Voice Mail
                      </p>
                    )}
                    {fields?.[selected]?.flow?.[subflowslected]['hangup'] && (
                      <p
                        style={{
                          fontSize: '1rem',
                          backgroundColor: 'RoyalBlue',

                          textAlign: 'center',
                          fontWeight: 'Bold',
                          color: 'white',
                          padding: '12px'
                        }}
                      >
                        Hangup call
                      </p>
                    )}
                    {Object.keys(fields?.[selected]?.flow?.[subflowslected])[0] === 'text' && (
                      <p
                        style={{
                          fontSize: '1rem',
                          backgroundColor: 'RoyalBlue',

                          textAlign: 'center',
                          fontWeight: 'Bold',
                          color: 'white',
                          padding: '12px'
                        }}
                      >
                        text
                      </p>
                    )}
                  </div>
                }
              />
              <CardContent>
                {Object.keys(fields?.[selected]?.flow?.[subflowslected])[0] === 'forward' && (
                  <div>
                    <Label>Forward Calls To</Label>
                    <Input
                      style={{ fontSize: '0.95em' }}
                      type="text"
                      value={
                        fields?.[selected]?.flow?.[subflowslected].forward === true
                          ? ''
                          : fields?.[selected]?.flow?.[subflowslected].forward
                      }
                      onChange={e => {
                        subforwardchange(e, selected, subflowslected);
                      }}
                      placeholder="Number"
                    />
                    {fields[selected].flow[subflowslected].noans_menue &&
                      fields[selected].flow[subflowslected].noans_menue.map((item, index) => {
                        return (
                          <div>
                            {item.text != undefined ? (
                              <Row>
                                <Col xs={12}>
                                  <Card className="mt-2">
                                    <CardHeader
                                      title={
                                        <p
                                          style={{
                                            fontSize: '1rem',
                                            backgroundColor: 'RoyalBlue',

                                            textAlign: 'center',
                                            fontWeight: 'Bold',
                                            color: 'white',
                                            padding: '12px'
                                          }}
                                        >
                                          If no one answer :-Greeting Play a message to the caller
                                        </p>
                                      }
                                    />
                                    <CardContent>
                                      <Input
                                        style={{ fontSize: '0.95em' }}
                                        type="textarea"
                                        value={item.text === true ? '' : item.text}
                                        onChange={e => {
                                          onCallNoansText(index, e);
                                        }}
                                        placeholder="text"
                                      />
                                    </CardContent>
                                  </Card>
                                </Col>
                              </Row>
                            ) : null}
                            {item.play != undefined ? (
                              <Card className="mt-2">
                                <CardHeader
                                  title={
                                    <p
                                      style={{
                                        fontSize: '1rem',
                                        backgroundColor: 'RoyalBlue',

                                        textAlign: 'center',
                                        fontWeight: 'Bold',
                                        color: 'white',
                                        padding: '12px'
                                      }}
                                    >
                                      If no one answer :-Voice Mail
                                    </p>
                                  }
                                />
                                <CardContent>
                                  <Row>
                                    <Col xs="6">
                                    <Input
                                    style={{ fontSize: '0.95em' }}
                                    type="text"
                                    value={item.play === true ? '' : item.play}
                                    onChange={e => {
                                      onCallNoansPlay(index, e);
                                    }}
                                    placeholder={item.txt?"Text":"Mp3 url"} 
                                  />
                                    </Col>
                                    <Col xs="2">
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  onClick={() => setpopup1(true)}
                                                >
                                                  Upload mp3 file
                                                </Button>
                                                <Popup open={popup1} setOpen={setpopup1} onChange={(e)=>onCallNoansPlay(index, e)} />
                                              </Col>
                                    <Col xs="4">
                                    {/* <Button variant="contained" color="primary" onClick={()=>changenoansPlayType(index)} >
                               {item.txt?"Text":"Mp3 url"}       
                        </Button> */}
                        <Tooltipbutton title="mp3 url">
                          <span style={{ fontWeight:"bold",marginRight: '5px', fontSize: '14px', paddingTop: '5px' }}>Mp3 url</span>
                        </Tooltipbutton>
                            <Switch
                              checked={item.txt}
                              onChange={() => changenoansPlayType(index)}
                              color="primary"
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Tooltipbutton title="text">
                          <span style={{ fontWeight:"bold",marginRight: '30px', fontSize: '14px', paddingTop: '5px' }}>Text</span>
                        </Tooltipbutton>
                                    </Col>
                                  </Row>
                                  
                                </CardContent>
                              </Card>
                            ) : null}
                            {item.forward != undefined ? (
                              <Card className="mt-2">
                                <CardHeader
                                  title={
                                    <p
                                      style={{
                                        fontSize: '1rem',
                                        backgroundColor: 'RoyalBlue',

                                        textAlign: 'center',
                                        fontWeight: 'Bold',
                                        color: 'white',
                                        padding: '12px'
                                      }}
                                    >
                                      If no one answer :- Forward Call
                                    </p>
                                  }
                                />
                                <CardContent>
                                  <Label>Forward Calls To</Label>
                                  <Input
                                    style={{ fontSize: '0.95em' }}
                                    type="text"
                                    value={item.forward === true ? '' : item.forward}
                                    onChange={e => {
                                      onCallNoansForward(index, e);
                                    }}
                                    placeholder="Number"
                                  />
                                </CardContent>
                              </Card>
                            ) : null}
                            {item.hangup != undefined ? (
                              <Card className="mt-2">
                                <CardHeader
                                  title={
                                    <p
                                      style={{
                                        fontSize: '1rem',
                                        backgroundColor: 'RoyalBlue',

                                        textAlign: 'center',
                                        fontWeight: 'Bold',
                                        color: 'white',
                                        padding: '12px'
                                      }}
                                    >
                                      If no one answer :-Hang Up
                                    </p>
                                  }
                                />
                                <CardContent>
                                  <Button variant="contained" color="secondary">
                                    Hang Up
                                  </Button>
                                </CardContent>
                              </Card>
                            ) : null}
                            {item.value != undefined ? (
                              <Card className="mt-2">
                                <CardHeader
                                  title={
                                    <p
                                      style={{
                                        fontSize: '1rem',
                                        backgroundColor: 'RoyalBlue',

                                        textAlign: 'center',
                                        fontWeight: 'Bold',
                                        color: 'white',
                                        padding: '12px'
                                      }}
                                    >
                                      Menu Prompt the caller to select from a menu of options using the keypad on their
                                      phone.
                                    </p>
                                  }
                                />
                                <CardContent>
                                  <Input
                                    style={{ fontSize: '0.95em' }}
                                    placeholder="1"
                                    type="number"
                                    value={item.value === true ? '' : item.value}
                                    onChange={e => onCallNoansValue(index, e)}
                                    placeholder="Number"
                                    onWheel={e => e.target.blur()}
                                  />
                                </CardContent>
                              </Card>
                            ) : null}
                          </div>
                        );
                      })}
                    <Row className="mt-3">
                      <Col md={12}>
                        <div class="dropdown">
                          <span style={{ color: '#2c7be5', display: 'flex', alignItems: 'center' }}>
                            <i class="fas fa-plus-circle fa-2x" />{' '}
                            <Label className="mb-0">&nbsp;If no one answer</Label>
                          </span>
                          <div class="dropdown-content">
                            <Label
                              style={{ display: 'block', cursor: 'pointer' }}
                              onClick={() => onAddNoAnsCall(subflowslected, 'text')}
                            >
                              Text
                            </Label>
                            <Label
                              style={{ display: 'block', cursor: 'pointer' }}
                              onClick={() => onAddNoAnsCall(subflowslected, 'hangup')}
                            >
                              Hangup
                            </Label>
                            {/* <Label
                              style={{ display: 'block', cursor: 'pointer' }}
                              onClick={() => onAddNoAnsCall(subflowslected, 'value')}
                            >
                              Menu
                            </Label> */}
                            <Label
                              style={{ display: 'block', cursor: 'pointer' }}
                              onClick={() => onAddNoAnsCall(subflowslected, 'forward')}
                            >
                              Dial
                            </Label>
                            <Label
                              style={{ display: 'block', cursor: 'pointer' }}
                              onClick={() => onAddNoAnsCall(subflowslected, 'play')}
                            >
                              Voicemail
                            </Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {Object.keys(fields?.[selected]?.flow?.[subflowslected])[0] === 'play' && (
                  <div>
                    <Row>
                      <Col xs="6">
                      <Input
                      style={{ fontSize: '0.95em' }}
                      type="text"
                      value={
                        fields?.[selected]?.flow?.[subflowslected].play === true
                          ? ''
                          : fields?.[selected]?.flow?.[subflowslected].play
                      }
                      onChange={e => {
                        subvoicemailchange(e, selected, subflowslected);
                      }}
                      placeholder= {fields?.[selected]?.flow?.[subflowslected].txt?"Text":"Mp3 url"} 
                    />
                      </Col>
                      <Col xs="2">
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  onClick={() => setpopup1(true)}
                                                >
                                                  Upload mp3 file
                                                </Button>
                                                <Popup open={popup1} setOpen={setpopup1} onChange={(e)=>subvoicemailchange(e, selected, subflowslected)} />
                                              </Col>
                      <Col xs="4">
                      {/* <Button variant="contained" color="primary" onClick={()=>changeType(subflowslected)} >
                               {fields?.[selected]?.flow?.[subflowslected].txt?"Text":"Mp3 url"}       
                        </Button> */}
                        <Tooltipbutton title="mp3 url">
                          <span style={{ fontWeight:"bold",marginRight: '5px', fontSize: '14px', paddingTop: '5px' }}>Mp3 url</span>
                        </Tooltipbutton>
                            <Switch
                              checked={fields?.[selected]?.flow?.[subflowslected].txt}
                              onChange={() => changeType(subflowslected)}
                              color="primary"
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Tooltipbutton title="text">
                          <span style={{ fontWeight:"bold",marginRight: '30px', fontSize: '14px', paddingTop: '5px' }}>Text</span>
                        </Tooltipbutton>
                      </Col>
                    </Row>
                    
                  </div>
                )}

                {Object.keys(fields?.[selected]?.flow?.[subflowslected])[0] === 'hangup' && (
                  <Button variant="contained" color="secondary">
                    Hang Up
                  </Button>
                )}
                {Object.keys(fields?.[selected]?.flow?.[subflowslected])[0] === 'text' && (
                  <div>
                    <Input
                      style={{ fontSize: '0.95em' }}
                      type="textarea"
                      value={
                        fields?.[selected]?.flow?.[subflowslected].text === true
                          ? ''
                          : fields?.[selected]?.flow?.[subflowslected].text
                      }
                      onChange={e => {
                        subtextchange(e, selected, subflowslected);
                      }}
                      placeholder="text"
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </Col>
        </Row>
      )}
      {fields[selected].flow.map((item, index) => {
        if (item.noans) {
          return (
            <div>
              <Card>
                <CardHeader
                  title={
                    <p
                      style={{
                        fontSize: '1rem',
                        backgroundColor: 'RoyalBlue',

                        textAlign: 'center',
                        fontWeight: 'Bold',
                        color: 'white',
                        padding: '12px'
                      }}
                    >
                      If no one answer
                    </p>
                  }
                />
                <CardContent>
                  {item.noans.map((i, ind) => {
                    return (
                      <div>
                        {i.text != undefined ? (
                          <Row>
                            <Col xs={12}>
                              <Card className="mt-2">
                                <CardHeader
                                  title={
                                    <p
                                      style={{
                                        fontSize: '1rem',
                                        backgroundColor: 'RoyalBlue',

                                        textAlign: 'center',
                                        fontWeight: 'Bold',
                                        color: 'white',
                                        padding: '12px'
                                      }}
                                    >
                                      Greeting Play a message to the caller
                                    </p>
                                  }
                                />
                                <CardContent>
                                  <Row>
                                    <Col xs="10">
                                      <Input
                                        style={{ fontSize: '0.95em' }}
                                        type="textarea"
                                        value={i.text === true ? '' : i.text}
                                        onChange={e => {
                                          onNoanstextChange(index, ind, e);
                                        }}
                                        placeholder="text"
                                      />
                                    </Col>
                                    <Col xs="2">
                                      {/* <Button variant="contained" color="primary" onClick={() => removeFlow(index)}>
                                        Delete&nbsp; <i class="fas fa-trash" />
                                      </Button> */}
                                      <i class="fas fa-trash" style={{color:"blue"}} onClick={() => removeFlow(index)}/>
                                    </Col>
                                  </Row>
                                </CardContent>
                              </Card>
                            </Col>
                          </Row>
                        ) : null}
                        {i.play != undefined ? (
                          <Card className="mt-2">
                            <CardHeader
                              title={
                                <p
                                  style={{
                                    fontSize: '1rem',
                                    backgroundColor: 'RoyalBlue',

                                    textAlign: 'center',
                                    fontWeight: 'Bold',
                                    color: 'white',
                                    padding: '12px'
                                  }}
                                >
                                  Voice Mail
                                </p>
                              }
                            />
                            <CardContent>
                              <Row>
                                <Col xs="6">
                                  <Input
                                    style={{ fontSize: '0.95em' }}
                                    type="text"
                                    value={i.play === true ? '' : i.play}
                                    onChange={e => {
                                      onNoansplayChange(index, ind, e);
                                    }}
                                    placeholder={i.txt?"Text":"Mp3 url"}  
                                  />
                                </Col>
                                <Col xs="2">
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  onClick={() => setpopup1(true)}
                                                >
                                                  Upload mp3 file
                                                </Button>
                                                <Popup open={popup1} setOpen={setpopup1} onChange={(e)=>onNoansplayChange(index, ind, e)} />
                                              </Col>
                                <Col xs="3">
                                {/* <Button variant="contained" color="primary" onClick={()=>changenoansType(index,ind)} >
                               {i.txt?"Text":"Mp3 url"}       
                        </Button> */}
                        <Tooltipbutton title="mp3 url">
                          <span style={{ fontWeight:"bold",marginRight: '5px', fontSize: '14px', paddingTop: '5px' }}>Mp3 url</span>
                        </Tooltipbutton>
                            <Switch
                              checked={i.txt}
                              onChange={() =>changenoansType(index,ind)}
                              color="primary"
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Tooltipbutton title="text">
                          <span style={{ fontWeight:"bold",marginRight: '30px', fontSize: '14px', paddingTop: '5px' }}>Text</span>
                        </Tooltipbutton>
                                </Col>
                                <Col xs="1">
                                  {/* <Button variant="contained" color="primary" onClick={() => removeFlow(index)}>
                                    Delete&nbsp; <i class="fas fa-trash" />
                                  </Button> */}
                                  <i class="fas fa-trash" style={{color:"blue"}} onClick={() => removeFlow(index)}/>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        ) : null}
                        {i.forward != undefined ? (
                          <Card className="mt-2">
                            <CardHeader
                              title={
                                <p
                                  style={{
                                    fontSize: '1rem',
                                    backgroundColor: 'RoyalBlue',

                                    textAlign: 'center',
                                    fontWeight: 'Bold',
                                    color: 'white',
                                    padding: '12px'
                                  }}
                                >
                                  Dial This is where the phone will ring
                                </p>
                              }
                            />
                            <CardContent>
                              <Label>Forward Calls To</Label>
                              <Row>
                                <Col xs="10">
                                  <Input
                                    style={{ fontSize: '0.95em' }}
                                    type="text"
                                    value={i.forward === true ? '' : i.forward}
                                    onChange={e => {
                                      onNoansforwardChange(index, ind, e);
                                    }}
                                    placeholder="Number"
                                  />
                                </Col>
                                <Col xs="2">
                                  {/* <Button variant="contained" color="primary" onClick={() => removeFlow(index)}>
                                    Delete&nbsp; <i class="fas fa-trash" />
                                  </Button> */}
                                  <i class="fas fa-trash" style={{color:"blue"}} onClick={() => removeFlow(index)}/>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        ) : null}
                        {i.hangup != undefined ? (
                          <Card className="mt-2">
                            <CardHeader
                              title={
                                <p
                                  style={{
                                    fontSize: '1rem',
                                    backgroundColor: 'RoyalBlue',

                                    textAlign: 'center',
                                    fontWeight: 'Bold',
                                    color: 'white',
                                    padding: '12px'
                                  }}
                                >
                                  Hang Up
                                </p>
                              }
                            />
                            <CardContent>
                              <Row>
                                <Col xs="10">
                                  <Button variant="contained" color="secondary">
                                    Hang Up
                                  </Button>
                                </Col>
                                <Col xs="2">
                                  {/* <Button variant="contained" color="primary" onClick={() => removeFlow(index)}>
                                    Delete&nbsp; <i class="fas fa-trash" />
                                  </Button> */}
                                  <i class="fas fa-trash" style={{color:"blue"}} onClick={() => removeFlow(index)}/>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        ) : null}
                        {i.value != undefined ? (
                          <Card className="mt-2">
                            <CardHeader
                              title={
                                <p
                                  style={{
                                    fontSize: '1rem',
                                    backgroundColor: 'RoyalBlue',

                                    textAlign: 'center',
                                    fontWeight: 'Bold',
                                    color: 'white',
                                    padding: '12px'
                                  }}
                                >
                                  Menu Prompt the caller to select from a menu of options using the keypad on their
                                  phone.
                                </p>
                              }
                            />
                            <CardContent>
                              <Input
                                style={{ fontSize: '0.95em' }}
                                placeholder="1"
                                type="number"
                                value={i.value === true ? '' : i.value}
                                onChange={e => onNoansvalueChange(index, ind, e)}
                                placeholder="Number"
                                onWheel={e => e.target.blur()}
                              />
                            </CardContent>
                          </Card>
                        ) : null}
                      </div>
                    );
                  })}
                </CardContent>
              </Card>
            </div>
          );
        }
      })}
      <Row className="mt-3">
        <Col md={12}>
          <div class="dropdown">
            <span style={{ color: '#2c7be5', display: 'flex', alignItems: 'center' }}>
              <i class="fas fa-plus-circle fa-2x" /> <Label className="mb-0">&nbsp;If no one answer</Label>
            </span>
            <div class="dropdown-content">
              <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('text')}>
                Text
              </Label>
              <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('hangup')}>
                Hangup
              </Label>
              {/* <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('value')}>
                Menu
              </Label> */}
              <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('forward')}>
                Dial
              </Label>
              <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('play')}>
                Voicemail
              </Label>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SubFlow_map;

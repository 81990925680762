import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { updatebillingaddress } from '../../../Redux/Actions/Payment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { toast } from 'react-toastify';
import CardBody from 'reactstrap/lib/CardBody';
import Label from 'reactstrap/lib/Label';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
const Register = ({ updatebillingaddress, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    address: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  });

  const { address, city, state, zip, country } = formData;
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async e => {
    e.preventDefault();

    if (address == '' || city == '' || state == '' || zip == '' || country == '') {
      toast.error('All fields are required');
    } else {
      updatebillingaddress({ address, city, state, zip, country });
    }
  };
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Row className="justify-content-center ">
      <Col xs="12">
        <Card>
          <Label className="m-3">Billing Adress</Label>
          <CardBody style={{backgroundColor:"#f9fafd"}}>
            
            <Row className="p-4" >
              
                <Row className="mt-2" style={{width:"100%"}}>
                  <Col xs="5">
                  <Label className="pt-1">
                    Enter the Address
                  </Label>
                  </Col>
                  <Col xs="7">
                  <input
                    style={{ fontSize: '0.95em', padding: '3px 12px 3px ' }}
                    placeholder="Address"
                    bsSize="sm"
                    type="text"
                    onChange={onChange}
                    name="address"
                    value={address}
                  />
                  </Col>
                </Row>
                <Row className="mt-2" style={{width:"100%"}}>
                  <Col xs="5">
                  <Label className="pt-1" >
                    Enter the City
                  </Label>
                  </Col>
                  <Col xs="7">
                  <input
                    style={{ fontSize: '0.95em', padding: '3px 12px 3px' }}
                    placeholder="City"
                    type="text"
                    onChange={onChange}
                    name="city"
                    value={city}
                  />
                  </Col>
                </Row>
                <Row className="mt-2" style={{width:"100%"}}>
                  <Col xs="5">
                  <Label className="pt-1">
                    Enter the State
                  </Label></Col>
                  <Col xs="7">
                  <input
                    style={{ fontSize: '0.95em', padding: '3px 12px 3px' }}
                    placeholder="State"
                    type="text"
                    onChange={onChange}
                    name="state"
                    value={state}
                  />
                  </Col>
                </Row>
                <Row className="mt-2" style={{width:"100%"}}>
                 <Col xs="5">
                  <Label className="pt-1">
                    Enter the Country
                  </Label></Col>
                  <Col xs="7">
                  <input
                    style={{ fontSize: '0.95em', padding: '3px 12px 3px' }}
                    placeholder="Country"
                    type="text"
                    onChange={onChange}
                    name="country"
                    value={country}
                  />
                  </Col>
                </Row>
                <Row className="mt-2" style={{width:"100%"}}>
                 <Col xs="5">
                  <Label >
                    Enter the Zip COde
                  </Label></Col>
                  <Col xs="7">
                  <input
                    style={{ fontSize: '0.95em', padding: '3px 12px 3px' }}
                    placeholder="Zip Code"
                    type="text"
                    onChange={onChange}
                    name="zip"
                    value={zip}
                  />
                  </Col>
                </Row>
             
              
            </Row>
            {/* <Row className="mt-3">
              <Col xs="12" className="pt-2">
                <input
                  style={{ fontSize: '0.95em', padding: '3px 12px 3px ' }}
                  placeholder="Address"
                  bsSize="sm"
                  type="text"
                  onChange={onChange}
                  name="address"
                  value={address}
                />
                <small id="emailHelp" class="form-text text-muted">
                  Enter the Address
                </small>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="6" className="pt-2">
                <input
                  style={{ fontSize: '0.95em', padding: '3px 12px 3px' }}
                  placeholder="City"
                  type="text"
                  onChange={onChange}
                  name="city"
                  value={city}
                />
                <small id="emailHelp" class="form-text text-muted">
                  Enter the City
                </small>
              </Col>
              <Col xs="6" className="pt-2">
                <input
                  style={{ fontSize: '0.95em', padding: '3px 12px 3px' }}
                  placeholder="State"
                  type="text"
                  onChange={onChange}
                  name="state"
                  value={state}
                />
                <small id="emailHelp" class="form-text text-muted">
                  Enter the State
                </small>
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="pt-2">
                <input
                  style={{ fontSize: '0.95em', padding: '3px 12px 3px' }}
                  placeholder="Country"
                  type="text"
                  onChange={onChange}
                  name="country"
                  value={country}
                />
                <small id="emailHelp" class="form-text text-muted">
                  Enter the Country
                </small>
              </Col>
              <Col xs="6" className="pt-2">
                <input
                  style={{ fontSize: '0.95em', padding: '3px 12px 3px' }}
                  placeholder="Zip Code"
                  type="text"
                  onChange={onChange}
                  name="zip"
                  value={zip}
                />
                <small id="emailHelp" class="form-text text-muted">
                  Enter the Zip COde
                </small>
              </Col>
            </Row> */}
            <Row className="pt-2">
              <Col xs="12" className="pl-4">
                <Tooltipbutton title="Save Billing address">
                <Button onClick={onSubmit} variant="contained" color="primary">
                  Save
                </Button>
                </Tooltipbutton>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

Register.propTypes = {
  updatebillingaddress: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  isAuthenticated: state.payment.updatedcustomer
});
export default connect(
  mapStateToProps,
  { updatebillingaddress }
)(Register);

import React, {  useEffect } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader} from 'reactstrap';

import {connect} from "react-redux"
import {CALLCAMPLOGS} from "../../../Redux/Actions/USER/user"
import {useParams} from "react-router-dom"
const Dial = ({voices, CALLCAMPLOGS})=>{
    const {id} = useParams()
    useEffect(()=>{
        CALLCAMPLOGS(id)
      }, [])
     
     return  <Row className="justify-content-center">
        <Col xs="12" sm="12">
          <Card>
              <CardHeader>
                  <CardTitle>Total numbers of calls {voices && voices.length && voices.length }</CardTitle>
              </CardHeader>
            <CardBody>
            <Table responsive bordered striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Record Link</th>
                  <th>Status</th>
                  <th>Charge</th>
                  <th>Direction</th>
                  <th>Duration</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {voices && voices.length &&
                  voices.map((item, index) => (
                    <tr key={item._id}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.call.from}</td>
                      <td style={{maxWidth:"16ch",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}} >{item.call.to}</td>
                      <td>
                        {item.call.RecordingUrl ? (
                          <div
                            className="btn btn-info btn-sm"
                            onClick={() => {
                              window.open(item.call.RecordingUrl);
                            }}
                          >
                            Click
                          </div>
                        ) : null}
                      </td>
                      <td>{item.call.status.charAt(0).toUpperCase()+item.call.status.slice(1)}</td>
                      <td>{item.call.charges != undefined ? `$${item.call.charges}` : 0}</td>
                      <td>
                        {item.call.direction == 'outbound-dial' ? (
                          <div className="btn btn-danger btn-sm ">Outbound</div>
                        ) : (
                          <div className="btn btn-success btn-sm ">{item.call.direction && item.call.direction.charAt(0).toUpperCase()+item.call.direction.slice(1)}</div>
                        )}
                      </td>
                      <td>{item.call.duration ? item.call.duration + 'sec' : null}</td>
                      <td style={{whiteSpace:"nowrap"}}  >
                        
                            {item.call.createdAt.slice(0, 10)},
                            {item.call.createdAt.slice(11, 19)}
                          
                        
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
             </CardBody>
          </Card>
        </Col>
      </Row>
    
      
}

const mapStateToProps = state => ({
    voices: state.bulk.CALLCAMPLOGS,
});
export default connect(
  mapStateToProps,
  {CALLCAMPLOGS}
)(Dial);

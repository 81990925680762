import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, Input as IMP, Input } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { availablenumber, buynumber } from '../../../Redux/Actions/Twilio';
import { FaFax, FaVideo } from 'react-icons/fa';
import { AiFillPhone, AiFillMessage } from 'react-icons/ai';
import Modal from '../../../components/Modal/Modal';
import { States } from './data';
import Pagination from 'react-js-pagination';

//table
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#edf2f9',
    // color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const Available = ({availablenumber, buynumber, available, count}) => {
  const [data, setdata] = useState([]);
  const [num, setnum] = useState('');
  const [prefix, setprefix] = useState('');
  const [offset, setoffset] = useState(0);
  const [activePage, setactivePage] = useState(0);

  const setarea = e => {
    setnum(e.target.value);
  };
  const setPrefix = e => {
    setprefix(e.target.value);
  };
 const getnumber = () => {
    availablenumber(num, prefix, offset);
  };
 const buyNumber = number => {
    buynumber(number);
  };
  const handlePageChange = pageNumber => {
    let off = pageNumber * 10 - 10;
    setactivePage(pageNumber);
    console.log({ pageNumber });

    console.log({ off });

    availablenumber(num, prefix, pageNumber);
  };
       const classes = useStyles();
console.log({count})
  return (
    <div>
      <Card>
        <CardBody>
          <Row className="p-1">
            <Col md="5" xs="6" >
              <Label>State</Label>
              <IMP style={{ fontSize: '0.95em' }} type="select" id="exampleSelect" onChange={(e)=>setarea(e)} name="num">
                <option value={num}>Select State</option>
                {States.map(item => (
                  <option key={item.abbreviation} value={item.abbreviation}>
                    {item.name}
                  </option>
                ))}{' '}
              </IMP>
            </Col>
            <Col md="4" xs="6" >
              <Label>Area Code</Label>
              <Input
                style={{ fontSize: '0.95em' }}
                placeholder="Enter Prefix of Number"
                maxLength={3}
                onChange={(e)=>setPrefix(e)}
                name="prefix"
                value={prefix}
              />
            </Col>
            <Col md="3" xs="4" className="pr-8 pt-1" >
              <br />
              <Button color="primary" size="sm" style={{float:"right"}} onClick={getnumber}>
                Search
              </Button>
              {/* <Button onClick={this.getnumber} disabled={!this.state.num}>Search</Button> */}
            </Col>
          </Row>
          {available != null && available.length > 0 ? (
            <div className="mt-1">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Number</StyledTableCell>
                    <StyledTableCell>Area</StyledTableCell>
                    <StyledTableCell>Country</StyledTableCell>
                    <StyledTableCell>Services</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {available.map(item => (
                    <StyledTableRow key={item.phoneNumber}>
                      <StyledTableCell>{item.phoneNumber}</StyledTableCell>
                      <StyledTableCell>
                        {item.locality} {item.locality ? "," : " "} {item.region}
                      </StyledTableCell>
                      <StyledTableCell>{item.isoCountry}</StyledTableCell>
                      <StyledTableCell>
                        <Row>
                          {item.capabilities.SMS ? (
                            <Col xs="2">
                              {' '}
                              <span>
                                <AiFillMessage size="1rem" />
                              </span>
                            </Col>
                          ) : null}

                          {item.capabilities.MMS ? (
                            <Col xs="2">
                              <span>
                                <FaVideo size="1rem" />
                              </span>
                            </Col>
                          ) : null}

                          {item.capabilities.fax ? (
                            <Col xs="2">
                              <span>
                                <FaFax size="1rem" />
                              </span>
                            </Col>
                          ) : null}
                          {item.capabilities.voice ? (
                            <Col xs="2">
                              <span>
                                <AiFillPhone size="1rem" />
                              </span>
                            </Col>
                          ) : null}
                        </Row>
                      </StyledTableCell>
                      <StyledTableCell>$3/month</StyledTableCell>
                      <StyledTableCell>
                        <Modal buttonLabel="BUY" number={item.phoneNumber} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Row className="mt-4" >
            <Col xs="12" >
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={count}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          /></Col>
          </Row> </div>
          ) : available != null && available.length === 0 ? (
            <Card className="text-center">
            <CardBody className="p-5">
              <div className="display-1 text-200 fs-error">404</div>
              <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
                The number you're looking for is not found.
              </p>
              <hr />
              <p>
                Make sure to use correct area code or try different area code.
                
              </p>
              
            </CardBody>
          </Card>
          ) : null}
          
        </CardBody>
      </Card>
    </div>
  );
};
const mapStateToProps = state => ({
  available: state.Twilio.available,
  count: state.Twilio.avai
});
export default connect(
  mapStateToProps,
  { availablenumber, buynumber }
)(Available);

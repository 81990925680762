import React, { useState , useEffect} from 'react'
import {Row, Col, Card, CardBody, CardHeader, CardTitle, Input, Label} from 'reactstrap'
import {connect} from 'react-redux'
import  {sendfax, getnumbers} from "../../../Redux/Actions/Twilio"
import {setAlert} from '../../../Redux/Actions/alert/alert'
import PropTypes from 'prop-types';
import GetCOmpany from "../../../components/GetCompany/getCompany"

import {Button} from "@material-ui/core"
import { toast } from 'react-toastify'
const qualities = [
    {
        name: "Fine",
        value: "fine"
    },
     {
        name: "Standard",
        value: "standard"
    },
    {
        name: "Superfine",
        value: "superfine"
    }
]
const expression =  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.pdf/ig
const SendFax = ({sendfax, getnumbers,numbers, setAlert})=> {
    const [to, setTo] = useState("")
    const [err, setErr] = useState(false)
    const [body, setbod] = useState("")
    const [num, setfrom]= useState("")
    const [quality, setquality] = useState("")
  
   

  useEffect(()=>{
       const comp = localStorage.getItem("comp")
        if(comp)
        {
            getnumbers(comp)
        }
        else {
                getnumbers()
        }
     
     
 
 }, [getnumbers])
 const submit =()=>{
     if(num=="" || to=="" || !body.match(expression) )
     {
         setErr(true)
         toast.error("Please enter valid details")
     }
     else
     {  
         
        setErr(false)
        sendfax(num , body, to, quality )
        setfrom("")
        setbod("")
        setTo("")
        setquality("")
        
        
     }
   
 }
   return <Row className="justify-content-center ">
              <Col md="10">
                  <Card>
                      <CardHeader>
                      <CardTitle>
                        <Row>
                          <Col md="3">
                            <p>Select Company</p>
                          </Col>
                          <Col md="8">
                            <GetCOmpany></GetCOmpany>
                          </Col>
                        </Row>
                      </CardTitle>
                    </CardHeader>
                    <Row>
                        <Col md="12" className="px-4 pt-2">
                            <Label>From Number</Label>
                                <Input  style={{ fontSize: "0.95em"}} type="select" id="exampleSelect" onChange={e=>setfrom(e.target.value)} name="num">
                                       <option value="">Select From Number</option>
                                   {numbers.map(item=>(
                                      <option key={item._id}  value={item.number}>{item.name ? item.name: item.number}</option>
                                   ))}
                                </Input>
                                <small id="emailHelp" class="form-text text-muted">Your fax will be placed by this number</small>
                                <small id="emailHelp" class="form-text text-muted">{ num != null && num != "" && `Fax id will be ${num}`}</small> 
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="px-4 pt-2">
                            <Label>To Number</Label>
                                <Input style={{ fontSize: "0.95em"}} placeholder="Select To number" type="number" onChange={e=>setTo(e.target.value)} name="to" value={to}></Input>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="px-4 pt-2">
                               <Label>Pdf Url</Label>
                                <Input style={{ fontSize: "0.95em"}} placeholder="Add PDF public url"  type="text" onChange={e=>setbod(e.target.value)} name="body" value={body}></Input>
                                <small id="emailHelp" class="form-text text-muted">Pdf URL should be public</small> 
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="px-4 pt-2">
                            <Label>Fax Quality</Label>
                            <Input style={{ fontSize: "0.95em"}}  type="select" id="exampleSelect" onChange={e=>setquality(e.target.value)} name="quality">
                                       <option value="">Select Fax Quality</option>
                                   {qualities.map(item=>(
                                      <option key={item.name} selected={quality}  value={item.value}>{item.name}</option>
                                   ))}
                            </Input>
                            
                        </Col>
                    </Row>
                   
                    <Row className="p-4">
                        <Col md="12">
                            <Button onClick={submit} variant="contained" color="primary">Send Fax</Button>
                        </Col>
                    </Row>
                </Card>
                    
              </Col>
          </Row>
     
  
  
}
SendFax.propTypes = {
    sendfax: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    getnumbers: PropTypes.func.isRequired
  };
const mapStateToProps = (state) => ({
numbers: state.Twilio.numbers

  });
export default connect(mapStateToProps, {sendfax,getnumbers, setAlert})(SendFax)
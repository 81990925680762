import {
  GET_COMPANY,
  GET_REPORT,
  GET_FLOWS,
  GET_DNC_LIST_ALL,
  GET_FORM_WEBHOOK,
  GET_USER_FORM,
  GET_TOKEN,
  GET_DETAIL,
  USER_MESSAGES,
  NUMBER_BOUGHT,
  GET_ALL_COMPANY,
  GET_ALL_FAX,
  GET_NUMBER,
  GET_FILTERNO,
  USER_VOICE,
  GET_AVAILABLE,
  GET_COUNTAVAI,
  SEND_MESSAGE,
  SEND_FAX,
  SENT_OTP,
  COUNT_VOICES,
  COUNT_MESSAGES,
  COUNT_NOTIFICATION,
  VERIFY_OTP,
  GET_USER_STATUS,
  GET_USER,
  GET_FLOW,
  OPEN_DIALOG,
  CLOSE_DIALOG
} from '../Actions/type';
import { GET_SEARCH, TIME_ZONE } from './../Actions/type';

const initialState = {
  companies: null,
  token: '',
  available: null,
  countavai: null,
  sended: false,
  Faxsended: false,
  otpsend: false,
  verify: false,
  numbers: [],
  filteredNumbers: [],
  detail: null,
  messages: null,
  voices: null,
  fax: '',
  hookform: null,
  userform: null,
  company: null,
  toggle: false,
  voicecount: 0,
  messagecount: 0,
  notificationcount: 0,
  getflow: null,
  getflows: null,
  report: null,
  searchData: null,
  user: {},
  dncdata: null,
  dnccount: 0,
  timezone: 'America/New_York',
  dialogOpen: false,
  outCallDialogData: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TIME_ZONE:
      return {
        ...state,
        timezone: payload
      };
    case GET_REPORT:
      return {
        ...state,
        report: payload
      };
    case GET_FLOWS:
      return {
        ...state,
        getflows: payload,
        available: null
      };
    case GET_SEARCH:
      return {
        ...state,
        voices: payload
      };
    case GET_FLOW:
      return {
        ...state,
        getflow: payload,
        available: null
      };
    case COUNT_VOICES:
      return {
        ...state,
        voicecount: payload,
        available: null
      };
    case COUNT_MESSAGES:
      return {
        ...state,
        messagecount: payload,
        available: null
      };
    case COUNT_NOTIFICATION:
      return {
        ...state,
        notificationcount: payload,
        available: null
      };
    case GET_COMPANY:
      return {
        ...state,
        company: payload,
        available: null
      };
    case GET_USER_STATUS:
      return {
        ...state,
        toggle: payload,
        available: null
      };
    case GET_USER:
      return {
        ...state,
        user: payload,
        available: null
      };
    case GET_USER_FORM:
      return {
        ...state,
        userform: payload,
        available: null
      };
    case NUMBER_BOUGHT:
      return {
        ...state,
        available: null
      };
    case SENT_OTP:
      return {
        ...state,
        otpsend: true
      };
    case SEND_FAX:
      return {
        ...state,
        Faxsended: true,
        available: null
      };
    case GET_TOKEN:
      return {
        ...state,
        token: payload,
        available: null
      };
    case VERIFY_OTP:
      return {
        ...state,
        verify: true,
        available: null
      };
    case SEND_MESSAGE:
      return {
        ...state,
        sended: true,
        available: null
      };
    case GET_AVAILABLE:
      return {
        ...state,
        available: payload
      };
    case GET_COUNTAVAI:
      return {
        ...state,
        avai: payload
      };
    case GET_NUMBER:
      return {
        ...state,
        numbers: payload,
        filteredNumbers: payload
      };
    case GET_FILTERNO:
      return {
        ...state,
        filteredNumbers: payload
      };
    case GET_DETAIL:
      return {
        ...state,
        detail: payload
      };
    case USER_MESSAGES:
      return {
        ...state,
        messages: payload,
        available: null
      };
    case USER_VOICE:
      return {
        ...state,
        voices: payload,
        available: null
      };
    case GET_ALL_COMPANY:
      return {
        ...state,
        companies: payload
      };
    case GET_ALL_FAX:
      return {
        ...state,
        fax: payload,
        available: null
      };
    case GET_FORM_WEBHOOK:
      return {
        ...state,
        hookform: payload
      };
    case GET_DNC_LIST_ALL:
      return {
        ...state,
        dncdata: payload.data,
        dnccount: payload.count
      };
    case OPEN_DIALOG:
      return { ...state, dialogOpen: true, outCallDialogData: payload };
    case CLOSE_DIALOG:
      return { ...state, dialogOpen: false };
    default:
      return state;
  }
}

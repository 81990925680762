import React,{useEffect,useState} from 'react'
import { Row, Col, Input, Label, Card, CardBody, CardHeader, CardTitle, Button } from "reactstrap"
import { ReactFormBuilder } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import { getFormhook, saveFormhook, getuserStatus, postuserStatus } from "../../../Redux/Actions/USER/user"

import { connect } from "react-redux"
// import {Button} from "@material-ui/core"

import { toast } from 'react-toastify';
import WidgetsSectionTitle from '../../../WidgetsSectionTitle/WidgetSectionTitle';
import '../../../scss/application.scss';


const UserSetting =({getFormhook,saveFormhook,getuserStatus,postuserStatus,formm,toggle})=>{

  const [state, setState] = useState({
    form:'',
    note:'',
    crm:'',
    checked:false
  });
  
useEffect(() => {
 getuserStatus()
 getFormhook()
 setState(s=>({ ...s,form: formm }))
}, []);

useEffect(() => {
  console.log("formm",formm)
  setState(s=>({...s, form:formm?.webhook,note:formm?.note, crm:formm?.crm,checked:toggle }))

}, [formm,toggle]);


const onChange = (e) => {
  console.log({e,target:e.target,name:e.target.name,val:e.target.value})
  e.persist()
  setState(s=>({...s,
    [e.target.name]: e.target.value
  }))
}
const handleChange = event => {
  postuserStatus(event.target.checked)
  // this.setState({ checked: event.target.checked })
  // setState(event.target.checked)
}
const submit = () => {

  const { form, note, crm } = state
  
  if (form === null || form == "" || form==undefined) {

    toast.error("Form webhook is required")

  }
  else {
    saveFormhook(form, note, crm)
  }
}
  return (
    <div>
      <Row>
        <Col md={12} className={"pb-4"}>
          <WidgetsSectionTitle
            className="my-2"
            title="Webhook Setting"
            icon="folder-plus"
            subtitle="The data will be send to these webhooks"
          />
          <Card>

            <CardBody>
              <Row className="justify-content-center p-2">
                <Col md="12">
                  <Label>Form Webhook</Label>
                  <Input style={{ fontSize: "0.95em" }} type="text" placeholder="Enter Form Webhook" name="form" value={state.form||""} onChange={(e)=>onChange(e)}></Input>
                  <small id="emailHelp" className="form-text text-muted">The data will sent to this webhook once cold calling form is filled</small>
                </Col>
              </Row>
              <Row className="justify-content-center p-2">
                <Col md="12">
                  <Label>Note Webhook</Label>
                  <Input style={{ fontSize: "0.95em" }} type="text" placeholder="Enter Note Webhook" name="note" value={state.note||""} onChange={(e)=>onChange(e)}></Input>
                  <small id="emailHelp" className="form-text text-muted">The notes will sent to this webhook once the notes are filled while receiving incoming call</small>
                </Col>
              </Row>
              <Row className="justify-content-center p-2">
                <Col md="12">
                  <Label>CRM  Webhook</Label>
                  <Input style={{ fontSize: "0.95em" }} type="text" placeholder="Enter CRM Webhook" name="crm" value={state.crm||""} onChange={(e)=>onChange(e)}></Input>
                  <small id="emailHelp" className="form-text text-muted">The data will sent to this webhook once the push to CRM button is clicked from conversation</small>
                </Col>

              </Row>
              <Row className="justify-content-center px-2 pt-2">
                <Col md="12">
                  <Button color="success" onClick={()=>submit()}>Save</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>


        </Col>
      </Row>
      <Row className="pt-4">

        <Col xs="12" >
          <WidgetsSectionTitle
            className="my-2"
            title="Form builder"
            icon="minus"
            subtitle=''

          />
          <ReactFormBuilder
            url={`${process.env.REACT_APP_PORT}/api/me/data?email=${localStorage.getItem("email")}`}
            saveUrl={`${process.env.REACT_APP_PORT}/api/me/data?email=${localStorage.getItem("email")}`}
            toolbarItems={[{
              key: 'Header',
            }, {
              key: 'TextInput',
            }, {
              key: 'TextArea',
            }, {
              key: 'RadioButtons',
            }, {
              key: 'Checkboxes',
            },
            {
              key: 'Paragraph',
            },
            {
              key: 'LineBreak',
            },
            {
              key: 'Dropdown',
            },
            {
              key: 'Tags',
            },
            {
              key: 'NumberInput',
            },
            {
              key: 'TwoColumnRow',

            }, {
              key: 'ThreeColumnRow',
            }, {
              key: 'FourColumnRow',
            },
            {
              key: 'Rating',
            }, {
              key: 'DatePicker',
            },
            {
              key: 'Range',
            }]}
          />
        </Col>

      </Row>
    </div>
  )
}

const mapStateToProps = (state) => ({
  formm: state.Twilio.hookform,
  toggle: state.Twilio.toggle

});
export default connect(mapStateToProps, { getFormhook, saveFormhook, getuserStatus, postuserStatus })(UserSetting)
import React, { useState } from 'react';
import { Row, Col, Input, Label, Button,Card, CardBody,CardHeader, CardTitle, } from 'reactstrap';
import { CardContent} from "@material-ui/core"
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";


const ContactUs=()=>{
  let history = useHistory();
  const [firstname, setfirstname] = useState('');
  const [email, setemail] = useState('');
  const [phoneno, setphoneno] = useState('');
  const [query, setquery] = useState('');
  const handleSubmit = async ()=>{
      console.log({firstname,email,phoneno,query})
      if (firstname == ''  || email == '' || phoneno == '' || query == '') {
        toast.error('All fields are required');
      } else {
        
        try {
          const res = await Axios({
            method: 'POST',
            url: process.env.REACT_APP_PORT + `/api/me/query`,
            data: { Source:"Mergecall",firstname, email, phoneno, query },
            headers: {
              'x-auth-token': localStorage.getItem('token')
            }
          });
          console.log('datttaaa', res.data);
          toast.success("Thanks. We will get back to you shortly!")
          history.push("/");
          
          
          
  
        } catch (e) {
          toast.error(e?.response?.data?.error);
        }
      }

  }
  return (
    <div className="container">
      <Card style={{width:'55vw',color: "#424242"}} className="container">
          <CardHeader>
          <CardTitle>
              <Label>
              <img className="mr-2" style={{marginBottom:"36px"}} src={"/logo128.png"} alt="Logo" width={"60px"} />
        <span style={{fontSize:"2.95rem",color:"#2c7be5"}}>ergeCall</span>
              </Label>
              <p>We'd love to hear from you.</p>
              <p>Please fill in the form below so that we can get more insights of what you are looking for and we can take things from there!</p>
              
              
              <Card>
            
            <CardBody className="pt-1">
              <Row>
                <Col xs="6">
                  <Label style={{fontSize:"1rem",color:"#2c7be5"}}>Canada Office</Label>
                  <p>325 Front St W, Toronto, ON M5V 2Y1, Canada</p>
                </Col>
                <Col xs="6">
                  <Label style={{fontSize:"1rem",color:"#2c7be5"}}>US Office</Label>
                  <p>2035 Sunset Lake Rd SUITE B2, Newark, DE 19702, United States</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
              {/* <p>325 Front St W, Toronto, ON M5V 2Y1, Canada</p>
              <p>© 2021 mergecall.com, Inc C Corp, Newark, DE, United States</p> */}
          </CardTitle>
          </CardHeader>
          
    <CardBody>
          
      <Row className="p-2">
        <Col >
          <Label>First Name</Label>
          <Input
            type="text"
            onChange={({ target }) => setfirstname(target.value)}
            placeholder="firstname"
            value={firstname}
          />
        </Col>
      </Row>

      <Row className="p-2">
        <Col>
          <Label>Email</Label>
          <Input type="email" onChange={({ target }) => setemail(target.value)} placeholder="Email" value={email} />
        </Col>
      </Row>
      <Row className="p-2">
        <Col>
          <Label>Phone number</Label>
          <Input
            type="text"
            onChange={({ target }) => setphoneno(target.value)}
            placeholder="Phone number"
            value={phoneno}
          />
        </Col>
      </Row>

      <Row className="p-2">
        <Col >
          <Label>Your query</Label>
          <Input
            type="textarea"
            onChange={({ target }) => setquery(target.value)}
            placeholder="Query"
            value={query}
          />
        </Col>
      </Row>
      <Row className='p-2 justify-content-center'>
        <Col md={{ size: 3 }}>
          <Button  color="primary" block className="mt-3" onClick={handleSubmit}>
           Submit
          </Button>
        </Col>
      </Row>
      </CardBody>
      <div></div>
      <Row className=' justify-content-center' >
        <Col md={{ size: 8 }}>
          
        <p style={{textAlign: "center",fontSize: "1.5rem",color:"#2c7be5"}}>Support <i class="material-icons">phone</i> +1 415-417-0410</p>
          <p  style={{textAlign: "center"}}>© 2022 mergecall.com, Inc C Corp</p>
          <p  style={{textAlign: "center"}}>325 Front St W, Toronto, ON M5V 2Y1, Canada</p>
          <p  style={{textAlign: "center"}}>2035 Sunset Lake Rd SUITE B2, Newark, DE 19702, United States</p>
        </Col>
      </Row>
      {/* <p className="container">Support <i class="material-icons">phone</i> +1 415-417-0410</p> */}
      </Card>
    </div>
  );
}

export default ContactUs;

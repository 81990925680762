import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Badge,
  Button as BUTTONS,
  Input as IMP,
  Form,Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBeforeunload } from 'react-beforeunload';
import { Button } from '@material-ui/core';
import { Device } from '@twilio/voice-sdk';
// import { Device } from '@twilio/voice-sdk';
import { connect } from 'react-redux';
import { gettoken } from '../../../Redux/Actions/Twilio';
import PropTypes from 'prop-types';

import Loader from '../../../components/common/Loader';
import 'react-phone-input-2/lib/style.css';
import { callmonitor, createSession } from '../../../Redux/Actions/USER/user';
import { toast } from 'react-toastify';
import UserForm from '../Home/Form';

import GetCOmpany from '../../../components/GetCompany/getCompany';
import {
  getContacts,
  getMultidialerContacts,
  getCampaigns,
  getcountcontact,
  updatestatus,
  callstatus,
  pullnew,
  refreshtoken,
  getContactss
} from '../../../Redux/Actions/Mask/Mask';
import io from 'socket.io-client';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { socket } from '../Main/socket';
import Switch from '@material-ui/core/Switch';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';
import UserVoice from '../User/Voice';
import { useHistory } from 'react-router-dom';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import axios from 'axios';
import { SyncStreamContext } from 'twilio/lib/rest/sync/v1/service/syncStream';
const Dial = ({
  Token,
  gettoken,
  campaigns,
  getcountcontact,
  countcontact,
  getCampaigns,
  refreshtoken,
  numbers,
  getContacts,
  contact,
  updatestatus,
  callmonitor,
  callstatus,
  createSession,
  pullnew,
  getMultidialerContacts,
  getContactss,
  handleswitch
}) => {
  const [dis, setdis] = useState(true);
  const [from, setfrom] = useState(null);
  const [offset, setoffset] = useState(0);
  const [campaign, setcampaign] = useState(null);
  const [back, setback] = useState(false);
  const [phone, setphone] = useState([]);
  const [callconnect, setcallconnect] = useState(false);
  const [diss, setdiss] = useState(false);
  const [oflimit, setLimit] = useState(1);
  const [session, setsession] = useState(false);
  const [search, setSearch] = useState('');
  const [count, setcount] = useState(0);
  var tcheck=false;
  const [value, setValue] = useState('');
  const [check, setcheck] = useState(false);
  const [device, setDevice] = useState(null);
  //toggle button
  const [state, setState] = useState({
    checkedB: false
  });
  useEffect(() => {
    const a = numbers.map(item => {
      if (item.activate) {
        return item.number;
      }
    });
  
    setfrom(a[0]);
  }, [numbers]);
  // console.log({ state });
  let history = useHistory();
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  //....
  const changestatus = () => {
    setcallconnect(false);
  };
  const handleTabClose = event => {
    event.preventDefault();

    console.log('beforeunload event triggered');
   return (event.returnValue = 'Are you sure you want to exit?');

  };
  const selectcampaign = e => {
    console.log("e is",{ target: e?.target, value: e?.target?.value });
    const value = e?.target?.value;
    
    if (check) {
      if (value) {
        localStorage.removeItem('check');
        setState(s => {
          console.log("s is",{ s });
          if (s.checkedB) {
            let limit = localStorage.getItem('limit');
            setcampaign(c => {
              getMultidialerContacts(c, limit);
              return c;
            });
          } else {
            console.log("count is",{ count });
            getContactss(value, count, oflimit,'tost');
            setoffset(count)
            console.log("test")
          }
          return s;
        });
        
        
        callmonitor(value);setcampaign(value);
      } else {
        setcampaign(null);
      }
    } else {
      console.log("else")
      if (e != '' && oflimit != "") {
        localStorage.removeItem('check');
        setState(s => {
          if (s.checkedB) {
            let limit = localStorage.getItem('limit');
            setcampaign(c => {
              getMultidialerContacts(c, limit);
              return c;
            });
          } else {
            console.log("count is",{ count });  
             getContactss(e, count, oflimit,'tost');
             setoffset(count)
          }
          return s;
        });
        
        callmonitor(e);setcampaign(e);
      } else {
        setcampaign(null);
      }
    }
  };
  useEffect(() => {
    if(campaign&&campaign!=""){
    getContactss(campaign, count, oflimit,'tost');
    setoffset(count)}
  }, [count])
  useEffect(() => {
    if (window.location.href.includes('call')) {
      setcheck(true);
    }
    if (window.location.href.includes('campaigns')) {
      setcheck(false);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('limit', oflimit);
  }, [oflimit]);
  useEffect(() => {
    getCampaigns();

    if (check) {

    } else {
      const a = window.location.href.split('/');
      selectcampaign(a[5]);
    }

    socket.on('nextcontact', data => {
      // console.log("socket enters")
      //  Device.disconnectAll();
       device.disconnectAll()
        setdis(true);
      
      
      setState(s => {
        if (s.checkedB) {
          setfrom(f => {
            setcampaign(c => {
              setLimit(l => {
                setdiss(d => {
                  submit(true, f, c, l, d);
                  return d;
                });

                return l;
              });

              return c;
            });
            return f;
          });
        }
        else{
          
        }
        return s;
      });
    });

    socket.on('statusofcall', data => {
      // console.log("data checked", data)
      let offsets = localStorage.getItem('offset');
      let limit = localStorage.getItem('limit');
      setState(s => {
        if (!s.checkedB) {
          pullnew(data, limit)
        } else { 

          pullnew(data, limit)
          // setcampaign(c => {
            
          //   getMultidialerContacts(c, limit);
          //   return c;
          // });
        }
        return s;
      });
    });


    if (Token == '') {
      gettoken();
    } else {
      fetchdata();
    }
    async function fetchdata() {
      var device1 = new Device(Token);
      setDevice(device1)
      // Device.setup(Token);
      // Device.on('ready', function(device) {});
      // Device.on('error', function(error) {
      //   toast.error(error.message);
      // });
    }
  }, [Token, gettoken, getCampaigns]);
  useEffect(() => {
    if (!check) {
      getCampaigns();
      const a = window.location.href.split('/');
      selectcampaign(a[5]);
    }
  }, [oflimit]);
  
  // useEffect(() => {
  //   if (campaign != null) {
  //     refresh();
  //   }
  // }, [campaign]);
  
   useEffect(() => {
    // setInterval(() => {
    //   setNum(s => {
    //     console.log({s});
    //     return s + 1;
    //   });
    // }, 1000);
    // const discon = Device.on('disconnect', async function(connection) {
    
    //   setdis(true);
      

    //   return () => {
    //     Device.removeListener('disconnect', discon);
    //   };
    //   // if(state.checkedB){

    //   //     submit(true)

    //   // }
    //   // getcountcontact()
    // });

    // Device.on('connect', function(device) {
    //   setdis(false);
    // });
  }, []);
  useEffect(()=>{

  }, [getMultidialerContacts])
  const remove = id => {
    updatestatus(id, offset, oflimit);
  };
  const update = id => {
    callstatus(id, offset, oflimit);
  };

  const [num, setNum] = useState(0);
  const fn = () => {
    // console.log({ num });
    setNum(s => s + 1);
  };
  window.fnnnn = fn;
 

  const submit = async (check, overwrideFrom, overwridecampaign, overwridelimit, forcedisconnect) => {
    const res = await axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + `/api/payment/checkbal`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      
    });
    if(res.data.data){
    if (check) {
      if (forcedisconnect) {
        toast.error('Autocall ended');
        return;
      }
      setdiss(false);
      navigator.permissions.query({ name: 'microphone' }).then(async function(permissionstatus) {
        if (permissionstatus.state === 'granted') {
          const finalFrom = overwrideFrom || from;
          if (finalFrom == null) {
            toast.error('Please  Select Dialer ID');
          } else {

      const id = overwridecampaign || campaign;
      const finallimit = overwridelimit || oflimit;
            const conta_ct = (await getMultidialerContacts(id, finallimit)) || [];
            // console.log("working phone",conta_ct )
            let phone = [];
            if(conta_ct.length > 0)
            {
              const item = conta_ct.filter(item => {
                const dd = item.phone.startsWith('+');
                if (item.show == true && item.status != 'completed' && item.count < 2) {
                  phone.push(item.phone);
                }
              });
            }
            phone.push(id);
            phone.push("auto")
            if (phone.length === 2) {
              toast.error('No Number remaining to call ');
              if(device){
                device.disconnectAll();
              }
              // Device.disconnectAll();
            } else {
              // 
              // const call=contact.find(item =>
              //   item.phoneno===phoneno[0]
              // )
              // console.log(call.status,"status")

              // console.log({phoneno})

              const data = {
                params:{
                  To: phone,
                  From: finalFrom,
                  FromNum:finalFrom
                }
            };
              // const connection = Device.connect(data);
              let connection=await device.connect(data);
              // console.log("connection",connection )
   
              toast.success('Calling...');
              setdis(false);
              setcallconnect(true);

              connection.on('disconnect', function(conn) {
                // console.log("the call has ended");
                setdis(true);
      

                return () => {
                  device.removeListener('disconnect');
                };
              });
            }
          }
        } else {
          toast.error('Micro-Phone is disabled please enabled it');
        }
      });
    } 
    
    
    else {
      navigator.permissions.query({ name: 'microphone' }).then(async function(permissionstatus) {
        if (permissionstatus.state === 'granted') {
          if (from == null) {
            toast.error('Please  Select Dialer ID');
          } else {
            const id = campaign;
            const finalFrom = overwrideFrom || from;
            let phone = [];
            const conta_ct = await getContactss(id, offset, oflimit,'tost') 
            if(conta_ct.length > 0)
            {
              const item = conta_ct.filter(item => {
                const dd = item.phone.startsWith('+');
                if (item.show == true && item.dnc != true &&item.status != 'completed') {
                  phone.push(item.phone);
                }
              });
            }
            phone.push(id);
            phone.push("auto")
            if (phone.length === 2) {
              toast.error('No Number remaining to call ');
            } else {
              const data = {
                params:{
                  To: phone,
                  From: from,
                  FromNum:from
                }
            };
              // const connection = Device.connect(data);
              let connection=await device.connect(data);

              toast.success('Calling...');
              setdis(false);
              setcallconnect(true);

              connection.on('disconnect', function(conn) {
                // console.log("the call has ended");
                setdis(true);
      

                return () => {
                  device.removeListener('disconnect');
                };
              });
            }
          }
        } else {
          toast.error('Micro-Phone is disabled please enabled it');
        }
      });
    }
    if (session === false) {
      createSession(campaign);
    }
    setsession(true);}
    else{
      toast.error('Please check Funds');
    }
  };
  useBeforeunload((event) => {
    if (value !== '') {
      pause()
      // event.preventDefault();
    }
  });
  const refresh = () => {
    setoffset(0);
    // setcount(0);
    localStorage.setItem('offset', 0);
    refreshtoken(campaign, offset, oflimit, true);
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    let data={offset:0,email:localStorage.getItem('email'),camp:campaign}
    axios.post(process.env.REACT_APP_PORT + '/api/campaign/savepause', data, head);
  };
  
  const pause = () => {
    console.log("pause")
    // console.log(campaign)
    setValue("")
    // window.removeEventListener('beforeunload', handleTabClose);
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    let data={offset:offset,email:localStorage.getItem('email'),camp:campaign}
    axios.post(process.env.REACT_APP_PORT + '/api/campaign/savepause', data, head);
    console.log("🚀 ~ file: newmultilineCall.js ~ line 465 ~ pause ~ data", data)
  };
  
  const resume = () => {
    setValue("")
    // tcheck=false
    // window.removeEventListener('beforeunload', handleTabClose);
    // window.addEventListener('beforeunload', handleTabClose)
    getContactss(campaign, count, oflimit,'tost');
    setoffset(count);
    
  };
 useEffect(() => {
  const head = {
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  };
  let data={email:localStorage.getItem('email'),camp:campaign}
  const re=async ()=>await axios.post(process.env.REACT_APP_PORT + '/api/campaign/getresume', data, head)
  .then((res)=>{if(res.data.data!="Not Found") {
    // console.log("in useeffect ",res.data.data);
    setcount(res.data.data);
    // console.log("setcount",count)
  }});
  re()
  console.log("contact",contact)
 }, [contact])
 
  
  const next = () => {
    // window.addEventListener('beforeunload', handleTabClose)
    // axios.post(process.env.REACT_APP_PORT + '/api/campaign/getresume', data, head).then((res)=>{console.log("in useeffect ",res.data.data)});

    let limit = countcontact - +offset;
    
    if (limit <= oflimit) {
      // console.log("inside if")
      
      getContactss(campaign, 0, oflimit, true);
      localStorage.setItem('check', true);

      localStorage.setItem('offset', 0);
      pause();
      setoffset(0);
    } else if (offset <= countcontact) {
      let of = +offset + +oflimit;
      // console.log("last ",of, oflimit);
      
      getContactss(campaign, of, oflimit,'tost');

      localStorage.setItem('offset', of);
      pause();
      setoffset(of);

      // console.log("Of is ",of)
      if(of>count){
        console.log("inside if")
        // tcheck=true
        // window.addEventListener('beforeunload', handleTabClose)
        setValue("cd")
      }
    }
    
    console.log("limit",limit," offset ",offset,"oflimit ",oflimit)
  };
  // const previous = () => {
  //   let limit = countcontact - +offset;
  //   console.log("limit",limit," offset ",offset,"oflimit ",oflimit)
  //   if (limit <= oflimit) {
  //     // console.log("inside if")
      
  //     getContactss(campaign, 0, oflimit, true);
  //     localStorage.setItem('check', true);

  //     localStorage.setItem('offset', 0);
  //     setoffset(0);
  //   } else if (offset <= countcontact) {
  //     let of = +offset - +oflimit;
  //     // console.log("last ",of, oflimit);
      
  //     getContactss(campaign, of, oflimit,'tost');

  //     localStorage.setItem('offset', of);
  //     setoffset(of);
  //   }
  // };


  const disconnect = check => {
    if (check) {
      // console.log("disconnected call why")
      // Device.disconnectAll();
      device.disconnectAll()
      setdiss(true);
    } else {
      setdis(true);
      device.disconnectAll()
      // Device.disconnectAll();
    }

    //  await getContacts(campaign,0)
  };
// console.log({contact})
  if (Token !== '') {
    return (
      <div>
        <Row>
          <Col xl={7} className="col-xxl-8 pl-xl-2 mb-3 mb-lg-0">
            <Card className="p-2">
              <Row>
                <Col xs={6}>
                  <div>
                    <Tooltipbutton title="Place call manually">
                      <span style={{ fontSize: '16px', fontWeight: 500 }}>Place call</span>
                    </Tooltipbutton>
                    <Switch
                      checked={state.checkedB}
                      onChange={handleChange}
                      color="primary"
                      name="checkedB"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <Tooltipbutton title="places call one after the call end untill all number are called ">
                      <span style={{ fontSize: '16px', fontWeight: 500 }}>Auto place call</span>
                    </Tooltipbutton>
                  </div>
                </Col>
                <Col xs={6}>
                  {check ? (
                    // <Button
                    //   variant="contained"
                    //   color="secondary"
                    //   style={{ float: 'right', whiteSpace: 'nowrap' }}
                    //   onClick={() => {
                    //     window.location.reload();
                    //   }}
                    // >
                    //   Back to Single Line dialer
                    // </Button>
                    <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 500 }}>
                      <Tooltipbutton title="Place call manually">
                        <span style={{ marginLeft: '30px', fontSize: '16px', paddingTop: '5px', fontWeight: 500 }}>
                          Dialer
                        </span>
                      </Tooltipbutton>
                      <Switch
                        checked={check}
                        onChange={() => {
                          window.location.href = '/pages/call';
                        }}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <Tooltipbutton title="Places call one after the another automatically">
                        <span style={{ marginRight: '30px', fontSize: '16px', paddingTop: '5px', fontWeight: 500 }}>
                          Multidialer
                        </span>
                      </Tooltipbutton>
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ float: 'right', whiteSpace: 'nowrap' }}
                      onClick={() => {
                        history.push('/pages/campaigns');
                      }}
                    >
                      Back to the list
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
            <Card className="mt-3 p-2" style={{ backgroundColor: '#f9fafd' }}>
              <Row>
                <Col xs={6}>
                  <Label>Select Company</Label>
                  <GetCOmpany />
                </Col>
                <Col xs={6}>
                  <Label>From Number</Label>
                  <IMP
                    style={{ fontSize: '0.95em' }}
                    type="select"
                    id="exampleSelect"
                    bsSize="sm"
                    onChange={e => setfrom(e.target.value)}
                    name="from"
                  >
                    {/* <option value="">Select Dialer Id</option> */}
                    {numbers != undefined&&numbers&& numbers[0] && numbers[0].hasOwnProperty("number")&&<option selected={numbers[0].number} value={numbers[0].number}>
                        {numbers[0].name ? numbers[0].name : numbers[0].number}
                      </option>}
            
            {numbers != undefined&&numbers&&numbers.slice(1).map(item => {
              if (item.activate) {
                return (
                  <option key={item._id} value={item.number} >
                        {item.name ? item.name : item.number}
                      </option>
                );
              }
            })}
                  </IMP>

                  <small id="emailHelp" class="form-text text-muted">
                    Your call will be placed using the browserphone
                    <br />
                    {from != null && from != '' && `Caller id Will be ${from}`}
                  </small>
                </Col>
              </Row>
              {check ? (
                <Row>
                  <Col xs="6">
                    <Label>Limit</Label>
                    <IMP
                      type="select"
                      value={oflimit}
                      bsSize="sm"
                      onChange={e => {
                        
                        if (e.target.value < 1&&e.target.value!="") {
                          setLimit(1);
                          if( campaign) {
                              
                              getContactss(campaign, 1, e.target.value,'tost');
                              // console.log("test")
                            }
                        } else {
                          
                          if(e.target.value!="")
                          {
                            getContactss(campaign, offset, e.target.value,'tost');
                            console.log("🚀 ~ file: newmultilineCall.js ~ line 687 ~ offset", offset)
                          }setLimit(e.target.value);
                        }

                        // localStorage.setItem('limit', e.target.value);
                      }}
                    > <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    </IMP>
                    <small id="emailHelp" class="form-text text-muted">
                      number of leads you want to call at same time
                    </small>
                  </Col>
                  <Col xs="6">
                    <Label>Campaign</Label>
                    <IMP
                      style={{ fontSize: '0.95em' }}
                      type="select"
                      id="exampleSelect"
                      bsSize="sm"
                      onChange={selectcampaign}
                    >
                      <option value="">Select Campaign</option>
                      {campaigns.map(item => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </IMP>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs="12">
                    <Label>Limit</Label>
                    <IMP
                      type="select"
                      value={oflimit}
                      bsSize="sm"
                     onChange={e => {
                        
                          if (e.target.value < 1&&e.target.value!="") {
                            setLimit(1);
                          if( campaign) {
                              
                              getContactss(campaign, 1, e.target.value,'tost');
                              // console.log("test")
                            }
                          } else {
                          //   setLimit(e.target.value);
                          // if(e.target.value!="")
                          // {
                          //   getContactss(campaign, 0, e.target.value,'tost');
                          // }
                          if(e.target.value!="")
                          {
                            getContactss(campaign, offset, e.target.value,'tost');
                            console.log("🚀 ~ file: newmultilineCall.js ~ line 748 ~ offset", offset)
                          }setLimit(e.target.value);
                          }
  
                          // localStorage.setItem('limit', e.target.value);
                        }}
                    > <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    </IMP>
                    <small id="emailHelp" class="form-text text-muted">
                      number of leads you want to call at same time
                    </small>
                  </Col>
                </Row>
              )}

              <Row className={'justify-content-center mt-2'}>
                {state.checkedB ? (
                  <Col className={'justify-content-center'}>
                    {dis && campaign != null ? (
                      <BUTTONS
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={() => submit(state.checkedB)}
                        size="small"
                        color="success"
                      >
                        Place Auto Call
                      </BUTTONS>
                    ) : !dis && campaign != null ? (
                      <Button
                        onClick={() => disconnect(state.checkedB)}
                        variant="contained"
                        size="small"
                        color="secondary"
                      >
                        End Auto Call
                      </Button>
                    ) : null}
                  </Col>
                ) : (
                  <Col>
                    {dis && contact.length > 0 && campaign != null ? (
                      <BUTTONS
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={() => submit(state.checkedB)}
                        size="small"
                        color="success"
                      >
                        Place Call
                      </BUTTONS>
                    ) : !dis && contact.length > 0 && campaign != null ? (
                      <Button
                        onClick={() => disconnect(state.checkedB)}
                        variant="contained"
                        size="small"
                        color="secondary"
                      >
                        End Call
                      </Button>
                    ) : null}
                  </Col>
                )}
              </Row>
            </Card>
          </Col>

          <Col xl={5} className="col-xxl-4 pr-xl-2">
          {campaign &&<Card className="p-2">
              <Row >
              {campaign && <Col md="4" className="my-1">
                  <Tooltipbutton title="Reset the current session">
                    <Button onClick={refresh} variant="contained" color="primary">
                    {/* <i class="fa fa-retweet fa-2x" aria-hidden="true"></i> */}
                    Reset
                    </Button>
                  </Tooltipbutton>
                </Col>}
                {campaign && <Col md="4" className="my-1">
                <Tooltipbutton title="Pause the current session">
                    <Button onClick={pause} variant="contained" color="primary">
                      {/* <i class="fa fa-pause fa-2x" aria-hidden="true"></i> */}
                      Pause
                    </Button>
                  </Tooltipbutton>
                </Col>}
                {campaign && <Col md="4" className="my-1">
                {/* <Tooltipbutton title="Resume the previous session">
                    <Button onClick={resume} variant="contained" color="primary">
                   
                    Resume
                    </Button>
                  </Tooltipbutton> */}
                </Col>}
                {campaign && <Col md="4" className=" my-1">
                  <Link className="btn btn-success btn-md "  to={`/multilinedialer/logs/${campaign}`}>
                    Logs
                  </Link>
                </Col>}
                {campaign && <Col md="4" className="my-1">
                  <Tooltipbutton title="view all call session">
                    <Link className="btn btn-success btn-md" to={`/multilinedialer/session/${campaign}`}>
                      Session
                    </Link>
                  </Tooltipbutton>
                </Col>}
                
              </Row>
            </Card>}
            <Card className="mt-3 p-2 " style={{ backgroundColor: '#f9fafd' }}>
              {campaign && (
                <div>
                  {' '}
                  <Row>
                    {contact?.map(item => (
                      <Col md="12" className="p-1 pl-3 pr-4" key={item?._id}>
                        <Row>
                          <Col md="3">
                            <Label>{item?.first_name}</Label>
                          </Col>
                          <Col md="4" className="pl-0">
                            <Label style={{ whiteSpace: 'nowrap' }}>{item?.phone}</Label>
                          </Col>
                          <Col md="2" className="pl-0">
                            <span>
                              {item?.show == true ? (
                                <Button color="secondary" onClick={() => remove(item?._id)}>
                                  <CheckIcon />
                                </Button>
                              ) : null}
                              {item?.show === false ? (
                                <Button color="primary" onClick={() => update(item?._id)}>
                                  <CloseIcon />
                                </Button>
                              ) : null}
                            </span>
                          </Col>
                          <Col md="3" className="pl-0">
                            <span> 
                              {item.dnc == true ?  <Badge color="danger">{'DNC'}</Badge>: item?.status ? (
                                item?.status == 'completed' ||
                                item?.status == 'queued' ||
                                item?.status == 'initiated' ||
                                item?.status == 'ringing' ||
                                item?.status == 'in-progress' ? (
                                  <Badge color="success">{item?.status}</Badge>
                                ) : item?.status == 'no-answer' ||
                                  item?.status == 'failed' ||
                                  item?.status == 'busy' ||
                                  item?.status == 'canceled' ? (
                                  <Badge color="danger">{item?.status}</Badge>
                                ) : ( 
                                  item?.status
                                )
                              ) : null}  
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                  <Row className="p-2 pr-4 ">
                      {/* <Col xs="4"> */}
                      {/* {contact.length > 0 &&<Button onClick={previous} className="mx-1" variant="contained" color="primary">
                            Previous
                          </Button> } */}
                        {contact.length > 0 &&<Button onClick={next} className="mx-1" variant="contained" color="primary">
                            Next
                          </Button> }
                          
                      {/* </Col> */}
                    </Row>
                
                </div>
              )}
            </Card>
          </Col>
        </Row>
        {callconnect == true ? (
          <Row>
          <Col xs={5} className="col-xxl-8 pr-xl-2 mb-2 mt-2" >
            <Card className="mt-2" style={{ width: '80%' }}>
              <CardHeader className="pb-0">
                <Label>Form</Label>
              </CardHeader>
              <CardBody className="pt-1" style={{ backgroundColor: '#f9fafd' }}>
                <UserForm changeStatus={changestatus} multi={true} />
              </CardBody>
            </Card>
          </Col>
          <Col xs={7} className="col-xxl-8 pr-xl-2 mb-2 mt-2" style={{ height: '276px', overflow: 'auto' }}>
          <Card className="mt-3 p-2 " style={{ backgroundColor: '#f9fafd' }}>
                {campaign && (
                  <div>
                    {' '}
                    <Row>
                    <Col md="4">
                              <Label>Name</Label>
                            </Col>
                            <Col md="5" className="pl-0">
                              <Label >Address</Label>
                            </Col>
                            <Col md="3" className="pl-0">
                            <Label >Email</Label>
                            </Col>
                      {contact?.map(item => (
                        <Col md="12" className="p-1 pl-3 pr-4" key={item?._id}>
                          <Row>
                            <Col md="4">
                              <Label>{item?.first_name} {item?.last_name}</Label>
                            </Col>
                            <Col md="5" className="pl-0">
                              <Label >{item?.address} {item?.street} {item?.city} {item?.state}</Label>
                            </Col>
                            <Col md="3" className="pl-0">
                            <Label >{item?.email}</Label>
                            </Col>
                            
                          </Row>
                        </Col>
                      ))}
                    </Row>               
                  </div>
                )}
              </Card>
          </Col>
        </Row>
        ) : null}
        <Row className="mt-3">
          <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
            <Card>
              <Background image={corner2} className="bg-card" />
              <Row className="pl-3 pr-3 pt-3 pb-1">
                <Col>
                  <Label>Call logs</Label>
                </Col>
                <Col className="mb-2">
                  <div   className="search-box" style={{ float: 'Right' }}>
                    <IMP
                        type="text"
                        placeholder="Search..."
                        aria-label="Search"
                        className="rounded-pill search-input"
                        value={search}
                       
                        onChange={(e) => {
                          setSearch(e.target.value)
                        }}
                      />
                      <FontAwesomeIcon  icon="search" className="position-absolute text-400 search-box-icon" />
                    
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
            <UserVoice search={search} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Loader />;
  }
};
Dial.propTypes = {
  gettoken: PropTypes.func.isRequired,

  Token: PropTypes.string.isRequired,
  updatestatus: PropTypes.string.isRequired
};
const mapStateToProps = state => ({
  Token: state.Twilio.token,
  numbers: state.Twilio.numbers,
  contact: state.bulk.contact,
  countcontact: state.bulk.countcontact,
  campaigns: state.bulk.campaigns
});
export default connect(
  mapStateToProps,
  {
    gettoken,
    updatestatus,
    getCampaigns,
    callstatus,
    getContacts,
    refreshtoken,
    getcountcontact,
    callmonitor,
    createSession,
    getMultidialerContacts,
    pullnew,
    getContactss
  }
)(Dial);
import React, { useState, useEffect } from 'react';
import { connect,useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getCategories,
  scheduler,
  getscheduler,
  updatescheduler,
  pausescheduler,
  startscheduler,
  cancellcheduler,
  resetSchedulerCount
} from '../../../Redux/Actions/Mask/Mask';
import { Row, Col, Button, Input, Label, Card, CardBody } from 'reactstrap';

import { toast } from 'react-toastify';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';

import { socket } from '../Main/socket';
var moment = require('moment-timezone');
const SendMessage = ({
  cancellcheduler,
  categories,
  getCategories,
  pausescheduler,
  startscheduler,
  getscheduler,
  scheduler,
  updatescheduler,
  scheduledata,
  count,
  resetSchedulerCount
}) => {
  let { id } = useParams();
  console.log('shedular id', id);
  const [selectedcategory, setCategory] = useState('');
  const [lead, setlead] = useState(100);
  const [start, setstart] = useState();
  const [end, setend] = useState();
  const [interval, setinterval] = useState(30);
  const [intervalH, setintervalH] = useState(0);
  const [intervalM, setintervalM] = useState(0);
  const [scount, setsCount] = useState(0);
  const currtz=useSelector(state=>state.Twilio.timezone)
  console.log(start);
  useEffect(() => {
    getCategories();
    getscheduler(id);
    if (scheduledata != null) {
      setCategory(scheduledata.category);
      setstart(scheduledata.start);
      setend(scheduledata.end);
      setinterval(scheduledata.interval);
      setlead(scheduledata.leadspr);
    }
    if (count == 0) {
      toast.success('No prospect available ! Completed');
    }
  }, [getCategories, scheduledata != null]);

  // useEffect(() => {
  //   if(scheduledata){
  //     setstart(new Date(scheduledata?.start));
  //     setend(new Date(scheduledata?.end));
  //   }

  // }, [scheduledata])
  const save = () => {
    let new_date = start;
    let new_enddate = end;
    
    console.log("My Log"+JSON.stringify(new_date)+JSON.stringify(new_enddate));
    try {
      // new_date = new Date(start).toUTCString();
      // new_enddate = new Date(end).toUTCString();
      new_date=moment(start).tz(currtz).format()
      new_enddate=moment(end).tz(currtz).format()
    } catch (error) {
      console.error(error);
    }
    if (new Date(end) > new Date(start)) {
      let email=localStorage.getItem('email');
      if (scheduledata != null) {
        updatescheduler({ selectedcategory, new_date, new_enddate, interval, lead }, scheduledata._id);
      } else {
        scheduler({ selectedcategory, new_date, new_enddate, interval, id, lead,email });
      }
    } else {
      toast.error('Please select END time greater than START time');
    }

    // setstart({new_date})
    // setend({new_enddate})
    // console.log({start});
  };
  const startsch = id => {
    resetSchedulerCount(scheduledata.campaign);
    startscheduler(id);
  };
  const pause = id => {
    pausescheduler(id);
  };
  const cancelled = id => {
    cancellcheduler(id);
  };

  //   const timezones = ct.getAllTimezones();
  // console.log(timezones);

  // const timezone = ct.getCountryForTimezone('Asia/Tokyo');
  // console.log("timezone",timezone );
  useEffect(() => {
    getscheduler(id);
    socket.on('sendschedulermessage', (i, total, camp) => {
      console.log('why not running', camp, id);
      if (camp == id) {
        console.log(i + 'aur' + total);
        setsCount(i);
      }
    });
  }, [scount]);
  return (
    <Row className="justify-content-center">
      <Col md={12} className="p-2">
        <Card>
          <CardBody>
            <Row>
              <Col md={12}>
              <p className="small">The schedular is working for {currtz} timezone</p>
                
                {scheduledata != null && scheduledata.status == 'running' && (
                  <p className="small">Schedular is scheduled for {moment(scheduledata.start).tz(currtz).format("LLL")}</p>
                )}
                
                              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {scheduledata != null && scheduledata.status == 'running' && (
                  <Label>
                    Send Count:{' '}
                    <b>
                      {scount}/{count}
                    </b>
                  </Label>
                )}
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={12}>
                <Label>Select Category</Label>
                <Input
                  type="select"
                  bsSize="sm"
                  onChange={e => {
                    setCategory(e.target.value);
                  }}
                >
                  <option value="">Select Category</option>
                  {categories.map(item => (
                    <option selected={item._id == selectedcategory} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={12}>
                <Label>Start Time</Label>
                <Input
                  type="datetime-local"
                  value={start}
                  bsSize="sm"
                  placeholder="10"
                  onChange={e => {
                    setstart(e.target.value);
                    console.log("start is "+start);
                    console.log("moment time is "+moment().tz(currtz).format().substring(0, 16))
                    
                  }}
                />
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={12}>
                <Label>End Time</Label>
                <Input
                  type="datetime-local"
                  value={end}
                  min={start}
                  placeholder="11"
                  bsSize="sm"
                  onChange={e => {
                    setend(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row className="p-2">
              <Col md={12}>
                <Label>No of leads to processed</Label>
                <Input
                  type="Number"
                  value={lead}
                  placeholder="100"
                  bsSize="sm"
                  onChange={e => {
                    setlead(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row className="p-2">
              <Col md={12}>
                <Label>Interval</Label>
                <Row xs="1" sm="2" md="4">
                  <Col>
                    <span>Hours:</span>
                    <Input
                      type="number"
                      bsSize="sm"
                      min="0"
                      step="1"
                      value={intervalH}
                      onChange={e => setintervalH(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <span>Minutes:</span>
                    <Input
                      type="number"
                      bsSize="sm"
                      min="0"
                      step="1"
                      value={intervalM}
                      onChange={e => setintervalM(e.target.value)}
                    />
                  </Col>
                </Row>
                <p style={{ fontSize: '11px', marginTop: '6px' }}>Time interval between the batches</p>
              </Col>
            </Row>
            <Row className="p-1">
              {scheduledata != null && scheduledata.status == 'running' && (
                <>
                  <Button color="danger" onClick={() => pause(scheduledata._id)}>
                    Pause
                  </Button>
                  <Button color="danger" className="mx-2" onClick={() => cancelled(scheduledata._id)}>
                    Cancel
                  </Button>
                </>
              )}
              {scheduledata != null && scheduledata.status != 'running' && (
                <Button color="success" onClick={() => startsch(scheduledata._id)}>
                  Start
                </Button>
              )}
              <Tooltipbutton title="Save to schedule message">
                <Button color="primary" className="mx-2" onClick={save}>
                  Save
                </Button>
              </Tooltipbutton>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
const mapeStateToProps = state => ({
  count: state.bulk.countProspect,
  categories: state.bulk.categories,
  scheduledata: state.bulk.scheduledata
});

export default connect(
  mapeStateToProps,
  {
    getCategories,
    pausescheduler,
    cancellcheduler,
    startscheduler,
    scheduler,
    getscheduler,
    updatescheduler,
    resetSchedulerCount
  }
)(SendMessage);

import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { getfund, savefund } from '../../../Redux/Actions/Payment';
import { useHistory } from "react-router-dom";

import Loader from '../../../components/common/Loader';

import { toast } from 'react-toastify';
import Label from 'reactstrap/lib/Label';
const FundDetail = ({ getfund, fund, savefund}) => {
  const [amount, setam] = useState(0);
  const [description, sedesc] = useState('');
  let history = useHistory();
  useEffect(() => {
    getfund();
  }, [getfund]);
  const submit =async () => {
    if (description == '') {
      toast.error('Description Cannot be Empty!');
    //   toggle();
    } else {
      const data = await savefund({ amount, description });
      if(data == false)
      {
      
        history.push("/getcompany");
      }
      else {
        
      history.push("/home/card");
      }
    //   toggle();
    }
  };
  if (fund === null) {
    return <Loader />;
  }
  return (
    <Row className="justify-content-center">
      <Col md="12">
          
        <Row className="pt-2">
          <Col md="12">
            <Input
            bsSize="sm"
              style={{ fontSize: '0.95em' }}
              type="number"
              placeholder="Amount($)"
              onChange={e => {
                setam(e.target.value);
              }}
            />
            <small id="emailHelp" class="form-text text-muted">
              Enter the amount
            </small>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col md="12">
            <Input
            bsSize="sm"
              style={{ fontSize: '0.95em' }}
              type="text"
              placeholder="Description"
              onChange={e => {
                sedesc(e.target.value);
              }}
            />
            <small id="emailHelp" class="form-text text-muted">
              Enter the Description
            </small>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col>
            <Button color="primary" size="sm" onClick={submit}>
              Add Fund
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
const mapeStateToProps = state => ({
  fund: state.payment.fund
});
export default connect(
  mapeStateToProps,
  { getfund, savefund }
)(FundDetail);

import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import DialpadIcon from '@material-ui/icons/Dialpad';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { TextFields } from '@material-ui/icons';
import { toast } from 'react-toastify';
import './content.css';
import { object } from 'joi';
import SubFlow_map from './SubFlow_map';
import { useMemo } from 'react';

import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import { uploadfile } from '../../../Redux/Actions/Mask/Mask';
import Popup from './popup';
const Content_map = ({ fields, setfields, setSubFlowMapComponent, remove_Flow }) => {
  const [selected, setselected] = useState();

  const containerRef = useRef();
  console.log('check', containerRef.current);
  useEffect(() => {
    console.log('ref', selected, containerRef.current);
    if (selected && containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selected, containerRef]);
  const onchange = (i, e) => {
    const values = [...fields];

    if (e.target.value < 0) {
      values[i].value = 0;
    } else if (e.target.value > 9) {
      values[i.value] = 9;
    } else {
      values[i].value = e.target.value;
    }
    setfields(values);

    // setset(true);
  };
  const text = (i, e) => {
    const values = [...fields];
    if (values[i].check) {
    } else {
      values[i].text = e.target.value;
      setfields(values);
    }
  };
  const play = (i, e) => {
    const values = [...fields];
    values[i].play = e.target.value;
    setfields(values);
  };

  const forward = (i, e) => {
    const values = [...fields];
    values[i].forward = e.target.value;
    setfields(values);
  };

  const onAddOptions = () => {
    const values = [...fields];
    if (Object.keys(values[values.length - 1])[0] == 'noans') {
      if (Object.keys(values[values.length - 2]).length > 1) {
        if (values[values.length - 2]['value']) {
          let a = parseInt(values[values.length - 2].value) + 1;
          values.push({ value: `${a}` });
        }
      } else {
        toast.error('please select options on the right');
      }
    } else {
      if (Object.keys(values[values.length - 1]).length > 1) {
        if (values[values.length - 1]['value']) {
          let a = parseInt(values[values.length - 1].value) + 1;
          values.push({ value: `${a}` });
        }
      } else {
        toast.error('please select options on the right');
      }
    }

    setfields(values);
  };
  const onAddNoAns = e => {
    var temp = {};
    if (e === 'hangup') {
      temp[e] = true;
    }
    if (e === 'play') {
      temp = {
        play: '',
        txt: false
      };
    } else {
      temp[e] = '';
    }

    const values = [...fields];

    let index = values.findIndex(e => e.noans);
    console.log({ index });
    if (index != -1) {
      values[index].noans[0] = temp;
      console.log('existfields', values);
      setfields(values);
    } else {
      values.push({ noans: [temp] });

      console.log(values);
      setfields(values);
    }
  };

  const subFlow = (e, index) => {
    //   let {v}=e.target
    var temp = {};
    if (e === 'hangup') {
      temp[e] = true;
    } else if (e === 'play') {
      temp = {
        play: '',
        txt: false
      };
    } else {
      temp[e] = '';
    }

    const values = [...fields];
    // debugger
    if (values[index].flow) {
      if (e === 'value') {
        if (values[index]?.flow[values[index]?.flow.length - 1]?.noans) {
          if (values[index]?.flow[values[index]?.flow.length - 2]?.value === '' && e === 'value') {
            toast.error('Please select a action before adding another menu');
          } else {
            values[index].flow.push(temp);
            console.log('existfields', values);
            setfields(values);
          }
        } else if (values[index]?.flow[values[index]?.flow.length - 1]?.value === '' && e === 'value') {
          toast.error('Please select a action before adding another menu');
        } else {
          values[index].flow.push(temp);
          console.log('existfields', values);
          setfields(values);
        }
      } else {
        let a = values[index]?.flow[values[index]?.flow.length - 1];
        if (Object.keys(a)[0] != 'value') {
          toast.error('Please select Menu before adding any thing');
        } else {
          values[index].flow.push(temp);
          console.log('existfields', values);
          setfields(values);
        }
      }
    } else if (e === 'value') {
      values[index]['flow'] = [];
      values[index].flow.push(temp);
      console.log(values);
      setfields(values);
    } else {
      toast.error('Please select Menu before adding any thing');
    }
  };
  const onNoanstextChange = (index, ind, e) => {
    const values = [...fields];
    values[index].noans[ind].text = e.target.value;
    setfields(values);
  };
  const onNoansplayChange = (index, ind, e) => {
    const values = [...fields];
    values[index].noans[ind].play = e.target.value;
    setfields(values);
  };
  const onNoansforwardChange = (index, ind, e) => {
    const values = [...fields];
    values[index].noans[ind].forward = e.target.value;
    setfields(values);
  };
  const onNoansvalueChange = (index, ind, e) => {
    const values = [...fields];
    values[index].noans[ind].value = e.target.value;
    setfields(values);
  };
  const onAddNoAnsCall = (i, e) => {
    var temp = {};
    if (e === 'hangup') {
      temp[e] = true;
    } else {
      temp[e] = '';
    }
    const values = [...fields];
    if (values[i].noans_menue) {
      values[i].noans_menue[0] = temp;
      console.log('existfields', values);
      setfields(values);
    } else {
      values[i]['noans_menue'] = [];
      values[i].noans_menue.push(temp);
      console.log(values);
      setfields(values);
    }
    // values[i].forward = e;
    // setfields(values);
  };

  const onCallNoansText = (i, e) => {
    const values = [...fields];

    values[selected].noans_menue[i].text = e.target.value;
    setfields(values);
  };
  const onCallNoansPlay = (i, e) => {
    const values = [...fields];

    values[selected].noans_menue[i].play = e.target.value;
    setfields(values);
  };
  const onCallNoansForward = (i, e) => {
    const values = [...fields];

    values[selected].noans_menue[i].forward = e.target.value;
    setfields(values);
  };
  const onCallNoansValue = (i, e) => {
    const values = [...fields];

    values[selected].noans_menue[i].value = e.target.value;
    setfields(values);
  };
  const removeFlow = j => {
    const values = [...fields];
    values.splice(j, 1);
    setfields(values);
    const i = values.findIndex(
      item =>
        // console.log(item)
        // console.log(Object.keys(item))
        Object.keys(item)[0] === 'noans'
    );
    setselected();
    if (i != -1 && values.length == 2) {
      values.splice(i, 1);
    }
    // if (key === 'value') {

    //   values.splice(j, 1);
    //   setfields(values);
    // } else if (key === 'text') {

    //   values.splice(j, 1);
    //   setfields(values);
    // }
    // else if (fields) {

    //   delete values[j][key];

    //   setfields(values);
    // }
  };
  const onNoAnsCall = (i, e) => {
    var temp = {};
    if (e === 'hangup') {
      temp[e] = true;
    } else {
      temp[e] = '';
    }

    const values = [...fields];
    if (values[i].noans_menue) {
      values[i].noans_menue[0] = temp;
      console.log('existfields', values);
      setfields(values);
    } else {
      values[i]['noans_menue'] = [];
      values[i].noans_menue.push(temp);
      console.log(values);
      setfields(values);
    }
  };
  console.log({ fields });
  const [popup1, setpopup1] = useState(false);

  const subFlowMapComponent = useMemo(() => {
    return selected ? (
      <div ref={containerRef}>
        <Col xs="11">
          <Card>
            <CardHeader
              title={
                <div>
                  {Object.keys(fields[selected])[1] === 'forward' && (
                    <p
                      style={{
                        fontSize: '1rem',
                        backgroundColor: 'RoyalBlue',

                        textAlign: 'center',
                        fontWeight: 'Bold',
                        color: 'white',
                        padding: '12px'
                      }}
                    >
                      Call Forward
                    </p>
                  )}
                  {Object.keys(fields[selected])[1] === 'play' && (
                    <p
                      style={{
                        fontSize: '1rem',
                        backgroundColor: 'RoyalBlue',

                        textAlign: 'center',
                        fontWeight: 'Bold',
                        color: 'white',
                        padding: '12px'
                      }}
                    >
                      Voice Mail
                    </p>
                  )}
                  {fields[selected]['hangup'] && (
                    <p
                      style={{
                        fontSize: '1rem',
                        backgroundColor: 'RoyalBlue',

                        textAlign: 'center',
                        fontWeight: 'Bold',
                        color: 'white',
                        padding: '12px'
                      }}
                    >
                      Hangup call
                    </p>
                  )}
                  {Object.keys(fields[selected])[1] === 'text' && (
                    <p
                      style={{
                        fontSize: '1rem',
                        backgroundColor: 'RoyalBlue',

                        textAlign: 'center',
                        fontWeight: 'Bold',
                        color: 'white',
                        padding: '12px'
                      }}
                    >
                      text
                    </p>
                  )}
                </div>
              }
            />
            <CardContent>
              {Object.keys(fields[selected])[1] === 'forward' && (
                <div>
                  <Label>Forward Calls To</Label>
                  <Input
                    style={{ fontSize: '0.95em' }}
                    type="text"
                    value={fields[selected]['forward'] === true ? '' : fields[selected]['forward']}
                    onChange={e => {
                      forward(selected, e);
                    }}
                    placeholder="Number"
                  />
                  {fields[selected].noans_menue &&
                    fields[selected].noans_menue.map((item, index) => {
                      return (
                        <div>
                          {item.text != undefined ? (
                            <Row>
                              <Col xs={12}>
                                <Card className="mt-2">
                                  <CardHeader
                                    title={
                                      <p
                                        style={{
                                          fontSize: '1rem',
                                          backgroundColor: 'RoyalBlue',

                                          textAlign: 'center',
                                          fontWeight: 'Bold',
                                          color: 'white',
                                          padding: '12px'
                                        }}
                                      >
                                        If no one answer :- Play a message to the caller
                                      </p>
                                    }
                                  />
                                  <CardContent>
                                    <Input
                                      style={{ fontSize: '0.95em' }}
                                      type="textarea"
                                      value={item.text === true ? '' : item.text}
                                      onChange={e => {
                                        onCallNoansText(index, e);
                                      }}
                                      placeholder="text"
                                    />
                                  </CardContent>
                                </Card>
                              </Col>
                            </Row>
                          ) : null}
                          {item.play != undefined ? (
                            <Card className="mt-2">
                              <CardHeader
                                title={
                                  <p
                                    style={{
                                      fontSize: '1rem',
                                      backgroundColor: 'RoyalBlue',

                                      textAlign: 'center',
                                      fontWeight: 'Bold',
                                      color: 'white',
                                      padding: '12px'
                                    }}
                                  >
                                    If no one answer :-Voice Mail
                                  </p>
                                }
                              />
                              <CardContent>
                                <Row>
                                  <Col xs="6">
                                    <Input
                                      style={{ fontSize: '0.95em' }}
                                      type="text"
                                      value={item.play === true ? '' : item.play}
                                      onChange={e => {
                                        onCallNoansPlay(index, e);
                                      }}
                                      placeholder={item.txt ? 'Text' : 'Mp3 url'}
                                    />
                                  </Col>
                                  <Col xs="2">
                                    <Button variant="contained" color="primary" size='small' onClick={() => setpopup1(true)}>
                                      Upload mp3 file
                                    </Button>
                                    <Popup open={popup1} setOpen={setpopup1} onChange={(e)=>onCallNoansPlay(index, e)} />
                                  </Col>
                                  <Col xs="4">
                                    {/* <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => changenoansPlayType(index)}
                                    >
                                      {item.txt ? 'Text' : 'Mp3 url'}
                                    </Button> */}
                                    <Tooltipbutton title="mp3 url">
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          marginRight: '5px',
                                          fontSize: '14px',
                                          paddingTop: '5px'
                                        }}
                                      >
                                        Mp3 url
                                      </span>
                                    </Tooltipbutton>
                                    <Switch
                                      checked={item.txt}
                                      onChange={() => changenoansPlayType(index)}
                                      color="primary"
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Tooltipbutton title="text">
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          marginRight: '30px',
                                          fontSize: '14px',
                                          paddingTop: '5px'
                                        }}
                                      >
                                        Text
                                      </span>
                                    </Tooltipbutton>
                                  </Col>
                                </Row>
                              </CardContent>
                            </Card>
                          ) : null}
                          {item.forward != undefined ? (
                            <Card className="mt-2">
                              <CardHeader
                                title={
                                  <p
                                    style={{
                                      fontSize: '1rem',
                                      backgroundColor: 'RoyalBlue',

                                      textAlign: 'center',
                                      fontWeight: 'Bold',
                                      color: 'white',
                                      padding: '12px'
                                    }}
                                  >
                                    If no one answer :- Dial This is where the phone will ring
                                  </p>
                                }
                              />
                              <CardContent>
                                <Label>Forward Calls To</Label>
                                <Input
                                  style={{ fontSize: '0.95em' }}
                                  type="text"
                                  value={item.forward === true ? '' : item.forward}
                                  onChange={e => {
                                    onCallNoansForward(index, e);
                                  }}
                                  placeholder="Number"
                                />
                              </CardContent>
                            </Card>
                          ) : null}
                          {item.hangup != undefined ? (
                            <Card className="mt-2">
                              <CardHeader
                                title={
                                  <p
                                    style={{
                                      fontSize: '1rem',
                                      backgroundColor: 'RoyalBlue',

                                      textAlign: 'center',
                                      fontWeight: 'Bold',
                                      color: 'white',
                                      padding: '12px'
                                    }}
                                  >
                                    If no one answer :-Hang Up
                                  </p>
                                }
                              />
                              <CardContent>
                                <Button variant="contained" color="secondary">
                                  Hang Up
                                </Button>
                              </CardContent>
                            </Card>
                          ) : null}
                          {item.value != undefined ? (
                            <Card className="mt-2">
                              <CardHeader
                                title={
                                  <p
                                    style={{
                                      fontSize: '1rem',
                                      backgroundColor: 'RoyalBlue',

                                      textAlign: 'center',
                                      fontWeight: 'Bold',
                                      color: 'white',
                                      padding: '12px'
                                    }}
                                  >
                                    If no one answer :- Menu Prompt the caller to select from a menu of options using
                                    the keypad on their phone.
                                  </p>
                                }
                              />
                              <CardContent>
                                <Input
                                  style={{ fontSize: '0.95em' }}
                                  placeholder="1"
                                  type="number"
                                  value={item.value === true ? '' : item.value}
                                  onChange={e => onCallNoansValue(index, e)}
                                  placeholder="Number"
                                  onWheel={e => e.target.blur()}
                                />
                              </CardContent>
                            </Card>
                          ) : null}
                        </div>
                      );
                    })}
                  <Row className="mt-3">
                    <Col md={12}>
                      <div class="dropdown">
                        <span style={{ color: '#2c7be5', display: 'flex', alignItems: 'center' }}>
                          <i class="fas fa-plus-circle fa-2x" /> <Label className="mb-0">&nbsp;If no one answer</Label>
                        </span>
                        <div class="dropdown-content">
                          <Label
                            style={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => onAddNoAnsCall(selected, 'text')}
                          >
                            Text
                          </Label>
                          <Label
                            style={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => onAddNoAnsCall(selected, 'hangup')}
                          >
                            Hangup
                          </Label>
                          {/* <Label
                              style={{ display: 'block', cursor: 'pointer' }}
                              onClick={() => onAddNoAnsCall(selected, 'value')}
                            >
                              Menu
                            </Label> */}
                          <Label
                            style={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => onAddNoAnsCall(selected, 'forward')}
                          >
                            Dial
                          </Label>
                          <Label
                            style={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => onAddNoAnsCall(selected, 'play')}
                          >
                            Voicemail
                          </Label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {Object.keys(fields[selected])[1] === 'play' && (
                <div>
                  <Row>
                    <Col xs="6">
                      <Input
                        style={{ fontSize: '0.95em' }}
                        type="text"
                        value={fields[selected]['play'] === true ? '' : fields[selected]['play']}
                        onChange={e => {
                          play(selected, e);
                        }}
                        placeholder={fields[selected].txt ? 'Text' : 'Mp3 url'}
                      />
                    </Col>
                    <Col xs="2">
                      <Button variant="contained" color="primary" size='small' onClick={() => setpopup1(true)}>
                        Upload mp3 file
                      </Button>
                      <Popup open={popup1} setOpen={setpopup1} onChange={(e)=>play(selected, e)} />
                    </Col>
                    <Col xs="4">
                      {/* <Button variant="contained" color="primary" onClick={() => changeType(selected)}>
                        {fields[selected].txt ? 'Text' : 'Mp3 url'}
                      </Button> */}
                      <Tooltipbutton title="mp3 url">
                        <span style={{ fontWeight: 'bold', marginRight: '5px', fontSize: '14px', paddingTop: '5px' }}>
                          Mp3 url
                        </span>
                      </Tooltipbutton>
                      <Switch
                        checked={fields[selected].txt}
                        onChange={() => changeType(selected)}
                        color="primary"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Tooltipbutton title="text">
                        <span style={{ fontWeight: 'bold', marginRight: '30px', fontSize: '14px', paddingTop: '5px' }}>
                          Text
                        </span>
                      </Tooltipbutton>
                    </Col>
                  </Row>
                </div>
              )}

              {fields[selected]['hangup'] && (
                <Button variant="contained" color="secondary">
                  Hang Up
                </Button>
              )}
              {Object.keys(fields[selected])[1] === 'text' && (
                <div>
                  <Input
                    style={{ fontSize: '0.95em' }}
                    type="textarea"
                    value={fields[selected]['text'] === true ? '' : fields[selected]['text']}
                    onChange={e => {
                      text(selected, e);
                    }}
                    placeholder="text"
                  />
                  {fields[selected].flow ? (
                    <SubFlow_map fields={fields} setfields={setfields} subFlow={subFlow} selected={selected} />
                  ) : null}

                  <Row className="mt-3">
                    <Col md={12}>
                      <div class="dropdown">
                        <span style={{ color: '#2c7be5', display: 'flex', alignItems: 'center' }}>
                          <i class="fas fa-plus-circle fa-2x" /> <Label className="mb-0">&nbsp;Add call flow</Label>
                        </span>
                        <div class="dropdown-content">
                          <Label
                            style={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => subFlow('text', selected)}
                          >
                            Text
                          </Label>
                          <Label
                            style={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => subFlow('hangup', selected)}
                          >
                            Hangup
                          </Label>
                          <Label
                            style={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => subFlow('value', selected)}
                          >
                            Menu
                          </Label>
                          <Label
                            style={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => subFlow('forward', selected)}
                          >
                            Dial
                          </Label>
                          <Label
                            style={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => subFlow('play', selected)}
                          >
                            Voicemail
                          </Label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </CardContent>
          </Card>
        </Col>
      </div>
    ) : null;
  }, [fields, selected, setfields,popup1,setpopup1]);

  useEffect(() => {
    setSubFlowMapComponent(subFlowMapComponent);
  }, [subFlowMapComponent, setSubFlowMapComponent]);

  const onCall_Noans_Text = (index, i, e) => {
    const values = [...fields];

    values[index].noans_menue[i].text = e.target.value;
    setfields(values);
  };
  const onCall_Noans_Play = (index, i, e) => {
    const values = [...fields];

    values[index].noans_menue[i].play = e.target.value;
    setfields(values);
  };
  const onCall_Noans_Forward = (index, i, e) => {
    const values = [...fields];

    values[index].noans_menue[i].forward = e.target.value;
    setfields(values);
  };
  const onCall_Noans_Value = (index, i, e) => {
    const values = [...fields];

    values[index].noans_menue[i].value = e.target.value;
    setfields(values);
  };
  const changeType = index => {
    const values = [...fields];

    values[index].txt = !values[index].txt;
    setfields(values);
  };
  const changenoansType = (index, ind) => {
    const values = [...fields];

    values[index].noans[ind].txt = !values[index].noans[ind].txt;
    setfields(values);
  };
  const changenoansPlayType = index => {
    const values = [...fields];

    values[selected].noans_menue[index].txt = !values[selected].noans_menue[index].txt;
    setfields(values);
  };
  
console.log({popup1})
  return (
    <div>
      {fields &&
        fields.map((item, index) => {
          return (
            <div>
              {item.text != undefined && Object.keys(item).length < 2 ? (
                <Row>
                  <Col md={12}>
                    <Card className="p-2 m-2">
                      {/* <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('text', index)} />
                            </div> */}
                      <CardHeader
                        title={
                          <p
                            style={{
                              fontSize: '1rem',
                              backgroundColor: 'RoyalBlue',

                              textAlign: 'center',
                              fontWeight: 'Bold',
                              color: 'white',
                              padding: '12px'
                            }}
                          >
                            Greeting Play a message to the caller
                          </p>
                        }
                      />
                      <CardContent>
                        <Row>
                          <Col xs="10">
                            <Input
                              style={{ fontSize: '0.95em' }}
                              type="textarea"
                              value={item.text === true ? '' : item.text}
                              onChange={e => {
                                text(index, e);
                              }}
                              placeholder="text"
                            />
                          </Col>
                          <Col xs="2">
                            {/* <Button variant="contained" color="primary" onClick={() => remove_Flow(index, 'text')}>
                              Delete&nbsp; <i class="fas fa-trash" />
                            </Button> */}
                            <i
                              class="fas fa-trash"
                              style={{ color: 'blue' }}
                              onClick={() => remove_Flow(index, 'text')}
                            />
                          </Col>
                        </Row>
                      </CardContent>
                      <hr />
                    </Card>{' '}
                  </Col>
                </Row>
              ) : null}
              {item.play != undefined && Object.keys(item).length <= 2 ? (
                <Row className={'pt-2'}>
                  <Col md={12}>
                    <Card className="p-2 m-2">
                      {/* <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('play', index)} />
                            </div> */}
                      <CardHeader
                        title={
                          <p
                            style={{
                              fontSize: '1rem',
                              backgroundColor: 'RoyalBlue',

                              textAlign: 'center',
                              fontWeight: 'Bold',
                              color: 'white',
                              padding: '12px'
                            }}
                          >
                            Voice Mail
                          </p>
                        }
                      />
                      <CardContent>
                        <Row>
                          <Col xs="6">
                            <Input
                              style={{ fontSize: '0.95em' }}
                              type="text"
                              value={item.play === true ? '' : item.play}
                              onChange={e => {
                                play(index, e);
                              }}
                              placeholder={item.txt ? 'Text' : 'Mp3 url'}
                            />
                          </Col>
                          <Col xs="2">
                            <Button variant="contained" color="primary"size='small' onClick={() => setpopup1(true)}>
                              Upload mp3 file
                            </Button>
                            <Popup open={popup1} setOpen={setpopup1} onChange={(e)=>play(index, e)} />
                          </Col>
                          <Col xs="3">
                            <Tooltipbutton title="mp3 url">
                              <span
                                style={{ fontWeight: 'bold', marginRight: '5px', fontSize: '14px', paddingTop: '5px' }}
                              >
                                Mp3 url
                              </span>
                            </Tooltipbutton>
                            <Switch
                              checked={item.txt}
                              onChange={() => changeType(index)}
                              color="primary"
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Tooltipbutton title="text">
                              <span
                                style={{ fontWeight: 'bold', marginRight: '30px', fontSize: '14px', paddingTop: '5px' }}
                              >
                                Text
                              </span>
                            </Tooltipbutton>
                            {/* <Button variant="contained" color="primary" onClick={()=>changeType(index)} >
                               {item.txt?"Text":"Mp3 url"}       
                        </Button> */}
                          </Col>
                          <Col xs="1">
                            <i class="fas fa-trash" style={{ color: 'blue' }} onClick={() => remove_Flow(index)} />
                          </Col>
                        </Row>
                      </CardContent>
                      <hr />
                    </Card>{' '}
                  </Col>
                </Row>
              ) : null}
              {item.forward != undefined && Object.keys(item).length < 2 ? (
                <Row className={'pt-2'}>
                  <Col md={12}>
                    <Card className="p-2 m-2">
                      {/* <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('forward', index)} />
                            </div> */}
                      <CardHeader
                        title={
                          <p
                            style={{
                              fontSize: '1rem',
                              backgroundColor: 'RoyalBlue',

                              textAlign: 'center',
                              fontWeight: 'Bold',
                              color: 'white',
                              padding: '12px'
                            }}
                          >
                            Dial This is where the phone will ring{' '}
                          </p>
                        }
                      />
                      <CardContent>
                        <Label>Forward Calls To</Label>
                        <Row>
                          <Col xs="10">
                            <Input
                              style={{ fontSize: '0.95em' }}
                              type="text"
                              value={item.forward === true ? '' : item.forward}
                              onChange={e => {
                                forward(index, e);
                              }}
                              placeholder="Number"
                            />
                          </Col>
                          <Col xs="2">
                            {/* <Button variant="contained" color="primary" onClick={() => remove_Flow(index)}>
                              Delete&nbsp; <i class="fas fa-trash" />
                            </Button> */}
                            <i class="fas fa-trash" style={{ color: 'blue' }} onClick={() => remove_Flow(index)} />
                          </Col>
                        </Row>
                        {fields[index].noans_menue &&
                          fields[index].noans_menue.map((i, ind) => {
                            return (
                              <div>
                                {i.text != undefined ? (
                                  <Row>
                                    <Col xs={12}>
                                      <Card className="mt-2">
                                        <CardHeader
                                          title={
                                            <p
                                              style={{
                                                fontSize: '1rem',
                                                backgroundColor: 'RoyalBlue',

                                                textAlign: 'center',
                                                fontWeight: 'Bold',
                                                color: 'white',
                                                padding: '12px'
                                              }}
                                            >
                                              If no one answer :- Play a message to the caller
                                            </p>
                                          }
                                        />
                                        <CardContent>
                                          <Input
                                            style={{ fontSize: '0.95em' }}
                                            type="textarea"
                                            value={i.text === true ? '' : i.text}
                                            onChange={e => {
                                              onCall_Noans_Text(index, ind, e);
                                            }}
                                            placeholder="text"
                                          />
                                        </CardContent>
                                      </Card>
                                    </Col>
                                  </Row>
                                ) : null}
                                {i.play != undefined ? (
                                  <Card className="mt-2">
                                    <CardHeader
                                      title={
                                        <p
                                          style={{
                                            fontSize: '1rem',
                                            backgroundColor: 'RoyalBlue',

                                            textAlign: 'center',
                                            fontWeight: 'Bold',
                                            color: 'white',
                                            padding: '12px'
                                          }}
                                        >
                                          If no one answer :-Voice Mail
                                        </p>
                                      }
                                    />
                                    <CardContent>
                                      <Input
                                        style={{ fontSize: '0.95em' }}
                                        type="text"
                                        value={i.play === true ? '' : i.play}
                                        onChange={e => {
                                          onCall_Noans_Play(index, ind, e);
                                        }}
                                        placeholder="mp3 url"
                                      />
                                    </CardContent>
                                  </Card>
                                ) : null}
                                {i.forward != undefined ? (
                                  <Card className="mt-2">
                                    <CardHeader
                                      title={
                                        <p
                                          style={{
                                            fontSize: '1rem',
                                            backgroundColor: 'RoyalBlue',

                                            textAlign: 'center',
                                            fontWeight: 'Bold',
                                            color: 'white',
                                            padding: '12px'
                                          }}
                                        >
                                          If no one answer :- Dial This is where the phone will ring
                                        </p>
                                      }
                                    />
                                    <CardContent>
                                      <Label>Forward Calls To</Label>
                                      <Input
                                        style={{ fontSize: '0.95em' }}
                                        type="text"
                                        value={i.forward === true ? '' : i.forward}
                                        onChange={e => {
                                          onCall_Noans_Forward(index, ind, e);
                                        }}
                                        placeholder="Number"
                                      />
                                    </CardContent>
                                  </Card>
                                ) : null}
                                {i.hangup != undefined ? (
                                  <Card className="mt-2">
                                    <CardHeader
                                      title={
                                        <p
                                          style={{
                                            fontSize: '1rem',
                                            backgroundColor: 'RoyalBlue',

                                            textAlign: 'center',
                                            fontWeight: 'Bold',
                                            color: 'white',
                                            padding: '12px'
                                          }}
                                        >
                                          If no one answer :-Hang Up
                                        </p>
                                      }
                                    />
                                    <CardContent>
                                      <Button variant="contained" color="secondary">
                                        Hang Up
                                      </Button>
                                    </CardContent>
                                  </Card>
                                ) : null}
                                {i.value != undefined ? (
                                  <Card className="mt-2">
                                    <CardHeader
                                      title={
                                        <p
                                          style={{
                                            fontSize: '1rem',
                                            backgroundColor: 'RoyalBlue',

                                            textAlign: 'center',
                                            fontWeight: 'Bold',
                                            color: 'white',
                                            padding: '12px'
                                          }}
                                        >
                                          If no one answer :- Menu Prompt the caller to select from a menu of options
                                          using the keypad on their phone.
                                        </p>
                                      }
                                    />
                                    <CardContent>
                                      <Input
                                        style={{ fontSize: '0.95em' }}
                                        placeholder="1"
                                        type="number"
                                        value={i.value === true ? '' : i.value}
                                        onChange={e => onCall_Noans_Value(index, ind, e)}
                                        placeholder="Number"
                                        onWheel={e => e.target.blur()}
                                      />
                                    </CardContent>
                                  </Card>
                                ) : null}
                              </div>
                            );
                          })}
                        <Row className="mt-3">
                          <Col md={12}>
                            <div class="dropdown">
                              <span style={{ color: '#2c7be5', display: 'flex', alignItems: 'center' }}>
                                <i class="fas fa-plus-circle fa-2x" />{' '}
                                <Label className="mb-0">&nbsp;If no one answer</Label>
                              </span>
                              <div class="dropdown-content">
                                <Label
                                  style={{ display: 'block', cursor: 'pointer' }}
                                  onClick={() => onNoAnsCall(index, 'text')}
                                >
                                  Text
                                </Label>
                                <Label
                                  style={{ display: 'block', cursor: 'pointer' }}
                                  onClick={() => onNoAnsCall(index, 'hangup')}
                                >
                                  Hangup
                                </Label>
                                {/* <Label
                              style={{ display: 'block', cursor: 'pointer' }}
                              onClick={() => onNoAnsCall(index, 'value')}
                            >
                              Menu
                            </Label> */}
                                <Label
                                  style={{ display: 'block', cursor: 'pointer' }}
                                  onClick={() => onNoAnsCall(index, 'forward')}
                                >
                                  Dial
                                </Label>
                                <Label
                                  style={{ display: 'block', cursor: 'pointer' }}
                                  onClick={() => onNoAnsCall(index, 'play')}
                                >
                                  Voicemail
                                </Label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardContent>
                      <hr />
                    </Card>{' '}
                  </Col>
                </Row>
              ) : null}
              {item.forward != undefined && Object.keys(item).length === 2 && Object.keys(item)[1] === 'noans_menue' ? (
                <Row className={'pt-2'}>
                  <Col md={12}>
                    <Card className="p-2 m-2">
                      {/* <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('forward', index)} />
                            </div> */}
                      <CardHeader
                        title={
                          <p
                            style={{
                              fontSize: '1rem',
                              backgroundColor: 'RoyalBlue',

                              textAlign: 'center',
                              fontWeight: 'Bold',
                              color: 'white',
                              padding: '12px'
                            }}
                          >
                            Dial This is where the phone will ring{' '}
                          </p>
                        }
                      />
                      <CardContent>
                        <Label>Forward Calls To</Label>
                        <Row>
                          <Col xs="10">
                            <Input
                              style={{ fontSize: '0.95em' }}
                              type="text"
                              value={item.forward === true ? '' : item.forward}
                              onChange={e => {
                                forward(index, e);
                              }}
                              placeholder="Number"
                            />
                          </Col>
                          <Col xs="2">
                            {/* <Button variant="contained" color="primary" onClick={() => remove_Flow(index)}>
                              Delete&nbsp; <i class="fas fa-trash" />
                            </Button> */}
                            <i class="fas fa-trash" style={{ color: 'blue' }} onClick={() => remove_Flow(index)} />
                          </Col>
                        </Row>
                        {fields[index].noans_menue &&
                          fields[index].noans_menue.map((i, ind) => {
                            return (
                              <div>
                                {i.text != undefined ? (
                                  <Row>
                                    <Col xs={12}>
                                      <Card className="mt-2">
                                        <CardHeader
                                          title={
                                            <p
                                              style={{
                                                fontSize: '1rem',
                                                backgroundColor: 'RoyalBlue',

                                                textAlign: 'center',
                                                fontWeight: 'Bold',
                                                color: 'white',
                                                padding: '12px'
                                              }}
                                            >
                                              If no one answer :- Play a message to the caller
                                            </p>
                                          }
                                        />
                                        <CardContent>
                                          <Input
                                            style={{ fontSize: '0.95em' }}
                                            type="textarea"
                                            value={i.text === true ? '' : i.text}
                                            onChange={e => {
                                              onCall_Noans_Text(index, ind, e);
                                            }}
                                            placeholder="text"
                                          />
                                        </CardContent>
                                      </Card>
                                    </Col>
                                  </Row>
                                ) : null}
                                {i.play != undefined ? (
                                  <Card className="mt-2">
                                    <CardHeader
                                      title={
                                        <p
                                          style={{
                                            fontSize: '1rem',
                                            backgroundColor: 'RoyalBlue',

                                            textAlign: 'center',
                                            fontWeight: 'Bold',
                                            color: 'white',
                                            padding: '12px'
                                          }}
                                        >
                                          If no one answer :-Voice Mail
                                        </p>
                                      }
                                    />
                                    <CardContent>
                                      <Input
                                        style={{ fontSize: '0.95em' }}
                                        type="text"
                                        value={i.play === true ? '' : i.play}
                                        onChange={e => {
                                          onCall_Noans_Play(index, ind, e);
                                        }}
                                        placeholder="mp3 url"
                                      />
                                    </CardContent>
                                  </Card>
                                ) : null}
                                {i.forward != undefined ? (
                                  <Card className="mt-2">
                                    <CardHeader
                                      title={
                                        <p
                                          style={{
                                            fontSize: '1rem',
                                            backgroundColor: 'RoyalBlue',

                                            textAlign: 'center',
                                            fontWeight: 'Bold',
                                            color: 'white',
                                            padding: '12px'
                                          }}
                                        >
                                          If no one answer :- Dial This is where the phone will ring
                                        </p>
                                      }
                                    />
                                    <CardContent>
                                      <Label>Forward Calls To</Label>
                                      <Input
                                        style={{ fontSize: '0.95em' }}
                                        type="text"
                                        value={i.forward === true ? '' : i.forward}
                                        onChange={e => {
                                          onCall_Noans_Forward(index, ind, e);
                                        }}
                                        placeholder="Number"
                                      />
                                    </CardContent>
                                  </Card>
                                ) : null}
                                {i.hangup != undefined ? (
                                  <Card className="mt-2">
                                    <CardHeader
                                      title={
                                        <p
                                          style={{
                                            fontSize: '1rem',
                                            backgroundColor: 'RoyalBlue',

                                            textAlign: 'center',
                                            fontWeight: 'Bold',
                                            color: 'white',
                                            padding: '12px'
                                          }}
                                        >
                                          If no one answer :-Hang Up
                                        </p>
                                      }
                                    />
                                    <CardContent>
                                      <Button variant="contained" color="secondary">
                                        Hang Up
                                      </Button>
                                    </CardContent>
                                  </Card>
                                ) : null}
                                {i.value != undefined ? (
                                  <Card className="mt-2">
                                    <CardHeader
                                      title={
                                        <p
                                          style={{
                                            fontSize: '1rem',
                                            backgroundColor: 'RoyalBlue',

                                            textAlign: 'center',
                                            fontWeight: 'Bold',
                                            color: 'white',
                                            padding: '12px'
                                          }}
                                        >
                                          If no one answer :- Menu Prompt the caller to select from a menu of options
                                          using the keypad on their phone.
                                        </p>
                                      }
                                    />
                                    <CardContent>
                                      <Input
                                        style={{ fontSize: '0.95em' }}
                                        placeholder="1"
                                        type="number"
                                        value={i.value === true ? '' : i.value}
                                        onChange={e => onCall_Noans_Value(index, ind, e)}
                                        placeholder="Number"
                                        onWheel={e => e.target.blur()}
                                      />
                                    </CardContent>
                                  </Card>
                                ) : null}
                              </div>
                            );
                          })}
                        <Row className="mt-3">
                          <Col md={12}>
                            <div class="dropdown">
                              <span style={{ color: '#2c7be5', display: 'flex', alignItems: 'center' }}>
                                <i class="fas fa-plus-circle fa-2x" />{' '}
                                <Label className="mb-0">&nbsp;If no one answer</Label>
                              </span>
                              <div class="dropdown-content">
                                <Label
                                  style={{ display: 'block', cursor: 'pointer' }}
                                  onClick={() => onNoAnsCall(index, 'text')}
                                >
                                  Text
                                </Label>
                                <Label
                                  style={{ display: 'block', cursor: 'pointer' }}
                                  onClick={() => onNoAnsCall(index, 'hangup')}
                                >
                                  Hangup
                                </Label>
                                {/* <Label
                              style={{ display: 'block', cursor: 'pointer' }}
                              onClick={() => onNoAnsCall(index, 'value')}
                            >
                              Menu
                            </Label> */}
                                <Label
                                  style={{ display: 'block', cursor: 'pointer' }}
                                  onClick={() => onNoAnsCall(index, 'forward')}
                                >
                                  Dial
                                </Label>
                                <Label
                                  style={{ display: 'block', cursor: 'pointer' }}
                                  onClick={() => onNoAnsCall(index, 'play')}
                                >
                                  Voicemail
                                </Label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardContent>
                      <hr />
                    </Card>{' '}
                  </Col>
                </Row>
              ) : null}
              {item.hangup && Object.keys(item).length < 2 ? (
                <Row className={'pt-2'}>
                  <Col md={12}>
                    <Card className="p-2 m-2">
                      {/* <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('hangup', index)} />
                            </div> */}
                      <CardHeader
                        title={
                          <p
                            style={{
                              fontSize: '1rem',
                              backgroundColor: 'RoyalBlue',

                              textAlign: 'center',
                              fontWeight: 'Bold',
                              color: 'white',
                              padding: '12px'
                            }}
                          >
                            Hangup Call
                          </p>
                        }
                      />
                      <CardContent>
                        <Row>
                          <Col xs="10">
                            <Button variant="contained" color="secondary">
                              Hang Up
                            </Button>
                          </Col>
                          <Col xs="2">
                            {/* <Button variant="contained" color="primary" onClick={() => remove_Flow(index)}>
                              Delete&nbsp; <i class="fas fa-trash" />
                            </Button> */}
                            <i class="fas fa-trash" style={{ color: 'blue' }} onClick={() => remove_Flow(index)} />
                          </Col>
                        </Row>
                      </CardContent>
                    </Card>{' '}
                  </Col>
                </Row>
              ) : null}
            </div>
          );
        })}
      {fields.filter(e => e.value !== undefined).length ? (
        <Row>
          <Col>
            <Card className="p-2 m-2">
              <CardHeader
                title={
                  <p
                    style={{
                      fontSize: '1rem',
                      backgroundColor: 'RoyalBlue',

                      textAlign: 'center',
                      fontWeight: 'Bold',
                      color: 'white',
                      padding: '12px'
                    }}
                  >
                    IVR Menu
                  </p>
                }
              />
              <CardContent>
                <Label> Menu Prompt the caller to select from a menu of options using the keypad on their phone.</Label>
                {fields &&
                  fields.map((item, index) => {
                    console.log({ item });
                    return (
                      <div className="mt-2">
                        {item.value != undefined ? (
                          <Row>
                            <Col xs="5">
                              <Input
                                style={{ fontSize: '0.95em' }}
                                placeholder="1"
                                type="number"
                                value={fields[index].value}
                                onChange={e => onchange(index, e)}
                                placeholder="Number"
                                onWheel={e => e.target.blur()}
                              />
                            </Col>
                            <Col xs="6">
                              {item.text != undefined ? (
                                <Button
                                  style={{ display: 'block', color: 'grey', width: '20%' }}
                                  variant="outlined"
                                  onClick={() => {
                                    setselected(index);
                                  }}
                                >
                                  <TextFields /> <p>text</p>
                                </Button>
                              ) : null}
                              {item.hangup != undefined ? (
                                <Button
                                  style={{ display: 'block', color: 'grey', width: '20%' }}
                                  variant="outlined"
                                  onClick={() => {
                                    setselected(index);
                                  }}
                                >
                                  <CallEndIcon />
                                  <p>Hang Up</p>
                                </Button>
                              ) : null}
                              {item.forward != undefined ? (
                                <Button
                                  style={{ display: 'block', color: 'grey', width: '20%' }}
                                  variant="outlined"
                                  onClick={() => {
                                    setselected(index);
                                  }}
                                >
                                  <PhoneForwardedIcon />
                                  <p>Dial</p>
                                </Button>
                              ) : null}
                              {item.play != undefined ? (
                                <Button
                                  style={{ display: 'block', color: 'grey', width: '20%' }}
                                  variant="outlined"
                                  onClick={() => {
                                    setselected(index);
                                  }}
                                >
                                  <VoicemailIcon /> <p>Voice Mail</p>
                                </Button>
                              ) : null}
                            </Col>
                            <Col xs="1">
                              {/* <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => removeFlow(index)}
                              >
                                Delete&nbsp; <i class="fas fa-trash" />
                              </Button> */}
                              <i class="fas fa-trash" style={{ color: 'blue' }} onClick={() => removeFlow(index)} />
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    );
                  })}
                <Button className="mt-2" onClick={() => onAddOptions()}>
                  <span style={{ color: '#2c7be5', display: 'flex', alignItems: 'center' }}>
                    <i class="fas fa-plus-circle fa-2x" /> <Label className="mb-0">&nbsp;Add options</Label>
                  </span>
                </Button>
                {fields.filter(e => e.noans !== undefined).length ? (
                  <div>
                    <Card>
                      <CardHeader
                        title={
                          <p
                            style={{
                              fontSize: '1rem',
                              backgroundColor: 'RoyalBlue',

                              textAlign: 'center',
                              fontWeight: 'Bold',
                              color: 'white',
                              padding: '12px'
                            }}
                          >
                            If no one answer
                          </p>
                        }
                      />
                      {fields &&
                        fields.map((item, index) => {
                          if (item.noans) {
                            return (
                              <div>
                                {item.noans.map((i, ind) => {
                                  return (
                                    <div>
                                      {i.text != undefined ? (
                                        <Row>
                                          <Col xs={12}>
                                            <Card className="mt-2">
                                              <CardHeader
                                                title={
                                                  <p
                                                    style={{
                                                      fontSize: '1rem',
                                                      backgroundColor: 'RoyalBlue',

                                                      textAlign: 'center',
                                                      fontWeight: 'Bold',
                                                      color: 'white',
                                                      padding: '12px'
                                                    }}
                                                  >
                                                    Greeting Play a message to the caller
                                                  </p>
                                                }
                                              />
                                              <CardContent>
                                                <Row>
                                                  <Col xs="8">
                                                    <Input
                                                      style={{ fontSize: '0.95em' }}
                                                      type="textarea"
                                                      value={i.text === true ? '' : i.text}
                                                      onChange={e => {
                                                        onNoanstextChange(index, ind, e);
                                                      }}
                                                      placeholder="text"
                                                    />
                                                  </Col>
                                                  <Col xs="4">
                                                    {/* <Button
                                                      variant="contained"
                                                      color="primary"
                                                      size="small"
                                                      onClick={() => removeFlow(index)}
                                                    >
                                                      Delete&nbsp; <i class="fas fa-trash" />
                                                    </Button> */}
                                                    <i
                                                      class="fas fa-trash"
                                                      style={{ color: 'blue' }}
                                                      onClick={() => removeFlow(index)}
                                                    />
                                                  </Col>
                                                </Row>
                                              </CardContent>
                                            </Card>
                                          </Col>
                                        </Row>
                                      ) : null}
                                      {i.play != undefined ? (
                                        <Card className="mt-2">
                                          <CardHeader
                                            title={
                                              <p
                                                style={{
                                                  fontSize: '1rem',
                                                  backgroundColor: 'RoyalBlue',

                                                  textAlign: 'center',
                                                  fontWeight: 'Bold',
                                                  color: 'white',
                                                  padding: '12px'
                                                }}
                                              >
                                                Voice Mail
                                              </p>
                                            }
                                          />
                                          <CardContent>
                                            <Row>
                                              <Col xs="6">
                                                <Input
                                                  style={{ fontSize: '0.95em' }}
                                                  type="text"
                                                  value={i.play === true ? '' : i.play}
                                                  onChange={e => {
                                                    onNoansplayChange(index, ind, e);
                                                  }}
                                                  placeholder={i.txt ? 'Text' : 'Mp3 url'}
                                                />
                                              </Col>
                                              <Col xs="2">
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  onClick={() => setpopup1(true)}
                                                >
                                                  Upload mp3 file
                                                </Button>
                                                <Popup open={popup1} setOpen={setpopup1} onChange={(e)=>onNoansplayChange(index, ind, e)} />
                                              </Col>
                                              <Col xs="3">
                                                {/* <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={() => changenoansType(index, ind)}
                                                >
                                                  {i.txt ? 'Text' : 'Mp3 url'}
                                                </Button> */}
                                                <Tooltipbutton title="mp3 url">
                                                  <span
                                                    style={{
                                                      fontWeight: 'bold',
                                                      marginRight: '5px',
                                                      fontSize: '14px',
                                                      paddingTop: '5px'
                                                    }}
                                                  >
                                                    Mp3 url
                                                  </span>
                                                </Tooltipbutton>
                                                <Switch
                                                  checked={i.txt}
                                                  onChange={() => changenoansType(index, ind)}
                                                  color="primary"
                                                  inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                                <Tooltipbutton title="text">
                                                  <span
                                                    style={{
                                                      fontWeight: 'bold',
                                                      marginRight: '30px',
                                                      fontSize: '14px',
                                                      paddingTop: '5px'
                                                    }}
                                                  >
                                                    Text
                                                  </span>
                                                </Tooltipbutton>
                                              </Col>
                                              <Col xs="1">
                                                <i
                                                  class="fas fa-trash"
                                                  style={{ color: 'blue' }}
                                                  onClick={() => removeFlow(index)}
                                                />
                                              </Col>
                                            </Row>
                                          </CardContent>
                                        </Card>
                                      ) : null}
                                      {i.forward != undefined ? (
                                        <Card className="mt-2">
                                          <CardHeader
                                            title={
                                              <p
                                                style={{
                                                  fontSize: '1rem',
                                                  backgroundColor: 'RoyalBlue',

                                                  textAlign: 'center',
                                                  fontWeight: 'Bold',
                                                  color: 'white',
                                                  padding: '12px'
                                                }}
                                              >
                                                Dial This is where the phone will ring
                                              </p>
                                            }
                                          />
                                          <CardContent>
                                            <Label>Forward Calls To</Label>
                                            <Row>
                                              <Col xs="8">
                                                <Input
                                                  style={{ fontSize: '0.95em' }}
                                                  type="text"
                                                  value={i.forward === true ? '' : i.forward}
                                                  onChange={e => {
                                                    onNoansforwardChange(index, ind, e);
                                                  }}
                                                  placeholder="Number"
                                                />
                                              </Col>
                                              <Col xs="4">
                                                {/* <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  onClick={() => removeFlow(index)}
                                                >
                                                  Delete&nbsp; <i class="fas fa-trash" />
                                                </Button> */}
                                                <i
                                                  class="fas fa-trash"
                                                  style={{ color: 'blue' }}
                                                  onClick={() => removeFlow(index)}
                                                />
                                              </Col>
                                            </Row>
                                          </CardContent>
                                        </Card>
                                      ) : null}
                                      {i.hangup != undefined ? (
                                        <Card className="mt-2">
                                          <CardHeader
                                            title={
                                              <p
                                                style={{
                                                  fontSize: '1rem',
                                                  backgroundColor: 'RoyalBlue',

                                                  textAlign: 'center',
                                                  fontWeight: 'Bold',
                                                  color: 'white',
                                                  padding: '12px'
                                                }}
                                              >
                                                Hang Up
                                              </p>
                                            }
                                          />
                                          <CardContent>
                                            <Row>
                                              <Col xs="8">
                                                <Button variant="contained" color="secondary">
                                                  Hang Up
                                                </Button>
                                              </Col>
                                              <Col xs="4">
                                                {/* <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  onClick={() => removeFlow(index)}
                                                >
                                                  Delete&nbsp; <i class="fas fa-trash" />
                                                </Button> */}
                                                <i
                                                  class="fas fa-trash"
                                                  style={{ color: 'blue' }}
                                                  onClick={() => removeFlow(index)}
                                                />
                                              </Col>
                                            </Row>
                                          </CardContent>
                                        </Card>
                                      ) : null}
                                      {i.value != undefined ? (
                                        <Card className="mt-2">
                                          <CardHeader
                                            title={
                                              <p
                                                style={{
                                                  fontSize: '1rem',
                                                  backgroundColor: 'RoyalBlue',

                                                  textAlign: 'center',
                                                  fontWeight: 'Bold',
                                                  color: 'white',
                                                  padding: '12px'
                                                }}
                                              >
                                                Menu Prompt the caller to select from a menu of options using the keypad
                                                on their phone.
                                              </p>
                                            }
                                          />
                                          <CardContent>
                                            <Input
                                              style={{ fontSize: '0.95em' }}
                                              placeholder="1"
                                              type="number"
                                              value={i.value === true ? '' : i.value}
                                              onChange={e => onNoansvalueChange(index, ind, e)}
                                              placeholder="Number"
                                              onWheel={e => e.target.blur()}
                                            />
                                          </CardContent>
                                        </Card>
                                      ) : null}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }
                        })}
                    </Card>
                  </div>
                ) : null}
                <Row className="mt-3">
                  <Col md={12}>
                    <div class="dropdown">
                      <span style={{ color: '#2c7be5', display: 'flex', alignItems: 'center' }}>
                        <i class="fas fa-plus-circle fa-2x" /> <Label className="mb-0">&nbsp;If no one answer</Label>
                      </span>
                      <div class="dropdown-content">
                        <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('text')}>
                          Text
                        </Label>
                        <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('hangup')}>
                          Hangup
                        </Label>
                        {/* <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('value')}>
                          Menu
                        </Label> */}
                        <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('forward')}>
                          Dial
                        </Label>
                        <Label style={{ display: 'block', cursor: 'pointer' }} onClick={() => onAddNoAns('play')}>
                          Voicemail
                        </Label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default Content_map;

import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Label,  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,} from 'reactstrap';
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';
import GetCOmpany from '../../../components/GetCompany/getCompany';
import { getnumbers } from '../../../Redux/Actions/Twilio';
import { getflows, deleteflow } from '../../../Redux/Actions/USER/user';
import Content from './Content';
import { connect } from 'react-redux';

import Loader from '../../../components/common/Loader';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import WidgetsSectionTitle from '../../../WidgetsSectionTitle/WidgetSectionTitle';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";



const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#edf2f9',
    // color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});
const CallFlow = ({ numbers, getnumbers, flows, getflows, deleteflow }) => {
  const [from, setfrom] = useState(null);
  const [add, setadd] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delID, setDelID] = useState('');
  const [delTitle, setDelTitle] = useState('');
  useEffect(() => {
    getflows();
    getnumbers();
  }, [getflows]);
  console.log({flows})
  const history = useHistory();
  const classes = useStyles();

  const deleteMe = (title, id) => {
    setDelID(id);
    setDelTitle(title);
    setDelModal(true);
  };
  const delToggle = () => {
    setDelModal(!delModal);
  };

  const removeMeConfirm = () => {
    deleteflow(delID);
    setDelModal(false);
  };




  if (from !== null) {
    return<div> <WidgetsSectionTitle
    className="my-2"
      title="Call Flow"
      icon="folder-plus"
      subtitle="Call Flow"
      /><Content number={from} /></div>;
  } else if (add) {
    // return (
    //   <div>
    //     <WidgetsSectionTitle
    //     className="my-2"
    //       title="Call Flow"
    //       icon="folder-plus"
    //       subtitle="Call Flow"
    //       />
    //     <Card>
    //       <CardHeader>
    //         <CardContent>Call Flows</CardContent>
    //       </CardHeader>
    //       <Row>
    //         <Col md="12">
    //           <CardContent>
    //             <Label>Company</Label>
    //             <GetCOmpany />
    //           </CardContent>
    //         </Col>
    //       </Row>
    //       <Row>
    //         <Col md="12">
    //           <CardContent>
    //             <Label>Number</Label>
    //             <Input
    //               style={{ fontSize: '0.95em' }}
    //               type="select"
    //               id="exampleSelect"
    //               onChange={e => setfrom(e.target.value)}
    //               name="from"
    //             >
    //               <option value="">Select Number</option>
    //               {numbers.map(item => (
    //                 <option key={item._id} selected={item.number == from} value={item._id}>
    //                   {item.name ? item.name : item.number}
    //                 </option>
    //               ))}
    //             </Input>
    //           </CardContent>
    //         </Col>
    //       </Row>
    //     </Card>
    //   </div>
    // );
  } else if (flows != null) {
    return (
      <div>   
        <WidgetsSectionTitle
        className="my-2"
          title="Call Flow"
          icon="folder-plus"
          subtitle="Call Flow"
          />
           <Card>
        <CardHeader
          title={
            <Row>
              {/* <Col md="8" xs="7">
                <Label> Call Flow</Label>
              </Col> */}
              <Col md="11" xs="12">
                {/* <Link to="/callflow/create"  className="btn btn-primary" style={{float:"right"}}>Create Flow</Link> */}
                <button style={{float:"right"}} className="btn btn-primary" onClick={() =>  window.location.href="/callflow/create"}>
                  Create Flow
                </button>
              </Col>
            </Row>
          }
        />
        <CardContent style={{ backgroundColor: '#f9fafd' }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Number</StyledTableCell>
                  <StyledTableCell>Edit</StyledTableCell>
                  <StyledTableCell>Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {flows.length > 0 &&
                  flows.map((item, index) => (
                    <StyledTableRow key={item._id}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{item?.name}</StyledTableCell>
                      <StyledTableCell>{item?.number?.number}</StyledTableCell>
                      <StyledTableCell>
                        <i onClick={(e) =>{ window.location.href=`/callflow/edit/${item?.number?._id}/${item.name}/${item._id}`}} className="is-isolated fas fa-edit" />
                      </StyledTableCell>
                      <StyledTableCell>
                        <i className="is-isolated fas fa-trash" onClick={() => deleteMe(item?.name, item?._id)} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>


          {delModal ? (
              <Modal isOpen toggle={delToggle}>
                <ModalHeader toggle={delToggle} style={{ backgroundColor: '#becdda8f' }}>
                  <h3 style={{ fontWeight: 'bold' }}>Remove - {delTitle}</h3>
                </ModalHeader>
                <ModalBody>Are you sure you want to remove this? This action cannot be undone.</ModalBody>
                <ModalFooter>
                  <Button className="mr-2" variant="contained" color="secondary" onClick={() => delToggle()}>
                    No
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#28a745', color: 'white' }}
                    onClick={() => removeMeConfirm()}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}

        </CardContent>
      </Card></div>

    );
  } else {
    return <Loader />;
  }
};
const mapStateToProps = state => ({
  numbers: state.Twilio.numbers,
  flows: state.Twilio.getflows
});
export default connect(
  mapStateToProps,
  { getflows, getnumbers, deleteflow }
)(CallFlow);

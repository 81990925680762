import { combineReducers } from 'redux';
import Twilio from './twilio';
import Alert from './Alert'
import Auth from "./Auth"
import Bulk from "./Bulk"
import payment from "./payment"
const rootReducer = combineReducers({
  Twilio: Twilio,
  alert: Alert,
  auth: Auth,
  bulk: Bulk,
  payment:payment
});
export default rootReducer;

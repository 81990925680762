import React, { useContext, useEffect, useState } from 'react';
import ProfileDropdown from './ProfileDropdown';
// import NotificationDropdown from './NotificationDropdown';
import SettingsAnimatedIcon from './SettingsAnimatedIcon';
// import CartNotification from './CartNotification';
import { getuserStatus, postuserStatus } from '../../Redux/Actions/USER/user';
import AppContext from '../../context/Context';
import classNames from 'classnames';
import { navbarBreakPoint } from '../../config';
import { connect } from 'react-redux';
import { notification } from '../../routes';
import { Collapse, Nav, NavItem } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import { readunread } from '../../Redux/Actions/Mask/Mask';
import {Device} from '@twilio/voice-sdk';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import io from 'socket.io-client';
import { socket } from '../../view/pages/Main/socket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DialBox from "../../view/pages/Home/newdialer"
// const socket = io(process.env.REACT_APP_PORT, {
//   query: {
//     email: localStorage.getItem('email'),
//     filename:"TopNavRightSideNavItem"
//   }
// });
const TopNavRightSideNavItem = ({ getuserStatus, postuserStatus, toggle, readunread,isPaid }) => {
  const [checked, setchecked] = useState(false);
  const { isTopNav, isCombo } = useContext(AppContext);
  const [count, setcount] = useState([])
  const [user, setuser] = useState()
  // const [popDialog, setpopDialog] = useState(false);
  // const [dialog, setDialog] = useState(false);
  // const  openDialog= () => {
  //   setDialog(true)
  //  }
  //  const setDialogs = ()=>{
  //     setDialog(false)
  //  }
  // const  setpopDialogs= (data)=>{
  //   // console.log("data ",data)
  //     setpopDialog(data)
  //  } 
  useEffect(() => {
    getuserStatus();
    if (toggle != checked) {
      setchecked(toggle);
    }
    getuserStatus();
    socket.emit( "notification", localStorage.getItem("email"))

  }, [getuserStatus, postuserStatus]);
 
  socket.on('notificationbadge', ({exist,user}) => {
    
    
    setcount(exist);
    setuser(user)
    // console.log(count,"notification length");
  });
  const handleChange = event => {
    postuserStatus(event);
    setchecked(event);
  };
  // console.log('hh', toggle);
  // Device.on('incoming', function(connection) {
  //   console.log(connection.parameters.From)
  //  console.log("incoming working")
  //     });
      // Device.on('connect', function(connection) {
      //   setpopDialog(true)
      // });
      // Device.on('disconnect', function() {
      //   // setAlert("Call Ended", "success")
      //   setpopDialog(false)
      //   setDialog(false)
      // });
      // const disconnect = () => {
      //   Device.disconnectAll();
      // };
  
  return (
    
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      {/* <div className="p-2">
        <NavItem>
        
        {popDialog ? <i className="fa fa-phone fa-3x cursor-pointer" onClick={openDialog} style={{color:"#25fd21"}} aria-hidden="true"></i>: null}
      
      {window.location.pathname != "/" &&dialog&&<DialBox disconnect={disconnect}
                  
                        ></DialBox>}
        </NavItem>
      </div> */}
       <div className="p-2">
        <NavItem>
          <NavLink onClick={()=>{
            
            readunread(user);
          }} className="nav-link" {...notification}>
            <NavbarVerticalMenuItem  route={{...notification,badge:{text:Object.values(count).filter(n=>n.status===true).length,color:"danger"}}} />
          </NavLink>
        </NavItem>
      </div>
      <div className="p-2">
        {toggle && isPaid ? (
          <div className="btn btn-success btn-sm" onClick={() => handleChange(false)}>
            {' '}
            Online
          </div> 
        ) : isPaid ? (
          <div className="btn btn-danger btn-sm" onClick={() => handleChange(true)}>
            {' '}
            Offline
          </div>
        ) : null}
      </div>

      {/* <NavItem>
        <SettingsAnimatedIcon />
      </NavItem> */}
      {(isCombo || isTopNav) && (
        <NavItem className={classNames(`p-2 px-lg-0 cursor-pointer`, { [`d-${navbarBreakPoint}-none`]: isCombo })}>
          {/* <NavLink tag={Link} to="/changelog" id="changelog">
            <FontAwesomeIcon icon="code-branch" transform="right-6 grow-4" />
          </NavLink>
          <UncontrolledTooltip autohide={false} placement="left" target="changelog">
            Changelog
          </UncontrolledTooltip> */}
        </NavItem>
      )}

      {/* <NotificationDropdown /> */}
      <ProfileDropdown />
    </Nav>
  );
};
const mapStateToProps = state => ({
  toggle: state.Twilio.toggle,
  isPaid: state.auth.isPaid,
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(
  mapStateToProps,
  { getuserStatus, postuserStatus,readunread }
)(TopNavRightSideNavItem);

import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import DialpadIcon from '@material-ui/icons/Dialpad';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import { TextFields } from '@material-ui/icons';
import { getnumber } from '../../../Redux/Actions/Twilio';
import { postflow, getflow } from '../../../Redux/Actions/USER/user';
import CallEndIcon from '@material-ui/icons/CallEnd';
import Loader from '../../../components/common/Loader';
import CloseIcon from '@material-ui/icons/Close';
import { Link, useHistory } from 'react-router-dom';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import ReactFlow from 'react-flow-renderer';
import { toast } from 'react-toastify';
import './content.css';

import Content_map from './Content_map';

const NewCallFlow = ({ number, getnumber, postflow, getflow, flow, detail }) => {
  const [fields, setfields] = useState([]);
  const [set, setset] = useState(false);
  const [name, setname] = useState('');
  useEffect(() => {
    getnumber(number);
    // getflow(number)
    if (flow) {
      setfields(flow.data);
      setname(flow.name);
    }
  }, [flow === null]);
  const history = useHistory();
  const flowsdata = data => {

    if (data === 'play') {
      const values = [...fields];

      if (values.length === 0) {
        values.push({ play: '',txt:false });
      } else if (values.length===1) {
        if(Object.keys(values[0])[0]==="text"){
          values.push({ play: '',txt:false });
        }else if(values[values.length - 1].value){
          values[values.length - 1]['play'] = '';
          values[values.length - 1]['txt'] = false;
        }else{
          values[values.length - 1] = {play:'',txt:false};
        }
        
      }else if(values[values.length - 1].value){
        values[values.length - 1]['play'] = '';
        values[values.length - 1]['txt'] = false;
      } else  {
        values[values.length - 1] = {play:'',txt:false};
      } 

      setfields(values);
      setset(false);
    }
    if (data === 'forward') {
      const values = [...fields];
      if (values.length === 0 ) {
        values.push({ forward: '' });
      }
      else if(values.length===1){
        if(Object.keys(values[0])[0]==="text"){
          values.push({ forward: '' });
        }else{
          values[values.length - 1] = {forward:''};
        }
      }
      else if(values[values.length - 1].value){
        values[values.length - 1]['forward'] = '';
      }
       else {
        values[values.length - 1] = {forward:""};
      } 
      console.log("values",values)
      setfields(values);
      setset(false);
    }
    if (data === 'text') {
      const values = [...fields];
      if (values.length === 0 ) {
        values.push({ text: '' });
      } 
      else if(values.length===1){
        if(Object.keys(values[0])[0]==="text"){
          values.push({ text: '' });
        }else if(values[values.length - 1].value){
          values[values.length - 1]['text'] = '';
        }else{
          values[values.length - 1] = {text:''};
        }
      }else if(values[values.length - 1].value){
        values[values.length - 1]['text'] = '';
      }else {
        values[values.length - 1]={text:""}
      } 

      setfields(values);
      setset(false);
    }
    if (data === 'hangup') {
      const values = [...fields];
      if (values.length === 0) {
        values.push({ hangup: true });
      } else if (values.length===1){
        if(Object.keys(values[0])[0]==="text"){
          values.push({ hangup: true });
        }else if(values[values.length - 1].value){
          values[values.length - 1]['hangup'] = true;
        }else{
          values[values.length - 1] = {hangup:true};
        }
      }else if(values[values.length - 1].value){
        values[values.length - 1]['hangup'] = true;
      }else {
        values[values.length - 1] = {hangup:true}
      } 

      setfields(values);
      setset(false);
    }
    if (data === 'menu') {
      const values = [...fields];
      if (values.length === 0) {
        values.push({ text: '' });
        values.push({ value: '1' });
        setfields(values);
        return;
      }else if(values.length===1){

        if(Object.keys(values[0])[0]==="text"){
          values.push({ value: '1' });
        }else{
          values[values.length - 1] = {text:''};
          values.push({ value: '1' });
        }
      }else {
        values[values.length - 1] = {value: '1'}
      }

      // if (Object.keys(values[values.length - 1])[0] == 'noans') {
      //   if (Object.keys(values[values.length - 2]).length > 1) {
      //     if (values[values.length - 2]['value']) {
      //       let a = parseInt(values[values.length - 2].value) + 1;
      //       values.push({ value: `${a}` });
      //     }
      //   } else {
      //     toast.error('please select options on the right');
      //   }
      // } else {
      //   if (Object.keys(values[values.length - 1]).length > 1) {
      //     if (values[values.length - 1]['value']) {
      //       let a = parseInt(values[values.length - 1].value) + 1;
      //       values.push({ value: `${a}` });
      //     }
      //   } else {
      //     toast.error('please select options on the right');
      //   }
      // }

      setfields(values);
      setset(false);
    }
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to match the format + followed by at least 10 digits
    const phoneRegex = /^\+\d{10,}$/;
    return phoneRegex.test(phoneNumber);
  };

  // Function to recursively validate each object in the fields array
const validateFields = (fields) => {
  for (const field of fields) {
    if (field.hasOwnProperty('forward')) {
      // Check if forward number is provided and valid
      if (!isValidPhoneNumber(field['forward'])) {
        toast.error(`Provided number ${field['forward']} is not a valid phone number, Should be in format like +XXXXXXXXXXX`);
        // toast.error('Please provide a valid phone number in the format +XXXXXXXXXXX');
        return false; // Exit the function early if any forward number is not valid
      }
    }

    if (field.hasOwnProperty('noans_menue')) {
      // If 'noans_menue' array exists, recursively validate its objects
      if (!validateFields(field['noans_menue'])) {
        return false; // Exit the function early if any forward number in 'noans_menue' is not valid
      }
    }
  }

  return true; // All forward numbers are valid
};

  const submit = () => {
    if (name === '') {
      toast.error('name is required');
      return
    }
    console.log("fields",fields)
    // Validate each object in the fields array
    if (!validateFields(fields)) {
      return; // Exit the function early if any forward number is not valid
    }
      // Iterate over the fields object and validate each 'forward' field
  // for (const key in fields) {
  //   if (fields.hasOwnProperty(key) && key === 'forward') {
  //     // Check if forward number is provided and valid
  //     if (!isValidPhoneNumber(fields[key])) {
  //       toast.error(`Provided mumber ${fields[key]} is not a valid phone number, Should be in format like +XXXXXXXXXXX`);
  //       return; // Exit the function early if any forward number is not valid
  //     }
  //   }
  // }
 
      postflow(fields, detail._id, name, 'create');
      history.push('/callflow');
  };

  const [subFlowMapComponent, setSubFlowMapComponent] = useState(null);

  const removeFlow = (index,check) => {
    const values = [...fields];
    if(values[index+1]){
      if(values.length>1&&Object.keys(values[index+1])[0]==="value"&&check==="text"){
toast.error("can not remove this ")
    }else{
       values.splice(index, 1);
    }
    }else{
      values.splice(index, 1);
    }
    
   
    setfields(values);
  };
  
  console.log({ fields });
  return (
    <div>
      <Row xs="12">
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Row className="pt-2">
              <Col md={12}>
                <Label>Flow Name</Label>
                <Input
                  style={{ fontSize: '0.95em' }}
                  type="text"
                  placeholder="Name of Flow"
                  value={name}
                  name="name"
                  onChange={e => setname(e.target.value)}
                />
              </Col>
            </Row>
          </CardContent>
        </Card>
      </Row>
      <div className="d-flex w-100 mt-2">
        <Col xs="11">
          <Card>
            <CardHeader
              title="Call start"
              style={{
                backgroundColor: 'RoyalBlue',
                display: 'flex',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 'Bold',
                color: 'white',
                padding: '12px'
              }}
            />

            <CardContent className="p-0">
              <Row>
                <Label style={{ width: '100%', textAlign: 'center' }}>When call begins what should we do?</Label>
              </Row>
              <Row>
                <Label style={{ width: '100%', textAlign: 'center' }}>
                  Click on the button on the right side to get started
                </Label>
              </Row>
            </CardContent>
          </Card>
          <Content_map fields={fields} setfields={setfields} setSubFlowMapComponent={setSubFlowMapComponent} remove_Flow={removeFlow} />
        </Col>
        <div
          style={{
            height: 'fit-content',
            position: 'sticky',
            top: 80 + 15
          }}
        >
          <Card>
            <Row style={{ margin: '7px 0px 0px 7px' }}>
              <Tooltipbutton title="Add the greeting">
                <Button
                  style={{ display: 'block', color: 'grey' }}
                  variant="outlined"
                  onClick={() => flowsdata('text')}
                >
                  <TextFields /> <p>text</p>
                </Button>
              </Tooltipbutton>
            </Row>
            <Row style={{ margin: '7px 0px 0px 7px' }} className="btnwidth">
              <Tooltipbutton title="Add the disconnect call">
                <Button
                  style={{ display: 'block', color: 'grey', marginRight: '7px' }}
                  variant="outlined"
                  onClick={() => flowsdata('hangup')}
                >
                  <CallEndIcon />
                  <p>Hang Up</p>
                </Button>
              </Tooltipbutton>
            </Row>
            <Row style={{ margin: '7px 0px 0px 7px' }} className="btnwidth">
              <Tooltipbutton title="Add the Menu option">
                <Button
                  style={{ display: 'block', color: 'grey', marginRight: '7px' }}
                  variant="outlined"
                  onClick={() => flowsdata('menu')}
                >
                  <DialpadIcon />
                  <p>Menu</p>
                </Button>
              </Tooltipbutton>
            </Row>
            <Row style={{ margin: '7px 0px 0px 7px' }} className="btnwidth" >
              <Tooltipbutton title="Add the forward call tool">
                <Button
                  style={{ display: 'block', color: 'grey', marginRight: '7px' }}
                  variant="outlined"
                  onClick={() => flowsdata('forward')}
                >
                  <PhoneForwardedIcon />
                  <p>Dial</p>
                </Button>
              </Tooltipbutton>
            </Row>
            <Row style={{ margin: '7px 0px 7px 7px' }} className="btnwidth" >
              <Tooltipbutton title="Add the play the voicemail">
                <Button
                  style={{ display: 'block', color: 'grey', marginRight: '7px' }}
                  variant="outlined"
                  onClick={() => flowsdata('play')}
                >
                  <VoicemailIcon /> <p>Voice Mail</p>
                </Button>
              </Tooltipbutton>
            </Row>
          </Card>
        </div>
      </div>
      <div>{subFlowMapComponent}</div>
      {fields.length > 0 ? (
        <Row>
          <Col>
            <Card className="mt-3">
              <CardContent>
                <Row>
                  <Col md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" onClick={submit}>
                      Save & Finish{' '}
                    </Button>
                  </Col>
                  <Col md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to="/callflow">
                      <Button color="secondary" variant="contained">
                        Cancel
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
const mapStateToProps = state => ({
  detail: state.Twilio.detail,
  flow: state.Twilio.getflow
});
export default connect(
  mapStateToProps,
  { getnumber, postflow, getflow }
)(NewCallFlow);

import React, { useState , useEffect} from 'react'
import {Row, Col, Label,   CardBody, Card, CardHeader, Button} from 'reactstrap'
import {connect} from 'react-redux'

import Select from 'react-select';
import { getLeadStage, filterprospects} from "../../../Redux/Actions/Mask/Mask"
import {useParams} from "react-router-dom"
const showdata = [
    { value: false, label: 'not replied' },
  { value: true, label: 'replied' },
  { value: 'both', label: 'All' },
]
const CreateFollowup = ({getLeadStage,leads, filterprospects, toggle})=> {
    const [lead, setLead] = useState([])
    const [replied, setreplied] = useState("")
    const {id} = useParams()
    useEffect(()=>{
      
        getLeadStage()
    }, getLeadStage)
    const handleChangere = selectedOption=>{
      setreplied(selectedOption)
    }
     const handleChange = selectedOption => {
          setLead( selectedOption );
          
      };
      const submit = ()=>{
        
        if(lead.length < 0 && setreplied == "")
        {

        }
        else {
          let filter = {lead: []}
          let value = lead.map(item=> item._id)
          filter.lead = value
          filter.mstatus = false 
         
          if(replied.value == "both")
          {  
             
             filterprospects(id, filter)
            //  toggle() 
             window.location.reload(); 
          }
          else{
              filter.replied = replied.value
              filterprospects(id, filter)
              // toggle() 
              window.location.reload(); 
          }
        }
      }  
      return  <Row  className="justify-content-center ">
              <Col xs="12">
                  
                        <Row  className="pt-2">
                          <Col xs={6}>
                            <Row  className="pt-2">
                              <Col xs={12}>
                                <Label>Lead Stage</Label>
                                  <Select
                                    value={lead}
                                    bsSize="sm"
                                    onChange={handleChange}
                                    options={leads != null && leads.length > 0 ? leads: null}
                                    getOptionLabel={option =>`${option.lead_Stage}`} 
										                getOptionValue={option=>option}
                                    isMulti
                                  />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6}>
                            <Row  className="pt-1">
                              <Col xs={12}>
                                <Label>Include Prospects who have responded?</Label>
                                  <Select
                                    value={replied}
                                    bsSize="sm"
                                    onChange={handleChangere}
                                    options={showdata}
                                    
                                  />
                              </Col>
                            </Row>
                          </Col>
                         
                        </Row>
                        <Row className="pt-2">
                          <Col xs={12}>
                            <Button onClick={submit}>Reset</Button>
                          </Col>
                        </Row>
                     
                    
              </Col>
          </Row>
    
  
}

const mapStateToProps = (state) => ({
  leads:state.bulk.leads   
  });
export default connect(mapStateToProps, { getLeadStage, filterprospects })(CreateFollowup)
import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Label } from 'reactstrap';
import { Card, CardContent, CardHeader, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import DialpadIcon from '@material-ui/icons/Dialpad';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import { TextFields } from '@material-ui/icons';
import { getnumber } from '../../../Redux/Actions/Twilio';
import { postflow, getflow } from '../../../Redux/Actions/USER/user';
import CallEndIcon from '@material-ui/icons/CallEnd';
import Loader from '../../../components/common/Loader';
import CloseIcon from '@material-ui/icons/Close';
import { Link, useHistory, useParams } from 'react-router-dom';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
import ReactFlow from 'react-flow-renderer';
import { toast } from 'react-toastify';
import './content.css';

const NewCallFlow = ({ number, getnumber, postflow, getflow, flow, detail }) => {
  const [fields, setfields] = useState([]);
  const [name, setname] = useState('');
  const [set, setset] = useState(false);
  const [oflimit, setLimit] = useState(0);
  const [showflow, setShow] = useState(false);
  const history = useHistory();
  console.log({ fields });
  const addField = () => {
    const values = [...fields];
    values.push({ value: '' });
    setfields(values);
  };
  const onchange = (i, e) => {
    const values = [...fields];

    if (e.target.value < 0) {
      values[i].value = 0;
    } else {
      values[i].value = e.target.value;
    }
    setfields(values);

    setset(true);
  };
  const subtext = (i, e, check) => {
    const values = [...fields];
    values[i].text = e.target.value;
    values[i].check = check;
    setfields(values);
  };
  const text = (i, e) => {
    const values = [...fields];
    if (values[i].check) {
    } else {
      values[i].text = e.target.value;
      setfields(values);
    }
  };
  const hangup = () => {
    const values = [...fields];
    values.push({ value: '' });
    setfields(values);
  };
  const forward = (i, e) => {
    const values = [...fields];
    values[i].forward = e.target.value;
    setfields(values);
  };
  const play = (i, e) => {
    const values = [...fields];
    values[i].play = e.target.value;
    setfields(values);
  };
  const remove = i => {
    const values = [...fields];
    values.splice(i, 1);
    setfields(values);
  };
  const removeobj = (key, j) => {
    // let len=Object.keys(fields[0]).length
    // let ln=Object.keys(fields[1]).length
    // if(len===0 && ln===0){
    //     const value=[...fields]
    //     value.splice(0,2)
    //     setfields(value)
    // }
    if (key === 'value') {
      const values = [...fields];
      values.splice(j, 1);
      setfields(values);
    } else if (key === 'text') {
      const values = [...fields];
      values.splice(j, 1);
      setfields(values);
    } else if (fields) {
      const values = [...fields];
      delete values[j][key];

      setfields(values);
    }
  };
  useEffect(() => {}, [fields]);
  useEffect(() => {
    getnumber(number);
    // getflow(number)
    if (flow) {
      setfields(flow.data);
      setname(flow.name);
    }
  }, [flow === null]);

  const submit = () => {
    if (name === '') {
      toast.error('name is required');
    } else {
      postflow(fields, detail._id, name, 'create');
      history.push('/callflow');
    }
  };
  const refresh = () => {
    history.push('/callflow');
  };
  const flowsdata = data => {
    if (data === 'play') {
      const values = [...fields];

      if (values.length === 0) {
        values.push({ play: '' });
      } else if (values[values.length - 1]['value'] == undefined) {
        values.push({ play: '' });
      } else if (values[values.length - 1]['showflow'] != true) {
        values[values.length - 1]['play'] = '';
      } else {
        toast.info('Please select menu before adding the voice mail');
      }

      setfields(values);
      setset(false);
    }
    if (data === 'forward') {
      const values = [...fields];
      if (values.length === 0 || values[values.length - 1]['value'] == undefined) {
        values.push({ forward: '' });
      } else if (values[values.length - 1]['showflow'] != true) {
        values[values.length - 1]['forward'] = '';
      } else {
        toast.info('Please select menu before adding the forword call tool');
      }

      setfields(values);
      setset(false);
    }
    if (data === 'text') {
      const values = [...fields];
      if (values.length === 0 || values[values.length - 1]['value'] == undefined) {
        values.push({ text: '' });
      } else if (values[values.length - 1]['showflow'] != true) {
        values[values.length - 1]['text'] = '';
      } else {
        toast.info('Please select menu before adding the greeting');
      }

      setfields(values);
      setset(false);
    }
    if (data === 'hangup') {
      const values = [...fields];
      if (values.length === 0) {
        values.push({ hangup: true });
      } else if (values[values.length - 1]['showflow'] != true) {
        values[values.length - 1]['hangup'] = true;
      } else {
        toast.info('Please select menu before adding the hangup tool');
      }

      setfields(values);
      setset(false);
    }
    if (data === 'menu') {
      const values = [...fields];
      if (values.length === 0) {
        values.push({ menu: '' });
      } else {
        values[values.length - 1]['hangup'] = true;
      }

      setfields(values);
      setset(false);
    }
  };
  const subFlow = (e, index) => {
    //   let {v}=e.target
    var temp = {};
    if (e === 'hangup') {
      temp[e] = true;
    } else {
      temp[e] = '';
    }

    const values = [...fields];
    if (values[index].flow) {
      values[index].flow.push(temp);
      console.log('existfields', values);
      setfields(values);
    } else {
      values[index]['flow'] = [];
      values[index].flow.push(temp);
      console.log(values);
      setfields(values);
    }
  };
  const subtextchange = (e, index, i) => {
    const { value } = e.target;
    console.log({ value });
    console.log('subchange', index, i);
    const values = [...fields];
    //   console.log({values})
    values[index].flow[i].text = value;
    setfields(values);
  };
  const submenuchange = (e, index, i) => {
    const { value } = e.target;
    console.log({ value });
    console.log('subchange', index, i);
    const values = [...fields];
    //   console.log({values})
    values[index].flow[i].value = value;
    setfields(values);
  };
  const subvoicemailchange = (e, index, i) => {
    const { value } = e.target;
    console.log({ value });
    console.log('subchange', index, i);
    const values = [...fields];
    //   console.log({values})
    values[index].flow[i].play = value;
    setfields(values);
  };
  const subforwardchange = (e, index, i) => {
    const { value } = e.target;
    console.log({ value });
    console.log('subchange', index, i);
    const values = [...fields];
    console.log({ values });
    values[index].flow[i].forward = value;
    setfields(values);
  };
  const handleShow = index => {
    const values = [...fields];
    values[index].showflow = true;
    delete values[index].forward;
    delete values[index].play;
    delete values[index].hangup;
    setfields(values);
  };
  const removesubobj = (key, index, i) => {
    const values = [...fields];
    values[index].flow.splice(i,1);
    setfields(values);
  };
  console.log({ detail });
  return (
    <div>
      <Row className="justify-content-center" style={{ paddingTop: '3%' }}>
        <Col md="12">
          <Card>
            <CardContent>
              <Row className="pt-2">
                <Col md={12}>
                  <Label>Flow Name</Label>
                  <Input
                    style={{ fontSize: '0.95em' }}
                    type="text"
                    placeholder="Name of Flow"
                    value={name}
                    name="name"
                    onChange={e => setname(e.target.value)}
                  />
                </Col>
              </Row>
            </CardContent>
          </Card>

          <Row className="pt-4">
            <Col md={12}>
              {fields.map((item, index) => {
                // console.log({index})
                // console.log({item})
                // var length= Object.keys(item)
                // console.log({length})
                return (
                  <div>
                    {/* <div style={{float: "right"}}><CloseIcon onClick={()=>remove(index)}></CloseIcon></div> */}
                    {item.value != undefined ? (
                      <Row>
                        <Col md="12">
                          {' '}
                          <Card className="p-2 m-2   ">
                            <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('value', index)} />
                            </div>
                            <CardHeader
                              title={
                                <p style={{ fontSize: '1rem' }}>
                                  Menu Prompt the caller to select from a menu of options using the keypad on their
                                  phone.
                                </p>
                              }
                            />
                            <CardContent>
                              <Input
                                style={{ fontSize: '0.95em' }}
                                placeholder="1"
                                type="number"
                                value={fields[index].value}
                                onChange={e => onchange(index, e)}
                                placeholder="Number"
                                onWheel={e => e.target.blur()}
                              />
                            </CardContent>

                            <CardContent>
                              {!item.showflow ? (
                                <div>
                                  <Tooltipbutton title="Click to add flow">
                                    <Button
                                      onClick={() => {
                                        handleShow(index);
                                      }}
                                      className="m-2"
                                    >
                                      <i class="fas fa-plus-circle" />
                                    </Button>
                                  </Tooltipbutton>
                                  <Label>Click + to add sub flow</Label>
                                </div>
                              ) : null}
                              {item.showflow ? (
                                <div>
                                  <Card className="p-2 m-2">
                                    {/* <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('text', index)} />
                            </div> */}
                                    <CardHeader
                                      title={<p style={{ fontSize: '1rem' }}>Greeting Play a message to the caller</p>}
                                    />
                                    <CardContent>
                                      <Input
                                        style={{ fontSize: '0.95em' }}
                                        type="textarea"
                                        value={item.text === true ? '' : item.text}
                                        onChange={e => {
                                          subtext(index, e, 'subflow');
                                        }}
                                        placeholder="text"
                                      />
                                    </CardContent>
                                    <hr />
                                  </Card>{' '}
                                  <div className="mt-4" />
                                </div>
                              ) : null}
                            </CardContent>
                            <CardContent>
                              {item.flow &&
                                item.flow.map((i, a) => {
                                  return (
                                    <div>
                                      {i.value != undefined ? (
                                        <Card className="mt-2">
                                          <div style={{ float: 'right' }}>
                                            <CloseIcon
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => removesubobj('value', index, a)}
                                            />
                                          </div>
                                          <CardHeader
                                            title={
                                              <p style={{ fontSize: '1rem' }}>
                                                Menu Prompt the caller to select from a menu of options using the keypad
                                                on their phone.
                                              </p>
                                            }
                                          />
                                          <CardContent>
                                            <Input
                                              style={{ fontSize: '0.95em' }}
                                              placeholder="1"
                                              type="number"
                                              value={i.value}
                                              onChange={e => submenuchange(e, index, a)}
                                              placeholder="Number"
                                              onWheel={e => e.target.blur()}
                                            />
                                          </CardContent>
                                        </Card>
                                      ) : null}
                                      {i.text != undefined ? (
                                        <Card className="mt-2">
                                          <div style={{ float: 'right' }}>
                                            <CloseIcon
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => removesubobj('text', index, a)}
                                            />
                                          </div>
                                          <CardHeader
                                            title={
                                              <p style={{ fontSize: '1rem' }}>Greeting Play a message to the caller</p>
                                            }
                                          />
                                          <CardContent>
                                            <Input
                                              style={{ fontSize: '0.95em' }}
                                              type="textarea"
                                              value={i.text === true ? '' : i.text}
                                              onChange={e => subtextchange(e, index, a)}
                                              placeholder="text"
                                            />
                                          </CardContent>
                                        </Card>
                                      ) : null}
                                      {i.play != undefined ? (
                                        <Card className="mt-2">
                                          <div style={{ float: 'right' }}>
                                            <CloseIcon
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => removesubobj('play', index, a)}
                                            />
                                          </div>
                                          <CardHeader title={<p style={{ fontSize: '1rem' }}>Voice Mail</p>} />
                                          <CardContent>
                                            <Input
                                              style={{ fontSize: '0.95em' }}
                                              type="text"
                                              value={i.play === true ? '' : i.play}
                                              onChange={e => {
                                                subvoicemailchange(e, index, a);
                                              }}
                                              placeholder="mp3 url"
                                            />
                                          </CardContent>
                                        </Card>
                                      ) : null}
                                      {i.forward != undefined ? (
                                        <Card className="mt-2">
                                          <div style={{ float: 'right' }}>
                                            <CloseIcon
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => removesubobj('forward', index, a)}
                                            />
                                          </div>
                                          <CardHeader
                                            title={
                                              <p style={{ fontSize: '1rem' }}>
                                                Dial This is where the phone will ring{' '}
                                              </p>
                                            }
                                          />
                                          <CardContent>
                                            <Label>Forward Calls To</Label>
                                            <Input
                                              style={{ fontSize: '0.95em' }}
                                              type="text"
                                              value={i.forward === true ? '' : i.forward}
                                              onChange={e => {
                                                subforwardchange(e, index, a);
                                              }}
                                              placeholder="Number"
                                            />
                                          </CardContent>
                                        </Card>
                                      ) : null}
                                      {i.hangup != undefined ? (
                                        <Card className="mt-2">
                                          <div style={{ float: 'right' }}>
                                            <CloseIcon
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => removesubobj('hangup', index, a)}
                                            />
                                          </div>
                                          <CardHeader title="Hangup Call" />
                                          <CardContent>
                                            <Button variant="contained" color="secondary">
                                              Hang Up
                                            </Button>
                                          </CardContent>
                                        </Card>
                                      ) : null}
                                    </div>
                                  );
                                })}
                              {item.showflow ? (
                                <Row className="mt-3">
                                  <Col md={12}>
                                    <div class="dropdown">
                                      <span style={{ color: '#2c7be5', display: 'flex', alignItems: 'center' }}>
                                        <i class="fas fa-plus-circle fa-2x" /> <Label className="mb-0">&nbsp;Add call flow</Label>
                                      </span>
                                      <div class="dropdown-content">
                                        <Label
                                          style={{ display: 'block', cursor: 'pointer' }}
                                          onClick={() => subFlow('text', index)}
                                        >
                                          Text
                                        </Label>
                                        <Label
                                          style={{ display: 'block', cursor: 'pointer' }}
                                          onClick={() => subFlow('hangup', index)}
                                        >
                                          Hangup
                                        </Label>
                                        <Label
                                          style={{ display: 'block', cursor: 'pointer' }}
                                          onClick={() => subFlow('value', index)}
                                        >
                                          Menu
                                        </Label>
                                        <Label
                                          style={{ display: 'block', cursor: 'pointer' }}
                                          onClick={() => subFlow('forward', index)}
                                        >
                                          Dial
                                        </Label>
                                        <Label
                                          style={{ display: 'block', cursor: 'pointer' }}
                                          onClick={() => subFlow('play', index)}
                                        >
                                          Voicemail
                                        </Label>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ) : null}
                            </CardContent>
                            <hr />
                          </Card>{' '}
                        </Col>
                      </Row>
                    ) : null}

                    {item.text != undefined && !item.check ? (
                      <Row className={'pt-2'}>
                        <Col md={12}>
                          <Card className="p-2 m-2">
                            <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('text', index)} />
                            </div>
                            <CardHeader
                              title={<p style={{ fontSize: '1rem' }}>Greeting Play a message to the caller</p>}
                            />
                            <CardContent>
                              <Input
                                style={{ fontSize: '0.95em' }}
                                type="textarea"
                                value={item.text === true ? '' : item.text}
                                onChange={e => {
                                  text(index, e);
                                }}
                                placeholder="text"
                              />
                            </CardContent>
                            <hr />
                          </Card>{' '}
                        </Col>
                      </Row>
                    ) : null}

                    {item.play != undefined ? (
                      <Row className={'pt-2'}>
                        <Col md={12}>
                          <Card className="p-2 m-2">
                            <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('play', index)} />
                            </div>
                            <CardHeader title={<p style={{ fontSize: '1rem' }}>Voice Mail</p>} />
                            <CardContent>
                              <Input
                                style={{ fontSize: '0.95em' }}
                                type="text"
                                value={item.play === true ? '' : item.play}
                                onChange={e => {
                                  play(index, e);
                                }}
                                placeholder="mp3 url"
                              />
                            </CardContent>
                            <hr />
                          </Card>{' '}
                        </Col>
                      </Row>
                    ) : null}

                    {item.forward != undefined ? (
                      <Row className={'pt-2'}>
                        <Col md={12}>
                          <Card className="p-2 m-2">
                            <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('forward', index)} />
                            </div>
                            <CardHeader
                              title={<p style={{ fontSize: '1rem' }}>Dial This is where the phone will ring </p>}
                            />
                            <CardContent>
                              <Label>Forward Calls To</Label>
                              <Input
                                style={{ fontSize: '0.95em' }}
                                type="text"
                                value={item.forward === true ? '' : item.forward}
                                onChange={e => {
                                  forward(index, e);
                                }}
                                placeholder="Number"
                              />
                            </CardContent>
                            <hr />
                          </Card>{' '}
                        </Col>
                      </Row>
                    ) : null}

                    {item.hangup ? (
                      <Row className={'pt-2'}>
                        <Col md={12}>
                          <Card className="p-2 m-2">
                            <div style={{ float: 'right' }}>
                              <CloseIcon onClick={() => removeobj('hangup', index)} />
                            </div>
                            <CardHeader title="Hangup Call" />
                            <CardContent>
                              <Button variant="contained" color="secondary">
                                Hang Up
                              </Button>
                            </CardContent>
                          </Card>{' '}
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                );
              })}
            </Col>
          </Row>
          <div>
            <ReactFlow elements={fields} />
          </div>
          <Row className="mt-3">
            <Col md={12}>
              <Card>
                <CardContent>
                  <Row>
                    <Col xs={1} />
                    <Col xs={2}>
                      <Tooltipbutton title="Add the greeting">
                        <Button style={{ display: 'block' }} onClick={() => flowsdata('text')}>
                          <TextFields /> <p>text</p>
                        </Button>
                      </Tooltipbutton>
                      {/* <Button style={{display: "block"}} onClick={()=>text}  ><TextFields></TextFields> <p>text</p>
                                                    </Button> */}
                    </Col>
                    <Col xs={2}>
                      <Tooltipbutton title="Add the disconnect call">
                        <Button style={{ display: 'block' }} onClick={() => flowsdata('hangup')}>
                          <CallEndIcon />
                          <p>Hang Up</p>
                        </Button>
                      </Tooltipbutton>
                      {/* <Button style={{display: "block"}}  onClick={()=>hangup} ><CallEndIcon/><p>Hang Up</p></Button> */}
                    </Col>
                    <Col xs={2}>
                      <Tooltipbutton title="Add the Menu option">
                        <Button style={{ display: 'block' }} onClick={addField}>
                          <DialpadIcon />
                          <p>Menu</p>
                        </Button>
                      </Tooltipbutton>
                      {/* <Button style={{display: "block"}} onClick={()=>flowsdata("menu")} ><DialpadIcon></DialpadIcon><p>Menu</p></Button> */}
                    </Col>
                    <Col xs={2}>
                      <Tooltipbutton title="Add the forward call tool">
                        <Button style={{ display: 'block' }} onClick={() => flowsdata('forward')}>
                          <PhoneForwardedIcon />
                          <p>Dial</p>
                        </Button>
                      </Tooltipbutton>
                      {/* <Button style={{display: "block"}}  onClick={()=>forward } ><PhoneForwardedIcon></PhoneForwardedIcon><p>Dial</p></Button> */}
                    </Col>
                    <Col xs={2}>
                      <Tooltipbutton title="Add the play the voicemail">
                        <Button style={{ display: 'block' }} onClick={() => flowsdata('play')}>
                          <VoicemailIcon /> <p>Voicemail</p>
                        </Button>
                      </Tooltipbutton>
                    </Col>
                    <Col xs={1} />
                  </Row>
                </CardContent>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center pt-4">
            <Col>
              <Card>
                <Row className=" p-4">
                  <Col md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    {fields.length > 0 ? (
                      <Button variant="contained" color="primary" onClick={submit}>
                        Save & Finish{' '}
                      </Button>
                    ) : null}
                  </Col>
                  <Col md={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to="/callflow">
                      <Button color="secondary" variant="contained" onClick={refresh}>
                        Cancel
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = state => ({
  detail: state.Twilio.detail,
  flow: state.Twilio.getflow
});
export default connect(
  mapStateToProps,
  { getnumber, postflow, getflow }
)(NewCallFlow);

import { SIGN_UP,  LOG_IN, LOGOUT, RESET_PASSWORD, PASSWORD_CHANGE,  GET_TOKENS} from '../Actions/Auth/type';
import {IS_PAID,HEARD_ABOUT, GET_TOKEN,CHECK_SUB} from "../Actions/type"
const initialState = {
  
    // token: localStorage.getItem('token'),
    isAuthenticated:  localStorage.getItem('token')  ? true : false,
    verified: false,
    changed: false,
    token: null,
    isPaid: false,
    heards: null,
    subs:null
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    
    case CHECK_SUB:
      return{
        ...state,
        subs: payload
      }
    case HEARD_ABOUT:
      return{
        ...state,
        heards: payload
      }
    case GET_TOKEN:
      return {
        ...state,
        isPaid: true

      }
    case IS_PAID:
      return {
        ...state,
        isPaid: payload
      }
    case SIGN_UP:
    case LOG_IN:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        verified: false,
        changed: false

      }
    case RESET_PASSWORD:
        return {
          ...state,
          isAuthenticated: false,
          verified: payload,
          changed: false
  
        }
    case PASSWORD_CHANGE:
          return {
            ...state,
            isAuthenticated: false,
            verified: false,
            changed: payload
    
          }
    case GET_TOKENS:
          return {
            ...state,
            isAuthenticated: true,
            verified: false,
            changed: false,
            token: payload,
      
            }
      case LOGOUT:
        return {
          ...state,
          // token: localStorage.removeItem('token'),
          isAuthenticated: false,
          verified: false,
          isPaid: false
        
        };
    default:
      return state;
  }
}

import React from 'react'
import PropTypes from 'prop-types';


import Loader from "../../../components/common/Loader"
import {connect} from 'react-redux'
import "./style.css"
import  {updatecard, getcardbyid} from "../../../Redux/Actions/Payment"
import {Redirect} from "react-router-dom"
import {Card, CardBody, Row, Col} from "reactstrap"
class EditCard extends React.Component
{   
    state= {
        
    }
    componentDidUpdate(prevProps)
    {
        if(this.props.card !== prevProps.card )
        {   
            const {card_exp_month, card_exp_year, card_id}= this.props.card
            this.setState({exp_month: card_exp_month, exp_year:card_exp_year, card_id: card_id })
        }
    }
    componentDidMount(){
        // this.props.getcardbyid(this.props.match.params.id)
        this.props.getcardbyid(this.props.id)
    }
    
    handleChange = (e)=>{
        this.setState({[e.target.name]:e.target.value})
     }
    Submit = (e)=> {
         e.preventDefault()
        this.props.updatecard(this.state )
    }
    render(){
        if(this.props.detail === null)
        {
            return <Loader></Loader>
        }
        else if(this.props.updated)
        {
            // return <Redirect to="/card"></Redirect>
            this.props.editToggle()

        }
        else {
         return <Row className="justify-content-center p-2">
           
           
           {/* <Card>
             <CardBody> */}
              <div className="">
                <form onSubmit={this.Submit}>
                
                  <Row>
                  <Col md={12}>
                      {/* <h3 style={{ fontSize: "0.95em"}}>{'Edit Card'}</h3> */}
                      
                      <label for="cname">Name on Card</label>
                      <input style={{ fontSize: "0.95em", marginBottom: "10px" ,padding: '3px 12px 3px '}} type="text" required onChange={this.handleChange} id="cname" name="name" placeholder="John More Doe"/>
                      <label for="ccnum">Credit card number</label>
                      <input style={{ fontSize: "0.95em", marginBottom: "10px", padding: '3px 12px 3px '}} type="text" maxLength="20" autoComplete="cc-number"  pattern="\d*" required onChange={this.handleChange} id="ccnum" name="number" placeholder="1111-2222-3333-4444"/>
                      <label for="expmonth">Exp Month</label>
                      <input style={{ fontSize: "0.95em", marginBottom: "10px", padding: '3px 12px 3px '}} type="text"  pattern="\d*" maxLength="2" minLength="2"   required onChange={this.handleChange} id="expmonth" name="exp_month" value={this.state.exp_month} placeholder="01"/>
                      <div className="row">
                        <div className="col1-50">
                          <label for="expyear">Exp Year</label>
                          <input style={{ fontSize: "0.95em", marginBottom: "10px", padding: '3px 12px 3px '}} type="text" maxLength="4"   pattern="\d*" required onChange={this.handleChange} id="expyear" name="exp_year" value={this.state.exp_year} placeholder="2018"/>
                        </div>
                        <div className="col1-50">
                          <label for="cvv">CVV</label>
                          <input style={{ fontSize: "0.95em", marginBottom: "10px", padding: '3px 12px 3px '}} type="text" required onChange={this.handleChange} id="cvv" name="cvc" placeholder="352"/>
                        </div>
                      </div>
                    </Col>
                    
                  </Row>
                  <input type="submit" value="Submit" className="btn btn-primary"/>
                </form>
              </div>
          
            
             {/* </CardBody>
           </Card> */}
          
          </Row>
    }
     }
}
const mapStateToProps = (state) => ({
    card: state.payment.card,
    updated: state.payment.updated

 });
EditCard.propTypes = {
    getcardbyid: PropTypes.func.isRequired,
    updatecard: PropTypes.func.isRequired
  };
export default connect(mapStateToProps, {getcardbyid, updatecard})(EditCard)
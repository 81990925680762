import React, { useEffect} from "react"
import {connect} from "react-redux"
import {useParams} from "react-router-dom"
import  {getmetrics, countstats, downloadstats} from "../../../Redux/Actions/Mask/Mask"
import {Row, Col,  Table, Card, CardBody, CardTitle} from "reactstrap"

import moment from 'moment'
import Loader from "../../../components/common/Loader"
import CardHeader from "reactstrap/lib/CardHeader"
import io from 'socket.io-client';
// const socket = io(process.env.REACT_APP_PORT, {
//     query: {
//       email: localStorage.getItem('email'),
//       filename:"Metrics"
//     }
//   });
const SendMessage = ({getmetrics, metrics, countstat, countstats, downloadstats })=>{
    let {id} = useParams()
    useEffect(()=>{
            
        getmetrics(id)
        
        countstats(id)
            
    }, [getmetrics])
    const statsfile = (cat)=>{
        const pathname = window.location.pathname

        downloadstats(id, cat,pathname)
        // socket.emit('notification', localStorage.getItem('email'));

    }
    if(metrics == null)
    {
        return <Loader/>
    }
    return<>
              <Row>
                <Col className="p-4" md={6}>
                    <Card style={{fontSize: "0.9rem"}}>
                        <CardHeader>
                            <CardTitle>Campaign Stats</CardTitle>
                            <hr/>
                        </CardHeader>
                        {countstat != null &&    
                        <CardBody>
                            <Row>
                                <Col md={8} xs={8}>
                                 <span className="text-success"><i  class="far fa-address-book" ></i>  Phone Numbers</span>
                                </Col>
                                <Col md={4} xs={4}>
                                  {countstat.phonenumbers} <i onClick={()=>statsfile("phone")} class="fas fa-download text-primary px-1"></i>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col md={8} xs={8}>
                                <span className="text-success"><i  class="fas fa-mobile"></i>  Mobile</span>
                                </Col>
                                <Col md={4} xs={4}>
                                {countstat.mobile}  <i onClick={()=>statsfile("mobile")} class="fas fa-download text-primary px-1"></i>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col md={8} xs={8}>
                                <span className="text-success"><i  class="fas fa-phone"></i>  Landline</span>
                                </Col>
                                <Col md={4} xs={4}>
                                {countstat.landline} <i onClick={()=>statsfile("landline")} class="fas fa-download text-primary px-1"></i>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col md={8} xs={8}>
                                <span className="text-success"><i  class="fas fa-question"></i>  Other Phone</span>
                                </Col>
                                <Col md={4} xs={4}>
                                {countstat.phoneother} <i onClick={()=>statsfile("other")} class="fas fa-download text-primary px-1"></i>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col md={8} xs={8}>
                                <span className="text-danger"><i  class="fas fa-gavel"></i>  Litigators</span>
                                </Col>
                                <Col md={4} xs={4}>
                                {countstat.litigator} <i onClick={()=>statsfile("litigator")} class="fas fa-download text-primary  px-1"></i>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col md={8} xs={8}>
                                <span className="text-danger"><i  class="fas fa-phone-slash"></i> DNC</span>
                                </Col>
                                <Col md={4} xs={4}>
                                {countstat.dnc}  <i onClick={()=>statsfile("dnc")} class="fas fa-download text-primary px-1"></i>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col md={8} xs={8}>
                                <span className="text-danger"><i  class="fas fa-bolt"></i>  Priority Count</span>
                                </Col>
                                <Col md={4} xs={4}>
                                {countstat.priority}  <i onClick={()=>statsfile("priority")} class="fas fa-download text-primary px-1"></i>
                                </Col>
                            </Row>
                            <hr/>
                            
                            <Row>
                                <Col md={8} xs={8}>
                                <span className="text-danger"><i  class="fas fa-star"></i>  Leads Count</span>
                                </Col>
                                <Col md={4} xs={4}>
                                {countstat.qualified}  <i onClick={()=>statsfile("qualified")} class="fas fa-download text-primary px-1"></i>
                                </Col>
                            </Row>
                            <hr/>
                        </CardBody>
                        
                    }
                     </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="p-4">
                    <Card>
                        <CardHeader>
                        <CardTitle style={{ fontSize: "0.95em"}}>Metrics</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                    <th>Batch #</th>
                                    <th>Sent</th>
                                    <th>Delivered</th>
                                    <th>Delivery Rate</th>
                                    <th>Response</th>
                                    <th>Response Rate</th>
                                    <th>Start</th>
                                    <th>End</th>
                                </thead>
                                {metrics.length > 0 && metrics.map(item=>(
                                    <tbody>
                                        <td>Batch #{item.name}</td>
                                        <td>{item.sent}</td>
                                        <td>{item.delivered}</td>
                                        <td>{item.sent == 0 ?  0 :(item.delivered/item.sent)*100}%</td>
                                        <td>{item.received}</td>
                                        <td>{item.sent == 0 ?  0 :(item.received/item.sent)*100}%</td>
                                        <td style={{whiteSpace:"nowrap"}} >{moment.utc(item.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td style={{whiteSpace:"nowrap"}} >{item.date_end != undefined ? moment.utc(item.date_end ).local().format('YYYY-MM-DD HH:mm:ss') : null}</td>
                                    </tbody>
                                ))}
                            </Table>
                
                        </CardBody>
                    </Card>
                </Col>
                
            </Row>
    </> 
    }
    const mapeStateToProps = (state)=>({
    metrics: state.bulk.metrics,
    countstat: state.bulk.countstat
   
    
    })

export default connect(mapeStateToProps, {getmetrics, countstats, downloadstats})(SendMessage)
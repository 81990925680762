import Axios from 'axios';

import { toast } from 'react-toastify';
import {
  GET_FLOW,
  GET_REPORT,
  GET_FLOWS,
  GET_COMPANY,
  COUNT_VOICES,
  COUNT_MESSAGES,
  USER_MESSAGES,
  GET_USER_FORM,
  USER_VOICE,
  GET_ALL_FAX,
  GET_ALL_COMPANY,
  GET_FORM_WEBHOOK,
  GET_USER_STATUS,
  GET_SEARCH,
  CALL_CAMP_LOGS,
  GET_USER,
  CALL_MONITOR,
  GET_SESSION,
  GET_DNC_LIST_ALL,TIME_ZONE
} from '../type';

export const submit = (tmptz)=>async dispatch=>{
  
  try{
    // console.log("insie action tz is"+tmptz)
    var data = JSON.stringify({
      timezone: tmptz,
      email:localStorage.getItem('email')
    });
    await Axios({
      url: process.env.REACT_APP_PORT + "/api/me/timeezone",
      method: "post",
      headers: {
        'x-auth-token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      data: data
    })
    dispatch({
      type: TIME_ZONE,
      payload: tmptz
    });
    // console.log("data inside action"+data)
    toast.success("Timezone Updated")
  }
  catch(e){
    toast.error(e.response.data.error);
    console.log(e);
  }
}
export const gettz = () => async dispatch => {
  try {
    var data = JSON.stringify({
      email:localStorage.getItem('email')
    });
    const res = await Axios({
      method: "post",
      url: process.env.REACT_APP_PORT + '/api/me/gettz',
      headers: {
        'x-auth-token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },data: data
    });
    // console.log("server response is "+JSON.stringify(res.data))
    dispatch({
      type: TIME_ZONE,
      payload: res.data
    });
      
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};

export const getdnclists = (off) => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/campaign/newdnc/?offset='+off,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    // console.log(res.data.data)
    dispatch({
      type: GET_DNC_LIST_ALL,
      payload: res.data.data
    });
      
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};

export const searchdnclists = (number) => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/campaign/searchdnc/?number='+number,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    // console.log("log",res.data.data)
    dispatch({
      type: GET_DNC_LIST_ALL,
      payload: res.data.data
    });
      
  } catch (e) {
    toast.error(e?.response?.data?.error);
    console.log(e);
  }
};

export const addDncList = (number) => async dispatch => {
  try {
    // console.log({number})
    let data
    let a=number.startsWith("+")
    // console.log({a})
    if(a){
      data=number.split("+")[1]
    }else{
      data=number
    }
    

    const res = await Axios({
      
      url: process.env.REACT_APP_PORT + '/api/market/dncadd',
      method: 'POST',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
       data: {
        phone:data
      }
    });
    // console.log("as",res.data.data)
    dispatch(getdnclists(0));
      
  } catch (e) {
    toast.error(e?.response?.data?.error);
    console.log(e);
  }
};

export const removednclist = (id, phone)=>async dispatch=>{
  try{
    await Axios({
      url: process.env.REACT_APP_PORT + "/api/campaign/removednc",
      method: "post",
      
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        id,
        phone
      }
    })
    dispatch(getdnclists(0))
    toast.success("Dnc Removed")
  }
  catch(e){
    toast.error(e.response.data.error);
    console.log(e);
  }
}





export const getSession = (camp) => async dispatch => {
  try {
    // console.log("jett", camp)
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/session/'+camp,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    dispatch({
      type: GET_SESSION,
      payload: res.data.data
    });
      
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const createSession = (camp) => async dispatch => {
  try {
    // console.log("jett", camp)
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + '/api/me/session/',
      data: {
        camp
      },
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const CALLCAMPLOGS = (camp) => async dispatch => {
  try {
    // console.log("jett", camp)
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/campcalllogs/'+camp,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;
    // console.log("dddd", token)
    dispatch({
      type: CALL_CAMP_LOGS,
      payload: token
    });
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const callmonitor = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/callmonitor',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;
    // console.log("token", token)
    dispatch({
      type: CALL_MONITOR,
      payload: token
    });
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const countmessages = offset => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/message/count',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;

    dispatch({
      type: COUNT_MESSAGES,
      payload: token
    });
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const countfiltermessages = (outboundcheck,inboundcheck,search) => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/message/filtercount?search='+search+"&inbound="+inboundcheck+"&outbound="+outboundcheck,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;
// console.log({token})
    dispatch({
      type: COUNT_MESSAGES,
      payload: token
    });
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const countfiltervoice = (outboundcheck,inboundcheck,search) => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/voice/filtercount?search='+search+"&inbound="+inboundcheck+"&outbound="+outboundcheck,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;
// console.log({token})
dispatch({
  type: COUNT_VOICES,
  payload: token
});
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const countvoices = offset => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/voice/count',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;

    dispatch({
      type: COUNT_VOICES,
      payload: token
    });
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const getMessages = (offset, inboundcheck, outboundcheck) => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/allmessage?offset=' + offset+"&inbound="+inboundcheck+"&outbound="+outboundcheck,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;

    dispatch({
      type: USER_MESSAGES,
      payload: token
    });
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const getVoices = (offset, inboundcheck, outboundcheck) => async dispatch => {
  try {
  
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/voice?offset=' + offset+"&inbound="+inboundcheck+"&outbound="+outboundcheck,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;
    dispatch({
      type: USER_VOICE,
      payload: token
    });
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};
export const getallfax = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/fax',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;
    dispatch({
      type: GET_ALL_FAX,
      payload: token
    });
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};
export const getallCompanies = name => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/company?name=' + name,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;
    dispatch({
      type: GET_ALL_COMPANY,
      payload: token
    });
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};
export const createComapny = name => async dispatch => {
  try {
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/company',
      data: {
        name: name
      },
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    dispatch(getallCompanies());
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

export const saveformdata = (data,number) => async dispatch => {
  try {
   
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/saveformdata',
      data: {
        data: data,
        number:number
      },
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    toast.success('Data Sent to Your Webhook');
  } catch (e) {
    console.log(e);
  }
};
export const getFormhook = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'get',
      url: process.env.REACT_APP_PORT + '/api/me/hook',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    dispatch({
      type: GET_FORM_WEBHOOK,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);

    // toast.error(e.response.data.error);
  }
};
export const saveFormhook = (hook, webhook, crm) => async dispatch => {
  try {
    // console.log('Dsa', crm);
    const res = await Axios({
      method: 'put',
      url: process.env.REACT_APP_PORT + '/api/me/hook',
      data: {
        hook: hook,
        note: webhook,
        crm: crm
      },
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    // console.log(res);
    toast.success('saved');
    dispatch(getFormhook());
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};
export const getuserform = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'get',
      url: process.env.REACT_APP_PORT + '/api/me/getform',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    // console.log("res data form",res.data.data)
    if(res.data.data!="Please create your form"){
    dispatch({
      type: GET_USER_FORM,
      payload: res.data.data
    });}
  } catch (e) {
    console.log(e);

    // toast.error(e.response.data.error);
  }
};
export const getCompanyById = id => async dispatch => {
  try {
    const res = await Axios({
      method: 'get',
      url: process.env.REACT_APP_PORT + '/api/me/company/' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    // console.log(id, res.data);
    dispatch({
      type: GET_COMPANY,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};
export const updateCompanyById = (id, name) => async dispatch => {
  try {
    const res = await Axios({
      method: 'put',
      url: process.env.REACT_APP_PORT + '/api/me/company/' + id,
      data: {
        name: name
      },
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    toast.success('Company Name Updated!');
    dispatch(getCompanyById(res.data.data._id));
    dispatch(getallCompanies())
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};
export const contactdeleted = (id, name) => async dispatch => {
  try {
    const res = await Axios({
      method: 'delete',
      url: process.env.REACT_APP_PORT + '/api/me/company/' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    toast.success('Company Deleted!');
    dispatch(getallCompanies());
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

export const getuserStatus = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'get',
      url: process.env.REACT_APP_PORT + '/api/me/status',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    // console.log(res.data.data);
    dispatch({
      type: GET_USER_STATUS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

export const postuserStatus = status => async dispatch => {
  try {
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/status',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        toggle: status
      }
    });
    // console.log({res})
    dispatch(getuserStatus());
    //    dispatch(setAlert("Status Changed", "success"))
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

export const postflow = (flows, number, name,check) => async dispatch => {
  try {
    // console.log("check",flows,number,name)
    if(check==="create"){
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/callflow',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        flow: flows,
        number: number,
        name: name
      }
    });
    // console.log({res})
    dispatch(getflows());
    toast.success('Flow Saved!');
  }else if(check==="edit"){
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/callflowedit',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        flow: flows,
        number: number,
        name: name
      }
    });
    // console.log({res})
    dispatch(getflows());
    toast.success('Flow Saved!');
  }

  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

export const postflowedit = (flows, number, name) => async dispatch => {
  try {
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/callflowedit',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        flow: flows,
        number: number,
        name: name
      }
    });
    dispatch(getflows());
    toast.success('Flow Saved!');
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

export const savenotes = (note, from, to) => async dispatch => {
  try {
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/note',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        note: note,
        from,
        to
      }
    });

    toast.success('Submitted ');
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

export const crmdata = id => async dispatch => {
  try {
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/crm',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        id: id
      }
    });

    toast.success('Data sent to webhook!');
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

export const getflows = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'get',
      url: process.env.REACT_APP_PORT + '/api/me/getflows',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    dispatch({
      type: GET_FLOWS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};
export const deleteflow = id => async dispatch => {
  try {
    const res = await Axios({
      method: 'delete',
      url: process.env.REACT_APP_PORT + '/api/me/deleteflow/' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    toast.success('Deleted!');
    dispatch(getflows());
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

export const getflow = (id,flow) => async dispatch => {
  try {
    const res = await Axios({
      method: 'get',
      url: process.env.REACT_APP_PORT + `/api/me/getflow/${id}/${flow}`,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
// console.log({res})
    dispatch({
      type: GET_FLOW,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    //
    toast.error(e.response.data.error);
  }
};

export const reportdetails = date => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/me/report?days=' + date,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    dispatch({
      type: GET_REPORT,
      payload: res.data.data
    });
    // console.log('response', res.data);
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};

//calling api for searching voice

export const voiceSearch = (search,outboundcheck,inboundcheck,offset) => async dispatch => {
  try {
    if(search.toLowerCase()==="noanswer"){
      // console.log("enterd")
      search="no-answer"
    }
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/find/voice?offset=' + offset,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        search: search.toLowerCase(),
        inbound:inboundcheck,
        outbound:outboundcheck
      }
    });
    const searchData = res.data.data;
    dispatch({
      type: USER_VOICE,
      payload: searchData
    });
  } catch (e) {
    console.log(e);

    toast.error(e?.response?.data?.error);
  }
};

//calling api for search message

export const messageSearch = (search,outboundcheck,inboundcheck,offset) => async dispatch => {
  try {
    
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/find/messages?offset=' + offset,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        search: search.toLowerCase(),
        inbound:inboundcheck,
        outbound:outboundcheck
      }
    });
    const searchData = res.data.data;
    dispatch({
      type: USER_MESSAGES,
      payload: searchData
    });
  } catch (e) {
   
    console.log(e);

    toast.error(e.response.data.error);
  }
};

//calling api for search fax

export const faxSearch = search => async dispatch => {
  try {
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/me/find/fax',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      },
      data: {
        search: search
      }
    });
    const searchData = res.data.data;
    dispatch({
      type: GET_ALL_FAX,
      payload: searchData
    });
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};
//get user details
export const getUser = () => async dispatch => {
  try {
    const res = await Axios({
      method: 'get',
      url: process.env.REACT_APP_PORT + '/api/me/user',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    // console.log(res.data.data);
    dispatch({
      type: GET_USER,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);

    toast.error(e.response.data.error);
  }
};

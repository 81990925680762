import React from 'react'
import PropTypes from 'prop-types';

import Loader from "../../../components/common/Loader"
import {connect} from 'react-redux'
import  {getnumber, updatenumber} from "../../../Redux/Actions/Twilio"
import {Row, Col, Card, CardBody, Button} from 'reactstrap'
import {Input , Label} from "reactstrap"
import   {getflows} from "../../../Redux/Actions/USER/user"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

class EditNumber extends React.Component
{   
    state= {
        num: "",
        twilio:"",
        voice: "",
        sms:"",
        name: "",
        callflow: ""
    }
    componentDidUpdate(prevProps)
    {
        if(this.props.detail !== prevProps.detail )
        {   
            const data = this.props.detail
            this.setState({callflow: data.callflow,num: data.pnumber, voice: data.voice, sms: data.sms, twilio: data.number, name: data.name })
        }
    }
    componentDidMount(){
        this.props.getnumber(this.props.match.params.id)
        this.props.getflows()
    }
    
    handleChange = (e)=>{
        this.setState({[e.target.name]:e.target.value})
     }
     // Function to validate phone number format
    isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to match the format + followed by at least 10 digits
    const phoneRegex = /^\+\d{10,}$/;
    return phoneRegex.test(phoneNumber);
    };
    Submit = (id)=> {
        const {num, twilio, voice, sms, name, callflow} = this.state
          // Validate 'num' field
        if (!this.isValidPhoneNumber(num)) {
            toast.error('Please provide a valid phone number in the format +1XXXXXXXXXX');
            return; // Exit the function early if the 'num' field is not valid
        }
        this.props.updatenumber(this.props.match.params.id, voice, sms, num, name , callflow )
    }
    render(){
        if(this.props.detail === null)
        {
            return <Loader></Loader>
        }
        else {
            return <Row  className="justify-content-center ">
            <Col md="10">
                <Card>
                <Row>
                    <Col md="12" className="px-4 pt-2">
                        
                            <p> Your number is :{this.state.twilio} </p>
                       
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="px-4 pt-2">
                        
                            <Label>Friendly Name</Label>
                            <Input style={{ fontSize: "0.95em"}} placeholder="Alias"  type="text" onChange={this.handleChange} name="name" value={this.state.name  }></Input>
                       
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="px-4 pt-2">
                        
                            <Label>Call Flow</Label>
                            <Input style={{ fontSize: "0.95em"}} placeholder="Call FLow" placeholder="Select Flow"  type="select" onChange={this.handleChange} name="callflow" >
                                <option >Select Call Flow</option>
                               {this.props.flows != null && this.props.flows.length > 0 ? this.props.flows.map(item=><option selected={item._id === this.state.callflow} value={item._id}>{item.name}</option>): null} 
                                
                            </Input>
                       
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="px-4 pt-2">
                        
                            <Label>Number</Label>
                            <Input style={{ fontSize: "0.95em"}} placeholder="Call Forwarding Number"  type="text" onChange={this.handleChange} name="num" value={this.state.num}></Input>
                            <small id="emailHelp" class="form-text text-muted">
                            Your calls will forward be to the above number
                      </small>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="px-4 pt-2">
                        
                            <Label>Voice</Label>
                            <Input style={{ fontSize: "0.95em"}} placeholder="Enter Voice Webhook "  type="text" onChange={this.handleChange} name="voice" value={this.state.voice}></Input>
                            <small id="emailHelp" class="form-text text-muted">
                            Your incoming and outgoing logs will be sent to the above mention webhook
                      </small>
                       
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="px-4 pt-2">
                        
                            <Label>SMS</Label>
                            <Input style={{ fontSize: "0.95em"}} placeholder="Enter SMS Webhook " type="text" onChange={this.handleChange} name="sms" value={this.state.sms}></Input>
                      <small id="emailHelp" class="form-text text-muted">
                      Your incoming and outgoing sms will be sent to the above mention webhook
                      </small>
                    </Col>
                </Row>
                <Row className="p-4">
                    <Col md="12" className="px-4 pt-2">
                        <Link to="/me/numbers">
                        <Button onClick={()=>this.Submit()} color="primary">Update Changes</Button></Link>
                    </Col>
                </Row>
            </Card>
                
            </Col>
        </Row>
    
        }
     }
}
const mapStateToProps = (state) => ({
    detail: state.Twilio.detail,
    flows: state.Twilio.getflows

 });
EditNumber.propTypes = {
    getnumber: PropTypes.func.isRequired,
    updatenumber: PropTypes.func.isRequired
  };
export default connect(mapStateToProps, {getnumber, updatenumber, getflows})(EditNumber)
import React, { useState , useEffect} from 'react'
import {TabContent, TabPane, Nav, NavItem, NavLink, Card,  Row, Col, Table,CardTitle, CardBody, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import PropTypes from 'prop-types';
import {Button } from "@material-ui/core"

import Loader from "../../../components/common/Loader"
import  {getTemplates,deleteTemplate,getCategories,deleteCategory} from "../../../Redux/Actions/Mask/Mask"
import {connect} from 'react-redux'
import {Link} from "react-router-dom"
import classnames from 'classnames';
import QuickReplies from "./quickReplies"
const TemplatePage = ({getTemplates,templates,deleteTemplate,categories,getCategories,deleteCategory}) => { 
    useEffect(()=>{
        getTemplates()
        getCategories()
    }, [getTemplates,getCategories])
console.log("Cat",templates)
    const delTemplate = (id)=>{
       deleteTemplate(id)
    }
    const delCategory = (id)=>{
console.log("Del Cat",id)
       deleteCategory(id)
    }
   
   const [activeTab, setActiveTab] = useState('2');
   const [delModal, setDelModal] = useState(false);
   const [delID, setDelID] = useState("")
const [delTitle, setDelTitle] = useState("")

const [delcModal, setDelcModal] = useState(false);
const [delcID, setDelcID] = useState("")
const [delcTitle, setDelcTitle] = useState("")

   const deleteMe=(title,id)=>{

    setDelID(id);
  setDelTitle(title);
  setDelModal(true)


}
const delToggle = () => {

setDelModal(!delModal)

};

const removeMeConfirm = () => {
  
  delTemplate(delID)
  setDelModal(false)   
};
//catogry
const deletecMe=(title,id)=>{

  setDelcID(id);
setDelcTitle(title);
setDelcModal(true)


}
const delcToggle = () => {

setDelcModal(!delcModal)

};

const removeConfirm = () => {
  delCategory(delcID)
setDelcModal(false)   
};

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
   if(templates === null || categories === null)
   {
       return <Loader></Loader>
   } 
  return (
      <Row className="justify-content-center ">
       <Col md="12">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Categories
          </NavLink>
        </NavItem>
        <NavItem>
        <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Templates
          </NavLink>

        </NavItem>
        <NavItem>
          
        <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Quick Replies
          </NavLink>
        </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                    <Row>
                        <Col md="10">
                          <CardTitle style={{fontSize: "0.95rem"}}>Template</CardTitle>
                        </Col>
                        <Col md="2">
                        <Link className="btn btn-primary btn-sm"to="/template/createTemplate" style={{whiteSpace:"nowrap"}}>Add Template</Link>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table responsive bordered striped hover >
                        <thead >
                            <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Edit</th>
                            <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates!= undefined && templates.map((item, index)=> (
                                <tr key={item._id}>
                                    <th scope="row">{index +1}</th>
                                    <td>{item.name}</td>
                                    <td>{item.category && item.category.name}</td>
                                    <td><Link to={`/template/template/${item._id}`}><i  className="fa fa-edit" ></i></Link></td>
                                    <td><i className="is-isolated fas fa-trash" onClick={()=>deleteMe(item.name,item._id)}></i></td>       
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {delModal?
                        <Modal isOpen toggle={delToggle}>
            <ModalHeader toggle={delToggle} style={{backgroundColor:"#becdda8f"}}><h5 style={{fontWeight:"bold"}}>Remove - {delTitle}</h5></ModalHeader>
            <ModalBody>Are you sure you want to remove this? This action cannot be undone.
          </ModalBody>
        <ModalFooter>
                     <Button className="mr-2" variant="contained" color="secondary" onClick={() =>delToggle()}>No</Button>
                  <Button variant="contained" style={{backgroundColor:"#28a745",color:"white"}} onClick={() =>removeMeConfirm()}>Yes</Button>
          
        </ModalFooter>
            </Modal>
            :null}
                </CardBody>
            </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                    <Row>
                        <Col md="10">
                            <CardTitle style={{fontSize: "0.95rem"}}>Category</CardTitle>
                        </Col>
                        <Col md="2">
                        <Link className="btn btn-primary btn-sm"to="/template/createCategory" style={{whiteSpace:"nowrap"}}>Add Category</Link>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table responsive bordered striped hover >
                        <thead >
                            <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Edit</th>
                            <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((item, index)=> (
                                <tr key={item._id}>
                                    <th scope="row">{index +1}</th>
                                    <td>{item.name}</td>
                                    <td><Link to={`/template/category/${item._id}`}><i  className="fa fa-edit" ></i></Link></td>
                                    <td><i className="is-isolated fas fa-trash" onClick={()=> deletecMe(item.name,item._id)}></i></td>       
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {delcModal?
                        <Modal isOpen toggle={delcToggle}>
            <ModalHeader toggle={delcToggle} style={{backgroundColor:"#becdda8f"}}><h5 style={{fontWeight:"bold"}}>Remove - {delcTitle}</h5></ModalHeader>
            <ModalBody>Are you sure you want to remove this? This action cannot be undone.
          </ModalBody>
        <ModalFooter>
                     <Button className="mr-2" variant="contained" color="secondary" onClick={() =>delcToggle()}>No</Button>
                  <Button variant="contained" style={{backgroundColor:"#28a745",color:"white"}} onClick={() =>removeConfirm()}>Remove</Button>
          
        </ModalFooter>
            </Modal>
            :null}
                </CardBody>
            </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <QuickReplies></QuickReplies>
         </TabPane>
        
       </TabContent>
      </Col>
      
     </Row>
    );
}

const mapStateToProps = (state) => ({
    templates: state.bulk.templates,
    categories:state.bulk.categories
 });

TemplatePage.propTypes = {
    getTemplates: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired
  };
export default connect(mapStateToProps, {getTemplates,deleteTemplate,getCategories,deleteCategory})(TemplatePage)
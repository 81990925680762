import React,{useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isLogin} from '../util/util'
import {connect} from "react-redux"
import {checksub,checkbal} from "../Redux/Actions/Payment"
import { useSelector } from 'react-redux'
var moment = require('moment-timezone');
const param = new URLSearchParams(window.location.search)
      const f = param.get('from')
      const g = param.get('to')
        if(f== null && g!=null)
       {
         localStorage.setItem("redirecturl",`/pages/call?to=${g}` )
       }
       else if(f!= null && g!=null)
       {
         localStorage.setItem("redirecturl",`/pages/call?from=${f}&to=${g}` )
       }

const PrivateRoute = ({
  component: Component,
  isPaid,checksub,subs,checkbal,
  ...rest
}) =>{ 
  // {console.log("subs",subs)}
// {console.log("login",isLogin())}
const currtz=useSelector(state=>state.Twilio.timezone)
useEffect(() => {
  checkbal();
  // console.log("page changed")
}, [Component])
// console.log("component ",Component)
var subsactive=true
// if(moment().unix()>subs.edate)
// {
//   subsactive=false
//   console.log("subs active is",subsactive)
//   // stripesub();
// }

if (subs&&moment.unix(subs.edate).utc().tz(currtz).endOf('day').isBefore(moment.tz(currtz))) {
  // console.log("true")
  subsactive=false
  console.log("subs active is",subsactive)
}
console.log("is paid is ----",subs,isPaid,subsactive)
    return(
  <Route
    {...rest}
    render={props =>
      
        isLogin() && isPaid &&subsactive? (
        <>
        <Component {...props} />
        {/* {window.location.pathname != "/conversation" ?<Footer/> : null} */}
        </>
      ) : isLogin() && !subsactive ? (
        // <Component {...props} />
        <Redirect to="/payment" />    
      ) : isLogin() && !isPaid? (<Redirect to="/payment" />  ): (
        <Redirect to="/authentication/login" />
      )
      
    }
  />
);
}



const mapeStateToProps = (state) => ({
    isPaid: state.auth.isPaid,
     isAuthenticated: state.auth.isAuthenticated,
     subs:state.auth.subs
})


export default connect(mapeStateToProps,{checksub,checkbal})(PrivateRoute);
import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CustomInput,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import PropTypes from 'prop-types';

import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';

import Loader from '../../../components/common/Loader';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import {
  getProspects,
  filterdatabyprospects,
  deleteprospects,
  getLeadStage,
  updatedncstatus,
  verifiedprospect,
  removeverifiedprospect,
  wrongnumprospects,
  removewrongnumprospects,
  isqualified,
  wasqualified,
  isPriority,
  wasPriority,
  exportprosdata,
  countallProspects,
  getCampaigns
} from '../../../Redux/Actions/Mask/Mask';
import { Link } from 'react-router-dom';
import Input from 'reactstrap/lib/Input';
import { CSVLink } from 'react-csv';
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSlash, faCheckSquare, faTimesCircle, faStar, faBolt } from '@fortawesome/free-solid-svg-icons';
import io from 'socket.io-client';
import { MouseOutlined } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import './page.css';
import Search from './search';
import { socket } from '../Main/socket';
import { getallCompanies } from '../../../Redux/Actions/USER/user';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';

import corner3 from '../../../assets/img/illustrations/corner-3.png';
import zIndex from '@material-ui/core/styles/zIndex';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';

// const socket = io(process.env.REACT_APP_PORT, {
//   query: {
//     email: localStorage.getItem('email'),
//     filename:"prospects"
//   }
// });
const getImage = color => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};
let headers = [
  { label: 'Campaign', key: 'campaign.name' },
  { label: 'First Name', key: 'first_name' },
  { label: 'Last Name', key: 'last_name' },
  { label: 'Address', key: 'address' },
  { label: 'Street', key: 'street' },
  { label: 'City', key: 'city' },
  { label: 'State', key: 'state' },
  { label: 'Zip', key: 'zip' },
  { label: 'Country', key: 'country' },
  { label: 'Phone', key: 'phone' },
  { label: 'Email', key: 'email' },
  { label: 'Lead', key: 'lead != null ? lead.lead_Stage: null' },
  { label: 'REPLIED', key: 'replied' },
  { label: 'dnc', key: 'dnc' },
  { label: 'Verified', key: 'verified' },
  { label: 'First Import', key: 'firstimport' },
  { label: 'Last Import', key: 'lasttimport' },
  { label: 'Wrong Number', key: 'Wrong_Number' },
  { label: 'Litigator', key: 'litigator' }
];
const UserVoice = ({
  getProspects,
  prospects,
  getLeadStage,
  deleteprospects,
  filterdatabyprospects,
  leads,
  updatedncstatus,
  verifiedprospect,
  removeverifiedprospect,
  wrongnumprospects,

  removewrongnumprospects,
  isqualified,
  wasqualified,
  isPriority,
  wasPriority,
  exportprosdata,
  countprospects,
  countallProspects,
  getCampaigns,
  campaigns,
  getallCompanies,
  companies
}) => {
  const [lead, setLead] = useState([]);
  const [verified, setverified] = useState('');
  const [activePage, setactivePage] = useState(0);
  const [prospectdata, setprospectdata] = useState([]);
  const [selecteditem, setselecteditem] = useState([]);
  const [dnc, setdnc] = useState('');
  const [priority, setpriority] = useState('');
  const [qualified, setqualified] = useState('');
  const [wrongnumber, setwrongnumber] = useState('');
  const [replied, setreplied] = useState('');
  const [hover, sethover] = useState(false);
  const [campFilter, setcampFilter] = useState([]);
  const [filteractive, setfilteactive] = useState(false);
  const [filterData, setFilterData] = useState(null);

  const [delModal, setDelModal] = useState(false);
  const [delID, setDelID] = useState('');
  const [delTitle, setDelTitle] = useState('');
  const [offset, setoffset] = useState('0');

  const [company, setComp] = useState('');

  useEffect(() => {
    getProspects(0);
    getLeadStage();
    countallProspects();
    getCampaigns();
    getallCompanies();
  }, [getProspects, getLeadStage, getCampaigns, getallCompanies]);
  useEffect(() => {
    setprospectdata(prospects);
    console.log('error', prospects);
  }, [prospects]);

  const setCompany = e => {
    setComp(e.target.value);
    getProspects(offset, e.target.value);
  };

  const deleteMe = () => {
    setDelModal(true);
  };
  const delToggle = () => {
    setDelModal(!delModal);
  };

  const removeMeConfirm = () => {
    deltepros();
    setDelModal(false);
  };
  const repliedfilter = e => {
    const value = e.target.checked;
    setreplied(value);
    filterprospects(lead, verified, dnc, priority, wrongnumber, qualified, value, campFilter);
  };

  const mousein = () => {
    sethover(true);
  };

  const mouseout = () => {
    sethover(false);
  };
  console.log({ hover });
  // const dncchange = e => {
  //   const { value } = e.target;
  //   setdnc(value);
  //   filterprospects(lead, verified, value, priority, wrongnumber, qualified,replied,campFilter);
  // };
  // const prioritychange = e => {
  //   const { value } = e.target;
  //   setpriority(value);
  //   filterprospects(lead, verified, dnc, value, wrongnumber, qualified,replied,campFilter);
  // };
  // const qualifiedchange = e => {
  //   const { value } = e.target;
  //   setqualified(value);
  //   filterprospects(lead, verified, dnc, priority, wrongnumber, value,replied,campFilter);
  // };
  // const wrongNumberchange = e => {
  //   const { value } = e.target;
  //   setwrongnumber(value);
  //   filterprospects(lead, verified, dnc, priority, value, qualified,replied,campFilter);
  // };
  const getfilterdata = selectedOption => {
    setLead(selectedOption);
    filterprospects(selectedOption, verified, dnc, priority, wrongnumber, qualified, replied, campFilter);
  };
  const setverfieduser = e => {
    setverified(e.target.value);
    filterprospects(lead, e.target.value, dnc, priority, wrongnumber, qualified, replied, campFilter);
  };
  const update = item => {
    let dnc = item.dnc;
    updatedncstatus(item._id, !dnc);
  };
  const verifiedNumber = item => {
    verifiedprospect(item._id, true);
  };
  const rverifiedNumber = item => {
    removeverifiedprospect(item._id, true);
  };
  const wrongNumber = item => {
    wrongnumprospects(item._id, true);
  };

  const rwrongNumber = item => {
    removewrongnumprospects(item._id, true);
  };
  const qualifiedNumber = item => {
    isqualified(item._id, true);
  };
  const rqualifiedNumber = item => {
    wasqualified(item._id, true);
  };
  const priorityNumber = item => {
    isPriority(item._id, true);
  };
  const rpriorityNumber = item => {
    wasPriority(item._id, true);
  };

  //  const cmapaignChange = e => {
  //     const value = e.target;
  //     console.log("testinfg",e.target.value,e.target.name)
  //      let exist = campFilter.findIndex(item => item == e.target.name)
  //     let data = [...campFilter]

  //     if(exist != -1)
  //     {
  //        data.splice(exist, 1)
  //        setcampFilter(data)

  //     }
  //     else{
  //       data = [...data, e.target.name]
  //      setcampFilter(data)

  //     }
  //     filterprospects(lead, verified, dnc, priority, wrongnumber, qualified, replied,data,campFilter);

  //   };
 const pathname = window.location.pathname
  const exportdata = async () => {
   

    let lea = lead;
    let filter = { lead: [] };
    if (lea != null) {
      let value = lea.map(item => item._id);
      filter.lead = value;
    }
    filter.dnc = dnc;
    filter.isPriority = priority;
    filter.Wrong_Number = wrongnumber;
    filter.isLead = qualified;
    filter.verified = verified;
    filter.campaigns = campFilter;
    await exportprosdata({ filter },pathname);
    socket.emit('notification', localStorage.getItem('email'));
  };

  const deltepros = () => {
    deleteprospects(selecteditem);

    setselecteditem([]);
  };

  const selectedall = e => {
    const { checked } = e.target;
    if (checked) {
      let selectarray = [];
      let data = prospectdata.map((item, index) => {
        prospectdata[index]['checked'] = true;
        selectarray.push(item._id);
        return prospectdata[index];
      });
      setprospectdata(data);
      setselecteditem(selectarray);
    } else {
      let data = prospectdata.map((item, index) => {
        prospectdata[index]['checked'] = false;
        return prospectdata[index];
      });
      setprospectdata(data);
      setselecteditem([]);
    }
  };

  const onchange = (id, e) => {
    const { checked } = e.target;
    let data = [...selecteditem];
    let selectarray = [...prospectdata];
    if (checked) {
      data = [...data, id];
      let exist = selectarray.findIndex(item => item._id === id);
      if (exist != -1) {
        console.log(exist, id);
        selectarray[exist]['checked'] = true;
        setprospectdata(selectarray);
      }
    } else {
      let exist = data.findIndex(item => item === id);
      if (exist != -1) {
        let existss = selectarray.findIndex(item => item._id === id);
        if (existss != -1) {
          console.log(existss, id);
          selectarray[existss]['checked'] = false;
          setprospectdata(selectarray);
        }
        data.splice(exist, 1);
        setprospectdata(selectarray);
      }
    }

    setselecteditem(data);
  };
  const handlePageChange = pageNumber => {
    let off = pageNumber * 100 - 100;
    setactivePage(pageNumber);
    setoffset(off);
    if (filteractive) filterdatabyprospects(filterData, off);
    else getProspects(off);
  };
  const filterprospects = (lea, ver, dnccheck, pcheck, wcheck, lecheck, replied, campaignsl) => {
    let filter = { lead: [] };
    if (lea != null) {
      let value = lea.map(item => item._id);
      filter.lead = value;
    }
    filter.dnc = dnccheck;
    filter.isPriority = pcheck;
    filter.Wrong_Number = wcheck;
    filter.isLead = lecheck;
    filter.verified = ver;
    filter.replied = replied;
    filter.campaigns = campaignsl;
    setfilteactive(true);
    setFilterData(filter);
    filterdatabyprospects(filter, 0);
  };
  //Multiple dropdown
  const objectArray = [
    { key: 'Verified', id: 1, cat: 'Verified' },
    { key: 'Not Verified', id: 2, cat: 'Verified' },
    { key: 'Yes ', id: 3, cat: 'DNC' },
    { key: 'No ', id: 4, cat: 'DNC' },
    { key: 'Yes  ', id: 5, cat: 'Priority' },
    { key: 'No  ', id: 6, cat: 'Priority' },
    { key: 'Yes   ', id: 7, cat: 'Qualified Lead' },
    { key: 'No   ', id: 8, cat: 'Qualified Lead' },
    { key: 'Yes    ', id: 9, cat: 'Wrong Number' },
    { key: 'No    ', id: 10, cat: 'Wrong Number' },
    { key: 'Yes     ', id: 11, cat: 'Replied' },
    { key: 'No     ', id: 12, cat: 'Replied' }
  ];
  const onSelect = (selectedList, selectedItem) => {
    console.log(selectedList, selectedItem, 'asfjhgivbiri');
    selectedList.map(item => {
      if (item.cat === 'Verified') {
        if (item.key === 'Verified') {
          setverified(true);
          filterprospects(lead, true, dnc, priority, wrongnumber, qualified, replied, campFilter);
        } else {
          setverified(false);
          filterprospects(lead, false, dnc, priority, wrongnumber, qualified, replied, campFilter);
        }
      }
      if (item.cat === 'DNC') {
        if (item.key === 'Yes ') {
          setdnc(true);
          filterprospects(lead, verified, true, priority, wrongnumber, qualified, replied, campFilter);
        } else {
          setdnc(false);
          filterprospects(lead, verified, false, priority, wrongnumber, qualified, replied, campFilter);
        }
      }
      if (item.cat === 'Priority') {
        if (item.key === 'Yes  ') {
          setpriority(true);
          filterprospects(lead, verified, dnc, true, wrongnumber, qualified, replied, campFilter);
        } else {
          setpriority(false);
          filterprospects(lead, verified, dnc, false, wrongnumber, qualified, replied, campFilter);
        }
      }
      if (item.cat === 'Qualified Lead') {
        if (item.key === 'Yes   ') {
          setqualified(true);
          filterprospects(lead, verified, dnc, priority, wrongnumber, true, replied, campFilter);
        } else {
          setqualified(false);
          filterprospects(lead, verified, dnc, priority, wrongnumber, false, replied, campFilter);
        }
      }
      if (item.cat === 'Wrong Number') {
        if (item.key === 'Yes    ') {
          setwrongnumber(true);
          filterprospects(lead, verified, dnc, priority, true, qualified, replied, campFilter);
        } else {
          setwrongnumber(false);
          filterprospects(lead, verified, dnc, priority, false, qualified, replied, campFilter);
        }
      }
      if (item.cat === 'Replied') {
        if (item.key === 'Yes     ') {
          setreplied(true);
          filterprospects(lead, verified, dnc, priority, wrongnumber, qualified, true, campFilter);
        } else {
          setreplied(false);
          filterprospects(lead, verified, dnc, priority, wrongnumber, qualified, false, campFilter);
        }
      }
    });
  };
  const onRemove = (selectedList, removedItem) => {
    console.log(removedItem, 'asdf');
    if (removedItem.cat === 'Verified') {
      setverified('');
      filterprospects(lead, '', dnc, priority, wrongnumber, qualified, replied, campFilter);
    }
    if (removedItem.cat === 'DNC') {
      setdnc('');
      filterprospects(lead, verified, '', priority, wrongnumber, qualified, replied, campFilter);
    }
    if (removedItem.cat === 'Priority') {
      setpriority('');
      filterprospects(lead, verified, dnc, '', wrongnumber, qualified, replied, campFilter);
    }
    if (removedItem.cat === 'Qualified Lead') {
      setqualified('');
      filterprospects(lead, verified, dnc, priority, wrongnumber, '', replied, campFilter);
    }
    if (removedItem.cat === 'Wrong Number') {
      setwrongnumber('');
      filterprospects(lead, verified, dnc, priority, '', qualified, replied, campFilter);
    }
    if (removedItem.cat === 'Replied') {
      setreplied('');
      filterprospects(lead, verified, dnc, priority, wrongnumber, qualified, '', campFilter);
    }
  };

  //campigns select
  const onSelectcampign = (selectedList, selectedItem) => {
    console.log({ selectedItem });
    let exist = campFilter.findIndex(item => item == selectedItem._id);
    let data = [...campFilter];

    if (exist != -1) {
      data.splice(exist, 1);
      setcampFilter(data);
    } else {
      data = [...data, selectedItem._id];
      setcampFilter(data);
    }
    filterprospects(lead, verified, dnc, priority, wrongnumber, qualified, replied, data, campFilter);
  };
  const onRemovecamign = (selectedList, removedItem) => {
    let exist = campFilter.findIndex(item => item == removedItem._id);
    let data = [...campFilter];

    if (exist != -1) {
      data.splice(exist, 1);
      setcampFilter(data);
    } else {
      data = [...data, removedItem._id];
      setcampFilter(data);
    }
    filterprospects(lead, verified, dnc, priority, wrongnumber, qualified, replied, data, campFilter);
  };
console.log({companies})
  if (prospects === null) {
    return <Loader />;
  } else {
    console.log('campiagns', campaigns);
    return (
      <Row className="justify-content-center">
        <Col md="12" xs="12">
          <Row>
            <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
              <Card className="mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
                <Row xs="12" style={{ padding: '15px 10px 0px 10px' }}>
                  <Col xs="2">
                    <p style={{ fontSize: '21px' }}>Prospects</p>
                  </Col>
                  <Col xs="6" className="p-0 mt-1">
                    <p className="text-primary">{countprospects} Prospects</p>
                    </Col>
                    

                  <Col xs="4" style={{ float: 'right' }}>
                    <Search />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs="4">
              <Card className="mb-3 h-100" >
                <Background image={getImage('warning')} className="bg-card" />
                <CardBody>
                <Label>Select Lead Stage</Label>
                  <Select
                    value={lead}
                    name="lead"
                    onChange={getfilterdata}
                    options={leads != null && leads.length > 0 ? leads : null}
                    getOptionLabel={option => `${option.lead_Stage}`}
                    getOptionValue={option => option}
                    isMulti
                    menuPortalTarget={document.querySelector("body")}
                  />
                  <Label>Select Status</Label>
                  <Multiselect
                    options={objectArray}
                    groupBy="cat"
                    placeholder="Select Status"
                    displayValue="key"
                    onSelect={(e, j) => onSelect(e, j)}
                    onRemove={(e, j) => onRemove(e, j)}
                    showCheckbox={true}
                    porta
                    menuPortalTarget={document.querySelector("body")}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs="4">
              <Card className="mb-3  h-100" style={{ minWidth: '12rem' }}>
                <Background image={getImage('info')} className="bg-card" style={{zIndex:"1"}} />
                <CardBody style={{zIndex:"10"}}>
                  <Label>Select Campaigns</Label>
                  <Multiselect
                    options={campaigns}
                    placeholder="Select Campaigns"
                    onSelect={(e, j) => onSelectcampign(e, j)}
                    onRemove={(e, j) => onRemovecamign(e, j)}
                    displayValue="name"
                    showCheckbox={true}
                    menuPortalTarget={document.querySelector("body")}
                  />

                  <Label>Select Company</Label>

                  <Input
                    style={{ fontSize: '0.95em' }}
                    type="select"
                    onChange={console.log}
                    name="company"
                    onClick={()=>console.log("CLIKED")}
                    style={{ background:"transparent"}}
                  >
                    <option value="">Select Company</option>
                    {companies &&
                      companies.map(item => (
                        <option selected={item._id === company} key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </Input>
                </CardBody>
              </Card>
            </Col>
            <Col xs="4">
              <Card className="mb-3 overflow-hidden h-100" style={{ minWidth: '12rem' }}>
                <Background image={getImage('success')} className="bg-card" />
                <CardBody>
                  <Row>
                    <Col className="m-2">
                      <Link
                        className="btn btn-primary btn-sm mt-1"
                        to="/pages/prospects/addprospect"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        Add New Prospect
                      </Link>
                    </Col>
                    <Col className="m-2">
                      <Tooltip title="This button will export the list given below">
                        <div className="btn btn-primary btn-sm mt-1 " style={{ whiteSpace: 'nowrap' }}onClick={exportdata}>
                          Export Prospect
                        </div>
                      </Tooltip>
                    </Col>
                    <Col className="m-2">
                      {selecteditem.length > 0 && (
                        <div className="btn btn-primary btn-sm mt-1 " style={{ whiteSpace: 'nowrap' }}onClick={() => deleteMe()}>
                        Delete Prospect
                      </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card className="mt-3">
           
            <div>
              <Table responsive bordered striped hover>
                <thead>
                  <tr>
                    <th style={{ paddingLeft: '33px' }}>
                      {' '}
                      <Input type="checkbox" onChange={e => selectedall(e)} />#
                    </th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Property Address</th>
                    <th>Campaign</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {prospects?.map((item, index) => (
                    <tr key={item._id}>
                      <th style={{ paddingLeft: '33px' }} scope="row">
                        <Input
                          type="checkbox"
                          checked={item.checked != undefined ? item.checked : false}
                          onChange={e => onchange(item._id, e)}
                        />
                        {index + 1}
                      </th>
                      {(item.last_name === undefined || item.last_name === null) && (item.last_name = '  ')}

                      <td style={{ whiteSpace: 'nowrap' }}>
                       
                          {item.first_name + ' ' + item.last_name}
                        
                      </td>
                      {(item.phone === undefined || item.phone === null) && (item.phone = '  ')}
                      <td>{item.phone}</td>
                      {(item.address === undefined || item.address === null) && (item.address = '  ')}
                      {(item.city === undefined || item.city === null) && (item.city = '  ')}
                      {(item.state === undefined || item.state === null) && (item.state = '  ')}

                      <td>{item.address + '   ' + item.city + '   ' + item.state}</td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {item.campaign && <Tooltipbutton title="Click to see the campaign"><Link to={`/pages/campaigns/${item.campaign._id}`}>{item.campaign.name}</Link></Tooltipbutton>}
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {item.dnc == false ? (
                          <Tooltipp
                            onClick={() => {
                              update(item);
                            }}
                            title="DNC"
                            icon={faPhoneSlash}
                          />
                        ) : (
                          <Tooltipp
                            onClick={() => {
                              update(item);
                            }}
                            title="DNC"
                            icon={faPhoneSlash}
                            color="red"
                          />
                        )}{' '}
                        {item.verified == false ? (
                          <Tooltipp
                            onClick={() => verifiedNumber(item)}
                            title="Verified Number"
                            icon={faCheckSquare}
                          />
                        ) : (
                          <Tooltipp
                            onClick={() => rverifiedNumber(item)}
                            title="Verified Number"
                            icon={faCheckSquare}
                            color="green"
                          />
                        )}{' '}
                        {item.Wrong_Number == false ? (
                          <Tooltipp onClick={() => wrongNumber(item)} title="Wrong Number" icon={faTimesCircle} />
                        ) : (
                          <Tooltipp
                            onClick={() => rwrongNumber(item)}
                            title="Wrong Number"
                            icon={faTimesCircle}
                            color="blue"
                          />
                        )}{' '}
                        {item.isLead == false ? (
                          <Tooltipp onClick={() => qualifiedNumber(item)} title="Qualified Number" icon={faStar} />
                        ) : (
                          <Tooltipp
                            onClick={() => rqualifiedNumber(item)}
                            title="Qualified Number"
                            icon={faStar}
                            color="purple"
                          />
                        )}{' '}
                        {item.isPriority == false ? (
                          <Tooltipp onClick={() => priorityNumber(item)} title="Priority Number" icon={faBolt} />
                        ) : (
                          <Tooltipp
                            onClick={() => rpriorityNumber(item)}
                            title="Priority Number"
                            icon={faBolt}
                            color="yellow"
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={100}
                totalItemsCount={countprospects}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </div>
            {delModal ? (
              <Modal isOpen toggle={delToggle}>
                <ModalHeader toggle={delToggle} style={{ backgroundColor: '#becdda8f' }}>
                  <h5 style={{ fontWeight: 'bold' }}>Remove Prospect</h5>
                </ModalHeader>
                <ModalBody>Are you sure you want to remove this? This action cannot be undone.</ModalBody>
                <ModalFooter>
                  <Button className="mr-2" variant="contained" color="secondary" onClick={() => delToggle()}>
                    No
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#28a745', color: 'white' }}
                    onClick={() => removeMeConfirm()}
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
          </Card>
        </Col>
      </Row>
    );
  }
};
const mapStateToProps = state => ({
  leads: state.bulk.leads,
  prospects: state.bulk.prospects,
  countprospects: state.bulk.countprospects,
  campaigns: state.bulk.campaigns,
  companies: state.Twilio.companies
});
UserVoice.propTypes = {
  getProspects: PropTypes.func.isRequired,
  getCampaigns: PropTypes.func.isRequired
};
export default connect(
  mapStateToProps,
  {
    getallCompanies,
    getProspects,
    filterdatabyprospects,
    getLeadStage,
    updatedncstatus,
    verifiedprospect,
    removeverifiedprospect,
    wrongnumprospects,
    removewrongnumprospects,
    isqualified,
    wasqualified,
    isPriority,
    wasPriority,
    exportprosdata,
    deleteprospects,
    countallProspects,
    getCampaigns
  }
)(UserVoice);

const Tooltipp = ({ onClick, title, icon, color }) => {
  const [hover, sethover] = useState(false);

  const mousein = () => {
    sethover(true);
  };

  const mouseout = () => {
    sethover(false);
  };
  return (
    <Tooltip title={title}>
      <button style={{ background: 'none', border: 0, color: '#5e6e82', padding: 0 }}>
        <FontAwesomeIcon color={color} onClick={onClick} icon={icon} />
      </button>
    </Tooltip>
  );
};

import React, {useEffect, useState, useRef} from 'react';
import { Nav } from 'reactstrap';
import Scrollbar from 'react-scrollbars-custom';
import { useSelector } from 'react-redux'
import Flex from "../../../components/common/Flex";
import classNames from 'classnames';
import {connect} from  "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {socket} from '../Main/socket'
import { Card, CardBody,TabContent, TabPane, Input, FormGroup, Form, Media, UncontrolledTooltip, Button, Col, Row } from 'reactstrap';
import axios from "axios"
import  {crmdata} from "../../../Redux/Actions/USER/user"
import { useHistory } from "react-router-dom";
import { sendmessage } from '../../../Redux/Actions/Twilio';
var moment = require('moment-timezone');
const ChatSidebar = ({sendmessage, crmdata}) => {
    const chatRef=useRef()
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState(null)
    const [contact, setcontact] = useState([])
    const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);
    const [id, setActiveID] = useState(0)
    const [scrollHeight, setScrollHeight] = useState(0);
    const [textAreaInitialHeight, setTextAreaInitialHeight] = useState(32);
    const [search, setSearch] = useState("")
    const isMountedRef = useRef(null);
    const history = useHistory()
    const currtz=useSelector(state=>state.Twilio.timezone)
    const handleSubmit = e => {
        e.preventDefault();
        console.log("inbound",message)
        sendmessage(messages.sender, message, messages.number);
        setMessage("")
    };
    const searchcontact = (e)=>{
        e.preventDefault();
        if(search !=""){
            apiCall(search)
        }else{
            apiCall("")

        }
        
    }
    useEffect(()=>{
       if(search != "")
       {
         apiCall(search)
       }
       else{
         apiCall("")
       }
      
    },[search])
    useEffect(()=>{
        socket.once('message', async(data) => {
            console.log(data)
            if(data){
               let contact =  await axios({
                   method: 'get',
                   url: process.env.REACT_APP_PORT + '/api/me/contacts?search='+search,
                   headers: {
                     'x-auth-token': localStorage.getItem('token')
                   } 
                 });
               let dd = contact.data.data
               setcontact(dd)
               let msg =  await axios({
                   method: 'get',
                   url: process.env.REACT_APP_PORT + '/api/me/message?id='+data,
                   headers: {
                     'x-auth-token': localStorage.getItem('token')
                   }
                 });
                let response = msg.data.data
                   if (response._id == data) {
                       setMessages(response);
                   }
           }
         }); 
    }, [messages])
    const crmdet  = () =>{
        crmdata(id)
    }
    const CallRedirect = ()=>{
        let path = `/pages/call?to=${messages.sender}&from=${messages.number}`
        history.push(path)
    }
    const remove = (data)=>{
        socket.emit("delete", localStorage.getItem("email"), data, id)
    }
    socket.once("contact", async(msg)=> {
      try{
        let msg =  await axios({
            method: 'get',
            url: process.env.REACT_APP_PORT + '/api/me/contacts',
            headers: {
              'x-auth-token': localStorage.getItem('token')
            } 
          });
        let dd = msg.data.data
        if(id != 0)
        {
            console.log(id)
        socket.emit('getmessageid', localStorage.getItem('email'), id, "contact");
        }
        else{
            socket.emit('getmessageid', localStorage.getItem('email'), dd[0]._id, "contact");
        }
        setcontact(dd)
      }
      catch(e){
          console.log("error", e)
      }
        
    })
   
    const selectmessage = async(id)=>{
        setMessages(null)
        let msg =  await axios({
            method: 'get',
            url: process.env.REACT_APP_PORT + '/api/me/message?id='+id,
            headers: {
              'x-auth-token': localStorage.getItem('token')
            }
          });
         let response = msg.data.data
            if (response._id == id) {
                setMessages(response);
        }
    }
    
    useEffect(() => {
        //TextBox and message body height controlling
        isMountedRef.current = true;
        let textAreaPreviousHeight = textAreaInitialHeight;
        const autoExpand = function (field) {
            // Reset field height
            field.style.height = '2rem';

            // Calculate the height
            const textAreaCurrentHeight = field.scrollHeight;

            if (textAreaCurrentHeight <= 160 && document.querySelector('.card-chat-pane')) {
                if (textAreaPreviousHeight !== textAreaCurrentHeight && isMountedRef.current) {
                    document.querySelector('.card-chat-pane').style.height = `calc(100% - ${textAreaCurrentHeight}px)`;
                    setTextAreaInitialHeight((textAreaPreviousHeight = textAreaCurrentHeight));
                }
            }

            field.style.height = textAreaCurrentHeight + 'px';
        };
        if (document.querySelector('.textarea')) {
            document.addEventListener(
                'input',
                function (event) {
                    if (event.target.className === 'textarea');
                    autoExpand(event.target);
                },
                false
            );
        }
        return () => {
            isMountedRef.current = false;
        };
    }, [textAreaInitialHeight, setTextAreaInitialHeight]);
    const apiCall = async(search)=>{
        try{
            let msg =  await axios({
                method: 'get',
                url: process.env.REACT_APP_PORT + '/api/me/contacts?search='+search,
                headers: {
                  'x-auth-token': localStorage.getItem('token')
                } 
              });
            let dd = msg.data.data
            console.log(dd)
            if(id != 0)
            {
                console.log(id)
            socket.emit('getmessageid', localStorage.getItem('email'), id, "contact");
            }
            else{
                socket.emit('getmessageid', localStorage.getItem('email'), dd[0]._id, "contact");
            }
            setcontact(dd)
          }
          catch(e){
              console.log("error", e)
          }
    }

    useEffect(() => {

    const temp1=chatRef.current
    if(temp1){
        temp1.scroll(0,temp1.scrollHeight)
    }
    }, [messages?.messages,chatRef])
    
    return (
         <Card style={{height:"80vh",width:'65vw'}} className="container">
            <CardBody tag={Flex} className="p-0 h-100">
                    <div className="chat-sidebar rounded-left">
                        <div className="contacts-list bg-white">
                        <Scrollbar
                            style={{
                                height: '100%',
                                minWidth: '75px'
                            }}
                            noScrollX
                            trackYProps={{
                                renderer(props) {
                                const { elementRef, ...restProps } = props;
                                return <span {...restProps} ref={elementRef} className="TrackY" />;
                                }
                            }}
                        >
                    <Nav className="border-0">
                        {contact && contact.length > 0 && contact.map(item => <Media key={item._id}
                            className={classNames(`chat-contact hover-actions-trigger w-100 `,{
                                active: item._id === id
                              })}
                            onClick={() => {
                                setActiveID(item._id)
                                selectmessage(item._id)
                            document.getElementsByClassName('chat-sidebar')[0].style.left = '-100%';
                                }}>
                                                {/* <Avatar className={user.status} src={user.avatarSrc} size="xl" /> */}
                            <Media body className="chat-contact-body ml-2 d-md-none d-lg-block">
                                <Flex justify="between">
                                                        {/* //name */}
                                    <h6 className="mb-0 chat-contact-title">{item.sender}</h6>
                                                        {/* //date */}
                                    <span className="message-time fs--2">  </span>
                                </Flex>
                                    <div className="min-w-0">
                                        <div className="chat-contact-content pr-3"
                                        >{item.messages[item.messages.length -1]?.body}</div>

                                    </div>
                            </Media>
                            </Media>)}
                        </Nav>
                                    </Scrollbar>
                            
                        </div>
                        <Form className="chat-editor-area bg-white" onSubmit={searchcontact}>
                                <Input  onChange={({ target }) => setSearch(target.value)}  className="border-0 outline-none shadow-none resize-none textarea bg-white"
                                    type="textarea"
                                    
                                    
                                    style={{
                                        height: '2.2rem',
                                        maxHeight: '10rem'
                                    }}
                                    placeholder="Search contacts ..." bsSize="sm" />
                                
                                     <Button
                                    color="transparent"
                                    size="sm"
                                    className={classNames(`btn-send outline-none ml-1`, {
                                        'text-primary': message.length > 0
                                    })}
                                    type="submit"
                                >
                                      <FontAwesomeIcon icon="search" className="fs--1" />
                                </Button>
                                
                            </Form>

                            </div>
                                { messages && contact.length > 0 && <TabContent className="card-chat-content fs--1 position-relative"> <TabPane className="card-chat-pane active">
                                    <div className="chat-content-header">
                                        <Row className="flex-between-center">
                                            <Col md={8} xs={6} tag={Flex} align="center">
                                                <div
                                                    className="pr-3 text-700 d-md-none contacts-list-show cursor-pointer"
                                                    onClick={() => (document.getElementsByClassName('chat-sidebar')[0].style.left = 0)}
                                                >
                                                    <FontAwesomeIcon icon="chevron-left" />
                                                </div>
                                                <div className="min-w-0">
                                                    <h5 className="mb-0 text-truncate fs-0">To: {messages && messages.sender}</h5>
                                                    <h5 className="mb-0 text-truncate fs-0">SMS ID: {messages && messages.number}</h5>
                                                    <div className="fs--2 text-400">
                                                        {/* {user.status === 'status-online' ? 'Active on  chat' : 'Active 7h ago'} */}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs="auto">
                                                <Button onClick={crmdet} color="falcon-primary" className="mr-2" size="sm" id="call-tooltips">
                                                  CRM
                                                  <UncontrolledTooltip placement="left" target="call-tooltips" innerClassName="fs--1">
                                                        Push to CRM
                                                    </UncontrolledTooltip>
                                                </Button>
                                                <Button onClick={CallRedirect} color="falcon-primary" className="mr-2" size="sm" id="call-tooltip">
                                                    <FontAwesomeIcon icon="phone" />
                                                    <UncontrolledTooltip placement="left" target="call-tooltip" innerClassName="fs--1">
                                                        Start a Audio Call
                                                    </UncontrolledTooltip>
                                                </Button>
                                                
                                                <Button
                                                    color="falcon-primary"
                                                    size="sm"
                                                    id="info-tooltip"
                                                    onClick={() => setIsOpenThreadInfo(prevState => !prevState)}
                                                >
                                                    <FontAwesomeIcon icon="info" />
                                                    <UncontrolledTooltip placement="left" target="info-tooltip" innerClassName="fs--1">
                                                        Conversation Information
                                                    </UncontrolledTooltip>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="chat-content-body" style={{ display: 'inherit' }}>
                                    <div className="chat-content-scroll-area" ref={chatRef}>
                                                {/* <ChatContentBodyIntro user={user} isGroup={isGroup} /> */}

                                                {messages &&  messages.messages.map((item, index) => (
                                                        <div key={index}>
                                                            <div className="text-center fs--2 text-500">{`${moment(item.createdAt).tz(currtz).format('LLL')}`}</div>
                                                        <Flex
                                                            align="center"
                                                            className={classNames('hover-actions-trigger px-1', {
                                                                'justify-content-end': item.direction != "outbound-api" ? false : true
                                                            })}
                                                        >
                                                              {/* <ul className="hover-actions position-relative list-inline mb-0 text-400 mx-2">
    
                                                                <li onClick={()=>remove(item._id)} className="list-inline-item cursor-pointer chat-option-hover" id={`delete-${item.id}`}>
                                                                <FontAwesomeIcon icon="trash-alt" />
                                                                <UncontrolledTooltip placement="top" target={`delete-${item.id}`}>
                                                                    Remove
                                                                </UncontrolledTooltip>
                                                                </li>
                                                            </ul> */}
                                                        <div
                                                                className={classNames('p-2 rounded-soft ', {
                                                                                'bg-200': item.direction == "outbound-api" ? false : true,
                                                                                'bg-primary text-white': item.direction != "outbound-api" ? false : true
                                                                })}
                                                                        >
                                                                <p className="mb-0">{item.body}</p>


                                                            </div>
                                                    
                                                        </Flex>


                                                    
                                                    
                                                        </div>
                                                ))}
                                            
                                            </div>
                                        {/* <Scrollbar
                                            style={{
                                                height: '100%',
                                                minWidth: '75px',
                                                display: 'block'
                                            }}
                                            scrollTop={scrollHeight}
                                            noScrollX
                                            trackYProps={{
                                                renderer(props) {
                                                    const { elementRef, ...restProps } = props;
                                                    return <span {...restProps} ref={elementRef} className="TrackY" />;
                                                }
                                            }}
                                        >
                                           
                                        </Scrollbar> */}
                                    </div>
                                    </TabPane>

                                <Form className="chat-editor-area bg-white" onSubmit={handleSubmit}>
                                <Button
                                        color="transparent"
                                        size="sm"
                                        className={classNames(`btn-send outline-none ml-1`, {
                                            'text-primary': message.length > 0
                                        })}
                                        type="submit"
                                    >
                                        Send
                                    </Button>
                                    <Input
                                    className="border-0 outline-none shadow-none resize-none textarea bg-white"
                                    type="textarea"
                                    placeholder="Type your message"
                                    bsSize="sm"
                                    value={message}
                                    onChange={({ target }) => setMessage(target.value)}
                                    style={{
                                        height: '2.2rem',
                                        maxHeight: '10rem'
                                    }}
                                    />

                                    
                                </Form>
                                
                            </TabContent>
                            }

            </CardBody>
      </Card>
         
    );
};
export default connect(
    null,
    { sendmessage, crmdata}
  )(ChatSidebar);
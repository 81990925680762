import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Label,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Badge,
  Button as BUTTONS,
  Input as IMP
} from 'reactstrap';
import { Button } from '@material-ui/core';
import { Device } from 'twilio-client';
import { connect } from 'react-redux';
import { gettoken } from '../../../Redux/Actions/Twilio';
import PropTypes from 'prop-types';

import Loader from '../../../components/common/Loader';
import 'react-phone-input-2/lib/style.css';
import {callmonitor, createSession} from "../../../Redux/Actions/USER/user"
import { toast } from 'react-toastify';
import UserForm from '../Home/Form';

import GetCOmpany from '../../../components/GetCompany/getCompany';
import {
  getContacts,
  getCampaigns,
  getcountcontact,
  updatestatus,
  callstatus,
  refreshtoken
} from '../../../Redux/Actions/Mask/Mask';
import io from 'socket.io-client';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { socket } from '../Main/socket';
import Switch from '@material-ui/core/Switch';
// const socket = io(process.env.REACT_APP_PORT, {
//   query: {
//     email: localStorage.getItem('email'),
//     filename:"multiline call"
//   }
// });
const Dial = ({
  Token,
  gettoken,
  campaigns,
  countcontact,
  getCampaigns,
  refreshtoken,
  numbers,
  getContacts,
  contact,
  updatestatus,
  callmonitor,
  callstatus,
  createSession
}) => {
  const [dis, setdis] = useState(true);
  const [from, setfrom] = useState(null);
  const [offset, setoffset] = useState(0);
  const [campaign, setcampaign] = useState(null);
  const [back, setback] = useState(false);
  const [phone, setphone] = useState([]);
  const [callconnect, setcallconnect] = useState(false);
  const [diss, setdiss] = useState(false);
  const [oflimit, setLimit] = useState(0);
  const [session, setsession] = useState(false)
  //toggle button
  const [state, setState] = useState({
    checkedB: false
  });
  console.log({ state });
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  //....
  const changestatus = () => {
    setcallconnect(false);
  };
  const selectcampaign = e => {
    if (e.target.value != '') {
       localStorage.removeItem('check');
        getContacts(e.target.value, 0,oflimit);
        setcampaign(e.target.value);
        callmonitor(e.target.value)
    } else {
      setcampaign(null);
    }
  };
  useEffect(() => {
    getCampaigns();
   
    socket.on('statusofcall', data => {
      let offsets = localStorage.getItem("offset")
      let limit = localStorage.getItem("limit")
      getContacts(data, offsets,limit, 'socket');
    });

    if (Token == '') {
      gettoken();
    } else {
      fetchdata();
    }
    async function fetchdata() {
      // Device.setup(Token);
      // Device.on('ready', function(device) {});
      // Device.on('error', function(error) {
      //   toast.error(error.message);
      // });
    }
  }, [Token, gettoken, getCampaigns]);
  // console.log({contact})

  // const call=contact.find(item =>
  //   item.phone===phone
  // )
  // console.log(call?.status,"status")
  // if(call?.status==="completed"){
  //   submit(true)
  // }
  useEffect(() => {
  //  Device.on('disconnect', async function() {
    const autocall=async()=>{
     
    const newContact = await getContacts(campaign, 0,oflimit);
    console.log('disss', diss);
    // if (diss) {
    //   console.log('entered diss');
    //   return;
    // }
    const call=newContact?.find(item => item.phone === phone[0])
    console.log(call?.status, 'status');
                // console.log(connection, 'connection');
    if (call?.status === 'completed' || call?.status === 'no-answer') {
      submit(true);
    }
    }
    autocall()
  // });
  if(diss)
  {
    console.log("running")
  }
  }, [diss])
  
  const remove = id => {
    updatestatus(id, offset, oflimit);
  };
  const update = id => {
    callstatus(id, offset, oflimit);
  };
  const submit = async check => {
    if (check) {
      setdiss(false);
      navigator.permissions.query({ name: 'microphone' }).then(async function(permissionstatus) {
        if (permissionstatus.state === 'granted') {
          if (from == null) {
            toast.error('Please  Select Dialer ID');
          } else {
            const id = campaign;
            let phone = [];
            const conta_ct = await getContacts(campaign, 0,oflimit);
            conta_ct.forEach(item => {
              
              if (item.show == true && item.status != 'completed') {
                console.log('entered', item.show, item.status);
                phone.push(item.phone);
               
              }
            });
            setphone(phone)
            phone.push(id);
            if (phone.length === 1) {
              toast.error('No Number remaining to call ');
            } else {
              // debugger
              // const call=contact.find(item =>
              //   item.phone===phone[0]
              // )
              // console.log(call.status,"status")

              // console.log({phone})

              const data = {
                To: phone,
                From: from,
                FromNum: from
              };
              const connection = Device.connect(data);
              toast.success('Calling...');
              setdis(false);
              setcallconnect(true);
              const discons = Device.on('disconnect', async function(connection) {
                setdiss(true)
                setdis(true);
                // const newContact = await getContacts(campaign, 0);
                
                
                // console.log('disss',diss );
                // if (diss) {
                //   setdis(true);
                //   console.log('entered diss');
                //   return;
                // }
                // const call = newContact?.find(item => item.phone === phone[0]);
                // console.log(call?.status, 'status');
                // console.log(connection, 'connection');
                // // if(call?.status==="no-answer"){
                // //   console.log("entered noanswer")
                // //   return
                // // }
                // if (call?.status === 'completed' || call?.status === 'no-answer') {
                //   submit(true);
                // }
                // submit(true)
              });
              //...
              // socket.on('statusofcall', data => {
              //   getContacts(data, offset, 'socket');
              // });
              // const call=contact.find(item =>
              //   item.phone===phone[0]
              // )
              // console.log(call?.status,"status")
              // if(call?.status==="completed"){
              //   submit(true)
              // }
              //...
            }
          }
        } else {
          toast.error('Micro-Phone is disabled please enabled it');
        }
      });
    } else {
      navigator.permissions.query({ name: 'microphone' }).then(function(permissionstatus) {
        if (permissionstatus.state === 'granted') {
          if (from == null) {
            toast.error('Please  Select Dialer ID');
          } else {
            const id = campaign;
            let phone = [];
            const item = contact.filter(item => {
              const dd = item.phone.startsWith('+');
              if (item.show == true && item.status != 'completed') {
                phone.push(item.phone);
              }
            });
            phone.push(id);
            if (phone.length === 1) {
              toast.error('No Number remaining to call ');
            } else {
              const data = {
                To: phone,
                From: from,
                FromNum:from
              };
              const connection = Device.connect(data);
              toast.success('Calling...');
              setdis(false);
              setcallconnect(true);
            }
          }
        } else {
          toast.error('Micro-Phone is disabled please enabled it');
        }
      });
    }
    if(session === false)
    {
      createSession(campaign)
    }
    setsession(true)
    
  };
  const refresh = () => {
    setoffset(0);
    localStorage.setItem('offset', 0);
    refreshtoken(campaign, offset,oflimit, true);
  };
  const next = () => {
    let limit = countcontact - +offset;
    if (limit <= oflimit) {
      setoffset(0);
      getContacts(campaign, 0, oflimit, true);
      localStorage.setItem('check', true);
      
    localStorage.setItem('offset', 0);
    } else {
      let of = +offset + +oflimit;
      setoffset(of);
      getContacts(campaign, of,oflimit);
        
    localStorage.setItem('offset', of);
    }
  };
  const prev = () => {
    localStorage.removeItem('check');
    let of = +offset - +oflimit;
    setoffset(of);
    getContacts(campaign, of,oflimit, false);
    
    localStorage.setItem('offset', of);
  };

  const disconnect = (check) => {
    if(check){ setdiss(true);
      Device.disconnectAll();
     
    }else{
      setdis(true);
       Device.disconnectAll();
    }
   
    

    //  await getContacts(campaign,0)
  };
  

  console.log({ contact });
  if (Token !== '') {
    return (
      <div>
        <Row style={{ paddingTop: '5%' }} className={'justify-content-center'}>
          <Col md={contact.length > 0 && campaign != null ? '12' : '10'}>
            <Row>
              {contact != null && contact.length > 0 && campaign != null ? (
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="6">
                          <Button onClick={refresh} variant="contained" color="primary">
                            Refresh
                          </Button>
                        </Col>
                        <Col md="3">
                          <Link className="btn btn-success btn-sm" to={`/multilinedialer/logs/${campaign}`}>logs</Link>
                        </Col>
                        <Col md="3">
                          <Link className="btn btn-success btn-sm" to={`/multilinedialer/session/${campaign}`}>session</Link>
                        </Col>
                      </Row>
                    </CardHeader> 
                    <CardBody>
                      <Row>
                        {contact.map(item => (
                          <Col md="12" className="p-4" key={item._id}>
                            <Row>
                              <Col md="3">
                                <span>{item.first_name}</span>
                              </Col>
                              <Col md="4">
                                <span style={{ whiteSpace: 'nowrap' }}>{item.phone}</span>
                              </Col>
                              <Col md="2">
                                <span>
                                  {item.show == true ? (
                                    <Button color="secondary" onClick={() => remove(item._id)}>
                                      <CheckIcon />
                                    </Button>
                                  ) : (
                                    <Button color="primary" onClick={() => update(item._id)}>
                                      <CloseIcon />
                                    </Button>
                                  )}
                                </span>
                              </Col>
                              <Col md="3">
                                <span>
                                  {item.status ? (
                                    item.status == 'completed' ||
                                    item.status == 'queued' ||
                                    item.status == 'initiated' ||
                                    item.status == 'ringing' ||
                                    item.status == 'in-progress' ? (
                                      <Badge color="success">{item.status}</Badge>
                                    ) : item.status == 'no-answer' ||
                                      item.status == 'failed' ||
                                      item.status == 'busy' ||
                                      item.status == 'canceled' ? (
                                      <Badge color="danger">{item.status}</Badge>
                                    ) : (
                                      item.status
                                    )
                                  ) : null}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col xs="4">
                          {offset + contact.length >= countcontact && offset != 0 ? (
                            <Button onClick={prev} variant="contained" color="primary">
                              Previous
                            </Button>
                          ) : null}
                        </Col>
                        <Col xs="5">
                          {offset + contact.length <= countcontact ? (
                            <p>{`${offset + contact.length}/${countcontact}`}</p>
                          ) : null}
                        </Col>
                        <Col xs="2">
                          {
                            <Button onClick={next} variant="contained" color="primary">
                              Next
                            </Button>
                          }
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              ) : contact.length == 0 ? null : null}
              <Col md={contact.length > 0 && campaign != null ? '6' : '12'}>
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <Row>
                        <Col md={campaign != null ? '12' : '4'} xs={12} className="pt-4">
                          <Link className="btn btn-primary btn-xs" to="/pages/campaigns/uploadcampaign">
                            Add Campaign
                          </Link>

                          {contact != null && contact.length > 0 && campaign != null ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ float: 'right' }}
                              onClick={() => {
                                window.location.reload();
                              }}
                            >
                              Back
                            </Button>
                          ) : null}
                        </Col>
                        <Col classname=" mt-4">
                          {contact != null && contact.length > 0 && campaign != null ? (
                            <div>
                              <Label style={{ marginRight: '30px', fontSize: '16px' }}>Place call</Label>
                              <Switch
                                checked={state.checkedB}
                                onChange={handleChange}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                              <Label style={{ marginLeft: '30px', fontSize: '16px' }}>Auto place call</Label>{' '}
                            </div>
                          ) : null}
                        </Col>
                        <Col xs={12} className="pt-4">
                          <GetCOmpany />
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardHeader>
                  <Row />
                  <Row>
                    <Col md="12">
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <Label>From Number</Label>
                            <IMP
                              style={{ fontSize: '0.95em' }}
                              type="select"
                              id="exampleSelect"
                              onChange={e => setfrom(e.target.value)}
                              name="from"
                            >
                              <option value="">Select Dialer Id</option>
                              {numbers.map(item => (
                                <option key={item._id} selected={item.number == from} value={item.number}>
                                  {item.name ? item.name : item.number}
                                </option>
                              ))}
                            </IMP>
                            <small id="emailHelp" class="form-text text-muted">
                              Your call will be placed using the browserphone
                            </small>
                            <small id="emailHelp" class="form-text text-muted">
                              {from != null && from != '' && `Caller id Will be ${from}`}
                            </small>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pt-4">
                            <Label>Limit</Label>
                            <IMP type="number" onChange={e =>{ setLimit(e.target.value)
                              localStorage.setItem("limit",e.target.value )}} />
                            
                            <small id="jg" class="form-text text-muted">
                              You can also add a campaign{' '} 
                            </small>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pt-4">
                            <Label>Campaign</Label>
                            <IMP
                              style={{ fontSize: '0.95em' }}
                              type="select"
                              id="exampleSelect"
                              onChange={selectcampaign}
                            >
                              <option value="">Select Campaign</option>
                              {campaigns.map(item => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </IMP>
                            <small id="jg" class="form-text text-muted">
                              You can also add a campaign{' '}
                            </small>
                          </Col>
                        </Row>
                        

                        <Row className={'justify-content-center'}>
                          {state.checkedB ? (
                            <Col md={6} className="pt-4 ml-6 ">
                              {dis && contact.length > 0 && campaign != null ? (
                                <BUTTONS
                                  style={{ whiteSpace: 'nowrap' }}
                                  onClick={() => submit(state.checkedB)}
                                  size="small"
                                  color="success"
                                >
                                  Place Auto Call
                                </BUTTONS>
                              ) : !dis && contact.length > 0 && campaign != null ? (
                                <Button
                                  onClick={() => disconnect(state.checkedB)}
                                  variant="contained"
                                  size="small"
                                  color="secondary"
                                >
                                  End Auto Call
                                </Button>
                              ) : null}
                            </Col>
                          ) : (
                            <Col md={6} className="pt-4 ml-6 ">
                              {dis && contact.length > 0 && campaign != null ? (
                                <BUTTONS
                                  style={{ whiteSpace: 'nowrap' }}
                                  onClick={() => submit(state.checkedB)}
                                  size="small"
                                  color="success"
                                >
                                  Place Call
                                </BUTTONS>
                              ) : !dis && contact.length > 0 && campaign != null ? (
                                <Button
                                  onClick={() => disconnect(state.checkedB)}
                                  variant="contained"
                                  size="small"
                                  color="secondary"
                                >
                                  End Call
                                </Button>
                              ) : null}
                            </Col>
                          )}
                        </Row>
                      </CardBody>
                    </Col>
                    {callconnect == true ? (
                      <Col md="12" className="pt-4">
                        <Card>
                          <CardHeader>
                            <CardTitle>Form</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <UserForm changeStatus={changestatus} />
                          </CardBody>
                        </Card>
                      </Col>
                    ) : null}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Loader />;
  }
};
Dial.propTypes = {
  gettoken: PropTypes.func.isRequired,

  Token: PropTypes.string.isRequired,
  updatestatus: PropTypes.string.isRequired
};
const mapStateToProps = state => ({
  Token: state.Twilio.token,
  numbers: state.Twilio.numbers,
  contact: state.bulk.contact,
  countcontact: state.bulk.countcontact,
  campaigns: state.bulk.campaigns
});
export default connect(
  mapStateToProps,
  { gettoken, updatestatus, getCampaigns, callstatus, getContacts, refreshtoken, getcountcontact, callmonitor, createSession}
)(Dial);

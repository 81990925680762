import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Button, CardSubtitle, CardText, CardTitle, Input } from 'reactstrap';
import { useDispatch,useSelector } from 'react-redux'
import {submit} from '../../../Redux/Actions/USER/user'

var moment = require('moment-timezone');

function TimeZone() {
  const [tmptz, settmptz] = useState('America/New_York');
  const [tzdefault, settzdefault] = useState('America/New_York');
  const dispatch =useDispatch();
  const currtz=useSelector(state=>state.Twilio.timezone)
  // console.log("state from store is "+currtz)
  let data = moment.tz.names();
  
  // const submit = async tmptz => {
  //   console.log('hey i am working' + tmptz);
    
  //   var axios = require('axios');
  //   var data = JSON.stringify({
  //     timezone: tmptz,
  //     email:localStorage.getItem('email')
  //   });

  //   var config = {
  //     method: 'post',
  //     url: 'http://localhost:3003/api/me/timeezone',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     data: data
  //   };

  //   axios(config)
  //     .then(function(response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // };
  // console.log('temp timezone is ' + JSON.stringify(tmptz));
  // console.log('converted time is ' + moment().tz(tmptz).format('LLL'));
  ;
  return (
    <Row className="justify-content-center">
      <Col md="12" sm="12">
        <Card>
          <CardBody>
            <CardTitle tag="h5">Update Timezone</CardTitle>
            <CardSubtitle>Current Timezone {currtz.replace("_", " ").replace("/", "- ")}</CardSubtitle>
            <Input
              style={{ fontSize: '0.95em', width: '35vw' }}
              type="select"
              bsSize="sm"
              onChange={e => settmptz(e.target.value)}
            >
              {data.map(item => (
                <option value={item} selected={item}>
                  {item.replace("_", " ").replace("/", "- ")}
                </option>
              ))}
              {
                <option value={tzdefault} selected={tzdefault}>
                  {tzdefault.replace("_", " ").replace("/", "- ")}
                </option>
              }
            </Input>
            <Button style={{ backgroundColor: '#00d27a', color: '#fff', borderColor: '#28a745' }} className="mt-1 " onClick={() => dispatch(submit(tmptz))}>Update</Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default TimeZone
import React, { Fragment, useEffect,useState } from 'react';
import { Row, Col, Card, CardBody, InputGroup, InputGroupText, InputGroupAddon, Input, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap';
import CardSummary from './CardSummary';
import { toast } from 'react-toastify';
import FalconCardHeader from '../common/FalconCardHeader';
import { connect } from 'react-redux';
import { getfund, getplans } from '../../Redux/Actions/Payment';
import { getapitoken } from '../../Redux/Actions/Auth/Auth';
import { reportdetails } from '../../Redux/Actions/USER/user';
import Clipboard from 'react-clipboard.js';
import { Button } from '@material-ui/core';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import Label from 'reactstrap/lib/Label';
import Tooltipbutton from '../Tooltip/Tooltip';

const Dashboard = ({ getfund, fund, getplans, plan, getapitoken, reportdetails, token, report }) => {
  const history = useHistory();
  // State
  const [showAlert, setshowAlert] = useState(true);
  useEffect(() => {
    getfund();
    getplans();
    getapitoken();
    reportdetails(7);
  }, [getfund, getplans, getapitoken, reportdetails]);
  useEffect(() => {
    toast(
      <Fragment>
        Welcome to <strong>MergeCall</strong>!<br />
      </Fragment>
    );
  }, []);

 
   

 

  // hit api to check if available in a2p collection //create an alert + hub link
  useEffect(() => {
    (async () => {
      try {
        const res = await Axios({
          method: 'GET',
          url: process.env.REACT_APP_PORT + `/api/payment/paymentdata`,
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        });
////////////////
        const getUserConfirmation = await Axios.get(
          process.env.REACT_APP_PORT + `/api/me/getA2pConfirmation`,
                    {
            headers: {
              'x-auth-token': localStorage.getItem('token')
            }
          }
        );
        console.log("getUserConfirmation===========",getUserConfirmation)
        if(getUserConfirmation.data.data==true)
        {
          console.log("getUserConfirmation===========",getUserConfirmation)
          setshowAlert(false)
        }
  ////////////////
        console.log('userdata', res.data.data);
        if(res.data.data<=0){
          setmodal(true)
        }
        
      } catch (e) {
        toast.error(e.response.data.error);
      }
    })();
    }
  , [])
  
    const [modal, setmodal] = useState(false);
    
  
    const Toggle = () => {
      setmodal(!modal);
    };
  
    const takeme =()=>{
      history.push("/home/fund");
  }
  const onSuccess = () => {
    toast.success('Copied');
  };
  const getText = () => {
    return token;

  }
return (
  
    <Fragment>
      
      {/* <PaymentsLineChart /> */}
      <Card>
      <div>
        
        {showAlert && (
        <div style={{ color: 'red', marginLeft: '10px' }}>
         NOTE: Kindly register for A2P 10 DLC{' '}
          <a href="/hub">
            here
          </a>
        </div>

      )}
      
      </div>
      </Card>
      <br></br>


      <div className="card-deck">
        <CardSummary title="Fund" to="/home/fund" color="warning" linkText="Add Fund">
          {fund != null ? `$ ${(Math.round(fund.balance * 100) / 100).toFixed(2)}` : 0}
        </CardSummary>
        <CardSummary title="Plan" color="info" to="/home/upgradeplans" linkText="View plans">
          {plan && plan.plan ? 'Premium' : 'No Active Plan'}
        </CardSummary>
        <CardSummary
          title="Click to Download Extention"
          onClick={()=>window.open("https://chromewebstore.google.com/detail/mergecall/ihgbmckjcoehaahnhhfcilllgkhkdgkh")} to="#"
          show="false"
        >
          <a onClick={()=>window.open("https://chromewebstore.google.com/detail/mergecall/ihgbmckjcoehaahnhhfcilllgkhkdgkh")} to="#"
          >
            <img src="/images/chrome.jpg" width="25%" alt="chrome" />
          </a>
          <a
            className="px-4"
            onClick={()=>{window.open('https://mergeasy.com/blog/podio-merge-call/')}}
          >
            <img src="/images/podio.jpg" width="25%" alt="chrome" />
          </a>
          
        </CardSummary>
      </div>
      
      <Card className="mb-3">
        {/* <FalconCardHeader title="Api Key" light={false} /> */}
       
        <CardBody className="p-0"> <Label className="pl-3">Api Key</Label>
          <Row className="justify-content-center">
            <Col md={10} className="pb-4">
              {token != null && (
                <InputGroup>
                  <Input type="text" value={token} disabled />
                  <InputGroupAddon addonType="append">
                    
                    <Tooltipbutton title="Click to Copy">
                      <Clipboard className="btn btn-primary" option-text={getText} onSuccess={onSuccess}>
                        {' '}
                        <i class="fas fa-copy" />
                      </Clipboard>
                      </Tooltipbutton>
                    
                  </InputGroupAddon>
                </InputGroup>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row noGutters>
        <Col lg="12" className="pr-lg-2">
          <Card className="mb-3">
            <FalconCardHeader title="Report" light={false}>
              <Input
                type="select"
                onChange={e => {
                  reportdetails(e.target.value);
                }}
              >
                <option value={7}>7days</option>
                <option value={15}>15days</option>
                <option value={30}>30days</option>
                <option value={90}>90days</option>
              </Input>
            </FalconCardHeader>
            <CardBody className="p-0">
              <Row className="justify-content-center">
                <Col md={10}>
                  <Row>
                    <Col className="px-4" md={8} xs={6}>
                      <p>Total Talktime</p>
                    </Col>{report != null &&console.log("reports is",report)}
                    <Col md={4} xs={6}>
                      {report != null &&
                        report.totaltalktime.length > 0 &&
                        `$${report.totaltalktime[0].total.toFixed(2)}`}{report != null &&
                          report.totaltalktime.length <= 0 &&
                          `$0`}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-4" md={8} xs={6}>
                      <p>Average call duration</p>
                    </Col>
                    <Col md={4} xs={6}>
                      {report != null &&
                        report.avergaeduration.length > 0 &&
                        `${report.avergaeduration[0].total.toFixed(2)}min`}{report != null &&
                          report.avergaeduration.length <= 0 &&
                          `0`}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-4" md={8} xs={6}>
                      <p>Total Inbound Text</p>
                    </Col>
                    <Col md={4} xs={6}>
                      {report != null && `${report.inbound}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-4" md={8} xs={6}>
                      <p>Total Outbound Text</p>
                    </Col>
                    <Col md={4} xs={6}>
                      {report != null && `${report.outbound}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-4" md={8} xs={6}>
                      <p>Number Lookup Charge</p>
                    </Col>
                    <Col md={4} xs={6}>
                    {report != null && `$${report.totalapicharge.toFixed(2)}`}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {modal ? (
              <Modal isOpen toggle={Toggle}>
                <ModalHeader toggle={Toggle} style={{ backgroundColor: '#becdda8f' }}>
                  <h3 style={{ fontWeight: 'bold' }}>Alert </h3>
                </ModalHeader>
                <ModalBody>Please add fund to the wallet</ModalBody>
                <ModalFooter>
                  <Button className="mr-2" variant="contained" color="secondary" onClick={() => Toggle()}>
                  Remind me later
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#28a745', color: 'white' }}
                    onClick={() => takeme()}
                  >
                     Take me to Wallet
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
    </Fragment>
  );
};
const mapeStateToProps = state => ({
  fund: state.payment.fund,
  plan: state.payment.plan,
  token: state.auth.token,
  report: state.Twilio.report
});
export default connect(
  mapeStateToProps,
  { getfund, getplans, getapitoken, reportdetails }
)(Dashboard);

import React from 'react'
import PropTypes from 'prop-types';

import Loader from "../../../components/common/Loader"
import {connect} from 'react-redux'
import  {getCompanyById, updateCompanyById} from "../../../Redux/Actions/USER/user"
import {Row, Col, Card, CardBody, Button, Input, Label} from 'reactstrap'
import {Link} from "react-router-dom"
import { toast } from 'react-toastify';
class EditCompany extends React.Component
{   
    state= {
        name: ""
    }
    componentDidUpdate(prevProps)
    {
        if(this.props.detail !== prevProps.detail )
        {   
            
            const data = this.props.detail
            this.setState({name: data.name})
        }
    }
    componentDidMount(){
        this.props.getCompanyById(this.props.id)
    }
    
    handleChange = (e)=>{
        this.setState({[e.target.name]:e.target.value})
     }
    Submit = ()=> {
         const {name} = this.state
        if (name===null||name===undefined||name==="" ){
            toast.error("Can not submit an empty name ")
        }else{
       
        this.props.updateCompanyById(this.props.id, name )
        this.props.toggle()
    }
    }
    render(){
        if(this.props.detail === null)
        {
            return <Loader></Loader>
        }
        else {
            return (
                <div>
                <Row>
                    <Col md="12" className="pb-2">
                      
                            <Label>Name</Label>
                            <Input style={{ fontSize: "0.95em"}} placeholder="Enter Name"  type="text" onChange={this.handleChange} name="name" value={this.state.name}></Input>
                        
                    </Col>
                
                 
                     
                    <Col md={8} xs="6">
                       
                        <Button style={{whiteSpace:"nowrap"}} onClick={()=>this.Submit()} size="sm" color="primary">Save Company name</Button>
                        
                    </Col>
                    {/* <Col md={4} xs="6">
                        <Link to="/getcompany"  className="btn btn-sm btn-primary">Back to Companies</Link>
                    </Col> */}
                </Row>
                </div>
            )
                
           
        }
     }
}
const mapStateToProps = (state) => ({
    detail: state.Twilio.company

 });
EditCompany.propTypes = {
    getCompanyById: PropTypes.func.isRequired,
    updateCompanyById: PropTypes.func.isRequired
  };
export default connect(mapStateToProps, {getCompanyById, updateCompanyById})(EditCompany)
import React, {useEffect, useState} from "react"
import {Card, CardBody, Row, Button, Col,  CardTitle, Input, CardHeader, Label,Modal, ModalHeader, ModalBody, ModalFooter,FormGroup,Toast,ToastBody,ToastHeader} from "reactstrap"
import {Link} from "react-router-dom"
import {connect} from "react-redux"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import { toast } from 'react-toastify';




const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#edf2f9',
    // color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14
  }
}))(TableCell);




const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);



const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});




const BusinessProfile =({})=>{
    
    const  [legalBusinessName, setLegalBusinessName] = useState("")  
    const  [streetAddress, setStreetAddress] = useState("")  
    // const [registrationType, setRegistrationType] = useState('');
    const [showAdditionalField, setShowAdditionalField] = useState(false);
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [country, setCountry] = useState("");
    const  [addModal, setAddModal] = useState(false);
    const  [addModal2, setAddModal2] = useState(false);
    const  [addModal3, setAddModal3] = useState(false);
    const  [addModal4, setAddModal4] = useState(false);
    const [streetAddress2, setStreetAddress2] = useState(""); // Example for Street Address 2
    const [businessType, setBusinessType] = useState(""); // Example for Business Type
    const [businessIndustry, setBusinessIndustry] = useState(""); // Example for Business Industry
    const [registrationIDType, setRegistrationIDType] = useState(""); // Example for Business Registration ID Type
    const [otherRegistrationType, setOtherRegistrationType] = useState(""); // Example for Other Registration Type (if applicable)
    


    const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState(""); // Example for Business Registration Number
    const [websiteURL, setWebsiteURL] = useState(""); // Example for Website URL
    const [socialMediaURL, setSocialMediaURL] = useState(""); // Example for Social Media Profile URL
    const [firstName, setFirstName] = useState(""); // Example for First Name
    const [lastName, setLastName] = useState(""); // Example for Last Name
    const [email, setEmail] = useState(""); // Example for Email
    const [phone, setPhone] = useState(""); // Example for Phone
    const [businessTitle, setBusinessTitle] = useState(""); // Example for Business Title
    const [jobPosition, setJobPosition] = useState("");
    const [firstName1, setFirstName1] = useState(""); // Example for First Name
    const [lastName1, setLastName1] = useState(""); // Example for Last Name
    const [email1, setEmail1] = useState(""); // Example for Email
    const [phone1, setPhone1] = useState(""); // Example for Phone
    const [businessTitle1, setBusinessTitle1] = useState(""); // Example for Business Title


    const [jobPosition1, setJobPosition1] = useState("");
    // subscriptionType && companyType && textBoxValue && newRadioOption &&

    const [subscriptionType, setSubscriptionType] = useState('soleProprietor');
    const [companyTypeL, setCompanyTypeL] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [checkboxError, setCheckboxError] = useState('');
    const [textBoxValueL, setTextBoxValueL] = useState('');
    const [textBoxValue, setTextBoxValue] = useState('');
    const [hasInteractedText, setHasInteractedText] = useState(false);
    const [newRadioOptionL, setNewRadioOptionL] = useState('');
    const [newRadioOption, setNewRadioOption] = useState('');
    const [brandName, setBrandName] = useState("");
    const [hasInteracted, setHasInteracted] = useState(false);
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [campaignVerifyTokenL, setCampaignVerifyTokenL] = useState("");
    const [campaignVerifyToken, setCampaignVerifyToken] = useState("");
    const [HasInteractedToken, setHasInteractedToken] = useState(false);

    const [isSecondSectionRequired, setIsSecondSectionRequired] = useState(false);
  
    const [stockExchangeL, setstockExchangeL] = useState("");
    const [stockExchange, setstockExchange] = useState("");
    const [vertical, setVertical] = useState("");

    
  
  const [campaignDescription, setCampaignDescription] = useState("");
  const [sampleMessage1, setSampleMessage1] = useState("");
  const [sampleMessage2, setSampleMessage2] = useState("");
  const [includeEmbeddedLinks, setIncludeEmbeddedLinks] = useState(false);
  const [includePhoneNumbers, setIncludePhoneNumbers] = useState(false);
  const [consentToReceiveMessages, setConsentToReceiveMessages] = useState("");
  const [optInKeywords, setOptInKeywords] = useState("");
  const [optInMessage, setOptInMessage] = useState("");
  const [useCaseFee, setuseCaseFee] = useState("");
  const [isCheckedCampaignUseCaseFee, setIsCheckedCampaignUseCaseFee] = useState(false);
  const [checkboxErrorCampaignUseCaseFee, setCheckboxErrorCampaignUseCaseFee] = useState('');

  const [show, setShow] = useState(false);

    const addToggle = () => {
        setAddModal(!addModal)

    };
    const addToggle2 = () => {
      setAddModal2(!addModal2)

  };
  const addToggle3 = () => {
    setAddModal3(!addModal3)

};
const addToggle4 = () => {
  setAddModal4(!addModal4)

};

const handleSecondSectionFieldChange = () => {
  // Check if any field in the second section is filled
  if (
    firstName1 ||
    lastName1 ||
    email1 ||
    phone1 ||
    businessTitle1 ||
    jobPosition1
  ) {
    // Set the flag to make the second section fields required
    setIsSecondSectionRequired(true);

  } else {
    // Reset the flag
    setIsSecondSectionRequired(false);
  }
};
const handleSubscriptionTypeChange = (event) => {
  const selectedValue = event.target.value;
  setSubscriptionType(selectedValue);
  setCompanyType(selectedValue === 'lowVolumeStandard' ? '' : companyType);
  setAddModal3(true);
};

const handleCompanyTypeChangeL = (event) => {
  const selectedValue = event.target.value;
  setCompanyTypeL(selectedValue);
};
const handleCompanyTypeChange = (event) => {
  const selectedValue = event.target.value;
  setCompanyType(selectedValue);
};

const handleCheckboxChange = (event) => {
  const checked = event.target.checked;
  setIsChecked(!isChecked);
  if (!checked) {
    setCheckboxError('Please acknowledge A2P fee charges.');
  } else {
    setCheckboxError('');
  }
};

const handleCheckboxChangeCampaignUseCaseFee = (event) => {
  const checked = event.target.checked;
  setIsCheckedCampaignUseCaseFee(!isCheckedCampaignUseCaseFee);
  if (!checked) {
    setCheckboxErrorCampaignUseCaseFee('Please acknowledge A2P fee charges.');
  } else {
    setCheckboxErrorCampaignUseCaseFee('');
  }
};


const handleNewRadioOptionChange = (value) => {
  setNewRadioOption(value);
};

const handleNewRadioOptionChangeL = (value) => {
  setNewRadioOptionL(value);
};

const handleBrandNameChange = (e) => {
  setBrandName(e.target.value);
  setHasInteracted(true);
};

const handleStockTickerChange = (e) => {
  setTextBoxValue(e.target.value);
  setHasInteractedText(true);
};
const handleStockTickerChangeL = (e) => {
  setTextBoxValueL(e.target.value);
  setHasInteractedText(true);
};
const handleCampaignVerifyToken = (e) => {
setCampaignVerifyToken(e.target.value);
setHasInteractedToken(true);
};

const handleCampaignVerifyTokenL = (e) => {
  setCampaignVerifyTokenL(e.target.value);
  setHasInteractedToken(true);
  };

function handlePhoneNumberChange(event) {
  const phoneNumber = event.target.value;
  setMobilePhoneNumber(phoneNumber);
  if (!phoneNumber.trim()) {
    setPhoneNumberError('Phone number is a required field');
  } else {
    setPhoneNumberError('');
  }

};



const areAllFieldsFilled = () => {
  if (subscriptionType === 'lowVolumeStandard') {
    if (companyTypeL === 'public')
    {
      return (
        stockExchangeL !== '' && stockExchangeL !== '--- Select ---' &&
        textBoxValueL !== '' &&
        isChecked
      );
    }
    if (companyTypeL === 'usNonProfit')
    {
      if (newRadioOptionL === 'yes') {
        return(
        campaignVerifyTokenL !== '' && isChecked
        )
      } else {
        return (newRadioOptionL !== '' && isChecked)
      }
    }

    if (companyTypeL === 'private')
    {
      return (
        isChecked
      );
    }
    if (companyTypeL === 'usGovernment')
    {
      return (
        isChecked
      );
    }
  } else if (subscriptionType === 'standard') {
    if (companyType === 'private')
    {
      return (
        isChecked
      );
    }
    if (companyType === 'usGovernment')
    {
      return (
        isChecked
      );
    }
    if (companyType === 'public')
    {
      return (
        stockExchange !== '' && stockExchange !== '--- Select ---' &&
        textBoxValue !== '' &&
        isChecked
      );
    }
    if (companyType === 'usNonProfit')
    {
      if (newRadioOption === 'yes') {
        return(
        campaignVerifyToken !== '' && isChecked
        )
      } else {
        return (newRadioOption !== '' && isChecked)
      }
    }
    if (companyType === 'private')
    {
      return (
        isChecked
      );
    }
    if (companyType === 'usGovernment')
    {
      return (
        isChecked
      );
    }
  } else if (subscriptionType === 'soleProprietor') {
    return (
      brandName !== '' &&
      mobilePhoneNumber !== '' &&
      isChecked
    );
  } 
  // else {
  //   // Handle other subscription types if needed
  //   return false;
  // }
};



    const handleRegistrationTypeChange = (event) => {
      const selectedValue = event.target.value;
      setRegistrationIDType(selectedValue);
  
      // Check if selected value is "Other" and update showAdditionalField accordingly
      setShowAdditionalField(selectedValue === 'Other');
    };



  




    const save1 = async ()=>{
      if (isCheckFirstModal()) {
        addToggle(); 
        addToggle2(); 
      } else {
        // Display a warning message or handle validation errors
        alert("Please fill in all required fields.");
      }

    

        // saveoptout({title, message})
        const requestData1 = {
          legalBusinessName,
          streetAddress,
          streetAddress2,
          city,
          zipCode,
          country,
          businessType,
          businessIndustry,
          registrationIDType,
          otherRegistrationType,
          businessRegistrationNumber,
          websiteURL,
          socialMediaURL
          // Add other state variables as needed
        };
        // try {
        //   const sendSave1 = await Axios.post(
        //     process.env.REACT_APP_PORT + `/api/me/sendDetailsA2p`,
        //     requestData1,
        //     {
        //       headers: {
        //         'x-auth-token': localStorage.getItem('token')
        //       }
        //     }
        //   );
        
        //   // Handle the response as needed
        //   console.log('Data sent successfully:', sendSave1.data);
        // } catch (error) {
        //   // Handle errors here
        //   console.error('Error sending data:', error);
        // }
        // setAddModal(!addModal)
    }
       
    const save2 = async ()=>{
      if (isSection1Valid()) {
        if(isSecondSectionValid())
        {
          if(isSection2Valid())
          {
          addToggle2();
          addToggle3();
          }
          else
          {
            alert("Please fill in all required fields for 2nd Authorized Representative.");

          }

        }
        else
        {
          addToggle2();
          addToggle3(); 
        }
        
        
        // addToggle3(); 
        // Proceed to the next modal or perform further actions
        // Here you can add code to handle form submission
        // If validation passes, close the current modal and open the next one
        // Add code to open the next modal here
      } else {
        // Display a warning message or handle validation errors
        alert("Please fill in all required fields for 1st Authorized Representative.");
      }


        // saveoptout({title, message})
        const requestData2 = {
         
          firstName,
          lastName,
          email,
          phone,
          businessTitle,
          jobPosition,
          firstName1,
          lastName1,
          email1,
          phone1,
          businessTitle1,
          jobPosition1,
          // Add other state variables as needed
        };
        // try {
        //   const sendSave2 = await Axios.post(
        //     process.env.REACT_APP_PORT + `/api/me/sendDetailsA2p`,
        //     requestData2,
        //     {
        //       headers: {
        //         'x-auth-token': localStorage.getItem('token')
        //       }
        //     }
        //   );
        
        //   // Handle the response as needed
        //   console.log('Data sent successfully:', sendSave2.data);
        // } catch (error) {
        //   // Handle errors here
        //   console.error('Error sending data:', error);
        // }
    }

    const save3 = async ()=>{
      // if (isCheckThirdModal()) {
        addToggle3(); 
        addToggle4(); 
      // } else {
      //   // Display a warning message or handle validation errors
      //   alert("Please fill in all required fields.");
      // }

    

        // saveoptout({title, message})
        const requestData3 = {
          
          subscriptionType,
          companyType,
          stockExchange,
          textBoxValue,
          newRadioOption,
          campaignVerifyToken,
          isChecked,
          stockExchangeL,
          companyTypeL,
          textBoxValueL,
          newRadioOptionL
          // Add other state variables as needed
        };
        
        // try {
        //   const sendSave3 = await Axios.post(
        //     process.env.REACT_APP_PORT + `/api/me/sendDetailsA2p`,
        //     requestData3,
        //     {
        //       headers: {
        //         'x-auth-token': localStorage.getItem('token')
        //       }
        //     }
        //   );
        
        //   // Handle the response as needed
        //   console.log('Data sent successfully:', sendSave3.data);
        // } catch (error) {
        //   // Handle errors here
        //   console.error('Error sending data:', error);
        // }
    }
    
    const save4 = async ()=>{
      // if (isCheckThirdModal()) {
        if (isCheckFourthModal()) 
        {
          addToggle4(); 
        } else {
          alert("Please fill in all required fields.");
        }

    

        // saveoptout({title, message})
        const requestData4 = {
          // 1st
          legalBusinessName,
          streetAddress,
          streetAddress2,
          city,
          zipCode,
          country,
          businessType,
          businessIndustry,
          registrationIDType,
          otherRegistrationType,
          businessRegistrationNumber,
          websiteURL,
          socialMediaURL,
          // 2nd
          firstName,
          lastName,
          email,
          phone,
          businessTitle,
          jobPosition,
          firstName1,
          lastName1,
          email1,
          phone1,
          businessTitle1,
          jobPosition1,
          // 3rd
          subscriptionType,
          companyType,
          stockExchange,
          textBoxValue,
          newRadioOption,
          campaignVerifyToken,
          isChecked,
          stockExchangeL,
          companyTypeL,
          textBoxValueL,
          newRadioOptionL,
          // 4th
          campaignDescription , 
          sampleMessage1 , 
          sampleMessage2 , 
          includeEmbeddedLinks ,
          includePhoneNumbers, 
          consentToReceiveMessages, 
          optInKeywords, 
          optInMessage,
          useCaseFee,
          isCheckedCampaignUseCaseFee      
          // Add other state variables as needed
        };
        console.log("requestData4===============",requestData4)
        try {
          const sendSave4 = await Axios.post(
            process.env.REACT_APP_PORT + `/api/me/sendDetailsA2p`,
            requestData4,
            {
              headers: {
                'x-auth-token': localStorage.getItem('token')
              }
            }
          );
          setShow(true);
          toast.success('Success! Your form details were received.');
        
          // Handle the response as needed
          console.log('Data sent successfully for DB:', sendSave4.data);
          // console.log('Data sent successfully for Email:', sendToPodio.data);
        } catch (error) {
          // Handle errors here
          console.error('Error sending data:', error);
        }
        // setAddModal(!addModal)
    }


const isCheckFirstModal=()=>{
  return legalBusinessName && streetAddress && city && zipCode && country && businessType && businessType !=="--- Select ---" && businessIndustry && businessIndustry !=="--- Select ---" && registrationIDType && registrationIDType!== "--- Select ---" && businessRegistrationNumber && websiteURL;
   
};


    const isSection1Valid = () => {
      return firstName && lastName && email && phone && businessTitle && jobPosition && jobPosition!=="--- Select ---";
    };
  
    // Function to check if fields in the second section are valid
    const isSection2Valid = () => {
      return firstName1 && lastName1 && email1 && phone1 && businessTitle1 && jobPosition1 && jobPosition1!=="--- Select ---";
    };
  
    // Function to check if at least one field in the second section is filled
    const isSecondSectionValid = () => {
      return (
        firstName1 || lastName1 || email1 || phone1 || businessTitle1 || (jobPosition1 && jobPosition1!=="--- Select ---")
      )
    };

    const isCheckFourthModal=()=>{
      return campaignDescription && sampleMessage1 && sampleMessage2 && consentToReceiveMessages && (useCaseFee &&useCaseFee!=="--- Select ---" && isCheckedCampaignUseCaseFee);
       
    };

    const classes = useStyles();
    return (
      <>
      <Toast isOpen={show}>
      <ToastHeader toggle={()=>setShow(!show)}>
        <strong className="me-auto">A2P 10DLC Registration! </strong>
        <small> just now</small>
      </ToastHeader>
      <ToastBody>Thanks for your A2P 10DLC registration with mergecall.com! Registration takes up to 1 week. <strong>Expect an email from us soon!</strong></ToastBody>
    </Toast>
        <Card>
          <CardHeader className="pb-0">
            <Row>
              <Col md={12}>
                <div
                  className="btn btn-primary btn-sm"
                  style={{ float: 'left', fontSize: '0.85rem' }}
                  color="primary"
                  onClick={addToggle}
                >
                  Register Now
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody style={{ backgroundColor: '#f9fafd', height: '309px', overflow: 'auto' }} />
        </Card>

        {addModal ? (
          <Modal isOpen toggle={addToggle}>
            <ModalHeader toggle={addToggle} style={{ backgroundColor: '#becdda8f' }}>
              Add Bussiness Information
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <Label>
                    <span style={{ color: 'red' }}>*</span>Legal Business Name
                  </Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    onChange={e => {
                      setLegalBusinessName(e.target.value);
                    }}
                    placeholder=""
                    value={legalBusinessName}
                    required
                  />
                </Col>
                <Col md={6} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span>Street Address
                  </Label>
                  <Input
                    type="text"
                    required
                    bsSize="sm"
                    onChange={e => {
                      setStreetAddress(e.target.value);
                    }}
                    placeholder=""
                    value={streetAddress}
                  />
                </Col>
                <Col md={6} className="pt-4">
                  <Label>Street Address 2</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    onChange={e => {
                      setStreetAddress2(e.target.value);
                    }}
                    placeholder=""
                    value={streetAddress2}
                  />
                </Col>
                <Col md={4} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span>City
                  </Label>
                  <Input
                    required
                    type="text"
                    bsSize="sm"
                    onChange={e => {
                      setCity(e.target.value);
                    }}
                    placeholder=""
                    value={city}
                  />
                </Col>
                <Col md={4} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span>Zip Code
                  </Label>
                  <Input
                    required
                    type="number"
                    bsSize="sm"
                    onChange={e => {
                      setZipCode(e.target.value);
                    }}
                    placeholder=""
                    value={zipCode}
                  />
                </Col>
                <Col md={4} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span>Country
                  </Label>
                  <Input
                    type="text"
                    required
                    bsSize="sm"
                    onChange={e => {
                      setCountry(e.target.value);
                    }}
                    placeholder=""
                    value={country}
                  />
                </Col>
                <Col md={6} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span>Business Type
                  </Label>
                  <Input
                    type="select"
                    required
                    id="BusinessType"
                    onChange={e => setBusinessType(e.target.value)}
                    value={businessType}
                  >
                    <option>--- Select ---</option>
                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Corporation">Corporation</option>
                    <option value="Co-operative">Co-operative</option>
                    <option value="Limited Liability Corporation">Limited Liability Corporation</option>
                    <option value="Non-profit Corporation">Non-profit Corporation</option>
                  </Input>
                </Col>
                <Col md={6} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span>Business Industry
                  </Label>
                  <Input
                    required
                    type="select"
                    id="BusinessIndustry"
                    onChange={e => setBusinessIndustry(e.target.value)}
                    value={businessIndustry}
                  >
                    <option>--- Select ---</option>
                    <option value="AUTOMOTIVE">Automotive</option>
                    <option value="AGRICULTURE">Agriculture</option>
                    <option value="BANKING">Banking</option>
                    <option value="CONSTRUCTION">Construction</option>
                    <option value="CONSUMER">Consumer</option>
                    <option value="EDUCATION">Education</option>
                    <option value="ENGINEERING">Engineering</option>
                    <option value="ENERGY">Energy</option>
                    <option value="OIL_AND_GAS">Oil &amp; Gas</option>
                    <option value="FAST_MOVING_CONSUMER_GOODS">Fast moving consumer goods</option>
                    <option value="FINANCIAL">Financial</option>
                    <option value="FINTECH">Fintech</option>
                    <option value="FOOD_AND_BEVERAGE">Food &amp; Beverage</option>
                    <option value="GOVERNMENT">Government</option>
                    <option value="HEALTHCARE">Healthcare</option>
                    <option value="HOSPITALITY">Hospitality</option>
                    <option value="INSURANCE">Insurance</option>
                    <option value="LEGAL">Legal</option>
                    <option value="MANUFACTURING">Manufacturing</option>
                    <option value="MEDIA">Media</option>
                    <option value="ONLINE">Online</option>
                    <option value="PROFESSIONAL_SERVICES">Professional Services</option>
                    <option value="RAW_MATERIALS">Raw materials</option>
                    <option value="REAL_ESTATE">Real Estate</option>
                    <option value="RELIGION">Religion</option>
                    <option value="RETAIL">Retail</option>
                    <option value="JEWELRY">Jewelry</option>
                    <option value="TECHNOLOGY">Technology</option>
                    <option value="TELECOMMUNICATIONS">Telecommunication</option>
                    <option value="TRANSPORTATION">Transportation</option>
                    <option value="TRAVEL">Travel</option>
                    <option value="ELECTRONICS">Electronics</option>
                    <option value="NOT_FOR_PROFIT">Not for profit</option>
                  </Input>
                </Col>
                <Col md={6} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span>Business Registration ID Type
                  </Label>
                  <Input
                    required
                    type="select"
                    id="BusinessRegistrationIDType"
                    onChange={handleRegistrationTypeChange}
                    value={registrationIDType}
                  >
                    <option>--- Select ---</option>
                    <option value="EIN">USA: Employer Identification Number (EIN)</option>
                    <option value="DUNS">USA: DUNS Number (Dun &amp; Bradstreet)</option>
                    <option value="CCN">Canada: Canadian Corporation Number (CCN)</option>
                    <option value="CBN">Canada: Canadian Business Number (CBN)</option>
                    <option value="CN">Great Britain: Company Number</option>
                    <option value="ACN">Australia: Company Number from ASIC (ACN)</option>
                    <option value="CIN">India: Corporate Identity Number</option>
                    <option value="VAT">Estonia: VAT Number</option>
                    <option value="VATRN">Romania: VAT Registration Number</option>
                    <option value="RN">Israel: Registration Number</option>
                    <option value="Other">Other</option>
                  </Input>
                </Col>
                {showAdditionalField && (
                  <Col md={6} className="pt-4">
                    <Label>Name of Other Registration Type</Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      value={otherRegistrationType}
                      onChange={e => {
                        setOtherRegistrationType(e.target.value);
                      }}
                      placeholder=""
                    />
                  </Col>
                )}
                <Col md={6} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span>Business Registration Number
                  </Label>
                  <Input
                    type="text"
                    required
                    bsSize="sm"
                    onChange={e => {
                      setBusinessRegistrationNumber(e.target.value);
                    }}
                    placeholder=""
                    value={businessRegistrationNumber}
                  />
                </Col>
                <Col md={6} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span>Website URL
                  </Label>
                  <Input
                    type="text"
                    required
                    bsSize="sm"
                    onChange={e => {
                      setWebsiteURL(e.target.value);
                    }}
                    placeholder=""
                    value={websiteURL}
                  />
                </Col>
                <Col md={6} className="pt-4">
                  <Label>Social Media Profile URL</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    onChange={e => {
                      setSocialMediaURL(e.target.value);
                    }}
                    placeholder=""
                    value={socialMediaURL}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outlined"
                onClick={() => {
                  save1();
                }}
                color="primary"
              >
                Next
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}


        {addModal2 ? (
          <Modal isOpen toggle={addToggle2}>
            <ModalHeader toggle={addToggle2} style={{ backgroundColor: '#becdda8f' }}>
              Add Authorized Representative
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={6}>
                  <Label>
                    <span style={{ color: 'red' }}>*</span> First Name
                  </Label>
                  <Input
                    type="text"
                    required
                    bsSize="sm"
                    value={firstName}
                    onChange={e => {
                      setFirstName(e.target.value);
                    }}
                    placeholder=""
                  />
                </Col>

                <Col md={6}>
                  <Label>
                    <span style={{ color: 'red' }}>*</span> Last Name
                  </Label>
                  <Input
                    type="text"
                    required
                    bsSize="sm"
                    value={lastName}
                    onChange={e => {
                      setLastName(e.target.value);
                    }}
                    placeholder=""
                  />
                </Col>
                <Col md={6}>
                  <Label>
                    <span style={{ color: 'red' }}>*</span> Email
                  </Label>
                  <Input
                    type="email"
                    required
                    bsSize="sm"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    placeholder="abc@email.com"
                  />
                </Col>
                <Col md={6}>
                  <Label>
                    {' '}
                    <span style={{ color: 'red' }}>*</span>Phone
                  </Label>
                  <Input
                    type="phone"
                    required
                    bsSize="sm"
                    value={phone}
                    onChange={e => {
                      setPhone(e.target.value);
                    }}
                    placeholder="Include country code eg. +1XXXXXXXXXX"
                  />
                </Col>
                <Col md={6}>
                  <Label>
                    {' '}
                    <span style={{ color: 'red' }}>*</span> Business Title
                  </Label>
                  <Input
                    type="text"
                    required
                    bsSize="sm"
                    value={businessTitle}
                    onChange={e => {
                      setBusinessTitle(e.target.value);
                    }}
                    placeholder=""
                  />
                </Col>
                <Col md={6} className="pt-4">
                  <Label>
                    <span style={{ color: 'red' }}>*</span> Job Position
                  </Label>
                  <Input
                    type="select"
                    required
                    id="JobPosition"
                    value={jobPosition}
                    onChange={e => setJobPosition(e.target.value)}
                  >
                    <option>--- Select ---</option>
                    <option value="Director">Director</option>
                    <option value="GM">GM</option>
                    <option value="VP">VP</option>
                    <option value="CEO">CEO</option>
                    <option value="CFO">CFO</option>
                    <option value="General Counsel">General Counsel</option>
                    <option value="Other">Other</option>
                  </Input>
                </Col>
              </Row>
              <hr />

              <Row>
                <Col md={6}>
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    value={firstName1}
                    onChange={e => {
                      setFirstName1(e.target.value);
                      handleSecondSectionFieldChange();
                    }}
                    placeholder=""
                    required={isSecondSectionRequired}
                  />
                </Col>
                <Col md={6}>
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    value={lastName1}
                    onChange={e => {
                      setLastName1(e.target.value);
                      handleSecondSectionFieldChange();
                    }}
                    placeholder=""
                    required={isSecondSectionRequired}
                  />
                </Col>
                <Col md={6}>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    bsSize="sm"
                    value={email1}
                    onChange={e => {
                      setEmail1(e.target.value);
                      handleSecondSectionFieldChange();
                    }}
                    placeholder="abc@email.com"
                    required={isSecondSectionRequired}
                  />
                </Col>
                <Col md={6}>
                  <Label>Phone</Label>
                  <Input
                    type="phone"
                    bsSize="sm"
                    value={phone1}
                    onChange={e => {
                      setPhone1(e.target.value);
                      handleSecondSectionFieldChange();
                    }}
                    placeholder="Include country code eg. +1XXXXXXXXXX"
                    required={isSecondSectionRequired}
                  />
                </Col>
                <Col md={6}>
                  <Label>Business Title</Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    value={businessTitle1}
                    onChange={e => {
                      setBusinessTitle1(e.target.value);
                      handleSecondSectionFieldChange();
                    }}
                    placeholder=""
                    required={isSecondSectionRequired}
                  />
                </Col>
                <Col md={6} className="pt-4">
                  <Label>Job Position</Label>
                  <Input
                    type="select"
                    id="JobPosition"
                    value={jobPosition1}
                    onChange={e => {
                      setJobPosition1(e.target.value);
                      handleSecondSectionFieldChange();
                    }}
                    required={isSecondSectionRequired}
                  >
<option>--- Select ---</option>
                    <option value="Director">Director</option>
                    <option value="GM">GM</option>
                    <option value="VP">VP</option>
                    <option value="CEO">CEO</option>
                    <option value="CFO">CFO</option>
                    <option value="General Counsel">General Counsel</option>
                    <option value="Other">Other</option>
                  </Input>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outlined"
                onClick={() => {
                  save2();
                }}
                color="primary"
              >
                Next
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}

        {addModal3 ? (
          <Modal isOpen toggle={addToggle3}>
            <ModalHeader toggle={addToggle3} style={{ backgroundColor: '#becdda8f' }}>
              Register new A2P Brand
            </ModalHeader>
            <ModalBody>
              <div>
                <b>
                  <h5>
                    <span style={{ color: 'red' }}>*</span> What type of brand do you need?
                  </h5>
                </b>
                <label>
                  <input
                    type="radio"
                    value="soleProprietor"
                    checked={subscriptionType === 'soleProprietor'}
                    onChange={handleSubscriptionTypeChange}
                    required
                  />
                  <b> &nbsp;&nbsp;Sole Proprietor</b> $4 one-time fee (for non Premium plus users)
                </label>
                <p>Recommended for customers sending fewer than 3,000 message segments per day to the US</p>
              </div>

              <div>
                <label>
                  <input
                    type="radio"
                    value="lowVolumeStandard"
                    checked={subscriptionType === 'lowVolumeStandard'}
                    onChange={handleSubscriptionTypeChange}
                    required
                  />
                  <b> &nbsp;&nbsp;Low-volume standard </b> $4 one-time fee (for non Premium plus users)
                </label>
                <p>
                  Recommended for customers sending fewer than 6,000 message segments per day to the US (2,000 message
                  segments per day to T-Mobile)
                </p>
              </div>

              <div>
                <label>
                  <input
                    type="radio"
                    value="standard"
                    checked={subscriptionType === 'standard'}
                    onChange={handleSubscriptionTypeChange}
                    required
                  />
                  <b> &nbsp;&nbsp;Standard</b> $44 one-time fee (for non Premium plus users)
                </label>
                <p>
                  Recommended for customers sending messages at scale. Your daily limit may fall between 6,000 and
                  600,000 message segments per day to the US (2,000 - 200,000 per day to T-Mobile).$4
                </p>
              </div>

              {subscriptionType === 'lowVolumeStandard' && (
                <div>
                  <hr />
                  <b>
                    <h6>
                      <span style={{ color: 'red' }}>*</span> Company type
                    </h6>
                  </b>
                  <p>Non-US nonprofits and governments should register as a private or public company.</p>
                  <label>
                    <input
                      type="radio"
                      value="private"
                      checked={companyTypeL === 'private'}
                      onChange={handleCompanyTypeChangeL}
                      required
                    />
                    <b> &nbsp;&nbsp;Private &nbsp;&nbsp;</b>
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="public"
                      checked={companyTypeL === 'public'}
                      onChange={handleCompanyTypeChangeL}
                      required
                    />
                    <b>&nbsp;&nbsp;Public &nbsp;&nbsp;</b>
                  </label>

                  <label>
                    <input
                      type="radio"
                      value="usNonProfit"
                      checked={companyTypeL === 'usNonProfit'}
                      onChange={handleCompanyTypeChangeL}
                      required
                    />
                    <b>&nbsp;&nbsp;US Non Profit &nbsp;&nbsp;</b>
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="usGovernment"
                      checked={companyTypeL === 'usGovernment'}
                      onChange={handleCompanyTypeChangeL}
                      required
                    />
                    <b>&nbsp;&nbsp;US Government &nbsp;&nbsp;</b>
                  </label>
                  {companyTypeL === 'public' && (
                    <div>
                      <hr />
                      <Label for="dropdown">
                        <h6>
                          <span style={{ color: 'red' }}>*</span>Stock Exchange
                        </h6>
                      </Label>
                      <Input
                        type="select"
                        name="dropdown"
                        id="dropdown"
                        value={stockExchangeL}
                        onChange={e => {
                          setstockExchangeL(e.target.value);
                        }}
                      >
                        <option>--- Select ---</option>
                        <option>AMEX : American Stock Exchange</option>
                        <option>AMX : Armenia Securities Exchange</option>
                        <option>ASX: Australian Securities Exchange</option>
                        <option>B3: Brazil Stock Exchange</option>
                        <option>BME : Bolsas y Mercados Espaioles</option>
                        <option>BSE : Bombay Stock Exchange</option>
                        <option>FRA: Frankfurt Stock Exchange</option>
                        <option>ICEX : Indian Commodity Exchange</option>
                        <option>JPX : Japan Exchange Group</option>
                        <option>JE: Jamaica Stock Exchange</option>
                        <option>KRX : Korea Exchange</option>
                        <option>LON : London Stock Exchange</option>
                        <option>NASDAQ</option>
                        <option>NSE : National Stock Exchange of India Ltd</option>
                        <option>NYSE : New York Stock Exchange</option>
                        <option>None</option>
                        <option>OMX: NASDAQ Stockholm</option>
                        <option>SEHK : Stock Exchange of Hong Kong</option>
                        <option>SGX : Singapore Exchange Limited</option>
                        <option>SSE : Shanghai Stock Exchange</option>
                        <option>STO : Stockholm Stock Exchange</option>
                        <option>SWX : Swiss Stock Exchange</option>
                        <option>SZSE : Shenzhen Stock Exchange</option>
                        <option>TSX: Toronto Stock Exchange</option>
                        <option>TWSE : Taiwan Stock Exchange</option>
                        <option>VSE : Vadodara Stock Exchange Limited</option>
                        <option>Other</option>
                      </Input>
                      <Label for="textBox" style={{ marginTop: '20px' }}>
                        <h6>
                          <span style={{ color: 'red' }}>*</span>Stock ticker
                        </h6>
                      </Label>
                      <Input
                        type="text"
                        name="textBox"
                        id="textBox"
                        placeholder="Enter text here"
                        value={textBoxValueL}
                        onChange={handleStockTickerChangeL}
                      />
                      {hasInteractedText && textBoxValueL === '' && (
                        <p style={{ color: 'red' }}>Company stock ticker symbol is required for public company types</p>
                      )}
                    </div>
                  )}
                  {companyTypeL === 'usNonProfit' && (
                    <div>
                      <hr />
                      <b>
                        <h6>
                          <span style={{ color: 'red' }}>*</span>Are you a 527 Political Organization
                        </h6>
                      </b>
                      <label>
                        <input
                          type="radio"
                          value="yes"
                          checked={newRadioOptionL === 'yes'}
                          onChange={() => handleNewRadioOptionChangeL('yes')}
                          required
                        />
                        <b> &nbsp;&nbsp;Yes &nbsp;&nbsp;</b>
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="no"
                          checked={newRadioOptionL === 'no'}
                          onChange={() => handleNewRadioOptionChangeL('no')}
                          required
                        />
                        <b> &nbsp;&nbsp;No &nbsp;&nbsp;</b>
                      </label>
                      {newRadioOptionL === 'yes' && (
                        <div>
                          <hr />
                          <Label for="campaignVerifyToken">
                            <h6>
                              <span style={{ color: 'red' }}>*</span> Campaign Verify Token
                            </h6>
                          </Label>
                          <Input
                            type="text"
                            name="campaignVerifyToken"
                            id="campaignVerifyToken"
                            placeholder="Paste your Token here"
                            value={campaignVerifyTokenL}
                            onChange={handleCampaignVerifyTokenL}
                            required
                          />
                          {HasInteractedToken && campaignVerifyTokenL === '' && (
                            <p style={{ color: 'red' }}>
                              Campaign Verify Token is required when you are a 527 Political Organization
                            </p>
                          )}
                          <p style={{ marginTop: '10px' }}>
                            All 527 Political Organizations (Federal, State, and Local) are required to provide a
                            Campaign Verify token to verify their identity and engage with voters via text messaging.
                            This token will qualify your organization to register using the Political campaign in the
                            campaign registration step.
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  <div style={{ marginLeft: '20px' }}>
                    <hr />
                    <Label check>
                      <Input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} required /><span style={{ color: 'red' }}>*</span>  I agree to
                      a one-time $4 A2P Brand registration fee(for non Premium plus users) and Term & Condition of our {' '}
                  <a href="/privacypolicy" target="_blank">Privacy Policy </a>{' & '}
                  <a href="/refundpolicy" target="_blank">Refund Policy.</a>

                    </Label>
                    {checkboxError && <p style={{ color: 'red' }}>{checkboxError}</p>}
                  </div>
                </div>
              )}

              {subscriptionType === 'standard' && (
                <div>
                  <hr />

                  <b>
                    <h6>
                      <span style={{ color: 'red' }}>*</span> Company Type
                    </h6>
                  </b>
                  <p>Non-US nonprofits and governments should register as a private or public company.</p>
                  <label>
                    <input
                      type="radio"
                      value="private"
                      checked={companyType === 'private'}
                      onChange={handleCompanyTypeChange}
                    />
                    <b> &nbsp;&nbsp;Private &nbsp;&nbsp;</b>
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="public"
                      checked={companyType === 'public'}
                      onChange={handleCompanyTypeChange}
                    />
                    <b> &nbsp;&nbsp;Public &nbsp;&nbsp;</b>
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="usNonProfit"
                      checked={companyType === 'usNonProfit'}
                      onChange={handleCompanyTypeChange}
                    />
                    <b> &nbsp;&nbsp;US Non Profit &nbsp;&nbsp;</b>
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="usGovernment"
                      checked={companyType === 'usGovernment'}
                      onChange={handleCompanyTypeChange}
                    />
                    <b> &nbsp;&nbsp;US Government &nbsp;&nbsp;</b>
                  </label>

                  {companyType === 'public' && (
                    <div>
                      <hr />
                      <Label for="dropdown">
                        <h6>
                          <span style={{ color: 'red' }}>*</span> Stock Exchange
                        </h6>
                      </Label>
                      <Input
                        type="select"
                        name="dropdown"
                        id="dropdown"
                        value={stockExchange}
                        onChange={e => {
                          setstockExchange(e.target.value);
                        }}
                        required
                      >
                        <option>--- Select ---</option>
                        <option>AMEX : American Stock Exchange</option>
                        <option>AMX : Armenia Securities Exchange</option>
                        <option>ASX: Australian Securities Exchange</option>
                        <option>B3: Brazil Stock Exchange</option>
                        <option>BME : Bolsas y Mercados Espaioles</option>
                        <option>BSE : Bombay Stock Exchange</option>
                        <option>FRA: Frankfurt Stock Exchange</option>
                        <option>ICEX : Indian Commodity Exchange</option>
                        <option>JPX : Japan Exchange Group</option>
                        <option>JE: Jamaica Stock Exchange</option>
                        <option>KRX : Korea Exchange</option>
                        <option>LON : London Stock Exchange</option>
                        <option>NASDAQ</option>
                        <option>NSE : National Stock Exchange of India Ltd</option>
                        <option>NYSE : New York Stock Exchange</option>
                        <option>None</option>
                        <option>OMX: NASDAQ Stockholm</option>
                        <option>SEHK : Stock Exchange of Hong Kong</option>
                        <option>SGX : Singapore Exchange Limited</option>
                        <option>SSE : Shanghai Stock Exchange</option>
                        <option>STO : Stockholm Stock Exchange</option>
                        <option>SWX : Swiss Stock Exchange</option>
                        <option>SZSE : Shenzhen Stock Exchange</option>
                        <option>TSX: Toronto Stock Exchange</option>
                        <option>TWSE : Taiwan Stock Exchange</option>
                        <option>VSE : Vadodara Stock Exchange Limited</option>
                        <option>Other</option>
                      </Input>
                      <Label for="textBox" style={{ marginTop: '20px' }}>
                        <h6>
                          <span style={{ color: 'red' }}>*</span> Stock Ticker
                        </h6>
                      </Label>
                      <Input
                        type="text"
                        name="textBox"
                        id="textBox"
                        placeholder="Enter text here"
                        value={textBoxValue}
                        onChange={handleStockTickerChange}
                        required
                      />
                      {hasInteractedText && textBoxValue === '' && (
                        <p style={{ color: 'red' }}>Company stock ticker symbol is required for public company types</p>
                      )}
                    </div>
                  )}
                  {companyType === 'usNonProfit' && (
                    <div>
                      <hr />
                      <b>
                        <h6>
                          <span style={{ color: 'red' }}>*</span> Are you a 527 Political Organization
                        </h6>
                      </b>
                      <label>
                        <input
                          type="radio"
                          value="yes"
                          checked={newRadioOption === 'yes'}
                          onChange={() => handleNewRadioOptionChange('yes')}
                        />
                        <b> &nbsp;&nbsp;Yes &nbsp;&nbsp;</b>
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="no"
                          checked={newRadioOption === 'no'}
                          onChange={() => handleNewRadioOptionChange('no')}
                        />
                        <b> &nbsp;&nbsp;No &nbsp;&nbsp;</b>
                      </label>
                      {newRadioOption === 'yes' && (
                        <div>
                          <hr />
                          <Label for="campaignVerifyToken">
                            <h6>
                              <span style={{ color: 'red' }}>*</span> Campaign Verify Token
                            </h6>
                          </Label>
                          <Input
                            type="text"
                            name="campaignVerifyToken"
                            id="campaignVerifyToken"
                            placeholder="Paste your Token here"
                            value={campaignVerifyToken}
                            onChange={handleCampaignVerifyToken}
                          />
                          {HasInteractedToken && campaignVerifyToken === '' && (
                            <p style={{ color: 'red' }}>
                              Campaign Verify Token is required when you are a 527 Political Organization
                            </p>
                          )}
                          <p style={{ marginTop: '10px' }}>
                            All 527 Political Organizations (Federal, State, and Local) are required to provide a
                            Campaign Verify token to verify their identity and engage with voters via text messaging.
                            This token will qualify your organization to register using the Political campaign in the
                            campaign registration step.
                          </p>
                        </div>
                      )}
                    </div>
                  )}

                  <div style={{ marginLeft: '20px' }}>
                    <hr />
                    <Label check>
                      <Input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} required /><span style={{ color: 'red' }}>*</span>  I agree to
                      a one-time $44 A2P Brand registration fee(for non Premium plus users) and Term & Condition of our {' '}
                  <a href="/privacypolicy" target="_blank">Privacy Policy </a>{' & '}
                  <a href="/refundpolicy" target="_blank">Refund Policy.</a>

                    </Label>
                    {checkboxError && <p style={{ color: 'red' }}>{checkboxError}</p>}
                  </div>
                </div>
              )}

              {subscriptionType === 'soleProprietor' && (
                <div>
                  <hr />
                  <Label for="brandName">
                    <h6>
                      <span style={{ color: 'red' }}>*</span> Brand Name
                    </h6>
                  </Label>
                  <Input
                    type="text"
                    id="brandName"
                    placeholder="Enter brand name"
                    value={brandName}
                    required
                    onChange={handleBrandNameChange}
                  />
                  {hasInteracted && brandName === '' && <p style={{ color: 'red' }}>Brand name is a required field</p>}
                  <p style={{ marginTop: '10px' }}>
                    If you are a sole proprietor business, please enter your business name (which is usually your first
                    name/last name). If you're a hobbyist, you can provide your first name/last name or a friendly name
                    for the brand name field.
                  </p>
                  <Label for="vertical" style={{ marginTop: '20px' }}>
                    <h6> Vertical</h6>
                  </Label>
                  <Input
                    type="select"
                    id="vertical"
                    value={vertical}
                    onChange={e => {
                      setVertical(e.target.value);
                    }}
                  >
                    <option>--- Select ---</option>
                    <option>Agriculture</option>
                    <option>Media and Communication</option>
                    <option>Construction, Materials, and Trade Services</option>
                    <option>Education</option>
                    <option>Energy and Utilities</option>
                    <option>Entertainment</option>
                    <option>Financial Services</option>
                    <option>Gambling and Lottery</option>
                    <option>Government Services and Agencies</option>
                    <option>Healthcare and Life Sciences</option>
                    <option>Hospitality and Travel</option>
                    <option>HR. Staffing or Recruitment</option>
                    <option>Insurance</option>
                    <option>Legal</option>
                    <option>Manufacturing</option>
                    <option>Non-profit Organization</option>
                    <option>Political</option>
                    <option>Postal and Delivery</option>
                    <option>Professional Services</option>
                    <option>Real Estate</option>
                    <option>Retail and Consumer Products</option>
                    <option>Information Technology Services</option>
                    <option>Transportation or Logistics</option>
                  </Input>
                  <b>
                    <h5 style={{ marginTop: '20px' }}>Submit mobile phone number for registration</h5>
                  </b>
                  <p style={{ marginTop: '10px' }}>
                    The owner of the phone number will receive a message to verify ownership of this number.
                  </p>
                  <Label for="mobilePhoneNumber" style={{ marginTop: '20px' }}>
                    <h6>
                      <span style={{ color: 'red' }}>*</span> Mobile Phone Number
                    </h6>
                  </Label>
                  <Input
                    type="tel"
                    id="mobilePhoneNumber"
                    placeholder="Include country code eg. +1XXXXXXXXXX"
                    value={mobilePhoneNumber}
                    onChange={handlePhoneNumberChange}
                    required
                  />
                  {phoneNumberError && <p style={{ color: 'red' }}>{phoneNumberError}</p>}
                  <p style={{ marginTop: '10px' }}>
                    Please provide a mobile number that you have access to in order to confirm OTP verification. Only
                    US/Canadian numbers will be accepted. You may not use a CPaaS phone number such as one obtained from
                    Twilio. You cannot use the same mobile number to register more than 3 Sole Proprietor brands. This
                    limit is managed at the TCR level.
                  </p>
                  <div style={{ marginLeft: '20px' }}>
                    <hr />
                    <Label check>
                      <Input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} required /><span style={{ color: 'red' }}>*</span>  I agree to
                      a one-time $4 A2P Brand registration fee(for non Premium plus users) and Term & Condition of our {' '}
                  <a href="/privacypolicy" target="_blank">Privacy Policy </a>{' & '}
                  <a href="/refundpolicy" target="_blank">Refund Policy.</a>

                    </Label>
                    {checkboxError && <p style={{ color: 'red' }}>{checkboxError}</p>}
                  </div>
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outlined"
                onClick={() => {
                  if (areAllFieldsFilled()) {
                  save3();
                  }
                  else
                  {
                    alert('Please fill in all the required fields.');
                  }
                }}
                color="primary"
              >
                Next
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}

        {addModal4 ? (
          <Modal isOpen toggle={addToggle4}>
            <ModalHeader toggle={addToggle4} style={{ backgroundColor: '#becdda8f' }}>
              Create new A2P Campaign
            </ModalHeader>
            <ModalBody>
           

                      <div>
                      <Label>
                    <span style={{ color: 'red' }}>*</span>Campaign use case
                  </Label>
                        <Input
                      type="select"
                      name="dropdown"
                      id="dropdown"
                      value={useCaseFee}
                      onChange={e => {
                        setuseCaseFee(e.target.value);
                      }}
                      required
                      >
                     <option>--- Select ---</option>
                     <option value="Low Volume Mixed">Low Volume Mixed ($3/month) Recommended</option>
  <option value="Sweepstake">Sweepstake ($15.00/month)</option>
  <option value="Emergency">Emergency ($8.00/month)</option>
  <option value="K-12 Education">K-12 Education ($15.00/month)</option>
  <option value="Proxy">Proxy ($15.00/month)</option>
  <option value="Social">Social ($15.00/month)</option>
  <option value="Polling and voting">Polling and voting ($15.00/month)</option>
  <option value="Public Service Announcement">Public Service Announcement ($15.00/month)</option>
  <option value="Security Alert">Security Alert ($15.00/month)</option>
  <option value="Agents and Franchises">Agents and Franchises ($40.00/month)</option>
  <option value="Delivery Notification">Delivery Notification ($15.00/month)</option>
  <option value="Fraud Alert Messaging">Fraud Alert Messaging ($15.00/month)</option>
  <option value="Higher Education">Higher Education ($15.00/month)</option>
  <option value="Marketing">Marketing ($15.00/month)</option>
  <option value="Mixed">Mixed ($15.00/month)</option>
  <option value="Two-Factor authentication (2FA)">Two-Factor authentication (2FA) ($15.00/month)</option>
  <option value="Account Notification">Account Notification ($15.00/month)</option>
  <option value="Customer Care">Customer Care ($15.00/month)</option>


                      
                      </Input></div>
              <div>
                <Label For="campaignDescription" ><h6><span style={{ color: 'red' }}>*</span>Campaign description</h6></Label>
                <textarea
                  type="text"
                  id="campaignDescription"
                  style={{
                    width: "700px", // Adjust the width as needed
                    height: "70px", // Adjust the height as needed
                    resize: "vertical",
                  }}
                  placeholder="Example: This campaign sends one-time passcodes to the end users when they try to log into our company’s website."
                  value={campaignDescription}
                  onChange={e => setCampaignDescription(e.target.value)}
                  ></textarea>
                  <p>Describe what you will be using this campaign for.</p>
              </div>
              <div>
                <Label for="sampleMessage1" style={{ marginTop: '20px' }}><h6><span style={{ color: 'red' }}>*</span>Sample message #1</h6></Label>
                <textarea
                  type="text"
                  id="sampleMessage1"
                  style={{
                    width: "700px", // Adjust the width as needed
                    height: "70px", // Adjust the height as needed
                    resize: "vertical",
                  }}
                  placeholder="Example: Your one-time passcode is 123456."
                  value={sampleMessage1}
                  onChange={e => setSampleMessage1(e.target.value)}
                  ></textarea>
                  <p>Provide an example of a message that you will be sending with this campaign. This message sample will be used by carriers to help identify your traffic.</p>
              </div>
              <div>
                <Label for="sampleMessage2" style={{ marginTop: '20px' }}><h6><span style={{ color: 'red' }}>*</span>Sample message #2</h6></Label>
                <textarea
                  type="text"
                  id="sampleMessage2"
                  style={{
                    width: "700px", // Adjust the width as needed
                    height: "70px", // Adjust the height as needed
                    resize: "vertical",
                  }}
                  placeholder="Example: Your one-time passcode is 123456."
                  value={sampleMessage2}
                  onChange={e => setSampleMessage2(e.target.value)}
                  ></textarea>
                  <p>Provide an example of a message that you will be sending with this campaign. This message sample will be used by carriers to help identify your traffic.</p>
              </div>
              <div>
              <Label for="messageContents" style={{ marginTop: '20px' }}><h6>Message contents</h6></Label>
                <FormGroup check style={{ marginLeft: '10px' }}>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={includeEmbeddedLinks}
                      onChange={() => setIncludeEmbeddedLinks(!includeEmbeddedLinks)}
                    />{' '}
                    Messages will include embedded links.
                  </Label>
                  
                </FormGroup>
                <FormGroup check style={{ marginLeft: '10px' }}>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={includePhoneNumbers}
                      onChange={() => setIncludePhoneNumbers(!includePhoneNumbers)}
                    />{' '}
                    Messages will include phone numbers.
                  </Label>
                </FormGroup>
              </div>
              <div>
                <Label for="consentToReceiveMessages" style={{ marginTop: '20px' }}><h6><span style={{ color: 'red' }}>*</span>
                  How do end-users consent to receive messages? (40-2048 characters)</h6>
                </Label>
                <textarea
                  type="text"
                  id="consentToReceiveMessages"
                  style={{
                    width: "700px", // Adjust the width as needed
                    height: "80px", // Adjust the height as needed
                    resize: "vertical",
                  }}
                  placeholder="Example: End users opt-in by visiting www.examplewebsite.com and adding their phone number. They then check a box agreeing to receive text messages from Example Brand. Opt-in occurs after end users create an account; see screenshot of opt-in (www.example.com/screenshot-of-the-form-field). Additionally, end users can also opt-in by texting START to (111) 222-3333 to opt in."
                  value={consentToReceiveMessages}
                  onChange={e => setConsentToReceiveMessages(e.target.value)}
                  ></textarea>
                  <p>This field should describe how end users opt-in to the campaign, therefore giving consent to the sender to receive their messages. If multiple opt-in methods can be used for the same campaign, they must all be listed.</p>
              </div>
              <div>
                <Label for="optInKeywords" style={{ marginTop: '20px' }}><h6>Opt-in Keywords (max 255 characters)</h6></Label>
                <textarea
                  type="text"
                  id="optInKeywords"
                  style={{
                    width: "700px", // Adjust the width as needed
                    height: "70px", // Adjust the height as needed
                    resize: "vertical",
                  }}
                  placeholder="Example: Subscribe, Start"
                  value={optInKeywords}
                  onChange={e => setOptInKeywords(e.target.value)}
                  ></textarea>
                  <p>If end users can text in a keyword (for example, “Subscribe”) to start receiving messages from your campaign, those keywords must be provided. If you do not support opt-in via text, please leave this blank.</p>
              </div>
              <div>
                <Label for="optInMessage" style={{ marginTop: '20px' }}><h6>Opt-in Message (20-320 characters)</h6></Label>
                <textarea
                  type="text"
                  id="optInMessage"
                  style={{
                    width: "700px", // Adjust the width as needed
                    height: "70px", // Adjust the height as needed
                    resize: "vertical",
                  }}
                  placeholder="Example: Acme Corporation: You are now opted-in. For help, reply HELP. To opt-out, reply STOP"
                  value={optInMessage}
                  onChange={e => setOptInMessage(e.target.value)}
                  ></textarea>
                  <p>If end users can text in a keyword to start receiving messages from your campaign, the auto-reply messages sent to the end users must be provided. The opt-in response should include the Brand name, confirmation of opt-in enrollment to a recurring message campaign, how to get help, and clear description of how to opt-out. If you do not support opt-in via text, please leave this blank.</p>
              </div>
              <div style={{ marginLeft: '20px' }}>
                    <hr />
                    <Label check>
                      <Input type="checkbox" checked={isCheckedCampaignUseCaseFee} onChange={handleCheckboxChangeCampaignUseCaseFee} required /><span style={{ color: 'red' }}>*</span>  I agree to
                      for the Campaign use case fee and Term & Condition of our {' '}
                  <a href="/privacypolicy" target="_blank">Privacy Policy </a>{' & '}
                  <a href="/refundpolicy" target="_blank">Refund Policy.</a>
                    </Label>
                    {checkboxErrorCampaignUseCaseFee && <p style={{ color: 'red' }}>{checkboxErrorCampaignUseCaseFee}</p>}
                  </div>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outlined"
                onClick={() => {
                  save4();
                }}
                color="primary"
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </>

    );
}
const mapStateTOProps = (state)=> ({
  

})
export default connect(mapStateTOProps, {})(BusinessProfile)
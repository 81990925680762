import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader, Input, Button } from 'reactstrap';

import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import moment from 'moment';
import { getNotification, downloadfile, countnotification } from '../../../Redux/Actions/Mask/Mask';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import Label from 'reactstrap/lib/Label';

const UserVoice = ({ notification, getNotification, downloadfile, countnotification, count }) => {
  useEffect(() => {
    getNotification(offset);
    countnotification();
  }, [getNotification]);
  const [offset, setoffset] = useState(0);
  const [activePage, setactivePage] = useState(0);
  let currentTimestamp = Date.now();

  let date = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }).format(currentTimestamp);
  const downloadpath = path => {
    // console.log("path is",path)
    window.open('https://mergecall.com/static/' + path);
  };
  const handlePageChange = pageNumber => {
    let off = pageNumber * 10 - 10;
    setactivePage(pageNumber);
    getNotification(off);
    setoffset(off);
  };
  if (notification === null) {
    return <Loader />;
  }
  // console.log({ notification });
  return (
    <Row className="justify-content-center">
      <Col md="10" sm="10">
        <Card>
          <CardBody className="p-0 ">
            <Table responsive bordered striped hover>
              <thead>
                <tr>
                  <th>Your Notification <br/><span style={{fontSize:"10px",fontWeight:"lighter"}}>Your Notification will be deleted after 3 days</span></th>
                </tr>
              </thead>
              <tbody>
                {notification.length > 0 &&
                  notification.reverse().filter((data) => !data.message).map((item, i) => (
                    <tr key={item._id}>
                      <td>
                        <Label> This file <span style={{fontWeight:"bold"}}>{item.name}</span> is generated from </Label> &nbsp;
                        <Link to={item.pathurl} style={{fontWeight:"bold"}} >{item.pagename}</Link> &nbsp;
                        <Label>
                          of size &nbsp;<span style={{fontWeight:"bold"}}>{item.size}</span>&nbsp;Kb on &nbsp;<span style={{fontWeight:"bold"}}> {moment(item.createdAt).format('LLL')} </span>.
                        </Label>{' '}
                        &nbsp;
                        <Label>
                          To download this file{' '}
                          <Button className="pl-1" color="link" style={{fontWeight:"bold"}} onClick={() => downloadpath(item.path)}>
                            Click here.
                          </Button>
                        </Label>
                      </td>
                      {/* <td>{item.name}</td>
                      <td>{item.size / 1000} KB</td>
                      <td>
                        {
                          <div onClick={() => downloadpath(item.path)} className="btn btn-sm btn-primary">
                            Download
                          </div>
                        }
                      </td>
                  {/* <td>{moment(item.createdAt).format("YYYY-MM-DDThh:mm").split("T").join(" ")}</td> */}

                      {/* <td>{moment(item.createdAt).format('LLL')}</td> */}
                    </tr>
                  ))}
                  {notification.length > 0 &&
                  notification.reverse().map((item, i) => (
                    <tr key={item._id}>
                      <td>
                    <Label> {item.message}</Label></td></tr>
                    ))}
              </tbody>
            </Table>
            <Row>
              <Col xs="12">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={count.length}
                  pageRangeDisplayed={3}
                  onChange={handlePageChange}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({
  notification: state.bulk.notification,
  count: state.Twilio.notificationcount
});
export default connect(
  mapStateToProps,
  { getNotification, downloadfile, countnotification }
)(UserVoice);

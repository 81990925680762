import React, { useContext, useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import ProductProvider from '../components/e-commerce/ProductProvider';
// import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils';
import PrivateRoute from "./PrivateRoute"
import PaymentRoute from "./PaymentRoute"
//Our
import CallActivity from "../view/pages/Home/Home"
import ChatActivity from "../view/pages/Chat/chat"
import UploadFile from "../view/pages/Mask/masktexting"
import MASSTEXTING from "../view/pages/Mask/MassTextingUI"
import Campaigns from '../view/pages/Mask/Campaigns'
import Campaign from '../view/pages/Mask/Campagin'
import MessageLogs from "../view/pages/User/messages"
import FaxLogs from "../view/pages/User/fax"
import VoiceLogs from "../view/pages/User/Voice"
import APIKey from "../view/pages/AccountSetting/Account"
import FormBuilder from "../view/pages/AccountSetting/webform"
import Callflow from "../view/pages/AccountSetting/callFlow"
import GetCompanies from "../view/pages/Company/company"
import GetCompany  from "../view/pages/Company/editCompany"
import MyNumber from "../view/pages/MyNumbers/mynumbers"
import EditNumber from  "../view/pages/MyNumbers/editnumber"
import BuyNumber from "../view/pages/Number/available"
import FaxActivity from "../view/pages/Fax/Fax"
import FundActivity from "../view/pages/Fund/Fund"
import CardActivity from "../view/pages/Stripe/Cards"
import billingaddress from '../view/pages/Stripe/billingaddress';
import Invoices from '../view/pages/Stripe/invoices';
import editCard from "../view/pages/Stripe/editcard";
import Payment from "../view/pages/Stripe/stripe"
import Conversation from "../view/pages/Conv/Conversation"
import Multilinedialer from "../view/pages/Multiline/multilineCall"
import NewMultiline from '../view/pages/Multiline/newmultilineCall'
import Prospects from "../view/pages/Prospects/prospects"
import GetProspect from "../view/pages/Prospects/getprospect"
import Pricing from "../components/pricing/Pricing"
import Landing from "../view/pages/Main/main"
import Inbox from "../view/pages/Conv/Inbox"
import Template from "../view/pages/Template/Templates"
import CreateTemplate from "../view/pages/Template/createTemplate"
import CreateCategory from "../view/pages/Template/createCategory"
import EditTemplate from "../view/pages/Template/Template"
import EditCategory from "../view/pages/Template/Category"
import GetMetrics from "../view/pages/Mask/Metrics"
import UploadData from "../view/pages/Mask/Uploads"
import CampaignChat from "../view/pages/Mask/Main"
import MessageScheduler from "../view/pages/Mask/Scheduler"
import Notification from "../view/pages/Notification/notification"
import LeadStage from "../view/pages/Mask/LeadStage"
import DncList from "../view/pages/AccountSetting/dnclist"
import ContactUs from '../view/pages/Main/contactUs'
import PrivacyPolicy from './PrivacyPolicy';
import RefundPolicy from './RefundPolicy';
import Optout from "../view/pages/optout/optout"
import editOptout from "../view/pages/optout/editoptout"
import Config from "../view/pages/AccountSetting/config"
import AddProspect from "../view/pages/Prospects/addProspect"
import Myaccount from '../view/Myaccount/Myaccount';
import {Device} from '@twilio/voice-sdk';
import  {gettoken} from "../Redux/Actions/Twilio"
import CallMonitor from "../view/pages/User/callmonitor"
import CAMPCALLLOGS from "../view/pages/User/CALLCAMPLOGS"
import Session from "../view/pages/User/session"
import UserChats from "../view/pages/Chat/UserChat"
import DialPad from "../view/pages/Home/newdialer"
import MainCamp from "../view/pages/Mask/CampaignItem"
import {connect} from 'react-redux'
import CreatecallFlow from '../view/pages/AccountSetting/CreatecallFlow';
import EditcallFlow from '../view/pages/AccountSetting/EditcallFlow';
// import NewCampagin from '../view/pages/Mask/NewCampagin';
import DNCLISTDATA from "../view/pages/User/Dnclist"
import PublicCard from '../view/pages/Stripe/PublicCard';

import {isLogin} from '../util/util'
import {checksub} from "../Redux/Actions/Payment"

import Hub from '../view/pages/ConfidenceHub/Hub';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = ({ location, Token,checksub,subs, gettoken,isPaid }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName('kanban');

  useEffect(() => {
    checksub();
  }, [])
  useEffect(() => {
    
    localStorage.setItem("isFluid", true)
    localStorage.setItem("isVertical", true)
    if(Token === "" && localStorage.getItem("token") != null)
    {
       gettoken()

    }
    else if(localStorage.getItem("token") != null && Token !== ""){
       fetchdata()
    }
    async function fetchdata() {
      // Device.setup(Token);
      // Device.on('ready', function(device) {});

      // Device.on('error', function(error) {
      //   console.log("lll",error.message);
      //   // window.location.reload()
      // });
    }
  }, [Token,gettoken]);
  useEffect(() => {
    DashboardRoutes.preload();
 
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [body, setbody] = useState("inherit")
  useEffect(() => {
    if(isVertical&&window.location.pathname != "/"){
      setbody("inherit")
     }else{
       setbody("contents")
     }
  }, [window.location.pathname]) 

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {isVertical && window.location.pathname != "/" && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
      <ProductProvider>
        <div className="content" style={{display:body}}>
          {window.location.pathname != "/" && <NavbarTop />}
          <Switch>
          <PrivateRoute  path="/callmonitor"
                exact
                component={CallMonitor} />
          <PrivateRoute  path="/dialer"
                exact
                component={DialPad} />
          <PrivateRoute  path="/multilinedialer/logs/:id"
                exact
                component={CAMPCALLLOGS} />
          <PrivateRoute  path="/template"
                exact
                component={MainCamp} />
          <PrivateRoute  path="/multilinedialer/session/:id"
                exact
                component={Session} />
          
          <PrivateRoute  path="/pages/prospects/addprospect"
                exact
                component={AddProspect} />
          <PrivateRoute  path="/optout/:id"
                exact
                component={editOptout} />
           <PrivateRoute  path="/optout"
              exact
              component={Optout} />
            <Route path="/myaccount" exact component={Myaccount} />

          <PrivateRoute path="/config" exact component={Config} />
          {/* <PrivateRoute path="/dnclist" exact component={DncList} /> */}
          <PrivateRoute path="/uploads/:id" exact component={UploadData} />
          <PrivateRoute path="/notification" exact component={Notification} />
          <PrivateRoute path="/leadstage" exact component={LeadStage} />
          <PrivateRoute path="/scheduler/:id" exact component={MessageScheduler} />
          {/* <PrivateRoute path="/template/createTemplate" exact component={CreateTemplate} />
         <PrivateRoute path="/template/createCategory" exact component={CreateCategory} />
         <PrivateRoute path="/template/template/:id" exact component={EditTemplate} />
         <PrivateRoute path="/template/category/:id" exact component={EditCategory} /> */}
         <PrivateRoute path="/campaignchat/:campid" exact component={CampaignChat}></PrivateRoute>
          <Route path="/" exact component={Landing}/>
          {/* <PrivateRoute path="/template" exact component={Template} /> */}
          <PrivateRoute path="/metric/:id" exact component={GetMetrics} />
      <PrivateRoute path="/home" exact component={Dashboard} />
      
      <PrivateRoute path="/home/upgradeplans" exact component={Pricing}></PrivateRoute>
    <PrivateRoute path="/inbox" exact component={UserChats}></PrivateRoute>
    <PrivateRoute path="/inbox/conversation/:id" exact component={Conversation}></PrivateRoute>
    <PrivateRoute path="/home/card/billingaddress" exact component={billingaddress}/>
    <PrivateRoute path="/home/card/invoices" exact component={Invoices}/>
    <PrivateRoute path="/call/multilinedialer" exact component={NewMultiline}/>
    <PrivateRoute path="/campaigns/multilinedialer/:id" exact component={NewMultiline}/>
    
    <PrivateRoute path="/pages/prospects" exact component={Prospects}/>
    <PrivateRoute
              path="/pages/prospects/prospect/:id"
              exact
              component={GetProspect}
            />
    <Route
              path="/editcard/:id"
              exact
              component={editCard}
            />
            {isLogin()&& isPaid?(<Route path="/home/card" exact component={CardActivity}/>):(<Route path="/home/card" exact component={PublicCard}/>  )}
          
    
    <Route
            path="/home/fund" exact component={FundActivity}
            ></Route>
            <Route path="/privacypolicy" component={PrivacyPolicy} />
            <Route path="/refundpolicy" component={RefundPolicy} />
     <PaymentRoute path="/payment" exact component={Pricing}/>
    <PrivateRoute path="/pages/call" exact component={CallActivity} />
    <PrivateRoute path="/call" exact component={CallActivity} />
    <PrivateRoute path="/pages/chat" exact component={ChatActivity} />
    <PrivateRoute path="/pages/fax" exact component={FaxActivity} />
    <PrivateRoute path="/pages/campaigns/uploadcampaign" exact component={UploadFile} />
    <PrivateRoute path="/addprospects/:id" exact component={UploadFile} />
    <PrivateRoute path="/pages/masstexting" exact component={MASSTEXTING} />
    <PrivateRoute path="/pages/campaigns" exact component={Campaigns} />
    <PrivateRoute path="/pages/campaigns/:id" exact component={Campaign} />
    
    
<     Route path={`/logs/message`} exact component={MessageLogs} />
      <PrivateRoute path={`/logs/fax`} exact component={FaxLogs} />
      <PrivateRoute path={`/logs/voice`} exact component={VoiceLogs} />
    <Route path="/account/api" exact component={APIKey} />
    <PrivateRoute path="/form" exact component={FormBuilder} />
    <PrivateRoute path="/callflow" exact component={Callflow} />
    <PrivateRoute path="/callflow/create" exact component={CreatecallFlow} />
    <PrivateRoute path="/callflow/edit/:id/:name/:flow" exact component={EditcallFlow} />

    <PrivateRoute path="/getcompany" component={GetCompanies} />

    <PrivateRoute path="/editcontact/:id" component={GetCompany} />
    <Route path="/contactus" component={ContactUs} />

    <PrivateRoute exact path="/dnclist" component={DNCLISTDATA}></PrivateRoute>
    {/*Numbers*/}
    <PrivateRoute path="/me/numbers" component={MyNumber} />
    <PrivateRoute path="/me/edit/:id" component={EditNumber} />
    <PrivateRoute path="/buynumber" component={BuyNumber} />
    <PrivateRoute path="/hub" component={Hub} />
            {/* <Route path="/dashboard-alt" exact component={DashboardAlt} /> */}
            <Redirect to="/errors/404" />
          {/* <DashboardRoutes /> */}
           
          </Switch>
          {!isKanban && <Footer />}
        </div>
        {/* <SidePanelModal path={location.pathname} /> */}
      </ProductProvider>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };
const mapStateToProps = (state) => ({
  Token: state.Twilio.token,
  isPaid : state.auth.isPaid,
  subs:state.auth.subs
  
  });
export default connect(mapStateToProps, {gettoken,checksub})(DashboardLayout);

import React, { useEffect,useState } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader, Button,Label } from 'reactstrap';
import PropTypes from 'prop-types';


import Loader from "../../../components/common/Loader"
import {connect} from 'react-redux'
import  {getinvoice} from "../../../Redux/Actions/Payment"
import moment from 'moment';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';


const CardDetaild = ({getinvoice, inovices}) => {
    useEffect(()=>{
        
        (async () => {
            try{
                 
                
                const res = await Axios({
                    method: 'GET',
                    url: process.env.REACT_APP_PORT+`/api/payment/getpayment`,
                    headers: {
                    "x-auth-token": localStorage.getItem("token") },
                   
                })
                
               console.log(res.data,"res.data")
            //    if(res.data.data==="price_1JX3J5GVMLEvSdQH3TMObFj7"){
            //    setplan("Premium plan")
            //   }
              if(res.data.data==="price_1Jjj2aGVMLEvSdQH0sXc4MRe"){
                setplan("Premium plan")
               }
            }
            catch(e)
            {   
                toast.error(e.response.data.error);
                
            }
        })()

        
    }, [getinvoice])
    useEffect(() => {
        getinvoice()
    }, [])
   const [plan, setplan] = useState("")
   if(inovices === null)
   {
       return <Loader></Loader>
   } 
  console.log({inovices})
  return (
      
            <Card style={{width:"100%"}}>
                {/* <CardHeader>
                   <Row>
                       <Col xs="12">
                         <CardTitle>Invoices</CardTitle>
                        </Col>
                        
                   </Row>
                </CardHeader> */}
                <CardBody>
                    <Label>Invoices</Label>
                    <Table responsive bordered striped hover >
                        <thead >
                            <tr>
                            <th>#</th>
                           
                            <th>PDF</th>
                            <th>Plan</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Billing Period</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inovices.length > 0 && inovices.map((item, index)=> (
                                <tr key={item._id}>
                                    <th scope="row">{index +1}</th>
                                   
                                    {item.status==="succeeded"?<td><Tooltipbutton title="Click to view invoice"><a href={item.invoice_pdf }>View</a></Tooltipbutton></td>:<td><Tooltipbutton title="Click to download invoice"><a href={item.invoice_pdf }>Download</a></Tooltipbutton></td>}
                                    {/* <td><a href={item.invoice_pdf }>Download</a></td> */}
                                    <td style={{ whiteSpace: 'nowrap' }}>{plan}</td>
                                    <td>$ {item.amount}</td>
                                   {item.amount===0?<td>Trial </td>:<td>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</td>} 
                                    <td style={{ whiteSpace: 'nowrap' }}>{ item.billing_period_start && moment.unix(item.billing_period_start).format('ll')}&nbsp;&nbsp;-&nbsp;&nbsp;{item.billing_period_end && moment.unix(item.billing_period_end).format('ll')}</td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </Table>
 
                </CardBody>
            </Card>
          
    );
}
const mapStateToProps = (state) => ({
    inovices: state.payment.invoices

 });
CardDetaild.propTypes = {
    getinvoice: PropTypes.func.isRequired,
  
  };
export default connect(mapStateToProps, {getinvoice,})(CardDetaild)
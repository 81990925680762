import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Toast,
  ToastBody,
  ToastHeader,
  Form,
  Input,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Loader from '../../../components/common/Loader';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux'
import { getMessages, countmessages, messageSearch, countfiltermessages } from '../../../Redux/Actions/USER/user';
import Label from 'reactstrap/lib/Label';
import Tooltipbutton from '../../../components/Tooltip/Tooltip';
var moment = require('moment-timezone');
const UserMessage = ({
  getMessages,
  messages,
  countmessages,
  count,
  searchData,
  messageSearch,
  search,
  inboundcheck,
  outboundcheck,
  countfiltermessages
}) => {
  // const [search, setSearch] = useState('');
  const [offset, setoffset] = useState(0);
  const [modal, setModal] = useState(false);
  const [body, setBody] = useState(null);
  const [activePage, setactivePage] = useState(0);
  const [filteractive, setfilteactive] = useState(false);
  const toggle = () => setModal(!modal);
  const currtz=useSelector(state=>state.Twilio.timezone)
  useEffect(() => {
    getMessages(offset);
    countmessages();
  }, []);
  useEffect(() => {
    if (search) {
      setoffset(0);
      setactivePage(0);
      messageSearch(search, outboundcheck, inboundcheck, 0);
      countfiltermessages(outboundcheck, inboundcheck, search);
    } else if (inboundcheck || outboundcheck) {
      setoffset(0);
      setactivePage(0);
      getMessages(offset, inboundcheck, outboundcheck);
      countfiltermessages(outboundcheck, inboundcheck);
    } else {
      getMessages(offset, inboundcheck, outboundcheck);
      countmessages();
    }
  }, [search, outboundcheck, inboundcheck]);
  useEffect(() => {
    setactivePage(0);
  }, [outboundcheck, inboundcheck]);
  // const next = () => {
  //   const data = offset + 10;

  //   setoffset(data);
  //   if (search) {
  //     messageSearch(search,outboundcheck,inboundcheck, data);
  //   } else {
  //     getMessages(data);
  //   }
  // };
  // const prev = () => {
  //   const data = offset - 10;

  //   setoffset(data);
  //   if (search) {
  //     messageSearch(search,outboundcheck,inboundcheck, data);
  //   } else {
  //     getMessages(data);
  //   }
  // };
  const handlePageChange = pageNumber => {
    let off = pageNumber * 10 - 10;
    setactivePage(pageNumber);
    console.log({ pageNumber });
    console.log({ filteractive });
    console.log({ search });
    if (search) {
      messageSearch(search, outboundcheck, inboundcheck, off);
    } else getMessages(off, inboundcheck, outboundcheck);
  };
  console.log({ messages });
  if (messages === null || messages.length === 0) {
    return (
      <Row className="justify-content-center">
        <Col sm="12">
          <Card>
            <CardBody>
              <Label style={{ display: 'flex', justifyContent: 'center' }}>No Log Present</Label>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <Row className="justify-content-center">
      <Col xs="12">
        <Card>
          <CardBody>
            {/* <input type="text" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} /> */}

            <Table responsive bordered striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Content</th>
                  <th>Status</th>
                  <th>Price</th>
                  <th>Direction</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {messages.length &&
                  messages.map((item, index) => (
                    <tr key={item._id}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.from}</td>
                      <td>{item.to}</td>
                      <td
                        onClick={() => {
                          setBody(item.body);
                          setModal(true);
                        }}
                      >
                        <Tooltipbutton title="Click here to see content">
                          <div className="btn  btn-primary btn-sm">Show</div>
                        </Tooltipbutton>
                      </td>
                      <td>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</td>
                      <td>{item.charges != undefined ? `$${item.charges.toFixed(3)}` : 0}</td>
                      <td>
                        {item.direction == 'outbound-api' ? (
                          <div className="btn btn-danger btn-sm ">Outbound</div>
                        ) : (
                          <div className="btn btn-success btn-sm ">{item.direction}</div>
                        )}
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                      {moment(item.createdAt).tz(currtz).format('LLL')}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Row>
              <Col xs="12">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={count}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />

                {/* {offset != 0 ? <Button onClick={prev}>Prev</Button> : null}
                {offset + messages.length < count ? <Button onClick={next}>Next</Button> : null} */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Message Body</ModalHeader>
        <ModalBody>{body}</ModalBody>
      </Modal>
    </Row>
  );
};
const mapStateToProps = state => ({
  messages: state.Twilio.messages,
  count: state.Twilio.messagecount,
  searchData: state.Twilio.searchData
});
UserMessage.propTypes = {
  getMessages: PropTypes.func.isRequired
};
export default connect(
  mapStateToProps,
  { getMessages, countmessages, messageSearch, countfiltermessages }
)(UserMessage);

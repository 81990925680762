import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Label,
  Input as IMP,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Device } from '@twilio/voice-sdk';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { savenotes } from '../../Redux/Actions/USER/user';
import { gettoken } from '../../Redux/Actions/Twilio';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import NewDialer from '../../view/pages/Home/newdialer';
import UserForm from '../../view/pages/Home/Form';
import AddProspect1 from '../../view/pages/Prospects/addProspect1';
import Popup from 'reactjs-popup';
import { initializeDevice } from './twilioDevice';
const axios = require('axios');
const AlertDialog = ({ dialog, setDialogs, setpopDialogs, savenotes, Token, gettoken }) => {
  console.log('inside dial modal top');
  const [open, setOpen] = React.useState(false);
  const [openI, setOpenI] = React.useState(false);
  const [notedial, setnotedial] = React.useState(false);
  const [connection, setConnection] = React.useState('');
  const [note, setnotes] = React.useState('');
  const [details, setdetails] = React.useState(null);
  const [cmpname, setcmpname] = React.useState(null);
  const [device, setDevice] = React.useState();
  const [isOpenDial, setclosedialer] = React.useState(false);
  const [dil, setdil] = React.useState(true);
  const [allowdigit, setallowdigit] = React.useState(false);
  const [callconnect, setcallconnect] = React.useState(false);
  const [notetoggle, setnotetoggle] = React.useState(false);
  const [mic, setmicrophone] = React.useState(false);
  const [formshow, setshowform] = React.useState(false);
  const [showprospet, setshowprospet] = React.useState(false);
  const [timer, setTimer] = React.useState(false);
  const [num, setnum] = React.useState('');
  const [seconds, setSeconds] = React.useState(0);
  const [handle, setHandle] = React.useState(null);
  const history = useHistory();
  const handleClose = () => {
    connection.reject();
    setOpen(false);
    setpopDialogs(false);
    setnotedial(true);
  };
  const handelOpen = () => {
    setshowform(false);
    setclosedialer(true);
  };
  const formtoogleToggle = () => {
    setshowform(!formshow);
  };
  const disconnect = () => {
    connection.disconnect();
    console.log('DialModel disconnect>> connection >>', connection);
    setallowdigit(false);
    setdil(true);
    setOpenI(false);
    setclosedialer(false);
    setshowprospet(false);
    setSeconds(0);
    if (handle) {
      clearInterval(handle);
    }
  };
  const closedialer = () => {
    setclosedialer(!isOpenDial);
  };
  const changestatus = () => {
    setcallconnect(false);
  };
  const NoteToggle = () => {
    setnotetoggle(!notetoggle);
  };
  console.log('inside dial modal');
  const handleAccept = () => {
    console.log('Before push device', device);
    // history.push({ pathname: '/call', state: { inbound: true, detailss: details,cmpnamee:cmpname} });

    connection.accept();
    setOpen(false);
    setOpenI(true);
    setpopDialogs(false);
    setdetails(details);
    setcmpname(cmpname);
    setdil(false);
    setclosedialer(true);
    setTimer(true);
  };
  const notedialog = () => {
    setnotedial(false);
  };
  const handleEsc = () => {
    // connection.ignore();
    setOpen(false);
    setpopDialogs(true);
  };
  const handleIgnore = () => {
    //  connection.ignore();
    setOpen(false);
    setpopDialogs(true);
  };
  const stateChanger = title => {
    setclosedialer(title);
  };
  const stateChanger1 = title => {
    setshowprospet(title);
  };
  useEffect(() => {
    if (dialog === true) {
      setOpen(true);
      setDialogs(false);
    }
  }, [dialog]);
  useEffect(() => {
    return () => {
      if (handle) {
        clearInterval(handle);
      }
    };
  }, [handle]);
  useEffect(() => {
    if (Token === '') {
      gettoken();
    } else {
      fetchdata();
    }
    if (timer) {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
      setHandle(interval);
    }
    async function fetchdata() {
      // Device.setup(Token);

      // const device1 = new Device(Token);
      const device1 = await initializeDevice(Token);

      // device.on('connect', function(connection) {
      //   console.log('connect working in modal');
      // });
      console.log('device1', device1);
      try {
        device1.register();
      } catch (errorReg) {
        console.log('Handle Register', errorReg);
      }
      device1.addListener('registered', device => {
        console.log('The device is ready to receive incoming calls.');
      });
      device1.on('incoming', function(connection) {
        console.log('incoming hit', connection);
        setConnection(connection);
        setOpen(true);
        console.log(connection.parameters.From);
        let url = process.env.REACT_APP_PORT + `/api/me/getdetails`;
        var data1 = connection.parameters.From;
        setnum(data1);
        var config = {
          method: 'post',
          url: url,
          headers: {
            'x-auth-token': localStorage.getItem('token')
          },
          data: { option: connection.parameters.From }
        };

        axios(config)
          .then(function(response) {
            console.log(JSON.stringify(response.data.data));
            setdetails(response.data.data[0]);
            setcmpname(response.data.data[1]);
          })
          .catch(function(error) {
            console.log(error);
          });

        // setConnection(connection);
        // setOpen(true);
        connection.on('disconnect', function() {
          setpopDialogs(false);
          setOpen(false);

          setTimer(false);
          setdil(true);
          setmicrophone(false);
          setdetails(null);
          setshowprospet(false);
          setOpenI(false);
          // callmonitor()
          setclosedialer(false);
          if (handle) {
            clearInterval(handle);
          }
          setSeconds(0);
        });
        connection.on('reject', function(conn) {
          setpopDialogs(false);
          setOpen(false);
        });
        connection.on('accept', function(conn) {
          setpopDialogs(true);
          connection.accept();
        });
        // setpopDialogs(true)
        // connection.accept();
        // do awesome ui stuff here
        // $('#call-status').text("you're on a call!");
      });

      device1.on('destroyed', function(connection) {
        console.log('DialModel device >> destroyed');
        setpopDialogs(false);
        setOpen(false);
      });
      setDevice(device1);
    }
  }, [Token, gettoken]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleEsc}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card>
          <CardHeader>
            <CardTitle>
              <DialogTitle id="alert-dialog-title">
                <div className="ml-6">
                  <i class="fas fa-user-circle" style={{ color: '#b4d9e5', fontSize: '100px' }} />
                </div>
                <p style={{ color: '#b4d9e5', marginLeft: '21px', marginTop: '10px' }}>
                  {' '}
                  From &nbsp; &nbsp; &nbsp;{connection != '' ? connection.parameters.From : '+17542002251'}
                </p>
                <p className="ml-6" style={{ color: '#b4d9e5' }}>
                  Incoming call
                </p>
              </DialogTitle>
              {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
           
          </DialogContentText>
        </DialogContent> */}
            </CardTitle>
          </CardHeader>
          <DialogActions>
            <CardBody>
              <Row xs={12}>
                <Col md={6}>
                  <Button onClick={handleAccept} style={{ borderRadius: '30px' }} variant="contained" color="primary">
                    {/* <img src="/images/icons/answer.png" /> */}
                    <CallIcon />
                    &nbsp;Accept
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    onClick={handleClose}
                    style={{ borderRadius: '30px', whiteSpace: 'nowrap' }}
                    variant="contained"
                    color="secondary"
                  >
                    {/* <img src="/images/icons/reject.png" /> */}
                    <i class="fas fa-phone-slash" />
                    &nbsp; Reject
                  </Button>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={12}>
                  <center>
                    <Button
                      onClick={handleIgnore}
                      style={{ borderRadius: '30px', whiteSpace: 'nowrap' }}
                      variant="contained"
                      color="red"
                    >
                      {/* <img src="/images/icons/ignore.png"alt="Ignore"/> */}
                      <CallEndIcon />
                      &nbsp; &nbsp; Ignore
                    </Button>
                  </center>
                </Col>
              </Row>
            </CardBody>
          </DialogActions>
          <center>
            {details != null && details != 'Not Found' && (
              <p>
                <strong>Prospect Info:</strong>
                <br />
                <strong>Name:</strong> {details != null && details.hasOwnProperty('first_name') && details.first_name}{' '}
                {details != null && details.hasOwnProperty('last_name') && details.last_name}
                <br />
                <strong>Campaign Name:</strong> {cmpname != null && cmpname} <br />
                <strong>Address: </strong> {details != null && details.hasOwnProperty('address') && details.address}{' '}
                {details != null && details.hasOwnProperty('street') && details.street}{' '}
                {details != null && details.hasOwnProperty('city') && details.city}{' '}
                {details != null && details.hasOwnProperty('state') && details.state}
                <br />
                <strong>Email:</strong> {details != null && details.hasOwnProperty('email') && details.email}
              </p>
            )}
          </center>
        </Card>
      </Dialog>

      <Dialog
        open={notedial}
        onClose={notedialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className="pl-1">
          <DialogActions>
            <CardBody className="p-0">
              <Row>
                <Col xs="12">
                  <DialogTitle className="p-0" id="alert-dialog-title">
                    Note{' '}
                  </DialogTitle>
                  <DialogContent className="p-0 mt-1">
                    <DialogContentText id="alert-dialog-description">
                      From {connection != '' ? connection.parameters.From : ''}
                    </DialogContentText>
                  </DialogContent>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <IMP type="textarea" onChange={e => setnotes(e.target.value)} placeholder="add note" />
                </Col>
                <Col md="4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      savenotes(note, connection.parameters.From, connection.parameters.CallSid);
                      setnotedial(false);
                    }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </DialogActions>
        </Card>
      </Dialog>
      <Popup modal open={isOpenDial} closeOnDocumentClick={false}>
        {close => (
          <>
            <Card>
              <CardHeader>
                {dil ? (
                  <span class="pull-right clickable close-icon" data-effect="fadeOut">
                    <i onClick={closedialer} class="fa fa-times" />
                  </span>
                ) : null}
              </CardHeader>
              <CardBody>
                {console.log(formshow, dil, isOpenDial)}
                {dil && isOpenDial ? (
                  <UserForm toogle={closedialer} show={true} changeStatus={changestatus} />
                ) : (
                  <>
                    <center className="pt-2">
                      <NewDialer
                        disconnect={disconnect}
                        savenotes={NoteToggle}
                        setmicrophone={setmicrophone}
                        mic={mic}
                        connection={connection}
                      />
                      <center className="pt-2">
                        <Button
                          onClick={() => {
                            setshowform(true);
                            setclosedialer(false);
                          }}
                          size="small"
                          color="primary"
                          variant="contained"
                        >
                          Form
                        </Button>
                        {details != null && details == 'Not Found' && (
                          <Button
                            onClick={() => {
                              setshowprospet(true);
                              setclosedialer(false);
                            }}
                            size="small"
                            color="primary"
                            variant="contained"
                            className="mx-1"
                          >
                            Add Prospect
                          </Button>
                        )}
                      </center>
                      {console.log('details', details)}
                      {details != null && details != 'Not Found' && (
                        <p>
                          <strong>Prospect Info:</strong>
                          <br />
                          <strong>Name:</strong>{' '}
                          {details != null && details.hasOwnProperty('first_name') && details.first_name}{' '}
                          {details != null && details.hasOwnProperty('last_name') && details.last_name}
                          <br />
                          <strong>Campaign Name:</strong> {cmpname != null && cmpname} <br />
                          <strong>Address: </strong>{' '}
                          {details != null && details.hasOwnProperty('address') && details.address}{' '}
                          {details != null && details.hasOwnProperty('street') && details.street}{' '}
                          {details != null && details.hasOwnProperty('city') && details.city}{' '}
                          {details != null && details.hasOwnProperty('state') && details.state}
                          <br />
                          <strong>Email:</strong> {details != null && details.hasOwnProperty('email') && details.email}
                        </p>
                      )}
                    </center>
                  </>
                )}
              </CardBody>
              {/* {num ? <CardFooter>To :{num}</CardFooter> : null}
                {errorexist && (
                  <Button size="sm" style={{ backgroundColor: '#F32013', color: '#FFFFFF' }}>
                    <FontAwesomeIcon icon="exclamation" />
                    Network issue
                  </Button>
                )} */}
            </Card>
          </>
        )}
      </Popup>
      {openI ? (
        <Button
          style={{
            backgroundColor: '#0bf957',
            position: 'fixed',
            top: '12vh',
            right: '4vw',
            zIndex: 1,
            borderRadius: '48%'
          }}
          onClick={handelOpen}
        >
          <img src="/images/icons/call.png" />
        </Button>
      ) : null}
      {formshow ? (
        <Modal size="lg" backdrop="static" isOpen={formshow} toggle={formtoogleToggle} style={{ width: '30%' }}>
          <ModalHeader toggle={formtoogleToggle}>
            <Button
              onClick={() => {
                setshowform(false);
                setclosedialer(true);
              }}
              size="small"
              color="primary"
              variant="contained"
            >
              {' '}
              <i className="fas fa-long-arrow-alt-left my-2" />
              &nbsp;&nbsp; Back to Dialer
            </Button>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#f9fafd', height: '600px', overflow: 'auto' }}>
            <UserForm changeStatus={changestatus} to={num} />
          </ModalBody>
        </Modal>
      ) : null}
      {details != null && details == 'Not Found' && showprospet && (
        <div style={{ position: 'fixed', top: '20vh', right: '25vw', zIndex: 1 }}>
          <AddProspect1 stateChanger={stateChanger} stateChanger1={stateChanger1} />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = state => ({
  Token: state.Twilio.token
});
export default connect(
  mapStateToProps,
  { savenotes, gettoken }
)(AlertDialog);

import React from 'react';

import { Card, Grid, CardContent, makeStyles,withStyles, List, ListItem, ListItemText } from '@material-ui/core';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: "#edf2f9",
//     // color: theme.palette.common.white,
//   fontSize: 16,
//   fontWeight:"bold"
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);


// const useStyles = makeStyles({
// table: {
//   minWidth: 650,
// },
// });
const styles = makeStyles({
  bb: {
    backgroundColor: '#FF0000'
  },
  back: {
    color: 'black',
    background: 'white',
    // background:"white",
    
    borderRadius: '12px',
    height: '500px',
    overflowY: 'scroll',
    margin:"0 5px 0 0"
  },
  borderlist: {
    border: '0px solid black',
    marginTop: '5%',
    // fontStyle: 'italic',
    fontVariant: 'normal',
    borderbottom: "5px solid #fff",
    boxShadow:" 0 5px 0 #3f51b5",
    
    

  },
  insidelist: {
    display: 'block'
  },
  root: {
    minWidth: 275,
  },
});


const Contact = ({ data, getmessage }) => {
  const classes =styles();
  const getmessages = item => {
    getmessage(item);
  };
// return(<Card variant="outlined" >
// <TableContainer component={Paper}>
// <Table className={classes.table} aria-label="simple table">
//   <TableBody>
//     {data.length>0 && data.map(item=>(
//       <StyledTableRow key={item._id} >
//         <StyledTableCell>{item.sender}<br/>        {item.messages.length > 0 ? item.messages[item.messages.length - 1].body : null}
// </StyledTableCell>
//       </StyledTableRow>
//     ))}
//   </TableBody>
// </Table>
// </TableContainer>
// </Card>)
  return (
    <Card className={classes.back} variant="outlined">
      <CardContent>
        <Grid container justify="center">
          {data.length > 0 &&
            data.map(item => (
              <Grid item xs={12} key={item._id}>
                <List key={item._id} className={classes.borderlist}>
                  <ListItem onClick={() => getmessages(item)} key={item._id} button>
                    <ListItemText
                      key={item._id}
                      primary={
                        <div>
                          <span >
                            <b className={item.status == 'unread' ? classes.bb : null}>{item.sender} </b>
                          </span>
                          
                          <span style={{maxWidth:"30ch",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}} >
                            {item.messages.length > 0 ? item.messages[item.messages.length - 1].body : null}
                          </span>
                          <span style={{float:"right",fontWeight:"900"}} >{">>"}</span>
                        </div>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Contact;

import React, { useState , useEffect} from 'react'
import {Row, Col, Card, CardBody, CardHeader, CardTitle, Label, Input } from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {createCategory} from "../../../Redux/Actions/Mask/Mask"
import {Button} from "@material-ui/core"
import {Redirect} from 'react-router-dom'
import { useHistory } from "react-router-dom";
const CreateCategory = ({ createCategory,redirectTemp})=> {

    let history = useHistory();
    const [name, setName] = useState("")

   const submit  = () => {
       
          let tempdata={"name":name}
         createCategory(tempdata);
         history.push("/template");

   }
if(redirectTemp)
      {
        return <Redirect to={`/templates`} />;
      }
      return  <Row className="justify-content-center ">
              <Col md="10">
                  <Card>

                      <CardHeader>
                      <CardTitle style={{fontSize: "0.95rem"}}>Create Category</CardTitle>
                      </CardHeader>
                   
                     <Row>
                        <Col md="12">
                            <CardBody>
                                <Input placeholder="Category Name" onChange={(e)=>{setName(e.target.value)}} Label="Category Name" type="text"  name="categoryname" ></Input>
                            </CardBody>
                        </Col>
                    </Row>
                   
                    <Row className="p-4">
                        <Col md="12">
                            <Button variant="outlined" onClick={submit}  color="primary">Create</Button>
                        </Col>
                    </Row>
                </Card>
                    
              </Col>
          </Row>
    
  
}
CreateCategory.propTypes = {
     createCategory: PropTypes.func.isRequired
  };
  const mapStateTOProps = (state)=> ({
    redirectTemp: state.bulk.redirectTemp


})
export default connect(mapStateTOProps, { createCategory})(CreateCategory)
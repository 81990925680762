import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Input, Button,Form } from 'reactstrap';
import { connect } from 'react-redux';
import { sendmessage, getnumbers } from '../../../Redux/Actions/Twilio';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import GetCOmpany from '../../../components/GetCompany/getCompany';
import { useHistory} from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from '../../../components/footer';
import Label from 'reactstrap/lib/Label';

import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';

import UserMessage from '../User/messages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormGroup from 'reactstrap/lib/FormGroup';
const axios = require('axios');


const SendText = ({ sendmessage, getnumbers, numbers }) => {
  const [num, setnum] = useState('');
  const [err, setErr] = useState(false);
  const [body, setbod] = useState('');
  const [bodyerr, setbodyerr] = useState(false);
  const [from, setfrom] = useState('');
  const [search, setSearch] = useState('');
  const [inboundcheck, setinboundcheck] = useState(false)
  const [outboundcheck, setoutboundcheck] = useState(false)
  const history = useHistory();
  // console.log({inbound:inboundcheck})
  // console.log({outbound:outboundcheck})
  const handleInboundChange=()=>{
    if(!inboundcheck&&!outboundcheck){
      setinboundcheck(true)
    }else{
      setinboundcheck(!inboundcheck)
      setoutboundcheck(!outboundcheck)
    }
    
  }
  const handleOutboundChange=()=>{
    if(!inboundcheck&&!outboundcheck){
setoutboundcheck(true)
    }else{
      setoutboundcheck(!outboundcheck)
    setinboundcheck(!inboundcheck)
    }
    
  }
useEffect(() => {
  const param = new URLSearchParams(window.location.search);
  const f = param.get('from');

  const g = param.get('to');
  setnum(g);
  setfrom(f)
}, [])
  useEffect(() => {
  const a = numbers.map(item => {
    if (item.activate) {
      return item.number;
    }
  });

  setfrom(a[0]);
}, [numbers]);
useEffect(() => {
  if(from=="buynumber")
  {{history.push('/buynumber');}}
}, [from])
  useEffect(() => {
    const comp = localStorage.getItem('comp');
    if (comp) {
      getnumbers(comp);
    } else {
      getnumbers();
    }
  }, [getnumbers]);
  const bodydata = e => {
    if (e.target.value.length > 1590) {
      setbodyerr(true);
      toast.error('Message Body length cannot be greater than 1590');
    } else {
      setbod(e.target.value);
      setbodyerr(false);
    }
  };
//  var newnum=()=>{numbers&& numbers[0] && numbers[0].hasOwnProperty("number").return(numbers[0])}
//  console.log("new number is"+newnum)
  const submit = () => {
    if (num === '' || num.length > 13 || from === '') {
      setErr(true);
    } else {
      setErr(false);
      sendmessage(num, body, from);
      setnum('');
      setbod('');
    }
  };

  async function geoCallerIDNumber(value,code){
    if(value && code.dialCode)
    {
    console.log("value.toString().length=",value.toString().length)
    var countryCode=code.dialCode
    console.log("countryCode=",countryCode)
    var codeLength=code.dialCode.toString().length
    console.log("codeLength=",codeLength)
    var areaCode=""
    console.log("value.toString().length=",value.toString().length)
  if(value.toString().length>7)
  {
   areaCode=value.toString().substring(codeLength, codeLength+3);
   
  const getAreaOrCountryNumberGeoCallerID = await axios({
    method: "get",
    url: process.env.REACT_APP_PORT + `/api/me/getAreaOrCountryNumberGeoCallerID?areaCode=${areaCode}&countryCode=${countryCode}`,
    headers: {
      'x-auth-token': localStorage.getItem('token')
    }
  });
  
  var areaNumber=getAreaOrCountryNumberGeoCallerID.data.data.areaNumber
  var countryNumber=getAreaOrCountryNumberGeoCallerID.data.data.countryNumber
  var defaultNumber=getAreaOrCountryNumberGeoCallerID.data.data.defaultNumber
  var geocallerid=getAreaOrCountryNumberGeoCallerID.data.data.geocallerid
  if(geocallerid==true && areaNumber!="")
  {
  console.log("areaNumber=",areaNumber)
  setfrom(areaNumber)
  }else if(geocallerid==true && countryNumber!="")
  {
    console.log("countryNumber=",countryNumber)
    setfrom(countryNumber)
  }else if(geocallerid==true && defaultNumber!="")
  {
    console.log("defaultNumber=",defaultNumber)
    setfrom(defaultNumber)
  }else{
    console.log("nothing to do")
  }
  }
    }
  
  }


  return (
    <>
    <Row><Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
      <Card >
        <Background image={corner2} className="bg-card" />
        <Row>
           
        <Col sm={{ size: 5}} style={{marginLeft:"24px"}} >
          <Label>Select Company</Label>
        
          <GetCOmpany />
        </Col>

        <Col sm={{ size: 5, offset: 1 }}>
          <Label>From Number</Label>
          <Input
            style={{ fontSize: '0.95em' }}
            type="select"
            id="exampleSelect"
            onChange={e => setfrom(e.target.value)}
            name="from"
          >
            {/* <option value="">Select From Number</option> */}
            {/* {numbers != undefined&& numbers[0] && numbers[0].hasOwnProperty("number")&& console.log("numbers is values "+from)} */}

            {numbers != undefined&&numbers&& numbers[0] && numbers[0].hasOwnProperty("number")&&<option selected={numbers[0].number} value={numbers[0].number}>
                        {numbers[0].name ? numbers[0].name : numbers[0].number}
                      </option>}
            
            {numbers != undefined&&numbers&&numbers.slice(1).map(item => {
              if (item.activate) {
                return (
                  <option key={item._id} value={item.number} >
                    {item.name ? item.name : item.number}
                  </option>
                );
              }
            })}
            {numbers==""&&(<option value="">Select Number</option>)}
        {numbers==""&&<option value="buynumber">Buy Number</option>}
            
          </Input>
          {numbers!=""&&<small id="emailHelp" class="form-text text-muted">
            Your message will be sent from this number
          </small>}
          {numbers!=""&&<small id="emailHelp" class="form-text text-muted">
            {from != null && from != '' && `Message id will be ${from}`}
          </small>}
        </Col>
        </Row>
      </Card>
      </Col></Row>


      
      
      <Row className="mt-3">
      <Col lg={12} className="pr-lg-2 mb-3 mb-lg-0">
          <Card>
              <CardBody>
      <Row>
          <Col md="12">
            <Label>To Number</Label>
            {/* <PhoneInput onChange={phone => setnum(phone)} value={num} /> */}
            <PhoneInput    onChange={async (value,country,e, formattedValue) => {geoCallerIDNumber(value,country); setnum(value)}}  value={num}/>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Label>Text</Label>
            <Input
              style={{ fontSize: '0.95em' }}
              placeholder="Enter a Text"
              type="textarea"
              onChange={bodydata}
              name="body"
              value={body}
            />
            <small id="emailHelp" class="form-text text-muted">
              Enter your text here
            </small>
          </Col>
        </Row>
        <Row >
          <Col md="6" xs="5">
            <Button onClick={submit} disabled={bodyerr} color="primary" size="sm" className="mr-2">
              Send Message
            </Button>
          </Col>
          </Row>
          </CardBody>
          </Card>
      </Col>
      
 </Row>

 <Row className="mt-3">
      <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
      <Card>
      <Background image={corner2} className="bg-card" />
           <Row className="pl-3 pr-3 pt-3 pb-1">
              <Col>
                <Label>Message logs</Label>
              </Col>
              <Col xs="4">
                    <Form style={{display:"flex"}}>
                    <FormGroup check style={{marginLeft:"90px"}}>
            <Label check>
              <Input type="radio" name="radio1" onChange={()=>handleInboundChange()} />{' '}
              Inbound
            </Label>
          </FormGroup>
          <FormGroup check style={{marginLeft:"90px"}}>
            <Label check>
              <Input type="radio" name="radio1"onChange={()=>handleOutboundChange()} />{' '}
              Outbound
            </Label>
          </FormGroup>
             </Form>  </Col> 
              <Col xs="3.5" className="mb-2">
              <div className="search-box" style={{float:"Right"}}>
                <Form >
                  <Input
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    className="rounded-pill search-input"
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                  />
                  <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
                </Form>
                </div>
              </Col>
              </Row>
              
              </Card>
      </Col>
      </Row>

      <Row className="mt-2">
          <Col xs={12} className="col-xxl-8 pr-xl-2 mb-3">
            <UserMessage search={search} outboundcheck={outboundcheck} inboundcheck={inboundcheck}/>
            </Col>
            </Row>
        {/* <Row>
          <Col md="12">
            <Label>To Number</Label>
            <PhoneInput onChange={phone => setnum(phone)} value={num} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Label>Text</Label>
            <Input
              style={{ fontSize: '0.95em' }}
              placeholder="Enter a Text"
              type="textarea"
              onChange={bodydata}
              name="body"
              value={body}
            />
            <small id="emailHelp" class="form-text text-muted">
              Enter your text here
            </small>
          </Col>
        </Row>
        <Row className="p-4">
          <Col md="6" xs="5">
            <Button onClick={submit} disabled={bodyerr} color="primary" size="sm" className="mr-2">
              Send Message
            </Button>
          </Col> */}
          {/* <Col md="6" xs="5">
                            <Link className="btn btn-danger" to="/pages/masstexting">Mass Texting</Link>
                        </Col> */}
        {/* </Row> */}
        {/* <Row className="p-4">
                       <Col xs="6">
                        <Footer/>
                       </Col>
                    </Row> */}
      
    </>
  );
};
SendText.propTypes = {
  sendmessage: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  numbers: state.Twilio.numbers
});
export default connect(
  mapStateToProps,
  { sendmessage, getnumbers }
)(SendText);

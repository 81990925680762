import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader } from 'reactstrap';

import { connect } from 'react-redux';
// import { callmonitor } from '../../../Redux/Actions/USER/user';
import Background from '../../../components/common/Background';
import corner1 from '../../../assets/img/illustrations/corner-1.png';
import corner2 from '../../../assets/img/illustrations/corner-2.png';
import corner3 from '../../../assets/img/illustrations/corner-3.png';
import {socket} from '../Main/socket'
const getImage = color => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};
const Dialmonitor = ({ style }) => {
  const [calldata, setcalldata] = useState([])
 
  useEffect(() => {
    socket.emit('callmonitor', localStorage.getItem('email'));
   
   
  }, [socket])
  useEffect(()=>{
    socket.on("calldata", data=>{
      console.log("calldata",data)
      if(data.CallStatus && data.CallStatus =='in-progress')
      {
        let ar = []
        ar.push(data)
        
        console.log("ar", ar)
        setcalldata(ar)
      }else{
        setcalldata([])
      }
    })
  }, [])
  
  if (calldata?.length > 0) {
    return (
      <Card style={style} >
        <Background image={corner1} className="bg-card" />
        <CardBody>
          <Table responsive bordered striped>
            <thead>
              <tr>
                <th>#</th>
                <th>From</th>
                <th>To</th>
                <th>Direction</th>
              </tr>
            </thead>
            <tbody>
              {calldata.length > 0  &&
                calldata.map((item, index) => (
                  <tr key={item._id}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.From}</td>
                    <td>{item.To}</td>
                    <td>{item.Direction}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Card style={style}>
                <Background image={corner3} className="bg-card" />

        <CardBody>
          <h5>No active call</h5>
        </CardBody>
      </Card>
    );
  }
};

const mapStateToProps = state => ({
  messages: state.bulk.callmonitor
});
export default connect(
  mapStateToProps,
  
)(Dialmonitor);

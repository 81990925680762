import {
  faPhone,
  faSms,
  faHistory,
  faWallet,
  faListOl,
  faComment,
  faFax,
  faPhoneAlt,
  faBell
} from '@fortawesome/free-solid-svg-icons';
const isAuthenticated = localStorage.getItem('token') ? true : false;
let data = [];
export const homeRoutes = {
  name: 'Dashboard',
  to: '/home',
  exact: true,
  icon: 'home',
  exact: false,
  strict: false
};

export const authenticationRoutes = {
  name: 'Authentication',
  to: '/authentication',
  icon: 'lock',
  children: [
    { to: '/authentication/login', name: 'Login' },
    { to: '/authentication/register', name: 'Register' },
    { to: '/authentication/forget-password', name: 'Forgot password' }
  ]
};

export const ECommerceRoutes = {
  name: 'Phone Number',
  to: '/e-commerce',
  icon: faListOl,
  children: [{ to: '/me/numbers', name: 'Tracking' }, { to: '/buynumber', name: 'Buy Number' }]
};
// export const callmonitor = {
//   name: 'Call Monitor',
//   to: '/callmonitor',
//   icon: faListOl
// };
//our
export const componentRoutes = {
  icon: 'puzzle-piece',
  to: '/getcompany',
  name: 'Company'
};

export const CallService = {
  name: 'Dialer',
  to: '/pages/call',
  icon: faPhone
};
export const MessageService = {
  name: 'Message Service',
  to: '/pages/chat',
  icon: faSms
};
// export const pluginRoutes = {
//   name: 'Logs',
//   to: '/plugins',
//   icon: faHistory,
//   children: [
//     { to: '/logs/message', name: 'Message' },
//     {
//       to: '/logs/voice',
//       name: 'Voices'
//     },
//     // {
//     //   to: '/logs/fax',
//     //   name: 'Fax'
//     // }
//   ]
// };

export const utilityRoutes = {
  name: 'Settings',
  to: '/utilities',
  icon: 'cog',
  children: [
    { to: '/account/api', name: 'Setting' },
    { to: '/form', name: 'Form Builder' },
    { to: '/callflow', name: 'Call Flow' },
    { to: '/hub', name: 'A2P 10 DLC' }
    // {to: "/dnclist", name: "DNC"}
    // { to: '/dnclist', name: 'DNC List' }
    // { to: '/optout', exact: true, name: 'Optout' },
    // { to: '/config', name: 'Config' }
  ]
};
export const Payment = {
  name: 'Payment',
  to: '/pages',
  icon: faWallet,
  children: [{ to: '/home/upgradeplans', name: 'Plans' }, { to: '/home/card', name: 'Billing' }]
};
// export const pageRoutes = {
//   icon: faFax,
//   to: '/pages/fax',
//   name: 'Fax'
// };
export const Chat = {
  to: '/inbox',
  name: 'Conversation',
  icon: faComment,
  exact: false,
  strict: false
};
// export const multiline = {
//   icon: faPhoneAlt,
//   to: '/multilinedialer',
//   name: 'Multiline Dialer'
// };
export const notification = {
  name: '',
  icon: faBell,
  to: '/notification'
};
export const BulkMessage = {
  icon: 'comments',
  to: '/market',
  name: 'Bulk Messaging',
  children: [
    { to: '/pages/campaigns', name: 'Campaigns', exact: false, strict: false },
    { to: '/pages/prospects', name: 'Prospects' },
    { to: '/template', name: 'Templates' }
  ]
};

if (isAuthenticated) {
  data = [
    homeRoutes,
    CallService,
    MessageService,
    BulkMessage,
    // pageRoutes,
    // multiline,
    Chat,
    Payment,
    ECommerceRoutes,
    componentRoutes,
    utilityRoutes
    // pluginRoutes,
    // callmonitor
  ];
} else {
  data = [authenticationRoutes];
}
export default data;

export default [
  // {
  //   type: 'Trial for 14 days',
  //   ribbon:true,
  //   description: '',
  //   click: false,
  //   price: {
  //     month: 0,
  //     year: 0
  //   },
  //   button: {
  //     text: 'Checkout',
  //     color: 'outline-primary'
  //   },
  //   featureTitle: 'Track team projects with free:',
    
  //   features: [
  //     { title: '$0.011 Inbound SMS' },
  //     { title: '$0.011 Outbound SMS' },
  //     {
  //       title: '$0.0195/min Call',
  //       badge: {
  //         text: 'New',
  //         color: 'soft-success'
  //       }
  //     },
  //     { title: ' $0.005/page Fax' },
  //     { title: 'Multiline dialing' },
  //     {title: "Easy  SMS/Call Tracking"},
  //     {title:"Easy Payments"},
  //     {title:"Mass texting"},
  //     {title:"Multiple companies"},
  //      {title:"24/7 Support"}
  //   ]
  // },
  {
    type: 'Premium',
    ribbon:false,
    description: '',
    click: false,
    price: {
      month: 34.99,
      year: 34.99
    },
    button: {
      text: 'Checkout',
      color: 'outline-primary'
    },
    featureTitle: 'Track team projects with free:',
    
    features: [
      {title:'45 days free trial',
    badge:{
      text:'Limited time offer',
      color: 'soft-success'
    }},
      { title: 'Free $15 Credits on Signup' },
      { title: '$0.011 Inbound SMS' },
      { title: '$0.011 Outbound SMS' },
      { title: '$3/number/month' },
      {
        title: '$0.0195/min Call',
        badge: {
          text: 'New',
          color: 'soft-success'
        }
      },
      { title: ' $0.005/page Fax' },
      { title: 'Multiline dialing' },
      {title: "Easy  SMS/Call Tracking"},
      {title:"Easy Payments"},
      {title:"Mass texting"},
      {title:"Multiple companies"},
      {title:"Messages upto 3000 per day"},
       {title:"24/7 Support"}
    ],
    backgroundColor: 'rgba(115, 225, 236, 0.18)',
    plan_id: "price_1Jjj2aGVMLEvSdQH0sXc4MRe",//live
    // plan_id: "price_1JX3J5GVMLEvSdQH3TMObFj7"//test

  },
  {
    type: 'Premium plus',
    ribbon:false,
    description: '',
    click: false,
    price: {
      month: 44.99,
      year: 44.99
    },
    button: {
      text: 'Checkout',
      color: 'outline-primary'
    },
    featureTitle: 'Track team projects with free:',
    
    features: [
      {title:'45 days free trial',
      badge:{
        text:'Limited time offer',
        color: 'soft-success'
      }},
      { title: 'Free $15 Credits on Signup' },
      { title: '$0.011 Inbound SMS' },
      { title: '$0.011 Outbound SMS' },
      { title: '$3/number/month' },
      {
        title: '$0.0195/min Call',
        badge: {
          text: 'New',
          color: 'soft-success'
        }
      },
      { title: ' $0.005/page Fax' },
      { title: 'Multiline dialing' },
      {title: "Easy  SMS/Call Tracking"},
      {title:"Easy Payments"},
      {title:"Mass texting"},
      {title:"Multiple companies"},
      {title:"3000+ Messages per day"},
       {title:"24/7 Support"}
    ],
    plan_id:"price_1Jjj7KGVMLEvSdQHq9ovpKMr"
    // plan_id:"price_1JZVf3GVMLEvSdQHaeNNYpcX"
  },
  
  {
    type: 'ENTERPRISE',
    description: '',
    click: true,
    backgroundColor: 'rgba(115, 225, 236, 0.18)',
    price: {
      month: 0,
      year: 0
    },
    button: {
      text: 'Contact us',
      color: 'primary'
    },
    featureTitle: 'Customized system build on top of our API.:',
    features: [
      { title: 'Dedicated System Manager' },
      { title: 'Have an idea to share?' },
      { title: '24/7 Support' },
    ],

  },
];

import React, { useState , useEffect} from 'react'
import {Row, Col, Card, CardBody, Button, Input, Label} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {getCampaigns, sendBulkMessage} from "../../../Redux/Actions/Mask/Mask"
import  {getnumbers} from "../../../Redux/Actions/Twilio"
import { toast } from 'react-toastify'
class  TEXTINGMASS extends React.Component  {
    state = {
        body: "",
        campaign: "",
        from:"",
        err: false

    }
    componentDidMount(){
        this.props.getnumbers()
        this.props.getCampaigns()
    }
 onChange = e =>
 {   
     if(e.target.name == "body")
     {  
         if(e.target.value.length > 1590)
         {
                this.setState({err: true})
                toast.error("Message Body length cannot be greater than 1590")
         }
         else{
            this.setState({err: false})
         this.setState({[e.target.name]: e.target.value})
         }
     }
     else {
                 this.setState({[e.target.name]: e.target.value})
     }
    
 }
 submit = () => {
     const {body,
        campaign,
        from} = this.state
      if(body === "" || campaign === "")
        {

            toast.error("All Fields are  required")
        }
        else {
           this.props.sendBulkMessage(from, body, campaign)
        } 
 }
  render() 
  
  {
      return <Row className="justify-content-center ">
              <Col md="10">
                  <Card>
                  
                    <Row>
                        <Col md="12">
                            <CardBody>
                                <Label>Campaign</Label>
                                <Input  style={{ fontSize: "0.95em"}} type="select" id="exampleSelect" onChange={this.onChange} name="campaign">
                                       <option value="">Select Campaign</option>
                                   {this.props.campaigns.map(item=>(
                                      <option key={item._id}  value={item._id}>{item.name}</option>
                                   ))}
                                </Input>
                            </CardBody>
                        </Col>
                    </Row>
                   <Row>
                        <Col md="12">
                            <CardBody>
                                <Label>Text</Label>
                                <Input placeholder="Enter a Text"  type="textarea" onChange={this.onChange} name="body" ></Input>
                                <small id="emailHelp" class="form-text text-muted">Enter your Text here</small> 
                            </CardBody>
                        </Col>
                    </Row>
        
                    <Row className="p-4">
                        <Col md="12">
                            <Button onClick={this.submit} disabled={this.state.err} color="success">Send Message</Button>
                        </Col>
                    </Row>
                </Card>
                    
              </Col>
          </Row>
         
  }
  
  
}
TEXTINGMASS.propTypes = {
    
     getCampaigns: PropTypes.func.isRequired,
     getnumbers:PropTypes.func.isRequired,
     sendBulkMessage: PropTypes.func.isRequired
  };
const mapStateTOProps = (state)=> ({
    campaigns: state.bulk.campaigns,
    numbers: state.Twilio.numbers
})
export default connect(mapStateTOProps, {sendBulkMessage, getnumbers, getCampaigns})(TEXTINGMASS)
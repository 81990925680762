import Axios from 'axios';
import {GET_NOTES} from '../type'
import { toast } from "react-toastify"




export const createNotes = (id,note) => async (dispatch) => {
    try{
        const head = {    headers: {
              "x-auth-token": localStorage.getItem("token"),
          }}
      const res = await  Axios.post(process.env.REACT_APP_PORT+"/api/notes/createNote",{notes:note,id:id},head)
       console.log("Get prospect")
       toast.success("Note Created Successfully")
       dispatch(getNotes(id))
    }
    catch(e)
    {
        console.log(e)
        toast.error(e.response.data.error)
    }
  
};


export const getNotes = (id) => async (dispatch) => {
    try{
        const head = {    headers: {
              "x-auth-token": localStorage.getItem("token"),
          }}
      const res = await  Axios.get(process.env.REACT_APP_PORT+"/api/notes/getNotes/"+id,head)
       console.log("Get note",res.data.data)

      dispatch({
          type: GET_NOTES,
          payload: res.data.data
      })
    }
    catch(e)
    {
        console.log(e)
    }
  
};


export const updateNotes = (id,notes,pros_id) => async (dispatch) => {
    try{
        const head = {    headers: {
              "x-auth-token": localStorage.getItem("token"),
          }}
      const res = await  Axios.put(process.env.REACT_APP_PORT+"/api/notes/updateNote/"+id,{"notes":notes},head)
       console.log("Updatet note")
      toast.success("Note Updated Successfully")
      dispatch(getNotes(pros_id))
    }
    catch(e)
    {
        console.log(e)
        toast.error(e.response.data.error)
    }
  
};


export const deleteNotes = (id,pros_id) => async (dispatch) => {
    try{
        const head = {    headers: {
              "x-auth-token": localStorage.getItem("token"),
          }}
      const res = await  Axios.delete(process.env.REACT_APP_PORT+"/api/notes/deleteNote/"+id,head)
       console.log("Updatet note")
      toast.error("Note Deleted")
      dispatch(getNotes(pros_id))
    }
    catch(e)
    {
        console.log(e)
        toast.error(e.response.data.error)
    }
  
};
import Axios from 'axios';

import {
  GET_UPLOADS,
  COUNT_ALL_PROSPECTS,
  SHOW_LOADER,
  GET_COUNTS_STATS,
  GET_SCHEDULE,
  GET_CONFIG,
  ONE_OPT_OUT,
  GET_OPTOUT,
  GET_LEAD_STAGE,
  GET_METRICS,
  SEND_MESSAGE,
  COUNT_PROSPECTS,
  GET_BATCH,
  GET_CLIENT_DATA,
  GET_QRS,
  GET_QR,
  REDIRECT_TEMP,
  GET_CATEGORIES,
  GET_CATEGORY,
  MASK_TEXTING_UPLOAD,
  GET_PROSPECT,
  GET_PROSPECTS,
  COUNT_CONTACT,
  GET_CONTACTS,
  GET_MULTI_CONTACTS,
  DONE,
  GET_CAMPAIGNS,
  GET_CAMPAIGN,
  GET_LOGS,
  GET_MULTILINE_TOKEN,
  GET_NOTIFICATION,
  GET_TEMPLATES,
  GET_TEMPLATE,
  GET_QuickReplies,
  GET_QuickReply,
  COUNT_NOTIFICATION,
  SKIP_TRACING,
  CAMP_PROGRESS,
} from '../type';

import { getnumbers } from '../Twilio';

import { toast } from 'react-toastify';

import { socket } from '../../../view/pages/Main/socket';
import { useHistory } from "react-router-dom";
export const updateleadstage = (id, lead) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      lead: lead
    };
    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/prospectlead/' + id, data, head);
    console.log(res);
    toast.success('Prospect Updated Successfully');
    dispatch(getProspect(id));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const countallProspects = data => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/countprospects', data, head);
    dispatch({
      type: COUNT_ALL_PROSPECTS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const getLeadStage = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/getLeadStage', head);
    console.log('Get lead saga');
    dispatch({
      type: GET_LEAD_STAGE,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const filterprospects = (id, filter, name) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      filter: filter,
      name: name
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/filterprospects/' + id, data, head);
    console.log(res);
    dispatch(getProspectsbycamp(id));
    dispatch(countProspects(id));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getUploads = campaign_id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullUploads/' + campaign_id, head);
    dispatch({
      type: GET_UPLOADS,
      payload: res.data.data
    });
    // console.log('res.data.data', res.data.data);
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getmetrics = id => async dispatch => {
  try {
    const config = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullBatches/' + id, config);

    dispatch({
      type: GET_METRICS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getProspects = (offset, company) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(
      process.env.REACT_APP_PORT + '/api/campaign/pullContacts?offset=' + offset + '&company=' + company,
      head
    );

    dispatch({
      type: GET_PROSPECTS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const searchProspectsByLS = data => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/getProspect?searchByLS=' + data, head);
    dispatch({
      type: GET_PROSPECTS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const searchProspects = sea => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/getProspect?search=' + sea, head);
    let data = {
      search: sea
    };
    dispatch(countallProspects(data));
    //  dispatch({
    //   type: COUNT_ALL_PROSPECTS,
    //   payload: res.data.data.length
    // });
    dispatch({
      type: GET_PROSPECTS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const updatedncstatus = (id, dnc, condition) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      dnc
    };
    console.log('dfdf', data);
    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/campaign/updatednc/' + id, data, head);

    dnc ? toast.success('DNC Marked') : toast.success('DNC Removed');
    if (condition) dispatch(getProspect(id));
    else dispatch(getProspects(0));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getProspect = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/campaign/pullContact/' + id, head);
    console.log('rea', res.data);
    dispatch({
      type: GET_PROSPECT,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const uploadfile = data => async dispatch => {
  try {
    
    console.log("data",data)
    if(!data)
    {
      toast.error('Please try again');
    }
    else{
      toast.success('Processing file');
      const formdata = new FormData();
    formdata.append('file', data);

    const config = {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
        'content-type': 'multipart/form-data'
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/me/upload', formdata, config);
    
console.log("res",res.data.data)
    dispatch({
      type: MASK_TEXTING_UPLOAD,
      payload: res.data.data
    });
    }
    
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const checkskiptracing = () => async dispatch => {
  try {
    
    const res = await Axios({
      method: 'POST',
      url: process.env.REACT_APP_PORT + '/api/me/checkskiptracing',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
console.log("resdats",res.data)
    dispatch({
      type: SKIP_TRACING,
      payload: res.data
    });
    return res.data
    
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const createCampaign = (data, camp_id) => async dispatch => {
  try {
    // let history = useHistory();
    console.log('campid', camp_id);
    const { firstname, lastname, address, state, city, phone, zip, street, email, country, company } = data;
    console.log(company, 'mask');
    const formdata = new FormData();
    formdata.append('file', data.uploadfile);
    formdata.append('campaignName', data.name);

    const config = {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
        'content-type': 'multipart/form-data'
      }
    };
    const res = await Axios.post(
      process.env.REACT_APP_PORT + '/api/campaign/createCampaign?id=' + camp_id + '&company=' + company,
      formdata,
      config
    );
    const id = res.data.data.new_Campaign._id;
    const value = res.data.data.value;

    // let page='/pages/campaigns/'+id
    // history.push(page);
    // this.props.history.push(page);
    // socket.emit('campid', id);
    // console.log("camp is ",res.data.data.new_Campaign)
    // console.log("vaule is ",value.length-1)
    localStorage.setItem('campiddddd', id);
    localStorage.setItem('cdatastate', value.length-1);
    dispatch({
      type: CAMP_PROGRESS,
      payload: value.length-1
    });
    const contact = {
      id: id,
      firstname,
      lastname,
      address,
      state,
      city,
      phone,
      zip,
      street,
      email,
      value,
      country
    };
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const con = await Axios.post(
      process.env.REACT_APP_PORT + '/api/campaign/createContact?company=' + company,
      contact,
      head
    );
    console.log("con data",con.data.data)
    // console.log("response received from server",con.data.data)
    if(con.data.data=="Please check notification"){
      toast.error(`Maximum limit of contacts per file is 5000`)
      socket.emit('notification', localStorage.getItem('email'));
    }
    else if(con.data.data!="done"&&con.data.data!="Please check notification"&&con.data.data!="Kindly Check Sample File"){
      toast.info(`Prospects saved ${con.data.data}`)
      toast.error("Please check funds")
    }
    else
    {
      toast.success('Campaign Created');
    }
    dispatch(getCampaigns());
    //    console.log(res.data)
    dispatch({
      type: DONE,
      payload: null
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getCampaigns = company => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/campaign/pullCampaigns?company=' + company, head);

    dispatch({
      type: GET_CAMPAIGNS,
      payload: res.data.data
    });
  } catch (e) {
    console.log('eroor', e);
    toast.error(e.response.data.error);
  }
};
export const getCampaign = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/campaign/' + id, head);

    dispatch({
      type: GET_CAMPAIGN,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const downloadcampaign = (id, name, pathname) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    if(!id)
    {toast.error("Sorry, the file is not available")}
    else{
    const res = await Axios.get(
      process.env.REACT_APP_PORT + '/api/campaign/getS3File/' + id + '?name=' + name + '&pathurl=' + pathname,
      head
    );
    toast.success('File generated ,Check notifications tab.');
    socket.emit('notification', localStorage.getItem('email'));
    dispatch(getCampaigns());}
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const download = (id, filename) => async dispatch => {
  try {
    console.log('filename', filename);
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/campaign/getS3File/' + id, head);

    let blob;
    if (filename.match(/\.(xlsx)$/)) {
      blob = new Blob([res.data], { type: 'xlsx' });
    } else {
      blob = new Blob([res.data], { type: 'csv' });
    }

    let url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
  } catch (e) {
    console.log(e);
    // toast.error(e.response.data.error);
  }
};
export const sendBulkMessage = (from, body, campaign) => async dispatch => {
  try {
    toast.success('Processing Please wait!!');
    const data = {
      from: from,
      body: body,
      campaign: campaign
    };
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/campaign/bulksms', data, head);
    toast.success('Messages Sent!');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const getLogs = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/campaign/pullCampaigns', head);

    dispatch({
      type: GET_LOGS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const multilinedialer = (from, campaign) => async dispatch => {
  try {
    const data = {
      from: from,
      campaign: campaign
    };
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/campaign/multilinedial', data, head);
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const gettoken = () => async dispatch => {
  try {
    //   const test = await Axios.get('https://jsonplaceholder.typicode.com/todos/1')
    //   console.log("test",test)
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/campaign/token',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    const token = res.data.token;
    dispatch({
      type: GET_MULTILINE_TOKEN,
      payload: token
    });
    dispatch(getnumbers());
  } catch (e) {
    console.log(e);
  }
};

export const getMultidialerContacts = (id, limit) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(
      process.env.REACT_APP_PORT + '/api/campaign/pullMultidialerContacts?campaignId=' + id + '&limit=' + limit,

      head
    );
    const contact = res.data.data;
    console.log('eror', res.data.data);
    if (res.data.data.length === 0) {
      toast.error('No prospects in the campaign ,calling can not be done');
    }
    // dispatch({
    //   type: GET_MULTI_CONTACTS,
    //   payload: res.data.data
    // });
    dispatch({
      type: GET_CONTACTS,
      payload: res.data.data
    });
    // dispatch(getcountcontact(id, check));
    return res.data.data;
  } catch (e) {
    console.log('contact what error', e);
    toast.error(e?.response?.data?.error);
  }
};

export const pullnew = (id, limit) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(
      process.env.REACT_APP_PORT + '/api/campaign/pullnew?campaignId=' + id + '&limit=' + limit,

      head
    );
    const contact = res.data.data;
    console.log('eror', res.data.data.length);
    if (res.data.data.length === 0) {
      toast.error('No prospects in the campaign ,calling can not be done');
    }
    // dispatch({
    //   type: GET_MULTI_CONTACTS,
    //   payload: res.data.data
    // });
    dispatch({
      type: GET_CONTACTS,
      payload: res.data.data
    });
  } catch (e) {
    console.log('contact what error', e);
    toast.error(e?.response?.data?.error);
  }
};

export const getContacts = (id, offset, limit, check) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(
      process.env.REACT_APP_PORT +
        '/api/campaign/pullContacts?campaignId=' +
        id +
        '&offset=' +
        offset +
        '&limit=' +
        limit +
        '&check=' +
        check,
      head
    );
    const contact = res.data.data;
    console.log('eror', res.data.data.length);
    if (res.data.data.length === 0) {
      toast.error('No prospects in the campaign ,calling can not be done');
    }
    dispatch({
      type: GET_CONTACTS,
      payload: res.data.data
    });
    if (id) {
      dispatch(getcountcontact(id, check));
    }

    return res.data.data;
  } catch (e) {
    console.log('contact what error', e);
    toast.error(e?.response?.data?.error);
  }
};

export const getContactss = (id, offset, limit, tost) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(
      process.env.REACT_APP_PORT +
        '/api/campaign/multicontact?campaignId=' +
        id +
        '&offset=' +
        offset +
        '&limit=' +
        limit,
      head
    );
    // console.log("eror",res.data.data)

    dispatch({
      type: GET_CONTACTS,
      payload: res.data.data.exist
    });
    dispatch({
      type: COUNT_CONTACT,
      payload: res.data.data.count
    });
    if (res.data.data.count === 0 && tost === 'tost') {
      toast.error('No prospects in the campaign ,calling can not be done');
    }
    return res.data.data.exist;
  } catch (e) {
    console.log('contact what error', e);
    toast.error(e?.response?.data?.error);
  }
};
export const getcountcontact = (id, status) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(
      process.env.REACT_APP_PORT + '/api/campaign/countcontact?campaignId=' + id + '&check=' + status,
      head
    );

    dispatch({
      type: COUNT_CONTACT,
      payload: res.data.data
    });
  } catch (e) {
    console.log('count contact', e);
    toast.error(e.response.data.error);
  }
};

export const updatestatus = (id, offset, limit) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const status = localStorage.getItem('check');
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/campaign/updatestatus?contactId=' + id, head);
    const camp = res.data.data.campaign;
    //   dispatch(getcountcontact(camp))
    dispatch(getContactss(camp, offset, limit, status));
  } catch (e) {
    console.log('update status', e);
    toast.error(e.response.data.error);
  }
};

export const callstatus = (id, offset, limit) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const status = localStorage.getItem('check');
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/campaign/callstatus?contactId=' + id, head);
    const camp = res.data.data.campaign;

    //   dispatch(getcountcontact(camp))
    dispatch(getContactss(camp, offset, limit, status));
  } catch (e) {
    console.log('callstatus', e);
    toast.error(e.response.data.error);
  }
};
export const campaigndeleted = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/campaign/deleteCampaign/' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    dispatch(getCampaigns());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const refreshtoken = (id, offset, limit) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/campaign/refresh?campaignId=' + id, head);
    localStorage.removeItem('check');
    dispatch(getcountcontact(id));
    dispatch(getContactss(id, 0, limit, false));
  } catch (e) {
    console.log('refreshtoken', e);
    toast.error(e.response.data.error);
  }
};

//LeadSherpa
export const getmarkets = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullmarkets', head);
    // dispatch({
    //     type: GET_marketS,
    //     payload: res.data.data
    // })
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const createmarket = data => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/createmarket', data, head);
    toast.success('market Created!');
    dispatch({
      type: REDIRECT_TEMP
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const getmarket = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullmarket/' + id, head);

    //   dispatch({
    //     type: GET_market,
    //     payload: res.data.data
    // })
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const updatemarket = (data, id) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/updatemarket/' + id, data, head);

    toast.success('market Saved!');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const deletemarket = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/market/deletemarket/' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    dispatch(getmarkets());
    toast.success('market Deleted!');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const getCategories = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullCategories', head);
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const createCategory = data => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/createCategory', data, head);
    toast.success('Category Created!');
    //   dispatch({
    //     type: REDIRECT_TEMP
    //   })
    dispatch(getCategories());
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const deleteCategory = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/market/deleteCategory/' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    toast.success('Deleted!');
    dispatch(getCategories());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const getCategory = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullCategory/' + id, head);

    dispatch({
      type: GET_CATEGORY,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const updateCategory = (data, id) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/updateCategory/' + id, data, head);

    toast.success('Category Updated!');
    dispatch(getCategories());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getQRS = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullQRS', head);

    dispatch({
      type: GET_QRS,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const createQR = data => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/createQR', data, head);
    toast.success('Quick Reply Added!');
    dispatch(getQRS());
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const getQR = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullQR/' + id, head);
    dispatch({
      type: GET_QR,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const updateQR = (data, id) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/updateQR/' + id, data, head);
    dispatch(getQRS());
    toast.success('Quick Reply Saved!');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const deleteQR = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/market/deleteQR/' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    toast.error('Quick Reply Removed!');
    dispatch(getQRS());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
//quick reply conversation
export const getQuickReplies = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullQuickReplies', head);

    dispatch({
      type: GET_QuickReplies,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const createQuickReply = data => async dispatch => {
  try {
    let body = {
      body: data
    };

    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/createQuickReply', body, head);
    toast.success('Quick Reply Added!');
    dispatch(getQuickReplies());
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const getQuickReply = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullQuickReply/' + id, head);
    dispatch({
      type: GET_QuickReply,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const updateQuickReply = (data, id) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/updateQuickReply/' + id, data, head);
    dispatch(getQuickReplies());
    toast.error('Quick Reply Saved!');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const deleteQuickReply = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/market/deleteQuickReply/' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });
    toast.error('Quick Reply Removed!');
    dispatch(getQuickReplies());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

//Working
//Working
export const countTempletebyCat = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/counttemp/' + id, head);
    // dispatch({
    //     type: COUNT_market,
    //     payload: res.data.data
    // })
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getmarketsbycat = (id, camp, count, procount, bulkid) => async dispatch => {
  try {
    console.log('ree', bulkid);
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(
      process.env.REACT_APP_PORT +
        '/api/market/catmapdata/' +
        id +
        '/' +
        camp +
        '/' +
        count +
        '/' +
        procount +
        '/' +
        bulkid,
      head
    );
    dispatch({
      type: GET_CLIENT_DATA,
      payload: res.data.data
    });
  } catch (e) {
    dispatch({
      type: GET_CLIENT_DATA,
      payload: null
    });
    toast.error(e.response.data.error);
  }
};
export const autosendcamp = (selectedcategory, id, counttemp, pct, bulkid, market) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      cat: selectedcategory,
      camp: id,
      counttemp,
      pct,
      bulkid,
      market
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/autosend/', data, head);
    toast.success('Processing');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const countProspects = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/countProspect/' + id, head);
    console.log('Get prospect');
    dispatch({
      type: COUNT_PROSPECTS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const getProspectsbycamp = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/getProspectbycamp/' + id, head);
    // console.log('Get prospect', res.data.data);
    dispatch({
      type: GET_PROSPECTS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getbatch = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/batch/' + id, head);
    console.log(res.data.data);
    dispatch({
      type: GET_BATCH,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const sendPropects = (
  id,
  camp,
  count,
  procount,
  bulkid,
  body,
  phone,
  from,
  prosid,
  market
) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    let resp;
    console.log('rrr', procount);
    if (procount - 1 == 0 || (procount - 1) % 100 == 0) {
      resp = await Axios.get(process.env.REACT_APP_PORT + '/api/market/batch/' + camp, head);

      dispatch({
        type: GET_BATCH,
        payload: resp.data.data
      });
    }
    const data = {
      body: body,
      phone: phone,
      prs: prosid,
      camp: camp,
      procount: procount,
      bulk: bulkid == null ? resp.data.data.uid : bulkid,
      market: market,
      from: from
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/sendmessage/', data, head);

    dispatch(updatestatuspros(prosid, id, camp, count, procount, data.bulk));
    dispatch({
      type: SEND_MESSAGE
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const updatestatuspros = (id, cat, camp, count, dd, bulk) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    let resp;
    if (bulk == null) {
      resp = await Axios.get(process.env.REACT_APP_PORT + '/api/market/batch/' + camp, head);
      dispatch({
        type: GET_BATCH,
        payload: resp.data.data
      });
    }
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/updatestatus/' + id + '/' + camp, head);
    const data = {
      camp: camp,
      procount: dd,
      bulk: bulk == null ? resp.data.data.uid : bulk
    };
    console.log('rrr', data);
    const rr = await Axios.put(process.env.REACT_APP_PORT + '/api/market/updatecamp/', data, head);
    dispatch(getmarketsbycat(cat, camp, count, dd, bulk));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const scheduler = data => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/schedule', data, head);
    toast.success('Saved');

    dispatch(getscheduler(data.id));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const getscheduler = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/getschedule/' + id, head);
    dispatch({
      type: GET_SCHEDULE,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const startscheduler = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/schedulenextdate/' + id, head);

    toast.success('Started');
    dispatch(getscheduler(id));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const pausescheduler = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/schedulepause/' + id, head);

    toast.success('Paused');
    dispatch(getscheduler(id));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const cancelauto = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/campaign/autocancel/' + id, head);

    toast.error('Cancelled');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const cancellcheduler = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/cancelled/' + id, {}, head);

    toast.success('Cancelled');
    dispatch(getscheduler(id));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const updatescheduler = (data, id) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/schedule/' + id, data, head);
    dispatch(getscheduler(id));
    toast.success('Updated');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

//phase 2
export const exportcampprosdata = (data, id) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.get(
      process.env.REACT_APP_PORT + '/api/market/exportcamppros/' + id + '?lead=' + data,
      head
    );

    toast.success('File is Processing, Please See Notification');
    socket.emit('notification', localStorage.getItem('email'));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const filterdatabyprospects = (filter, offset) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      filter,
      offset
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/filter', data, head);
    dispatch(countallProspects(data));

    dispatch({
      type: GET_PROSPECTS,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

/* --------------------------------VIDEO 2---------------------------------------------*/

export const wrongnumprospects = (id, condition) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/wrongnumber/' + id, null, head);
    console.log(res);
    if (condition) dispatch(getProspects(0));
    else dispatch(getProspect(id));

    toast.success('Marked as Wrong Number');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const verifiedprospect = (id, condition) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/verifiednumber/' + id, null, head);
    if (condition) dispatch(getProspects(0));
    else dispatch(getProspect(id));
    toast.success('Marked as verified');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const removewrongnumprospects = (id, condition) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/removewrongnumber/' + id, null, head);
    if (condition) dispatch(getProspects(0));
    else dispatch(getProspect(id));
    toast.success('Removed from Wrong Number');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const pushToWH = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {};
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/pushToWH/' + id, data, head);

    toast.success('Sent Successfully!');
    dispatch(getProspect(id));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const removeverifiedprospect = (id, condition) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/removeverifiednumber/' + id, null, head);
    console.log(res);
    if (condition) dispatch(getProspects(0));
    else dispatch(getProspect(id));

    toast.success('Removed  from Verified Number');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const isqualified = (id, condition) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/isqualified/' + id, null, head);
    console.log(res);
    if (condition) dispatch(getProspects(0));
    else dispatch(getProspect(id));
    toast.success('Marked as Qualified');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const wasqualified = (id, condition) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/wasqualified/' + id, null, head);
    console.log(res);
    if (condition) dispatch(getProspects(0));
    else dispatch(getProspect(id));
    toast.success('Removed from qualified');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const isPriority = (id, condition) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/isPriority/' + id, null, head);
    if (condition) dispatch(getProspects(0));
    else dispatch(getProspect(id));
    toast.success('Marked as Priority');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const wasPriority = (id, condition) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/market/wasPriority/' + id, null, head);
    if (condition) dispatch(getProspects(0));
    else dispatch(getProspect(id));
    toast.success('Removed from Priority');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

/* --------------------------------VIDEO 2  END---------------------------------------------*/

export const downloadfile = (id, name) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/downloadnotfic/' + id, head, {
      responseType: 'blob'
    });

    console.log(res.data.path);
    const url = window.URL.createObjectURL(new Blob([res.data.path]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name + '.csv');
    link.click();
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const getNotification = offset => async dispatch => {
  try {
    console.log({ offset });
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/notification?offset=' + offset, head);
    dispatch({
      type: GET_NOTIFICATION,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const countnotification = offset => async dispatch => {
  try {
    const res = await Axios({
      method: 'GET',
      url: process.env.REACT_APP_PORT + '/api/market/notification/count',
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    const token = res.data.data;

    dispatch({
      type: COUNT_NOTIFICATION,
      payload: token
    });
  } catch (e) {
    toast.error(e.response.data.error);
    console.log(e);
  }
};

export const exportprosdata = (data, pathname) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/exportpros?pathurl=' + pathname, data, head);
    // console.log("responce",res)
    if (res.data.data === 'done') {
      toast.success('File is Processing, Please See Notification');
    } else {
      toast.error('No prospect available in this filter');
    }
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const resetSchedulerCount = id => async dispatch => {
  console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx', id);
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/reset/' + id, {}, head);
    // console.log("responce",res)
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

//DNC bulk

export const DncReset = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    console.log('reset');

    await Axios.get(process.env.REACT_APP_PORT + '/api/market/dncreset/', head);
    toast.error('DNC List Reset');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const DncExport = pathname => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    console.log('export');

    await Axios.get(process.env.REACT_APP_PORT + '/api/market/importdnc?pathurl=' + pathname, head);
    toast.success('DNC List Exported');
    socket.emit('notification', localStorage.getItem('email'));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const DNCFunctinalities = (data, action) => async dispatch => {
  try {
    dispatch({
      type: SHOW_LOADER,
      payload: true
    });
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
        'content-type': 'multipart/form-data'
      }
    };
    const formdata = new FormData();
    formdata.append('file', data);
    formdata.append('action', action);
    await Axios.post(process.env.REACT_APP_PORT + '/api/market/importdnc/', formdata, head);
    toast.success('DNC List Imported');
    dispatch({
      type: SHOW_LOADER,
      payload: false
    });
    dispatch(getOptOut());
  } catch (e) {
    dispatch({
      type: SHOW_LOADER,
      payload: false
    });
    console.log(e.response.data.error);
    toast.error(e.response.data.error);
    dispatch(getOptOut());
    // toast.error('Choose the file ');
  }
};

export const deleteprospects = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      id
    };

    await Axios.post(process.env.REACT_APP_PORT + '/api/market/deleteprospects/', data, head);
    toast.error('Deleted');
    dispatch(getProspects(0));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

// opt out

export const deleteopt = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.delete(process.env.REACT_APP_PORT + '/api/market/optout/' + id, head);
    dispatch(getOptOut());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const oneoptout = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/optout/' + id, head);
    dispatch({
      type: ONE_OPT_OUT,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const updateoptout = (data, id) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    await Axios.put(process.env.REACT_APP_PORT + '/api/market/optout/' + id, data, head);
    toast.success('updated!');
    dispatch(oneoptout(id));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const saveoptout = data => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };

    await Axios.post(process.env.REACT_APP_PORT + '/api/market/saveoptout', data, head);
    toast.success('saved!');
    dispatch(getOptOut());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const getOptOut = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullOptOut', head);
    console.log('optout res', res.data);
    dispatch({
      type: GET_OPTOUT,
      payload: res.data.data
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const createTemplate = data => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/createTemplate', data, head);
    toast.success('Template Created!');
    //   dispatch({
    //     type: REDIRECT_TEMP
    //   })
    dispatch(getTemplates());
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
export const getTemplates = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullTemplates', head);
    dispatch({
      type: GET_TEMPLATES,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getTemplate = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullTemplate/' + id, head);

    dispatch({
      type: GET_TEMPLATE,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const deleteTemplate = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios({
      method: 'post',
      url: process.env.REACT_APP_PORT + '/api/market/deleteTemplate/' + id,
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    });

    dispatch(getTemplates());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const updateTemplate = (data, id) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/updateTemplate/' + id, data, head);

    toast.success('Template Saved!');
    dispatch(getTemplates());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};
export const getTemplatesbycat = (id, camp, count, procount, bulkid) => async dispatch => {
  try {
    console.log('ree', bulkid);
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(
      process.env.REACT_APP_PORT +
        '/api/market/catmapdata/' +
        id +
        '/' +
        camp +
        '/' +
        count +
        '/' +
        procount +
        '/' +
        bulkid,
      head
    );
    dispatch({
      type: GET_CLIENT_DATA,
      payload: res.data.data
    });
  } catch (e) {
    dispatch({
      type: GET_CLIENT_DATA,
      payload: null
    });
    toast.success(e.response.data.error);
  }
};

export const getCONFIG = () => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/pullPTCRM', head);
    dispatch({
      type: GET_CONFIG,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const savefilterdata = (filter, dnc) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      filter: filter,
      dncfilter: dnc
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/filtersave', data, head);
    dispatch(getCONFIG());

    toast.success('Saved!');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const countstats = id => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const res = await Axios.get(process.env.REACT_APP_PORT + '/api/market/countstat/' + id, head);
    console.log('result', res.data.data);
    dispatch({
      type: GET_COUNTS_STATS,
      payload: res.data.data
    });
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const downloadstats = (id, cat, pathname) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    // toast.success("Processing")
    // debuggers
    const res = await Axios.get(
      process.env.REACT_APP_PORT + '/api/market/downloadstats/' + id + '?query=' + cat + '&pathurl=' + pathname,
      head
    );
    socket.emit('notification', localStorage.getItem('email'));

    toast.success('Process completed Please see the notification');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const saveprospectdata = pdata => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      data: pdata
    };
    const res = await Axios.post(process.env.REACT_APP_PORT + '/api/market/saveprospect', data, head);
    dispatch(getCONFIG());

    toast.success('Saved!');
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

export const updatecampname = (id, name, delay) => async dispatch => {
  try {
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      id: id,
      name: name,
      delay: delay
    };
    const res = await Axios.put(process.env.REACT_APP_PORT + '/api/campaign/editcampname/', data, head);
    toast.success('Processing');
    dispatch(getCampaigns());
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

//notification read unread
export const readunread = user => async dispatch => {
  try {
    if (!user) return;
    const head = {
      headers: {
        'x-auth-token': localStorage.getItem('token')
      }
    };
    const data = {
      user: user
    };
    console.log('export', data);

    await Axios.post(process.env.REACT_APP_PORT + '/api/market/readunread/', data, head);
    //    toast.success("read unread updated")
    socket.emit('notification', localStorage.getItem('email'));
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.error);
  }
};

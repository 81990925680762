import React from 'react'

import {Row, Col, Card, Button} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import { releasenumber} from '../../../Redux/Actions/Twilio'
class Buynumber extends React.Component{
    buyNumber = (text, number)=> {
       if(text === "Yes")
       {
        this.props.releasenumber(number)
           this.props.toggle()
       }
       else{
        
        this.props.toggle()
       }
     }
    render(){
        return <Row className="justify-content-center ">
        <Col md="12">
            <p style={{textAlign:"center",fontSize:"21px"}}>Do you really want to delete this number?</p>
            <p style={{textAlign:"center",fontSize:"19px"}}>This process cannot be undone.</p>
            <div style={{float:"right"}}>
                      <Button  onClick={()=>this.buyNumber('No')}  color="danger">NO</Button>

                      <Button onClick={()=>this.buyNumber('Yes', this.props.number)} className="ml-2"  color="success">Yes</Button>
            </div>
           {/* <Card>
                <Row className="p-4">
                  <Col md="12">
                      
                  </Col>
              </Row>
              <Row className="p-4">
                  <Col md="6">
                  </Col>
                  <Col md="6" >
                  </Col>
              </Row>
              <br/>
          </Card> */}
              
        </Col>
    </Row>
    }
}
Buynumber.propTypes = {
   releasenumber:  PropTypes.func.isRequired
  };

export default connect(null,{releasenumber})(Buynumber)

import { setAlert } from '../alert/alert';
import Axios from 'axios'
import {gettoken as getdeviceReady} from '../Twilio'
import { SIGN_UP, LOG_IN, LOGOUT, RESET_PASSWORD, PASSWORD_CHANGE, GET_TOKENS} from './type';
import {gettoken} from "../Twilio"
import {HEARD_ABOUT} from "../type"
import { toast } from 'react-toastify';
export const heard = ( ) => async (dispatch) => {
  try{
    const res = await  Axios({
        method: "get",
        url:process.env.REACT_APP_PORT+"/api/me/heard",  
      })
     
    dispatch({
        type: HEARD_ABOUT,
        payload: res.data.data
    })
  }
  catch(e)
  {
      toast.error(e.response.data.error);
  }

};
export const register = (formData ) => async (dispatch) => {
    try{
      var data = encodeURI(formData.email)

      console.log(formData,data,"kfslfbbld")
      // const res = await  Axios({
      //   method: "POST",
      //   url:"https://api.twilio.com/2010-04-01/Accounts.json", 
      //   data: formData 
      // })
      const res = await  Axios({
          method: "POST",
          url:process.env.REACT_APP_PORT+"/api/auth/signup", 
          data: formData 
        })
        const token = res.data.user.token
      //  await  Axios({
      //     method: "POST",
      //     url:process.env.REACT_APP_PORT+"/api/payment/createCustomer", 
      //     headers: {
      //       'x-auth-token': token
      //     },
      //     data: formData 
      //   })    
      const email = res.data.user.uname
      localStorage.setItem('token', token);
      localStorage.setItem('email', email);
      
      toast.success(`Successfully registered as ${formData.name}`);
      dispatch(gettoken())
      dispatch({
          type: SIGN_UP,
          payload: token
      })
    }
    catch(e)
    {
        toast.error(e.response.data.error);
    }
  
};
export const login = (formData ) => async (dispatch) => {
  try{
    const res = await  Axios({
        method: "POST",
        url:process.env.REACT_APP_PORT+"/api/auth/login", 
        data: formData 
      })
    const token = res.data.user.token
    const email = res.data.user.name
    localStorage.setItem('token', token);
    localStorage.setItem('email', email);
    await dispatch(gettoken())
    dispatch({
        type: LOG_IN,
        payload: token
    })
    
    toast.success(`Logged in as ${email}`);
  }
  catch(e)
  {
    toast.error(e.response.data.error);
  }

};
export const verify = (rtoken ) => async (dispatch) => {
  try{
    const res = await  Axios({
        method: "get",
        url:process.env.REACT_APP_PORT+"/api/auth/verifytoken/"+rtoken,
        headers: {
          "x-auth-token": rtoken
        }
      })
    const token = res.data.user
    
    dispatch({
        type: RESET_PASSWORD,
        payload: token
    })
  }
  catch(e)
  {
      toast.error(e.response.data.error);
  }

};
export const reserpassword = (password,rtoken ) => async (dispatch) => {
  try{
    const res = await  Axios({
        method: "post",
        url:process.env.REACT_APP_PORT+"/api/auth/change/"+rtoken,
        headers: {
          "x-auth-token": rtoken
        },
        data:{
          password:password
        }
      })
    const token = res.data.user
    
    dispatch({
        type: PASSWORD_CHANGE,
        payload: token
    })
    
    toast.success('Login with your new password');
  }
  catch(e)
  {
      toast.error(e.response.data.error);
  }

};
export const sendEmail = (email ) => async (dispatch) => {
  try{
    const res = await  Axios({
        method: "post",
        url:process.env.REACT_APP_PORT+"/api/auth/sendtoken",
        data: {
          email: email
        }
        
      })
    const token = res.data.user
    
    toast.success(`An email is sent to ${email} with password reset link`);
  }
  catch(e)
  {
      toast.error(e.response.data.error);
  }

};
export const logout = () =>  async (dispatch)=> {
  localStorage.removeItem("token")
  localStorage.removeItem('email');
  localStorage.removeItem("state");
  dispatch({
    type: LOGOUT
  })
};
export const getapitoken = () => async (dispatch) => {
  try{
    const res = await  Axios({
        method: "get",
        url:process.env.REACT_APP_PORT+"/api/auth/user/token",
        headers: {
          'x-auth-token': localStorage.getItem("token")
        }
      })
      const token = res.data.user
      
    dispatch({
        type: GET_TOKENS,
        payload: token
    })
}
  catch(e)
  {
      toast.error(e.response.data.error);
  }

};
export const refreshtoken = () => async (dispatch) => {
  try{
    const res = await  Axios({
        method: "get",
        url:process.env.REACT_APP_PORT+"/api/auth/user/refreshtoken",
        headers: {
          'x-auth-token': localStorage.getItem("token")
        }
      })
      const token = res.data.user.token
      
      localStorage.setItem('token', token);
      dispatch({
          type: LOG_IN,
          payload: token
      })
      dispatch(gettoken())
      dispatch(getdeviceReady())
      dispatch(setAlert("successfully updated", "success"))
}
  catch(e)
  {
      toast.error(e.response.data.error);
  }

};